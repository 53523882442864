<script>
import { rules } from '../libs/validator'

export default {
  name: 'ValidateRules',
  validations: {
    firstName: rules.firstNameRule,
    lastName: rules.lastNameRule,
    email: rules.emailRule,
    phone: rules.phoneRule,
    login: rules.loginRule,
    password: rules.passwordRule,

    confirmPassword: rules.confirmPasswordRule,
    promo: rules.promo,
    country: rules.countryRule
  }
}
</script>
