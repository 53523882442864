<template>
  <div class="col-lg-6">
    <div class="card h-100">
      <div class="card-inner p-0 d-flex flex-column">
        <div class="card-inner-item flex-fill">
          <h3 class="card-title">{{ $t('partnership-subtitle-2') }}</h3>
          <p>
            {{ $t('news-text-7') }}
          </p>
          <div class="user-links mt-4">
            <div class="user-links-item">
              <div class="card card_inside">
                <div class="card-inner d-flex alig-items-center">
                  <div class="user-links-text flex-fill">
                    {{ $t('partnership-text-36') }}
                    <span class="text-accent"> {{ promoLink.label }}</span>
                  </div>
                  <button
                    class="button__clipboard"
                    type="button"
                    @click="copyCode('link')"
                    data-message="Copied!"
                    data-value="http://ca.coinfuze.c...entIndex=1&amp;promo"
                  >
                    <span
                      class="tooltip__content"
                      :class="{ show: linkFlag }"
                      >{{ $t('copied') }}</span
                    >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1.23223 1.23223C1.70107 0.763392 2.33696 0.5 3 0.5H11C11.663 0.5 12.2989 0.763392 12.7678 1.23223C13.2366 1.70107 13.5 2.33696 13.5 3V4.5H15C16.3807 4.5 17.5 5.61929 17.5 7V15C17.5 16.3807 16.3807 17.5 15 17.5H7C5.61929 17.5 4.5 16.3807 4.5 15V13.5H3C2.33696 13.5 1.70107 13.2366 1.23223 12.7678C0.763392 12.2989 0.5 11.663 0.5 11V3C0.5 2.33696 0.763392 1.70107 1.23223 1.23223ZM5.5 15C5.5 15.8284 6.17157 16.5 7 16.5H15C15.8284 16.5 16.5 15.8284 16.5 15V7C16.5 6.17157 15.8284 5.5 15 5.5H7C6.17157 5.5 5.5 6.17157 5.5 7V15ZM12.5 4.5H7C5.61929 4.5 4.5 5.61929 4.5 7V12.5H3C2.60218 12.5 2.22064 12.342 1.93934 12.0607C1.65804 11.7794 1.5 11.3978 1.5 11V3C1.5 2.60218 1.65804 2.22064 1.93934 1.93934C2.22064 1.65804 2.60218 1.5 3 1.5H11C11.3978 1.5 11.7794 1.65804 12.0607 1.93934C12.342 2.22064 12.5 2.60218 12.5 3V4.5Z"
                        fill="#625B7A"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="user-links-item">
              <div class="card card_inside">
                <div class="card-inner d-flex alig-items-center">
                  <div class="user-links-text flex-fill">
                    {{ $t('partnership-text-37') }}

                    <span class="text-accent">{{ user.referal_link }}</span>
                  </div>
                  <button
                    @click="copyCode('code')"
                    class="button__clipboard"
                    type="button"
                    data-message="Copied!"
                  >
                    <span
                      class="tooltip__content"
                      :class="{ show: codeFlag }"
                      >{{ $t('copied') }}</span
                    >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1.23223 1.23223C1.70107 0.763392 2.33696 0.5 3 0.5H11C11.663 0.5 12.2989 0.763392 12.7678 1.23223C13.2366 1.70107 13.5 2.33696 13.5 3V4.5H15C16.3807 4.5 17.5 5.61929 17.5 7V15C17.5 16.3807 16.3807 17.5 15 17.5H7C5.61929 17.5 4.5 16.3807 4.5 15V13.5H3C2.33696 13.5 1.70107 13.2366 1.23223 12.7678C0.763392 12.2989 0.5 11.663 0.5 11V3C0.5 2.33696 0.763392 1.70107 1.23223 1.23223ZM5.5 15C5.5 15.8284 6.17157 16.5 7 16.5H15C15.8284 16.5 16.5 15.8284 16.5 15V7C16.5 6.17157 15.8284 5.5 15 5.5H7C6.17157 5.5 5.5 6.17157 5.5 7V15ZM12.5 4.5H7C5.61929 4.5 4.5 5.61929 4.5 7V12.5H3C2.60218 12.5 2.22064 12.342 1.93934 12.0607C1.65804 11.7794 1.5 11.3978 1.5 11V3C1.5 2.60218 1.65804 2.22064 1.93934 1.93934C2.22064 1.65804 2.60218 1.5 3 1.5H11C11.3978 1.5 11.7794 1.65804 12.0607 1.93934C12.342 2.22064 12.5 2.60218 12.5 3V4.5Z"
                        fill="#625B7A"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-inner-item">
          <div class="row gy-base">
            <div class="col-md-6">
              <div class="stat_item">
                <div class="stat_item-title">
                  {{ $t('partnership-text-17') }}
                </div>
                <div class="stat_item-val stat_item-val-large">
                  {{ totalReferralMoney }} USDT
                  <div class="stat_item-icon">
                    <img src="@/assets/images/usdt.svg" alt="coinfuze" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="stat_item">
                <div class="stat_item-title">
                  {{ $t('partnership-text-38') }}
                </div>
                <div class="stat_item-val stat_item-val-large">
                  {{ formatBalance(user.referal_comand_turnover) }} USDT
                  <div class="stat_item-icon">
                    <img src="@/assets/images/usdt.svg" alt="coinfuze" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ReferralBlock',
  data() {
    return {
      linkFlag: false,
      codeFlag: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/getUser',
      transactions: 'user/getPaymentHystory'
    }),

    promoLink() {
      const baseUrl = 'http://ca.coinfuze.com/auth/register?promo='
      let label = 'http://ca.coinfuze.com...&promo=' + this.user.referal_link
      return { value: baseUrl + this.user.referal_link, label: label }
    },
    totalReferralMoney() {
      let totals = this.user.totals_new
      let sum =
        totals.bns_rfrl.amount + totals.rfrl.amount + totals.cmd_bns.amount

      return this.formatBalance(sum)
    }
  },
  methods: {
    flagClick(arg) {
      this[arg] = true
      setTimeout(() => {
        this[arg] = false
      }, 1000)
    },
    copyCode(type) {
      const tempInput = document.createElement('input')
      tempInput.value =
        type === 'code' ? this.user.referal_link : this.promoLink.value
      document.body.appendChild(tempInput)
      tempInput.select()
      document.execCommand('copy')
      document.body.removeChild(tempInput)
      this.flagClick(type === 'code' ? 'codeFlag' : 'linkFlag')
    },
    formatBalance(balance) {
      return new Intl.NumberFormat(this.$i18n.locale).format(balance)
    }
  }
}
</script>
<style lang="css" scoped>
.tooltip__content.show {
  opacity: 1;
  transition: 1s;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.tooltip__content {
  padding-left: 50px;
  right: -20px;
  top: -30px;
  background: #1b2129;
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  color: #fff;
  display: inline-block;
  font-size: 12px;
  padding: 5px 8px;
  position: absolute;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  width: auto;
  opacity: 0;
}
</style>
