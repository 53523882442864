<template>
  <div class="dropdown__content card">
    <div class="card-inner p-0">
      <div
        class="dropdown__content-item"
        v-for="(stat, idx) in statsList"
        :key="`${idx}+${stat.label}`"
      >
        <div class="dropdown__content-info">{{ $t(stat.label) }}</div>
        <div class="dropdown__content-val fw-500">
          {{ stat.value }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'BallanceWindow',
  computed: {
    ...mapGetters({
      locale: 'app/getLocale',
      balance: 'user/getBalance'
    }),
    statsList() {
      return [
        {
          label: 'header-item-1',
          value: this.formatBalance(this.balance.total)
        },
        {
          label: 'header-item-2',
          value: this.formatBalance(this.balance.free)
        },
        {
          label: 'header-item-3',
          value: this.formatBalance(this.balance.active)
        }
      ]
    }
  },
  methods: {
    formatBalance(balance) {
      return new Intl.NumberFormat(this.locale).format(balance) + ' USDT'
    }
  }
}
</script>
