<template>
  <div class="col plan-col">
    <div class="card card_inside plan" :class="`plan-${planNumber.value}`">
      <div class="card-inner p-0">
        <div class="plan-header d-flex align-items-center">
          <div class="plan-header-icon d-flex-center">
            <img
              :src="
                require(`@/assets/images/plan-icon-${planNumber.value}.png`)
              "
              alt="coinfuze"
            />
          </div>
          <div class="plan-header-info flex-fill">
            <div class="plan-header-name">
              {{ $t('plans-text-38') }} <span>{{ planNumber.label }}</span>
            </div>
            <div class="plan-header-status">
              {{ $t('plans-text-41') }}
              <span class="text-accent">{{ $t('plans-text-36') }}</span>
            </div>
          </div>
        </div>
        <div class="plan-content">
          <div class="plan-content-line">
            <div class="plan-content-name">{{ $t('plans-text-14') }}</div>
            <div class="plan-content-val">
              {{ formatDate(plan.start_time) }}
            </div>
          </div>
          <div class="plan-content-line">
            <div class="plan-content-name">{{ $t('plans-text-15') }}</div>
            <div class="plan-content-val">{{ formatDate(plan.end_time) }}</div>
          </div>
          <div class="plan-content-line">
            <div class="plan-content-name">{{ $t('plans-text-16') }}</div>
            <div class="plan-content-val">
              {{ plan.pay_summ.toFixed(2) }}
              <img
                class="plan-content-icon"
                src="@/assets/images/usdt.svg"
                alt="coinfuze"
              />
            </div>
          </div>
          <div class="plan-content-line">
            <div class="plan-content-name">{{ $t('plans-text-17') }}</div>
            <div class="plan-content-val">{{ plan.fin_resalt_percent }}</div>
          </div>
          <div class="plan-content-line">
            <div class="plan-content-name">{{ $t('plans-text-18') }} usdt</div>
            <div class="plan-content-val">{{ plan.fin_resalt_usdt }}</div>
          </div>
          <div class="plan-content-line">
            <div class="plan-content-name">{{ $t('plans-text-20') }}</div>
            <div class="plan-content-val">
              <label class="input-switcher" @click="initReinvest">
                <!-- <input
                  type="checkbox"
                  v-model="reinvestState"
                  @change="reinvestPlan"
                /> -->
                <input type="checkbox" disabled v-model="reinvestState" />
                <span></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <confirmation-modal
      :show="confirmationModal"
      @close="closeConfirmation"
      @confirm="confirmReinvest"
    />
  </div>
</template>
<script>
import ConfirmationModal from '../global/ConfirmationModal.vue'
export default {
  name: 'ActivePlanComponent',
  components: { ConfirmationModal },
  props: {
    plan: {
      require: true,
      type: Object
    }
  },
  data() {
    return {
      loading: false,
      confirmationModal: false,
      reinvestState: this.plan.reinvest
    }
  },
  watch: {
    'plan.reinvest': function (newVal) {
      this.reinvestState = newVal
    }
  },
  computed: {
    planNumber() {
      let val = this.plan.plan.name.slice(-1)
      return { label: `No. ${val}`, value: parseInt(val) }
    },
    imgUrl() {
      return require(`@/assets/images/plan-icon-${this.planNumber.value}.png`)
    }
  },
  methods: {
    closeConfirmation() {
      this.confirmationModal = false
    },
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()
      return `${day}.${month}.${year}`
    },
    confirmReinvest() {
      this.reinvestPlan()
      this.closeConfirmation()
    },
    initReinvest() {
      this.confirmationModal = true
    },
    reinvestPlan() {
      let payload = {
        id: this.plan.id,
        reinvest: !this.reinvestState
      }
      this.loading = true
      this.$store
        .dispatch('user/reinvestPlan', payload)
        .then((response) => this.$showSuccess(response.data.response))
        .catch((error) => this.$showError(error.detail))
        .finally(() => (this.loading = false))
    }
  }
}
</script>
