<template>
  <div class="app__panel-nav-icon d-flex-center">
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.5 1.25C4.94365 1.25 1.25 4.94365 1.25 9.5C1.25 14.0563 4.94365 17.75 9.5 17.75C14.0563 17.75 17.75 14.0563 17.75 9.5C17.75 4.94365 14.0563 1.25 9.5 1.25ZM0.25 9.5C0.25 4.39137 4.39137 0.25 9.5 0.25C14.6086 0.25 18.75 4.39137 18.75 9.5C18.75 14.6086 14.6086 18.75 9.5 18.75C4.39137 18.75 0.25 14.6086 0.25 9.5ZM9.5 5.625C8.80964 5.625 8.25 6.18464 8.25 6.875C8.25 7.15114 8.02614 7.375 7.75 7.375C7.47386 7.375 7.25 7.15114 7.25 6.875C7.25 5.63236 8.25736 4.625 9.5 4.625C10.7426 4.625 11.75 5.63236 11.75 6.875C11.75 7.32217 11.619 7.74028 11.3932 8.09125C11.2468 8.31879 11.0745 8.53766 10.9115 8.74022C10.8822 8.7767 10.8532 8.81259 10.8246 8.84802C10.6891 9.01567 10.5619 9.1731 10.4445 9.33437C10.1567 9.72994 10 10.0564 10 10.375V10.8125C10 11.0886 9.77614 11.3125 9.5 11.3125C9.22386 11.3125 9 11.0886 9 10.8125V10.375C9 9.7271 9.31814 9.18279 9.63594 8.74602C9.76962 8.56229 9.91593 8.38129 10.0517 8.21332C10.0791 8.17943 10.1061 8.14607 10.1324 8.11333C10.2946 7.91178 10.4372 7.72898 10.5523 7.55013C10.6774 7.35569 10.75 7.12459 10.75 6.875C10.75 6.18464 10.1904 5.625 9.5 5.625ZM9.5 12.5C9.77614 12.5 10 12.7239 10 13V13.875C10 14.1511 9.77614 14.375 9.5 14.375C9.22386 14.375 9 14.1511 9 13.875V13C9 12.7239 9.22386 12.5 9.5 12.5Z"
        fill="#57435F"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'FAQMenuIcon'
}
</script>
