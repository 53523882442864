export default {
  'dashboard-text-5-1': 'Beneficio de planes activos',

  'partnership-title': 'Solicitud de asociación con CoinFuze',
  'partnership-form-1': 'ID de su cuenta',
  'partnership-form-2': 'ID de embajador',
  'partnership-form-3': 'Enlace al blog(s):',
  'partnership-form-3-1': 'Instagram, Telegram, Youtube, Twitter, etc.',
  'partnership-form-4': 'Telegram para comunicarse',
  'partnership-form-5-1': 'Acepto',
  'partnership-form-5-2': 'Términos y condiciones (Afiliado)',
  'partnership-form-btn': 'Enviar',
  'partnership-form-footer': 'Información de contacto:',
  'withdrawal-ph-1': 'ID de la billetera',
  'withdrawal-ph-2': 'Cantidad a retirar',
  'confirm-modal-1': 'Сonfirme la acción',
  'confirm-modal-2': 'Confirmar',
  'confirm-modal-3': 'Cancelar',
  copy: 'Copiar',
  delete: 'Borrar',

  download: 'Descargar',
  'modal-p-text-1':
    'Instrucciones detalladas sobre cómo trabajar con Planes de Inversión',
  'modal-p-text-2':
    'Una vez que el depósito se haya acreditado en su cuenta, puede invertir sus fondos en el plan seleccionado.',
  'modal-p-text-3':
    'Siga las instrucciones a continuación para activar su plan de inversión.',
  'modal-p-text-4':
    "En su cuenta personal, vaya a la página de 'Gestión de Planes'. Aquí encontrará información detallada sobre todos los productos de inversión. Esto incluye los períodos de inversión, las comisiones de servicio, así como la rentabilidad del plan.",
  'modal-p-text-5':
    "Para activar el plan deseado, haga clic en el botón 'Seleccionar', ingrese la cantidad de capital inicial con la que desea iniciar el plan y haga clic en el botón 'Activar'.",
  'modal-p-text-6':
    "Con la función de 'Reinversión Automática', puede invertir automáticamente sus fondos por un largo período y obtener beneficios adicionales.",
  'modal-p-text-7':
    'Justo después de lanzar el plan de inversión, puede activar o desactivar la opción con un solo clic.',
  'modal-p-text-8': '¡Felicidades, ha lanzado con éxito un plan de inversión!',
  'modal-p-text-9':
    'Puede cambiar esta configuración a su discreción, incluso después de que haya comenzado el período de ejecución. Si ha activado esta opción, entonces al vencimiento del plazo del plan de inversión, todo el capital, incluidas todas las ganancias, se reinvertirá automáticamente. Si esta opción está desactivada, no se producirá la reinversión automática, y todo el capital con ganancias se acreditará al Saldo Libre.',

  update: 'Actualizar',
  'update-avatar': 'Actualizar tu avatar',
  save: 'Guardar',

  'modal-d-text-1': 'Instrucciones para Depositar Fondos en CoinFuze',
  'modal-d-text-2': "Dirígete a la página de 'Depósito' en tu cuenta.",
  'modal-d-text-3':
    "Para iniciar el proceso de depósito, haz clic en el botón 'Agregar Fondos'.",
  'modal-d-text-4':
    'Se te proporcionará un identificador de billetera único para enviar fondos. Asegúrate de usar este identificador al realizar el pago.',
  'modal-d-text-5':
    'También tienes la opción de utilizar el código QR ubicado a la izquierda del identificador de la billetera. Puedes escanearlo con tu teléfono móvil para introducir los datos más fácilmente.',
  'modal-d-text-6':
    'Después de enviar fondos a la billetera especificada, los fondos se acreditarán automáticamente en tu Balance. Esto suele tardar entre 1 minuto y 2 horas, pero los plazos pueden variar según la blockchain y otros factores.',
  'modal-d-text-7':
    "Los depósitos confirmados se mostrarán en la parte inferior de la página en la pestaña 'Historial de Depósitos'. Aquí puedes hacer un seguimiento de todas tus solicitudes de depósito.",
  'modal-d-text-8':
    'Sigue esta guía para depositar fondos en tu cuenta de CoinFuze de forma fácil y segura. Si tienes alguna pregunta o necesitas ayuda adicional, por favor contacta a nuestro servicio de soporte.',

  'modal-w-text-1':
    'Para iniciar el proceso de retiro, sigue estas instrucciones:',
  'modal-w-text-2':
    "En el menú izquierdo de tu cuenta, ve a la página de 'Retiro'.",
  'modal-w-text-3': 'Ingresa la cantidad de USDT que deseas retirar.',
  'modal-w-text-4':
    'Especifica tu billetera USDT TRC20 a la que deseas recibir los fondos.',
  'modal-w-text-5':
    'Confirma la transacción ingresando el código de confirmación que se enviará a tu correo electrónico, así como el código de contraseña (2FA) para la autenticación de dos factores si lo tienes activado.',
  'modal-w-text-6':
    'Después de la confirmación, tu solicitud de retiro será procesada. Esto suele tardar desde unos minutos hasta 24 horas, dependiendo de la carga del sistema.',
  'modal-w-text-7':
    'Ten en cuenta que el monto mínimo de retiro es de 10 USDT y hay una pequeña tarifa de red. Además, debes asegurarte de que el plazo de tu plan de inversión haya expirado y las ganancias se hayan acreditado en tu Saldo Libre para poder retirar fondos.',

  validation: {
    nameValidFormat: '{attribute} no debe contener dígitos ni símbolos',
    twoCharactersInRow:
      '{attribute} no debe tener más de dos caracteres idénticos seguidos',
    email: 'Formato de correo electrónico no válido',
    required: '{attribute} es obligatorio',
    minValue: 'El valor mínimo de {attribute} no puede ser menor que {min}',
    maxValue: 'El valor máximo de {attribute} no puede ser mayor que {max}',
    minLength: '{attribute} debe tener al menos {min} caracteres',
    maxLength: '{attribute} no debe tener más de {max} caracteres',
    validFormat:
      '{attribute} debe contener la primera letra mayúscula y solo caracteres',
    validPassword:
      '{attribute} debe contener al menos un dígito y una letra mayúscula',
    sameAsPassword: 'Las contraseñas deben ser idénticas',
    numeric: '{attribute} no es válido. Debe ser un dígito',
    minimumValue: 'La cantidad no debe ser inferior a 100 USDT'
  },
  Country: 'País',
  'phone-input-placeholder': 'Ingresa tu número de teléfono',
  'withdrawal-instruction': 'Instrucciones detalladas para retirar fondos',
  'withdrawal-instruction-text-1':
    'Para comenzar el proceso de retiro, siga estas instrucciones:',
  'withdrawal-instruction-text-2':
    "En su panel personal en el menú izquierdo, vaya a la página 'Retiro de fondos'.",
  'withdrawal-instruction-text-3':
    'Ingrese la cantidad de USDT que desea retirar.',
  'withdrawal-instruction-text-4':
    'Especifique su billetera USDT TRC20 a la que desea recibir los fondos.',
  'withdrawal-instruction-text-5':
    'Confirme la transacción ingresando el código de confirmación que se enviará a su correo electrónico, así como el código de contraseña (2FA) de autenticación de dos factores si lo tiene conectado.',
  'withdrawal-instruction-text-6':
    'Después de la confirmación, su solicitud de retiro será procesada. Esto suele llevar desde unos minutos hasta 24 horas, según la carga del sistema.',
  'withdrawal-instruction-text-7':
    'Tenga en cuenta que la cantidad mínima para retirar es de 10 USDT y hay una pequeña tarifa de red. Además, deberá asegurarse de que su plan de inversión haya vencido y las ganancias se hayan acreditado en el saldo libre para poder retirarlas.',
  'plans-instruction-text-1':
    'Una vez que se haya acreditado el depósito en su cuenta, puede invertir sus fondos en el plan elegido.',

  'plans-instruction-text-2':
    'Siga las siguientes instrucciones para activar su plan de inversión.',
  'plans-instruction-text-3':
    "Vaya a la página 'Planes de inversión' en su panel personal. Aquí encontrará información detallada sobre cada plan de inversión. Esto incluye los términos de inversión, comisiones y el rendimiento del plan.",
  'plans-instruction-text-4':
    "Para activar el plan deseado, haga clic en 'Más' e ingrese en el campo 'Capital inicial' la cantidad con la que desea comenzar el plan y haga clic en el botón 'Activar'.",
  'plans-instruction-text-5':
    "Con la función de 'Reinversión automática', puede invertir automáticamente sus fondos a largo plazo y obtener beneficios adicionales.",
  'plans-instruction-text-6':
    'Inmediatamente después de iniciar un plan de inversión, puede activar o desactivar la opción con un solo clic.',
  'plans-instruction-text-7':
    '¡Felicidades, ha lanzado con éxito un plan de inversión!',
  'plans-instruction-text-8':
    'Puede cambiar esta configuración según lo desee, incluso cuando la fecha de vencimiento ya haya comenzado. Si ha activado esta opción, al final del plazo del plan de inversión, todo el capital, incluidas todas las ganancias, se reinvierte automáticamente. Si esta opción está desactivada, no hay reinversión automática y todo el capital con ganancias se acreditará al Saldo Libre.',
  'plans-instruction-text-9':
    'Si tiene la "Reinversión automática" activada, todo el capital y las ganancias del plan se reinvertirán permanentemente después de su vencimiento hasta que desactive esta opción.',
  'plans-instruction-text-10':
    'De esta manera, puede invertir automáticamente su capital a largo plazo con un costo mínimo. Para retirar todo el capital, debe desactivar esta función. Una vez que se desactive la función, todo el capital, incluidos los rendimientos, se acreditará al Saldo Libre al final del plazo del plan de inversión actual. No se cobrarán tarifas adicionales al utilizar esta opción.',
  'plans-instruction-text-11':
    'Toda la información sobre cómo trabajar con Planes de Inversión Activos y Completados se puede encontrar en la sección "Historial de Planes" en la parte inferior de la página.',
  'popup-instruction-title-2': 'Guía paso a paso',
  'popup-instruction-text-1': 'Instrucciones de depósito de CoinFuze',
  'popup-instruction-text-2':
    "Vaya a la página 'Depósito' en su cuenta personal.",
  'popup-instruction-text-3':
    "Para comenzar el proceso de depósito, haga clic en el botón 'Depósito'.",
  'popup-instruction-text-4':
    'Se le proporcionará una identificación única de la billetera para enviar fondos. Asegúrese de utilizar este identificador al realizar un pago.',
  'popup-instruction-text-5':
    'También tiene la opción de utilizar el código QR ubicado a la izquierda de la identificación de la billetera. Puede escanearlo con su teléfono celular para facilitar la entrada de datos.',
  'popup-instruction-text-6':
    'Después de enviar fondos a la billetera especificada, los fondos se acreditarán automáticamente en su Saldo. Esto generalmente toma entre 1 minuto y 2 horas, pero el plazo puede variar según la cadena de bloques y otros factores.',
  'popup-instruction-text-7':
    "Los depósitos confirmados se mostrarán en la parte inferior de la página en la pestaña 'Historial de depósitos'. Aquí podrá hacer un seguimiento de todas sus solicitudes de depósito.",
  'popup-instruction-text-8':
    'Siga estas instrucciones para realizar su depósito en CoinFuze de manera fácil y segura. Si tiene alguna pregunta o necesita ayuda adicional, no dude en ponerse en contacto con nuestro equipo de soporte.',
  error: 'Error',
  'partnership-text-31': 'Número de referencias',
  'partnership-text-30': 'Monto de los depósitos',
  'autorization-tab-1': 'Autorización',
  'autorization-tab-2': 'Registro',
  'autorization-login': 'Inicio de sesión',
  'autorization-username': 'Nombre de usuario/Correo electrónico/Teléfono',
  'autorization-password': 'Contraseña',
  'autorization-forgot': '¿Olvidó su contraseña?',
  'autorization-btn-login': 'Iniciar sesión en la cuenta',
  'autorization-name': 'Nombre',
  'autorization-surname': 'Apellido',
  'autorization-repeat-password': 'Confirmar contraseña',
  'autorization-ypass': 'Su contraseña',
  'autorization-newpass': 'Su contraseña',
  'autorization-email': 'Correo electrónico',
  'autorization-tel': 'Teléfono',
  'autorization-promo': 'Código promocional',
  'autorization-text-0': 'Acepto los',
  'autorization-text-1': 'Términos y condiciones,',
  'autorization-text-2': 'Política de privacidad',
  'autorization-text-3': 'Política AML',
  'autorization-text-4': 'Tengo 18 años o más',
  'autorization-text-5': 'Términos de servicio',
  'autorization-text-6': 'Recuérdame',
  'autorization-text-7': '¿No tienes una cuenta?',
  'autorization-text-8':
    'Ingrese su nombre de usuario y le enviaremos instrucciones a su correo electrónico:',
  'autorization-text-10': '¿Ya tienes una cuenta?',
  'autorization-text-11': 'Iniciar sesión',
  'autorization-btn-register': 'Registro',
  'autorization-btn-cancel': 'Restablecer contraseña',
  'autorization-status-2':
    'El correo electrónico/teléfono especificado no se encuentra.',
  'autorization-status-1': 'Inicio de sesión o contraseña incorrectos',
  'autorization-status-name': 'Nombre incorrecto',
  'autorization-status-surname': 'Apellido incorrecto',
  'autorization-status-password':
    'La contraseña debe tener al menos 7 caracteres y contener al menos 1 letra mayúscula y 1 letra minúscula',
  'autorization-status-repeat-password': 'Las contraseñas no coinciden',
  'autorization-status-email': 'Formato de correo incorrecto',
  'autorization-status-phone': 'Número de teléfono incorrecto',
  'autorization-status-agree-rules': 'Confirmar condiciones',
  'autorization-status-agree-age': 'Confirmar condiciones',
  'validation-1': 'Para registrarse, debe tener más de 18 años',
  'validation-2': 'Para registrarse, debe aceptar los términos y condiciones',
  'header-item-1': 'Saldo total',
  'header-item-2': 'Saldo libre:',
  'header-item-3': 'Saldo activo:',
  'non-message': 'No hay nuevas notificaciones',
  'message-btn': 'Mostrar todas las notificaciones',
  'header-item-4': 'Información personal',
  'header-item-5': 'Invitar amigos',
  'header-item-6': 'Agregar',
  'header-item-7': 'Cerrar sesión',
  'footer-item-2': 'Términos de uso',
  'footer-item-3': 'Política de privacidad',
  'footer-item-1': 'Política AML',
  'sidebar-item-1': 'Tablero',
  'sidebar-item-2': 'Banca',
  'sidebar-item-3': 'Gestión de planes',
  'sidebar-item-4': 'Programa de afiliados',
  'sidebar-item-5': 'Retiros',
  'sidebar-item-6': 'Información personal',
  'sidebar-item-7': 'Noticias',
  'sidebar-item-8': 'Cerrar sesión de la cuenta',
  'dashboard-subtitle-1': 'Cuenta',
  'dashboard-subtitle-2': 'Finanzas',
  'dashboard-subtitle-3': 'Inversiones',
  'dashboard-subtitle-4': 'Programa de Afiliados',
  'dashboard-subtitle-5': 'Plazo',
  'dashboard-subtitle-6': 'Resultado Actual',
  'dashboard-subtitle-7': 'Resultado Financiero',
  'dashboard-text-1': 'Saldo Libre',
  'dashboard-text-2': 'Fondos Activos',
  'dashboard-text-3': 'Verificado',
  'dashboard-text-4': 'Participante desde',
  'dashboard-text-5': 'Planes de Ganancias Totales',
  'dashboard-text-6': 'Ingresos por Referidos',
  'dashboard-text-7': 'Depósito',
  'dashboard-text-8': 'Retiros',
  'dashboard-text-9': 'Ganancias Totales',
  'dashboard-text-10': 'Planes Activos',
  'dashboard-text-10-1': 'Planes Cerrados',
  'dashboard-text-11': 'Planes en Reinversión',
  'dashboard-text-12': 'Número de Socios',
  'dashboard-text-13': 'Socios de 1ª Línea',
  'dashboard-text-14': 'Socios de 2ª Línea',
  'dashboard-text-15': 'Socios de 3ª Línea',
  'dashboard-text-16': 'Período',
  'dashboard-text-17': 'días',
  'dashboard-text-18': 'Aún no hay noticias',
  'dashboard-text-19': 'Actualmente',
  'dashboard-text-20': 'No hay datos suficientes para el gráfico',
  'dashboard-text-21': '- No hay inversiones activas',
  'dashboard-text-22': 'Leer todas las noticias',
  'dashboard-text-22-1': 'Leer todo',
  'dashboard-text-23': 'Plan de Inversión',
  progressLabel: 'Progreso del Plan de Inversión',
  daysLabel: 'Días para Completar',
  'deposit-subtitle-1-1':
    'Antes de realizar un depósito, asegúrese de haber completado el',
  'deposit-subtitle-1-2': 'Registro de tu cuenta.',
  'deposit-subtitle-1-3': 'De lo contrario, no podrás realizar un depósito.',
  'deposit-subtitle-2': 'Gana dinero con CoinFuze',
  'deposit-text-1':
    "Para depositar tu saldo, haz clic en el botón 'Fondear tu cuenta'.",
  'deposit-text-2':
    "Todas tus transacciones de depósito se guardarán en la sección 'Historial' a continuación, donde podrás hacer un seguimiento del estado de tus solicitudes.",
  'deposit-text-3':
    "Cuando una solicitud reciba el estado 'Completada', los fondos se agregarán automáticamente a tu 'Saldo Libre'.",
  'deposit-text-4':
    'En este momento, utilizamos la criptomoneda más estable para nuestro sistema: Tether USDT.',
  'deposit-text-5':
    'Después de realizar un depósito, podrás disfrutar de todos los beneficios de la cooperación con CoinFuze.',
  'deposit-text-6': 'Beneficio mensual promedio: 6.8%*',
  'deposit-text-7': 'Beneficio anual: 98.7%',
  'deposit-text-8': 'Sistema de comercio totalmente automatizado',
  'deposit-text-9':
    'Posibilidad de reinversión y sistema flexible para elegir los términos de inversión',
  'deposit-text-10':
    '*Los datos sobre rentabilidad se toman de las estadísticas abiertas del funcionamiento del algoritmo para 2024.',
  'deposit-text-11': 'Instrucciones detalladas sobre cómo hacer un depósito',
  'deposit-text-12':
    '¿Tienes alguna pregunta sobre los depósitos? Pregúntala en el chat de soporte',

  'deposit-text-13':
    "Para depositar tu saldo, haz clic en el botón 'Fondear tu cuenta'.",
  'deposit-text-14':
    "Todas tus transacciones de depósito se guardarán en la sección 'Historial' a continuación, donde podrás hacer un seguimiento del estado de tus solicitudes.",
  'deposit-text-15':
    "Cuando una solicitud reciba el estado 'Completada', los fondos se agregarán automáticamente a tu 'Saldo Libre'.",
  'deposit-text-16':
    'En este momento, utilizamos la criptomoneda más estable para nuestro sistema: Tether USDT.',
  'deposit-text-17':
    'Después de realizar un depósito, podrás disfrutar de todos los beneficios de cooperación con CoinFuze.',
  'deposit-text-18':
    '¿Tienes alguna pregunta sobre depósitos? Pregúntala en el chat de soporte.',
  'deposit-btn': 'Depositar en la cuenta',
  notification: 'Copiado',
  'deposit-popup-text-1': 'Criptomoneda',
  'deposit-popup-text-2': 'Moneda de depósito',
  'deposit-popup-text-3': 'Tu saldo:',
  'deposit-popup-text-4': 'Red',
  'deposit-popup-text-5': 'Nota:',
  'deposit-popup-text-6-1': 'Enviar solo',
  'deposit-popup-text-6-2':
    'a esta dirección de depósito. Las monedas se acreditarán automáticamente después de',
  'deposit-popup-text-6-3': '6 confirmaciones de red.',
  'deposit-popup-text-6-4':
    'No se admiten direcciones de contrato inteligente (contáctanos).',
  'deposit-popup-text-7': 'Dirección de depósito:',
  'deposit-popup-text-8': 'Copiar',
  history: 'Historial',
  'deposit-history-text-1': 'Fecha de depósito',
  'deposit-history-text-2': 'Método de pago',
  'deposit-history-text-3': 'Monto de depósito',
  'deposit-history-text-4': 'Estado de la transacción',
  'deposit-history-text-5': 'Transacción',
  'deposit-history-text-6': 'No hay transacciones',
  'deposit-history-text-7': 'Mostrado',
  'deposit-history-text-8': 'de',
  'plans-subtitle-1': 'Gestión de inversiones',
  'plans-subtitle-2': 'Primeras inversiones',
  'plans-text-1':
    'En esta sección, puedes crear planes a partir de los planes disponibles o ver los resultados financieros de todos tus planes activos o cerrados.',
  'plans-text-2':
    'Hay seis planes de inversión disponibles para que elijas, cada uno con diferentes términos y condiciones en cuanto al plazo de inversión, rendimientos esperados y tarifas de servicio de Coinfuze.',
  'plans-text-3':
    "Para activar el plan seleccionado, los usuarios deben realizar un depósito mínimo y hacer clic en el botón 'Activar'.",
  'plans-text-4':
    'Instrucciones detalladas sobre cómo trabajar con los planes de inversión.',
  'plans-text-5': 'Preguntas frecuentes sobre la sección de Inversiones.',
  'plans-text-6':
    'Para comenzar a invertir, debes realizar un depósito. Puedes hacerlo en la página de "Depósito" en el menú de la izquierda o en el botón de abajo.',
  'plans-text-7':
    'Después de que se procese tu depósito, activa el plan de inversión seleccionado.',
  'plans-text-8':
    'CoinFuze ofrece una función de reinversión automática. Esto significa que al final del plazo del plan, todas las ganancias del período anterior se transfieren automáticamente al capital inicial del nuevo plan.',
  'plans-text-9': 'Ir a depósito',
  'plans-text-10': 'Activar plan de inversión',
  'plans-text-11': 'Sí',
  'plans-text-12': 'No',
  'plans-text-13': 'Planes activos',
  'plans-text-14': 'Fecha de inicio',
  'plans-text-15': 'Fecha de finalización',
  'plans-text-16': 'Capital inicial',
  'plans-text-17': '% de resultado financiero',
  'plans-text-18': 'Ganancia en',
  'plans-text-19': 'Estado del plan',
  'plans-text-20': 'Reinversión',
  'plans-text-21': 'Guardar',
  'plans-text-22': 'Planes disponibles',
  'plans-text-23': 'Inversión mínima',
  'plans-text-24': 'Plazo',
  'plans-text-25': 'Rendimiento',
  'plans-text-26': 'hasta',
  'plans-text-27': 'Comisión',
  'plans-text-28': 'Ingresa la cantidad de capital inicial:',
  'plans-text-29': 'Activar',
  'plans-text-30': 'Ingresar cantidad mínima',
  'plans-text-31': 'Nombre',
  'plans-text-32': 'Capital inicial',
  'plans-text-33': 'Resultado actual',
  'plans-text-34': 'Ganancia',
  'plans-text-35': 'Comisión',
  'plans-text-36': 'Activo',
  'plans-text-37': 'Completado',
  'plans-text-38': 'Plan de inversión',
  'plans-text-39': 'días',
  'plans-text-40': 'Seleccionar',
  'plans-text-41': 'Estado:',

  'partnership-subtitle-1': 'Programa de Referidos',
  'partnership-subtitle-2': 'Tu enlace de referido',
  'partnership-subtitle-3': 'Referidos Invitados',
  'partnership-subtitle-4': 'Nivel 1 - comisión por depósito y por ganancia',
  'partnership-subtitle-5': 'Nivel 2 - comisión por depósito y por ganancia',
  'partnership-subtitle-6': 'Nivel 3 - comisión por depósito y por ganancia',
  'partnership-text-1': 'Recomiéndanos a tus amigos.',
  'partnership-text-2':
    'Obtén ingresos pasivos a través del programa de referidos.',
  'partnership-text-3': 'Copia tu enlace de referido.',
  'partnership-text-4': 'Envíalo a tus amigos.',
  'partnership-text-5': 'Obtén bonificaciones por sus recargas de depósito.',
  'partnership-text-6': 'Obtén bonificaciones en sus ganancias.',
  'partnership-text-7':
    'Cada miembro de CoinFuze recibe un enlace de referido único y un código promocional para compartir con amigos de todo el mundo. Simplemente copia este enlace y envíalo a quienes desees invitar a CoinFuze.',
  'partnership-text-8': 'TU CÓDIGO PROMOCIONAL',
  'partnership-text-9': 'Comparte tu enlace de afiliado o código promocional.',
  'partnership-text-10': 'Estudia las estadísticas de clics.',
  'partnership-text-11':
    'Mantén un seguimiento de cuántas personas se han registrado a través de tu enlace.',
  'partnership-text-12': 'Observa cómo crecen tus ganancias.',
  'partnership-text-13': 'Total de invitados',
  'partnership-text-14': 'Socios de 1ª línea',
  'partnership-text-15': 'Socios de 2ª línea',
  'partnership-text-16': 'Socios de 3ª línea',
  'partnership-text-17': 'Ganancias totales:',
  'partnership-text-18': 'Buscar',
  'partnership-text-19':
    'Mira quiénes ya se han registrado a través de tu enlace de afiliado y cuántas bonificaciones has recibido. No olvides que tenemos un programa de referidos de tres niveles. Obtienes bonificaciones no solo por quienes has invitado a CoinFuze, sino también por los miembros que tus referidos han invitado.',
  'partnership-text-20': 'Ingresa el nombre de tu referido',
  'partnership-text-21': 'Bonificaciones de depósito',
  'partnership-text-22': 'Bonificaciones de ganancias',
  'partnership-text-23': 'Ingresos de afiliados',
  'partnership-text-24': 'Fecha de inscripción',
  'partnership-text-25': 'No hay tal referido',
  'partnership-text-26': 'Buscar...',
  'partnership-text-27': 'Sin referidos',
  'partnership-text-28': 'Etapa',
  'partnership-text-29': 'Monto de Rotación',
  'partnership-text-32': 'Bonificaciones',
  'partnership-text-33': 'Pasado',
  'partnership-text-34': 'Tu Nivel',
  'partnership-text-35': 'Cerrar',
  'partnership-text-36': 'Enlace de Referido:',
  'partnership-text-37': 'Tu Código de Promoción:',
  'partnership-text-38': 'Rotación Activa del Equipo:',
  'partnership-text-39': 'Comisión por depósito y ganancia:',
  'partnership-text-40': 'Referidos',
  'partnership-text-41': 'Bonificaciones del Equipo',
  'news-text-1': 'Aún no hay noticias.',
  'news-text-2': 'Leer más',
  'news-text-3': 'Materiales promocionales',
  'news-text-4':
    '¡Comparte nuestros materiales promocionales con tus amigos y gana!',
  'news-text-5':
    'Hemos seleccionado materiales promocionales para que los utilices para atraer nuevos miembros de CoinFuze.',
  'news-text-6': '¡Comparte y gana con tus amigos!',
  'news-text-7':
    'Cada miembro de CoinFuze recibe un enlace de referido único y un código promocional para compartir con amigos de todo el mundo. Simplemente copia este enlace y envíalo a quienes desees invitar a CoinFuze.',
  'news-text-8': 'TU CÓDIGO PROMOCIONAL',
  'news-text-9': 'Comparte tu enlace de afiliado o código promocional.',
  'news-text-10': 'Estudia las estadísticas de clics en él.',
  'news-text-11':
    'Lleva un registro de cuántas personas se han registrado a través de tu enlace.',
  'news-text-12': 'Observa cómo crecen tus ganancias.',
  'news-text-13': 'Gana hasta un 6% en bonificaciones de referidos.',
  'withdrawal-text-1': 'Fecha de Retiro',
  'withdrawal-text-2': 'Método de Pago',
  'withdrawal-text-3': 'Monto de Retiro',
  'withdrawal-text-4': 'Necesitas verificar tu identidad para retirar fondos.',
  'withdrawal-text-5':
    'Necesitas completar el proceso de verificación cargando tus documentos de identidad antes de poder retirar.',
  'withdrawal-text-6': 'Verificar',
  'withdrawal-text-7': 'Retirar',
  'withdrawal-text-8':
    'Ingresa el código de confirmación enviado a tu correo electrónico:',
  'withdrawal-text-9': 'Ingresar código',
  'withdrawal-text-10': 'Enviar nuevo código nuevamente a través de:',
  'withdrawal-text-11': 'Verificar código',
  'withdrawal-text-12': 'Reenviar',
  'withdrawal-text-13': 'Verificado',
  'withdrawal-text-14': 'Reenviar nuevo código nuevamente a través de:',
  'withdrawal-text-15': 'Ingresa el código de la aplicación móvil',
  'withdrawal-text-16': 'Ingresa el código de',
  'withdrawal-text-17': 'Información:',
  'withdrawal-text-18':
    'El saldo libre es tu saldo disponible para retiro. Si tienes inversiones activas, no se cuentan porque están bloqueadas hasta que termine la inversión.',
  'withdrawal-text-19': 'Retiro de Fondos',
  'withdrawal-text-19-1': 'Disponible para retiro:',
  'withdrawal-text-19-2': 'Comenzar Retiros',
  'withdrawal-text-20':
    'En esta página, puedes retirar fondos a tu billetera de criptomonedas. Sigue las indicaciones para hacer el retiro de la forma más rápida y conveniente posible.',
  'withdrawal-text-21':
    'Los retiros, al igual que los depósitos, están disponibles en criptomonedas.',
  'withdrawal-text-22':
    'El retiro puede tomar algo de tiempo, ya que las transacciones en la red de criptomonedas se realizan a diferentes velocidades.',
  'withdrawal-text-23':
    'Las solicitudes de retiro se aceptan y procesan automáticamente en poco tiempo.',
  'withdrawal-text-24': 'Comenzar retiro',
  'withdrawal-text-24-1': 'Instrucciones detalladas para el retiro',
  'withdrawal-text-25': 'Aumenta la rentabilidad de tus inversiones.',
  'withdrawal-text-26':
    'Puedes obtener aún más ganancias reinvirtiendo tus fondos.',
  'withdrawal-text-27': 'Reinvierte tu depósito y las ganancias que obtengas.',
  'withdrawal-text-28':
    'Comienza el período de inversión con un gran capital inicial.',
  'withdrawal-text-29': 'Observa cómo aumentan tus ingresos diariamente.',
  'withdrawal-text-30': 'Reinvierte ahora',
  'withdrawal-text-31': 'Elige la billetera adecuada para el retiro.',
  'withdrawal-text-32':
    'Para retirar fondos a tu billetera, agrégala a tu cuenta y crea una solicitud de retiro.',
  'withdrawal-text-33': 'Retirar a esta dirección',
  'withdrawal-text-34': 'Enviar solicitud',
  'withdrawal-text-35': 'Información:',
  'withdrawal-text-36':
    'El saldo mostrado es tu saldo disponible, no tus fondos totales. Si tienes inversiones activas, no se cuentan porque están bloqueadas hasta el final del período de inversión.',
  'withdrawal-text-37': 'Monto de Retiro',
  'personal-text-1': 'Datos personales',
  'personal-text-2': 'Autenticación de dos factores',
  'personal-text-3': 'Contraseña',
  'personal-text-4': 'Verificación',
  'personal-text-5': 'Tu Nombre',
  'personal-text-6': 'Ingresa un nombre correcto',
  'personal-text-7': 'Tu Apellido',
  'personal-text-8': 'Ingresa un apellido correcto',
  'personal-text-9': 'Cancelar',
  'personal-text-10': 'Guardar',
  'personal-text-11': 'Activar/desactivar',
  'personal-text-12':
    'La Autenticación de Dos Factores (2FA) por correo electrónico está habilitada por defecto para todas las cuentas y no puede desactivarse.',
  'personal-text-13':
    'Para cambiar tu dirección de correo electrónico en CoinFuze, debes ponerte en contacto con el soporte. Una vez que el soporte haya procesado tu solicitud, recibirás una notificación de que tu dirección se ha cambiado con éxito a una nueva.',
  'personal-text-14':
    'Es importante recordar que, para evitar fraudes y proteger tus datos, el soporte puede solicitar información o documentos adicionales para verificar tu identidad.',
  'personal-text-15':
    '2FA está desactivado. Recomendamos a todos los clientes activar la autenticación de dos factores (2FA) para mayor seguridad.',
  'personal-text-16': 'Desactivar',
  'personal-text-17': 'Activar',
  'personal-text-18': 'Cuenta',
  'personal-text-19':
    'Esta función te permite utilizar Google Authenticator o tu cuenta de Telegram para la autenticación de dos factores. Además de la confirmación por correo electrónico, deberás proporcionar un código de seguridad de Google Authenticator o Telegram para poder realizar ciertas acciones en tu cuenta personal. Estas acciones incluyen: Retirar fondos, cambiar información personal y la contraseña de tu cuenta. Esto proporciona una protección adicional contra hackers que intenten acceder a tu cuenta, incluso si lograran obtener tu contraseña.',
  'personal-text-20':
    'Esta función te permite utilizar Google Authenticator u otra aplicación compatible como FreeOTP para la autenticación de dos factores. Además de tu nombre de usuario y contraseña, deberás proporcionar un código de seguridad de seis dígitos para poder iniciar sesión en este sitio. El código de seguridad cambia cada 30 segundos. Esto proporciona una protección adicional contra hackers que intenten acceder a tu cuenta, incluso si lograran obtener tu contraseña.',
  'personal-text-21': 'Obtener',
  'personal-text-22':
    'Descarga e instala Google Authenticator en tu smartphone o computadora.',
  'personal-text-23': 'Utiliza uno de los siguientes pasos:',
  'personal-text-24':
    'La aplicación oficial de Google Authenticator para Android, iOS y BlackBerry. Asegúrate de sincronizar el reloj de tu dispositivo con el servidor de tiempo. Un desfase de tiempo en tu dispositivo puede provocar que no puedas iniciar sesión en tu cuenta personal.',
  'personal-text-25': 'y',
  'personal-text-26':
    'Recuerda sincronizar el reloj de tu dispositivo con el servidor de tiempo. Un desfase de tiempo en tu dispositivo puede provocar que no puedas iniciar sesión en tu cuenta personal.',
  'personal-text-27': 'Configuración.',
  'personal-text-28': 'Escaneea el siguiente código QR en',
  'personal-text-29': 'Clave de Personalización',
  'personal-text-30':
    'Si deseas cambiar la clave, desactiva la autenticación de dos factores. Cuando intentes volver a activarla, generará una nueva clave.',
  'personal-text-31': 'Activar autenticación de dos factores.',
  'personal-text-33': 'Código de Seguridad',
  'personal-text-34': 'Tu Contraseña',
  'personal-text-35': 'Habilitar',
  'personal-text-37': 'Contraseña Actual',
  'personal-text-38': 'Nueva Contraseña',
  'personal-text-39': 'Confirmar nueva contraseña',
  'personal-text-41': 'Documentos Descargables',
  'personal-text-42': 'Descargar',
  'personal-text-43': 'Formato Aceptable',
  'personal-text-44': 'Tus documentos están bajo verificación',
  'personal-text-45': 'Tus documentos han sido verificados',
  'personal-text-46': 'Tus documentos',
  'personal-text-47': 'Sesiones Activas',
  'personal-text-48': 'Sesiones del Navegador',
  'personal-text-49':
    'Administra y cierra sesión en tus sesiones activas en otros navegadores y dispositivos.',
  'personal-text-50':
    'Puedes cerrar sesión en todas tus otras sesiones de navegador en todos tus dispositivos si es necesario. Algunas de tus sesiones recientes se enumeran a continuación; sin embargo, esta lista puede no ser exhaustiva. Si crees que tu cuenta ha sido comprometida, también debes cambiar tu contraseña.',
  'personal-text-51': 'Este dispositivo',
  'personal-text-51-1': 'cerrar sesión',
  'personal-text-52': 'Cerrar sesión en otras sesiones de navegador',
  'personal-text-53': 'Licencia de Conducir',
  'personal-text-54': 'Recibo Bancario',
  file: 'Archivo no seleccionado',
  document: 'Pasaporte',
  'personal-text-55':
    'Para cambiar tu contraseña, pasa por la autenticación de dos factores (2FA)',
  'personal-text-56':
    'Para cambiar tu nombre y apellido, pasa por la autenticación de dos factores (2FA).',
  'personal-text-57':
    'Actualmente, la Autenticación de Dos Factores (2FA) está habilitada en esta cuenta.',
  'personal-text-58':
    'Por favor, copia esta contraseña de emergencia de un solo uso y guárdala en un lugar seguro. Si no tienes acceso a un dispositivo de autenticación de dos factores, puedes usar esta contraseña en lugar de tu código de seguridad regular. Esta contraseña se destruye inmediatamente cuando se utiliza. Te recomendamos imprimir esta contraseña y guardar una copia impresa en un lugar seguro y accesible, como tu billetera o caja fuerte.',
  'personal-text-59': 'Desactivando la autenticación de dos factores.',
  'personal-text-60':
    'Por favor, ingresa el código de autenticación de dos factores desde.',
  'personal-text-61': 'Enviar',
  'personal-text-62': 'Cancelar',
  'text-63': 'Ir a la cuenta personal',
  'text-64': 'Volver',
  'text-65': 'Notificaciones',
  'text-66': 'Marcar todas las notificaciones como leídas',
  'text-67': 'Marcar',
  'text-68': 'Tu registro está casi completo',
  'text-69':
    'Debes completar el proceso de registro antes de invertir. Para hacerlo, haz clic en el botón "Completar" y confirma tu registro desde el correo electrónico enviado a tu correo.',
  'text-70': 'Completar registro',
  'text-71': 'Seleccionar idioma',

  'cookies-1': 'Al navegar por este sitio, aceptas',
  'cookies-2': 'nuestra política de privacidad',
  ok: 'Ok',

  'table-1': 'Inicio de Sesión',
  'table-2': 'Bono (Depósito)',
  'table-3': 'Bono (Ganancias)',
  'table-4': 'Número de Referidos',
  'table-5': 'Fecha de Inscripción',
  'table-6': 'Tipo de Transacción',
  'table-7': 'Fecha',
  'table-8': 'Monto',
  'table-9': 'Estado de la Transacción',
  'table-10': 'ID de la Transacción',

  'profile-window-1': 'Inicio de Sesión:',
  'profile-window-2': 'Teléfono:',
  'profile-window-3': 'Idioma:',
  'profile-window-4': 'Tema:',
  'profile-window-5': 'Invitar amigos:',

  'two-factor-1': 'Validación de Dos Factores',
  'two-factor-2':
    'Ingresa el código de confirmación enviado a tu correo electrónico:',
  'two-factor-3': 'Reenviar',
  'two-factor-4': 'Validar',
  'two-factor-5': 'Validado',
  'two-factor-6': 'Reenviar nuevo código nuevamente a través de:',
  'two-factor-7': 'Ingresa el código de confirmación enviado a tu',
  'two-factor-8': 'Ingresar código',
  back: 'Volver a la Gestión de Planes',
  'back-to-site': 'Volver al Sitio',
  'back-to-news': 'Volver a Noticias',
  support: 'Soporte',
  title: 'Título',
  step: 'Paso',
  'page-not-found': 'Página no encontrada',
  home: 'Inicio',

  'staking-1': 'Volver a la Gestión de Planes',
  'staking-2': 'Staking de Criptomonedas',
  'staking-3':
    'Puede cerrar la sesión en todas sus otras sesiones de navegador en todos sus dispositivos si es necesario. Algunas de sus sesiones recientes se enumeran a continuación; sin embargo, esta lista puede no ser exhaustiva. Si cree que su cuenta ha sido comprometida, también debe actualizar su contraseña.',
  'staking-4': 'Ir a',
  'staking-4-1': 'Activar',
  'staking-5': 'Staking',
  'staking-6': 'Resultado Financiero:',
  'staking-7': 'Fecha del último beneficio:',
  'staking-8': 'Depósito en Staking:',
  'staking-9': 'Niveles',
  'staking-10': 'Última inversión:',
  'staking-11': 'nivel',
  'staking-12': 'Monto de Rotación',
  'staking-13': 'Bonificaciones',
  'staking-14': 'Aprobado',
  'staking-15': 'Su Nivel',
  'staking-16': 'Cerrar',
  'staking-17': 'Nivel de Staking:',
  'staking-18': 'Activo',
  'staking-19': 'Inactivo',
  'staking-20': 'Completado',
  'staking-table-1': 'Plan de Inversión',
  'staking-table-2': 'Fecha de Inicio',
  'staking-table-3': 'Fecha de Finalización',
  'staking-table-4': 'Capital Inicial',
  'staking-table-5': 'Resultado Actual, USDT',
  'staking-table-6': 'Beneficio, USDT',
  'staking-table-7': 'Comisión, USDT',
  'staking-table-8': 'Estado del Plan',

  'plan-modal-1': 'Plan de Inversión',
  'plan-modal-2': 'Fecha de Inicio',
  'plan-modal-3': 'Fecha de Finalización',
  'plan-modal-4': 'Capital Inicial',
  'plan-modal-5': 'Resultado Financiero',
  'plan-modal-6': 'Beneficio en USDT',
  'plan-modal-7': 'Ingrese la cantidad de capital inicial:',
  'plan-modal-8': 'Activar',

  'plan-page-1': 'Planes Activos',
  'plan-page-2': 'Planes Disponibles',
  done: 'Hecho',
  purpose: 'Objetivo',
  'password-reset': 'La contraseña ha sido enviada a tu correo electrónico.',
  'table-next': 'Siguiente',
  'table-prev': 'Anterior',

  'tr_status-1': 'Pendiente',
  'tr_status-2': 'Manual',
  'tr_status-3': 'Rechazado',
  'tr_status-4': 'Aprobado',
  'tr-type-0': 'Todo',
  'tr-type-1': 'Depósito',
  'tr-type-2': 'Retiro',
  'tr-type-3': 'Dividendos',
  'tr-type-4': 'Bono por depósito',
  'tr-type-5': 'Pago de referido',
  'tr-type-6': 'Bono por volumen de equipo',
  'tr-type-7': 'Activación de plan',
  'tr-type-8': 'Cierre de plan',
  'tr-type-9': 'Multa',
  'tr-type-10': 'Comisión',
  'document-1': 'Tarjeta de identificación',

  'deposit-benefits-1': 'Gana con',
  'deposit-benefits-2': 'Beneficio mensual promedio',
  'deposit-benefits-3': 'Beneficio anual',
  'deposit-benefits-4': 'Sistema de trading completamente automatizado.',
  'deposit-benefits-5':
    'Oportunidad de reinversión y un sistema flexible para elegir los plazos de inversión.',

  'staking-1-1': 'Producto de inversión',
  'staking-1-2':
    'diseñado y enfocado en usuarios que quieren obtener un ingreso pasivo estable, una herramienta fácil de manejar, así como acumulaciones diarias y retiro de beneficios.',
  'staking-1-3':
    'un producto con el potencial para el desarrollo y crecimiento en rentabilidad, gracias a 5 niveles de la herramienta.',
  copied: 'copiada',
  'email-verification-1': 'Verifica tu cuenta.',
  'email-verification-2':
    'Se envió un correo de confirmación a tu email al registrarte con un botón de verificación. Si por alguna razón no puedes encontrar el correo, haz clic en el botón de abajo y reenviaremos el correo.',
  'email-verification-3':
    'Para confirmar tu cuenta, haz clic en el botón en el correo electrónico.',
  'email-verification-4':
    'Después de confirmar tu email, podrás iniciar sesión en tu cuenta y comenzar a usar todas las funciones de CoinFuze.',
  'email-verification-5': 'Enviar',
  'email-verification-6': 'Reenviar correo de confirmación',
  'def-file-input': 'Archivo no seleccionado',
  'personal-text-16-1': 'Conecta tu telegrama',
  'new-notification': 'Nueva notificación',
  'personal-text-32':
    'Para asegurarte de que todo esté configurado correctamente, ingresa el código de seguridad recibido en Google Authenticator y la contraseña de tu cuenta en el campo a continuación. Si el código y la contraseña son correctos, haz clic en el botón "Activar" y se activará la autenticación de dos factores.',
  'personal-label-1': 'Código de seguridad',
  'personal-label-2': 'Tu contraseña',
  'withdrawal-text-24-2': 'Preguntas frecuentes en la sección de Retiros'
}
