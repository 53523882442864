<template>
  <div class="app__start-content">
    <div class="app__start-title text-center text-accent fw-600">
      {{ $t('autorization-tab-2') }}
    </div>
    <div class="app__start-inner">
      <div class="input-group">
        <form-field-wrapper
          :v="$v.firstName"
          :validator="$v.firstName"
          :attribute="$t('autorization-name')"
        >
          <input
            class="input-control"
            type="text"
            :placeholder="$t('autorization-name')"
            v-model.trim="firstName"
            @input="$v.firstName.$touch()"
            required
          />
        </form-field-wrapper>
      </div>
      <div class="input-group">
        <form-field-wrapper
          :v="$v.lastName"
          :validator="$v.lastName"
          :attribute="$t('autorization-surname')"
        >
          <input
            class="input-control"
            type="text"
            :placeholder="$t('autorization-surname')"
            v-model.trim="lastName"
            @input="$v.lastName.$touch()"
            required
          />
        </form-field-wrapper>
      </div>

      <div class="input-group">
        <form-field-wrapper
          :v="$v.login"
          :validator="$v.login"
          :attribute="$t('autorization-login')"
        >
          <input
            class="input-control"
            type="text"
            v-model="login"
            :placeholder="$t('autorization-login')"
            required
          />
        </form-field-wrapper>
      </div>
      <div class="input-group">
        <form-field-wrapper
          :v="$v.password"
          :validator="$v.password"
          :attribute="$t('autorization-password')"
        >
          <input
            class="input-control"
            type="password"
            :placeholder="$t('autorization-password')"
            v-model.trim="password"
            @input="$v.password.$touch()"
            required
          />
        </form-field-wrapper>
      </div>
      <div class="input-group">
        <form-field-wrapper
          :v="$v.confirmPassword"
          :validator="$v.confirmPassword"
          :attribute="$t('autorization-repeat-password')"
        >
          <input
            class="input-control"
            type="password"
            :placeholder="$t('autorization-repeat-password')"
            v-model.trim="confirmPassword"
            @input="$v.password.$touch()"
            required
          />
        </form-field-wrapper>
      </div>
      <div class="input-group">
        <form-field-wrapper
          :v="$v.email"
          :validator="$v.email"
          :attribute="$t('autorization-email')"
        >
          <input
            class="input-control"
            type="email"
            :placeholder="$t('autorization-email')"
            v-model.trim="email"
            @input="$v.email.$touch()"
            required
          />
        </form-field-wrapper>
      </div>
      <div class="input-group">
        <form-field-wrapper
          :v="$v.promo"
          :validator="$v.promo"
          :attribute="$t('autorization-promo')"
        >
          <input
            class="input-control"
            :placeholder="$t('autorization-promo')"
            v-model.trim="promo"
            @input="$v.promo.$touch()"
            required
          />
        </form-field-wrapper>
      </div>
      <div class="input-group">
        <form-field-wrapper
          :customInput="true"
          :v="$v.country.name"
          :validator="$v.country.name"
          :attribute="$t('Country')"
        >
          <v-select
            :options="COUNTRIES"
            v-model="country"
            :placeholder="$t('Country')"
            label="name"
            class="v-select-c"
          />
        </form-field-wrapper>
      </div>
      <div class="input-group" v-if="defaultCountry">
        <vue-tel-input
          ref="v-tel"
          v-model="phone"
          :defaultCountry="defaultCountry"
          @country-changed="tCountry"
          :allCountries="formatedCountryList"
          mode="auto"
          :autoFormat="true"
          autocomplete="off"
          :dropdownOptions="{
            showFlags: true,
            showDialCodeInSelection: true,
            showDialCodeInList: true
          }"
          :showDialCode="false"
          :inputOptions="{ placeholder: $t('phone-input-placeholder') }"
        >
        </vue-tel-input>
        <form-field-wrapper
          :v="$v.phone"
          :validator="$v.phone"
          :attribute="$t('autorization-tel')"
        >
        </form-field-wrapper>
      </div>

      <div class="input-group pb-3">
        <div class="row gy-small">
          <div class="col-12">
            <label class="input-checkbox">
              <input type="checkbox" v-model="isAgreeRulesChecked" />
              <span>
                {{ $t('autorization-text-0') }}
                <a @click="openFile('General_Terms')" style="cursor: pointer">
                  {{ $t('autorization-text-1') }}
                </a>
                <a @click="openFile('Privacy_Policy')" style="cursor: pointer">
                  {{ $t('autorization-text-2') }}</a
                >,
                <a @click="openFile('AML')" style="cursor: pointer">
                  {{ $t('autorization-text-3') }}</a
                >
              </span>
            </label>
          </div>
          <div class="col-12">
            <label class="input-checkbox">
              <input type="checkbox" v-model="isAgreeAgeChecked" />
              <span> {{ $t('autorization-text-4') }} </span>
            </label>
          </div>
        </div>
      </div>
      <div class="input-group pb-2">
        <button class="button w-100" :disabled="loading" @click="register">
          <span>{{ $t('autorization-btn-register') }}</span>
        </button>
      </div>
      <div class="input-group">
        <div class="input-meta text-center">
          {{ $t('autorization-text-10') }}
          <router-link to="login">{{ $t('autorization-text-11') }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FormFieldWrapper from '@/components/global/FormFieldWrapper.vue'
import ValidateRules from '@/mixins/ValidateRules.vue'
// import VueHcaptcha from '@hcaptcha/vue-hcaptcha'
// import CountrySelect from '@/components/global/CountrySelect.vue'
// import LoadingComponent from '../global/preloaders/LoadingComponent.vue'

export default {
  name: 'RegisterForm',
  mixins: [ValidateRules],
  components: {
    FormFieldWrapper
    // CountrySelect
    // LoadingComponent
  },
  data() {
    return {
      loading: false,
      countrySelect: false,

      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      email: '',
      promo: '',
      phoneCountry: '',
      phone: '',
      country: '',
      login: '',

      defaultCountry: '',

      isAgreeAgeChecked: false,
      isAgreeRulesChecked: false
    }
  },
  watch: {
    country(newValue) {
      this.defaultCountry = newValue.code
    }
  },
  beforeCreate() {
    this.$store
      .dispatch('app/getCountries')
      .catch((error) => this.$showError(error.detail))
  },
  computed: {
    ...mapGetters({
      COUNTRIES: 'app/getCountries',
      locale: 'app/getLocale',
      localeList: 'app/getLocalesList',
      auth: 'app/getAuthToken'
    }),

    formatedCountryList() {
      return this.COUNTRIES.map(({ name, dial_code, code, id }) => ({
        name,
        dialCode: dial_code.slice(1),
        iso2: code,
        id
      }))
    }
  },

  mounted() {
    //     const script = document.createElement('script')
    //     script.id = 'roistat-script'
    //     script.innerHTML = `(function(w, d, s, h, id) {
    // w.roistatProjectId = id; w.roistatHost = h;
    // var p = d.location.protocol == "https:" ? "https://" : "http://";
    // var u = /^.roistat_visit=[^;]+(.)?$/.test(d.cookie) ? "/dist/module.js" : "/api/site/1.0/"+id+"/init?referrer="+encodeURIComponent(d.location.href);
    // var js = d.createElement(s); js.charset="UTF-8"; js.async = 1; js.src = p+h+u; var js2 = d.getElementsByTagName(s)[0]; js2.parentNode.insertBefore(js, js2);
    // })(window, document, 'script', 'cloud.roistat.com', 'f5154a47715fbb5b35fb704cd376a28d');`
    //     document.body.appendChild(script)

    if (this.auth) {
      this.$store.dispatch('app/logout')
    }
    if (Object.keys(this.$route.query).length > 0) {
      Object.entries(this.$route.query).forEach(([key, value]) => {
        if (key === 'promo') this.promo = value
        if (key === 'locale') {
          this.$i18n.locale = value
          document.documentElement.setAttribute('lang', value)
          this.$store.dispatch('app/setLocale', value)
        }
      })
    } else {
      // console.log('В URL нет параметров запроса.')
    }
  },
  // beforeDestroy() {
  //   const existingScript = document.getElementById('roistat-script')
  //   if (existingScript) {
  //     existingScript.parentNode.removeChild(existingScript)
  //   }
  // },
  methods: {
    tCountry(v) {
      this.phoneCountry = v
    },
    toggleCountrySelect() {
      this.countrySelect = !this.countrySelect
    },

    register() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        Object.keys(this.$v).forEach((field) => {
          if (this.$v[field].$invalid) {
            console.log(`Field ${field} is invalid.`)
            Object.keys(this.$v[field]).forEach((rule) => {
              if (this.$v[field][rule] === false) {
                console.log(` - Rule ${rule} failed.`)
              }
            })
          }
        })
        return
      }
      if (this.$v.$invalid) return
      if (!this.isAgreeAgeChecked) {
        return this.$showError(this.$t('validation-1'))
      }
      if (!this.isAgreeRulesChecked) {
        return this.$showError(this.$t('validation-2'))
      }

      this.loading = true

      let payload = {
        data: {
          name: this.firstName,
          last_name: this.lastName,
          login: this.login,
          phone: this.phoneCountry.dialCode + this.phone,
          email: this.email,
          password: this.password,
          promo: this.promo,
          country: this.country.name
        },
        param:
          Object.keys(this.$route.query).length > 0
            ? '/?' + new URLSearchParams(this.$route.query).toString()
            : '/'
      }
      this.$store
        .dispatch('user/registration', payload)
        .then(() => this.$router.push('/'))
        .catch((error) => this.$showError(error.detail))
        .finally(() => (this.loading = false))
    },
    selectCountry(country) {
      this.defaultCountry = country.code
      this.country = country.name
    },
    openFile(fileType) {
      window
        .open(
          `https://coinfuze.com/docs/${fileType}_${this.locale}.pdf`,
          '_blank'
        )
        .focus()
    }
  }
}
</script>

<style lang="scss">
.v-select-c {
  margin-bottom: 12px;
}
.vs__dropdown-toggle {
  background-color: var(--s-input-bg-color) !important;
  border: 1px solid var(--s-card-border-color) !important;
  min-height: 3.429rem !important;
  font-family: inherit !important;
  font-size: var(--s-base-val) !important;
  color: var(--s-app-accent-color) !important;
  border-radius: 0.714rem !important;
}
.vs__selected {
  color: var(--s-app-accent-color) !important;
  padding-left: 14px !important;
}
.vs__clear {
  fill: #666 !important;
}
.vs__open-indicator {
  fill: #666 !important;
  cursor: pointer;
}
.vs__search {
  font-family: inherit !important;
  font-size: var(--s-base-val) !important;
  padding-left: 14px !important;
}
.vs__dropdown-menu {
  background-color: var(--s-input-bg-color) !important;
  border: 1px solid var(--s-card-border-color) !important;
  color: var(--s-app-accent-color) !important;
  &::-webkit-scrollbar {
    border-radius: 10rem;
    width: 0.429rem;
    height: 0.429rem;
    background-color: var(--s-card-inside-bg-color);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.429rem;
    background-color: var(--s-app-accent-color);
  }

  &::-webkit-scrollbar-track {
    border-radius: 10rem;
    background-color: var(--s-card-inside-bg-color);
  }
}

// .cselect-wrapper {
//   cursor: pointer;
//   position: relative;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   // border-radius: 1.214rem;
//   background-color: var(--s-input-bg-color);
//   border: 1px solid var(--s-card-border-color);
//   color: var(--s-app-accent-color);
//   padding: 1px 10px;
//   &.active {
//     .cselect-arrow {
//       transform: rotate(180deg);
//     }
//     .cselect-list {
//       display: flex;
//       flex-direction: column;
//       gap: 5px;
//     }
//   }
// }
// .cselect-label {
//   padding: 0.371rem;
// }
// .cselect-dlabel {
//   color: var(--s-app-text-color);
// }
// .cselect-arrow {
//   position: relative;
//   display: flex;
//   padding: 0.371rem;
//   transition: transform ease-in-out 0.2s;
//   -webkit-transition: transform ease-in-out 0.2s;
//   path {
//     stroke: var(--s-app-accent-color);
//   }
// }
// .cselect-list {
//   max-height: 30vh;
//   overflow-y: auto;

//   z-index: 7;
//   position: absolute;
//   top: 40px;
//   left: -1px;
//   display: none;
//   border: 1px solid var(--s-card-border-color);
//   background-color: var(--s-input-bg-color);
//   color: var(--s-app-accent-color);
//   border-radius: 0.714rem;
//   padding: 10px 0px;
//   width: auto;
//   &::-webkit-scrollbar {
//     width: 5px;
//   }
//   &::-webkit-scrollbar-track {
//     background-color: var(--s-input-ph-color);
//     border-radius: 5px;
//   }

//   &::-webkit-scrollbar-thumb {
//     background-color: var(--s-input-bg-color);
//     border-radius: 6px;
//   }

//   &::-webkit-scrollbar-thumb:hover {
//     background-color: var(--s-input-bg-color);
//   }
// }
// .cselect-item {
//   padding: 0px 20px;
// }
// .cselect-item:hover {
//   background-color: var(--s-input-hover-bg-color);
// }
</style>
