<template>
  <div class="col-lg-6">
    <div class="card h-100">
      <div class="card-inner p-0 d-flex flex-column">
        <div class="card-inner-item flex-fill">
          <h3 class="card-title">
            {{ $t('partnership-text-1') }}
            <br />{{ $t('partnership-text-2') }}
          </h3>
          <ul class="reset-list dot-list">
            <li>{{ $t('partnership-text-3') }}</li>
            <li>{{ $t('partnership-text-4') }}</li>
            <li>{{ $t('partnership-text-5') }}</li>
            <li>{{ $t('partnership-text-6') }}</li>
          </ul>
        </div>
        <div class="card-inner-item">
          <div class="row gy-base">
            <div class="col-md-3 col-sm-6 col-6">
              <div class="stat_item">
                <div
                  class="stat_item-title"
                  v-html="$t('partnership-text-13')"
                ></div>
                <div class="stat_item-val stat_item-val-large">
                  {{ totalPartners }}
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-6">
              <div class="stat_item">
                <div class="stat_item-title">
                  {{ $t('partnership-text-14') }}
                </div>
                <div class="stat_item-val stat_item-val-large">
                  {{ user.referal_first_count }}
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-6">
              <div class="stat_item">
                <div class="stat_item-title">
                  {{ $t('partnership-text-15') }}
                </div>
                <div class="stat_item-val stat_item-val-large">
                  {{ user.referal_second_count }}
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-6">
              <div class="stat_item">
                <div class="stat_item-title">
                  {{ $t('partnership-text-16') }}
                </div>
                <div class="stat_item-val stat_item-val-large">
                  {{ user.referal_third_count }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AffiliateStats',
  computed: {
    ...mapGetters({
      user: 'user/getUser'
    }),
    totalPartners() {
      return (
        this.user.referal_first_count +
        this.user.referal_second_count +
        this.user.referal_third_count
      )
    }
  }
}
</script>
