<template>
  <div class="col-lg-6">
    <div class="card h-100">
      <div class="card-inner d-flex flex-column align-items-start">
        <div class="flex-fill">
          <h3 class="card-title">{{ $t('plans-subtitle-1') }}</h3>
          <p>{{ $t('plans-text-1') }}</p>
          <p>{{ $t('plans-text-2') }}</p>
          <p>{{ $t('plans-text-3') }}</p>
          <ul class="reset-list links-list mt-4">
            <li>
              <span class="btn-link" @click="openPlanTutorialModal">
                {{ $t('plans-text-4') }}
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.833 7L1.16634 7M12.833 7L7.83301 12M12.833 7L7.83301 2"
                    stroke="#37BBC6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </li>
            <li>
              <a
                target="_blank"
                :href="`https://faq.coinfuze.com/${locale}/investments-questions/how-to-invest-in-coinfuze`"
              >
                {{ $t('plans-text-5') }}
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.833 7L1.16634 7M12.833 7L7.83301 12M12.833 7L7.83301 2"
                    stroke="#37BBC6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </div>
        <router-link class="button button_bordered w-sm-100" to="banking">
          <span>{{ $t('plans-text-9') }}</span>
        </router-link>
      </div>
    </div>
    <plan-tutorial-modal
      v-if="showTutorialModal"
      @close-modal="closePlanTutorialModal"
      :show="showTutorialModal"
    />
  </div>
</template>
<script>
import PlanTutorialModal from './PlanTutorialModal.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'InvestmentManagementBlock',
  components: { PlanTutorialModal },
  mixins: [],
  props: {},
  data() {
    return {
      showTutorialModal: false
    }
  },
  computed: {
    ...mapGetters({
      locale: 'app/getLocale'
    })
  },
  watch: {},
  mounted() {},
  methods: {
    openPlanTutorialModal() {
      this.showTutorialModal = true
    },
    closePlanTutorialModal() {
      this.showTutorialModal = false
    }
  }
}
</script>
<style lang="scss" scoped>
.btn-link {
  color: var(--s-blue-color);
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}
</style>
