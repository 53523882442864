<template>
  <div class="app__content-item">
    <div class="app__content-header d-flex flex-wrap align-items-center">
      <h3 class="app__content-title h3">{{ $t('history') }}</h3>
      <div class="input-group-line d-inline-flex align-items-center">
        <div class="input-group-line-text">{{ $t('table-6') }}:</div>
        <div class="input-group-line-control">
          <div
            class="cselect-wrapper"
            :class="{ active: TRTypeMenu }"
            @click="toggleTRTypeMenu"
            v-click-outside="() => (this.TRTypeMenu = false)"
          >
            <span class="cselect-label">
              {{ selectedTransactionType.label }}
            </span>
            <div class="cselect-arrow">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 7.5L10 12.5L15 7.5"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div class="cselect-list">
              <span
                class="cselect-item"
                v-for="(type, idx) in transactionTypes"
                :key="idx + type"
                @click="selectTRType(type)"
              >
                {{ type.label }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card overflow-hidden">
      <div class="card-inner">
        <div class="plans">
          <div class="customscroll plan-row">
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :pagination-options="paginationOptions"
              :sort-options="{
                enabled: true,
                initialSortBy: { field: 'date_create', type: 'desc' }
              }"
              styleClass="vgt-table condensed"
              style="min-width: 1250px"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'tr_type'">
                  {{ tr_types[props.row.tr_type] }}
                </span>
                <span
                  v-if="props.column.field == 'tr_status'"
                  class="status-label"
                  :class="props.row.tr_status"
                >
                  {{ tr_statuses[props.row.tr_status] }}
                </span>

                <span v-if="props.column.field == 'date_create'">
                  {{ formatDate(props.row.date_create) }}
                </span>

                <span v-if="props.column.field == 'tr_amount'">
                  {{ props.formattedRow[props.column.field].toFixed(2) }}
                </span>

                <span v-if="props.column.field == 'id'">
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import vClickOutside from 'v-click-outside'

export default {
  name: 'HystoryTable',
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      selectedTransactionType: { label: this.$t('tr-type-0'), type: 'All' },
      loading: true,
      TRTypeMenu: false
    }
  },
  computed: {
    ...mapGetters({
      paymentHystory: 'user/getPaymentHystory',
      types: 'user/getTotalsStats'
    }),
    columns() {
      return [
        {
          label: this.$t('table-6'),
          field: 'tr_type'
        },
        {
          label: this.$t('table-7'),
          field: 'date_create'
        },
        {
          label: this.$t('table-8'),
          field: 'tr_amount'
        },
        {
          label: this.$t('table-9'),
          field: 'tr_status'
        },
        {
          label: this.$t('table-10'),
          field: 'id'
        }
      ]
    },
    transactionTypes() {
      return Object.keys(this.tr_types).map((key) => {
        return { type: key, label: this.tr_types[key] }
      })
      // const uniqueTypes = { All: this.$t('tr-type-0') }
      // this.paymentHystory.forEach((item) => {
      //   const type = item.tr_type
      //   const label = this.tr_types[type]
      //   uniqueTypes[type] = label
      // })
      // return Object.entries(uniqueTypes).map(([type, label]) => ({
      //   type,
      //   label
      // }))
    },

    tr_statuses() {
      return {
        wait: this.$t('tr_status-1'),
        pending: this.$t('tr_status-2'),
        rejected: this.$t('tr_status-3'),
        approved: this.$t('tr_status-4')
      }
    },
    tr_types() {
      return {
        All: this.$t('tr-type-0'),
        pay: this.$t('tr-type-1'),
        draw: this.$t('tr-type-2'),
        dvdnt: this.$t('tr-type-3'),
        bns_rfrl: this.$t('tr-type-4'),
        rfrl: this.$t('tr-type-5'),
        cmd_bns: this.$t('tr-type-6'),
        pl_start: this.$t('tr-type-7'),
        pl_stop: this.$t('tr-type-8'),
        // penalty: this.$t('tr-type-9'),
        comes: this.$t('tr-type-10')
      }
    },

    formattedRows() {
      return this.rows.map((row) => ({
        ...row,
        date_create: this.formatDate(row.date_create),
        tr_amount: row.tr_amount.toFixed(2)
      }))
    },
    rows() {
      if (this.selectedTransactionType.type === 'All') {
        return this.paymentHystory
      } else {
        return this.paymentHystory.filter(
          (item) => item.tr_type === this.selectedTransactionType.type
        )
      }
    },

    paginationOptions() {
      return {
        enabled: true,
        perPageDropdownEnabled: false,
        mode: 'page',
        nextLabel: this.$t('table-next'),
        prevLabel: this.$t('table-prev')
      }
    }
  },
  created() {
    this.getHistroryData()
  },
  methods: {
    getHistroryData() {
      this.loading = true
      this.$store
        .dispatch('user/getHistoryData')
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.newsLoading = false
        })
    },
    toggleTRTypeMenu() {
      this.TRTypeMenu = !this.TRTypeMenu
    },
    selectTRType(type) {
      this.selectedTransactionType = type
    },
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()
      return `${day}.${month}.${year}`
    }
  }
}
</script>
<style lang="scss" scoped>
.cselect-wrapper {
  z-index: 2;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 1.214rem;
  width: auto;
  min-width: 160px;
  // border: 1px solid var((--s-card-border-bg-color));
  // background-color: var(--s-card-bg-color);
  color: var(--s-app-accent-color);
  // padding: 1px 10px;
  &.active {
    .cselect-arrow {
      transform: rotate(180deg);
    }
    .cselect-list {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }
}
.cselect-label {
  padding: 0.371rem;
}
.cselect-arrow {
  position: relative;
  display: flex;
  padding: 0.371rem;
  transition: transform ease-in-out 0.2s;
  -webkit-transition: transform ease-in-out 0.2s;
  path {
    stroke: var(--s-app-accent-color);
  }
}
.cselect-list {
  position: absolute;
  top: 40px;
  display: none;
  border: 1px solid var((--s-card-border-bg-color));
  background-color: var(--s-card-bg-color);
  color: var(--s-app-accent-color);
  border-radius: 1.214rem;
  padding: 10px 0px;
  width: auto;
}
.cselect-item {
  padding: 0px 20px;
}
.cselect-item:hover {
  background-color: var(--s-input-hover-bg-color);
}
</style>
