<template>
  <div
    :class="{ 'has-file': showRemovedBtn }"
    class="c-file-input js-file-input"
  >
    <div class="c-file-input__indicator">
      <svg
        class="c-file-input__indicator__icon c-icon c-icon--attach"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        enable-background="new 0 0 91 91"
        height="24px"
        width="24px"
        id="Layer_1"
        version="1.1"
        viewBox="0 0 91 91"
        xml:space="preserve"
      >
        <g>
          <path
            d="M63.327,11.104c-1.846,0-3.341,1.496-3.341,3.34v52.971c0,8.73-6.812,15.834-15.185,15.834   c-8.375,0-15.186-7.1-15.189-15.826c0-0.002,0.001-0.004,0.001-0.006V17.092c0-5.277,4.294-9.568,9.569-9.568   c5.262,0,9.541,4.26,9.572,9.511v51.261c0,2.055-1.668,3.725-3.719,3.725c-2.055,0-3.727-1.67-3.727-3.725V22.389   c0-1.844-1.495-3.34-3.34-3.34c-1.844,0-3.34,1.496-3.34,3.34v45.908c0,0.001,0,0.001,0,0.002c0.002,5.736,4.67,10.4,10.406,10.4   c5.733,0,10.398-4.666,10.398-10.404V17.131c0-0.014,0.004-0.025,0.004-0.039c0-0.026-0.004-0.051-0.004-0.077v-0.806   c0-0.24-0.027-0.474-0.076-0.7C54.56,7.29,47.612,0.844,39.183,0.844c-8.42,0-15.362,6.438-16.168,14.65   c-0.051,0.23-0.081,0.469-0.081,0.715v51.186c0,0.007-0.002,0.013-0.002,0.02c0,12.414,9.811,22.514,21.87,22.514   c12.057,0,21.865-10.1,21.865-22.514V14.443C66.667,12.6,65.171,11.104,63.327,11.104z"
            fill="#647F94"
          />
        </g>
      </svg>
    </div>
    <label class="c-file-input__label js-file-input__label" for="inputfile">
      <span>{{ theFileName }}</span>
      <input
        id="inputfile"
        type="file"
        :accept="type === 'img' ? 'image/*' : ''"
        @change="fileChangeHandler"
        name="attachment"
        class="c-file-input__field js-file-input__field"
      />
    </label>
    <div
      @click.stop="clearFileHandler"
      class="c-file-input__remove js-file-input__remove"
    >
      <svg
        class="c-file-input__remove__icon c-icon c-icon--remove-circle"
        xmlns="http://www.w3.org/2000/svg"
        data-name="Livello 1"
        id="Livello_1"
        height="24px"
        width="24px"
        viewBox="0 0 128 128"
        fill="red"
      >
        <title />
        <path
          d="M64,0a64,64,0,1,0,64,64A64.07,64.07,0,0,0,64,0Zm0,122a58,58,0,1,1,58-58A58.07,58.07,0,0,1,64,122Z"
        />
        <path d="M90,61H38a3,3,0,0,0,0,6H90a3,3,0,0,0,0-6Z" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FileInput',
  props: {
    type: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      value: '',
      files: [],
      showRemovedBtn: false
    }
  },
  computed: {
    labelPlaceholder() {
      return this.$t('def-file-input')
    },
    theFileName() {
      if (this.files.length > 0 && 'name' in this.files[0]) {
        return this.files[0].name
      }
      return this.labelPlaceholder
    }
  },
  methods: {
    fileChangeHandler(e) {
      this.files = Array.from(e.target.files)
      this.showRemovedBtn = true
      this.$emit('file-selected', this.files[0])
      this.$emit('file-change', e)
    },
    clearFileHandler() {
      const el = this.$el.querySelector('.js-file-input__field')
      const wrapper = document.createElement('form')
      this.wrapInputAndReset(el, wrapper)
      this.$emit('file-selected', null)
      this.$emit('file-clear')
    },
    wrapInputAndReset(el, wrapper) {
      el.parentNode.insertBefore(wrapper, el)
      wrapper.appendChild(el)
      wrapper.reset()
      const docFrag = document.createDocumentFragment()
      while (wrapper.firstChild) {
        const child = wrapper.removeChild(wrapper.firstChild)
        docFrag.appendChild(child)
      }
      wrapper.parentNode.replaceChild(docFrag, wrapper)
      this.files = []
      this.showRemovedBtn = false
    }
  }
}
</script>

<style lang="css" scoped>
.o-container {
  padding-top: 40px;
  padding-bottom: 40px;
}

.c-field__error {
  font-size: 12px;
  color: #d71149;
}

.c-file-input {
  border-radius: 0.714rem;
  position: relative;
  display: block;
  height: 100%;
  background-color: var(--s-input-bg-color);
  border: 1px solid var(--s-card-border-color);
  min-height: 46px;
}

.c-file-input:invalid,
.c-field--error .c-file-input {
  background-color: #ffe6e9;
  border-color: #ff566a;
}

.c-file-input:invalid:focus,
.c-field--error .c-file-input:focus {
  background-color: #ffe6e9;
  border-color: #ff566a;
}

.c-file-input__label {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  /* width: 100%; */
  height: 100%;
  padding-left: 36px;
  padding-right: 36px;
  font-weight: 500;
  font-size: var(--s-base-val);
  color: var(--s-input-ph-color);
  overflow: hidden;
  word-wrap: break-word;
  z-index: 1;
  cursor: pointer;
}

.c-file-input__field {
  position: absolute !important;
  height: 1px !important;
  width: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  z-index: -1;
}

.c-file-input__field:focus {
  outline: none;
}

.c-file-input__indicator {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 36px;
  z-index: 2;
}

.c-file-input__indicator__icon {
  color: #bbb;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  left: 4px;
  font-size: 20px;
}

.has-file .c-file-input__indicator__icon {
  color: #d71149;
}

.c-file-input__remove {
  display: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 36px;
  z-index: 2;
}

.has-file > .c-file-input__remove {
  display: block;
}

.c-file-input__remove__icon {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  left: 4px;
  font-size: 20px;
  color: #ff566a;
}
</style>
