import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import NotFound from '../components/NotFound'

import LoginForm from '../components/auth/LoginForm'
import RegisterForm from '../components/auth/RegisterForm'
import PasswordResetConfirm from '../components/auth/PasswordResetConfirm'
import AuthPage from '../views/AuthPage'

import EmailActivationPage from '../views/EmailActivationPage'
import DashboardView from '../views/DashboardView'
import DashboardPage from '../components/dashboard/DashboardPage'
import BankingPage from '../components/banking/BankingPage'
import PlanManagementPage from '../components/planManagement/PlanManagementPage'
import AffiliateProgramPage from '../components/affiliateProgram/AffiliateProgramPage'
// import WithdrawalsPage from '../components/withdrawals/WithdrawalsPage'
import PersonalInfoPage from '../components/personalInfo/PersonalInfoPage'
import StakingPage from '@/components/staking/StakingPage'
import NewsPage from '../components/news/NewsPage'
import NewsComponent from '../components/news/NewsComponent'
import BlockedRegion from '@/components/BlockedRegion.vue'
import PartnershipForm from '@/components/PartnershipForm.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    component: NotFound
  },
  {
    path: '/email_activation',
    component: EmailActivationPage,
    meta: {
      isPrivate: true
    }
  },
  {
    path: '/blocked_region',
    component: BlockedRegion,
    meta: {
      isPrivate: false
    }
  },
  {
    path: '/affiliate',
    component: PartnershipForm,
    meta: {
      isPrivate: true
    }
  },
  {
    path: '/',
    component: DashboardView,
    children: [
      {
        path: '/',
        name: 'Dashboard',
        component: DashboardPage,
        meta: {
          title: 'sidebar-item-1',
          isPrivate: true,
          transitionIdx: 1
        }
      },
      {
        path: '/banking',
        name: 'Banking',
        component: BankingPage,
        meta: {
          title: 'sidebar-item-2',
          isPrivate: true,
          transitionIdx: 2
        }
      },
      {
        path: '/plans',
        name: 'Plans',
        component: PlanManagementPage,
        meta: {
          title: 'sidebar-item-3',
          isPrivate: true,
          transitionIdx: 3
        }
      },
      {
        path: '/partnership',
        component: AffiliateProgramPage,
        name: 'Partnership',
        meta: {
          title: 'sidebar-item-4',
          isPrivate: true,
          transitionIdx: 4
        }
      },
      {
        path: '/staking',
        name: 'Staking',
        component: StakingPage,
        meta: {
          title: 'backPlanManagement',
          isPrivate: true,
          transitionIdx: 5
        }
      },
      {
        path: '/profile',
        name: 'Profile',
        component: PersonalInfoPage,
        meta: {
          title: 'sidebar-item-6',
          isPrivate: true,
          transitionIdx: 6
        }
      },
      {
        path: '/news',
        name: 'News',
        component: NewsPage,
        meta: {
          title: 'sidebar-item-7',
          isPrivate: true,
          transitionIdx: 7
        }
      },
      {
        path: '/news/:slug',
        name: 'NewsComponent',
        component: NewsComponent,
        meta: {
          title: 'sidebar-item-7',
          isPrivate: true,
          transitionIdx: 8
        }
      }
    ]
  },
  {
    path: '/auth',
    component: AuthPage,
    children: [
      {
        path: '',
        redirect: 'login'
      },
      {
        path: 'login',
        component: LoginForm,
        name: 'Login'
      },
      {
        path: 'register',
        component: RegisterForm,
        name: 'Register'
      },
      {
        path: 'reset_password',
        component: PasswordResetConfirm,
        name: 'PasswordResetConfirm'
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // if (from.name && to.name === 'Login') {
  //   localStorage.setItem('loginRedirect', from.path)
  // }

  if (to.fullPath === from.fullPath) {
    next(false)
  }
  let token = store.getters['app/getAuthToken']
  if (to.name === 'Login' && token) {
    next({ name: 'Dashboard' })
  } else if (!token && to.meta.isPrivate && to.name !== 'Login') {
    const locale = to.query.locale
    let redirect = { name: 'Login' }
    if (locale) {
      redirect.query = { locale }
    }
    localStorage.setItem('loginRedirect', to.fullPath)
    next(redirect)
  } else next()
})

export default router
