<template>
  <div class="tabs-content-item active" id="tab-item-2">
    <div class="card-inner-item">
      <div class="card-text">
        <p>
          {{ $t('personal-text-12') }}
          <label
            class="input-switcher d-inline-flex align-items-center align-middle"
          >
            <input type="checkbox" style="cursor: default" checked disabled />
            <span></span>Enabled
          </label>
        </p>
      </div>
    </div>
    <div class="card-inner-item">
      <div class="card-text">
        <p>
          Email: <span>{{ user.email }}</span>
        </p>
        <p>
          {{ $t('personal-text-13') }}
        </p>
        <p>
          {{ $t('personal-text-14') }}
        </p>
      </div>
    </div>
    <div class="card-inner-item">
      <div class="message d-flex">
        <div class="message-icon">
          <img src="@/assets/images/warning-icon.svg" alt="coinfuze" />
        </div>
        <div class="message-text text-accent flex-fill">
          {{ $t('personal-text-19') }}
        </div>
      </div>
    </div>
    <div class="card-inner-item">
      <div class="tabs">
        <div class="tabs-buttons d-flex flex-wrap">
          <div
            v-if="showAuthenticatorTab"
            class="tabs-button"
            :class="{ active: selectedTab === 'AuthenticatorTab' }"
            data-tab="tab-sub-item-1"
            @click="selectTab('AuthenticatorTab')"
          >
            Google Authenticator
            <img
              src="@/assets/images/google-authenticator.svg"
              alt="coinfuze"
            />
          </div>
          <div
            class="tabs-button"
            :class="{ active: selectedTab === 'TelegramTab' }"
            data-tab="tab-sub-item-2"
            @click="selectTab('TelegramTab')"
            v-if="showTelegramTab"
          >
            Telegram<img
              src="@/assets/images/telegram-fill.svg"
              alt="coinfuze"
            />
          </div>
        </div>
        <div class="tabs-content">
          <component
            :is="selectedTab"
            :enable="this.twoFactorData.aditional_service"
            class="active"
            @select-default-tab="selectDefaultTab"
            @select-tab="selectTab"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TelegramTab from './TelegramTab.vue'
import AuthenticatorTab from './AuthenticatorTab.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'TwoFactorTab',
  components: { TelegramTab, AuthenticatorTab },

  data() {
    return {
      selectedTab: 'AuthenticatorTab'
    }
  },
  watch: {
    twoFactorData(newVal) {
      if (newVal && newVal.aditional_service === 'Telegram Authenticator') {
        this.selectTab('TelegramTab')
      } else this.selectTab('AuthenticatorTab')
    }
  },
  computed: {
    ...mapGetters({
      twoFactorData: 'user/getTwoFactorData',
      user: 'user/getUser'
    }),
    showAuthenticatorTab() {
      return (
        !this.twoFactorData.aditional_service ||
        this.twoFactorData.aditional_service === 'Google Authenticator'
      )
    },
    showTelegramTab() {
      return (
        !this.twoFactorData.aditional_service ||
        this.twoFactorData.aditional_service === 'Telegram Authenticator'
      )
    }
  },
  mounted() {
    this.selectDefaultTab()
  },
  methods: {
    selectTab(tab) {
      this.selectedTab = tab
    },
    selectDefaultTab() {
      const tabs = {
        'Google Authenticator': 'AuthenticatorTab',
        'Telegram Authenticator': 'TelegramTab'
      }
      !this.twoFactorData.aditional_service
        ? this.selectTab('AuthenticatorTab')
        : this.selectTab(tabs[this.twoFactorData.aditional_service])
      // if (this.user.get_aditional_validation_display === '') this.selectTab(this.$route.params.tab)
    }
  }
}
</script>
