<template>
  <div class="tabs-content-item active" id="tab-item-1">
    <div class="card-inner-item">
      <div class="row gy-base">
        <div class="col-lg-6">
          <div class="input-group m-0">
            <form-field-wrapper
              :v="$v.firstName"
              :validator="$v.firstName"
              :attribute="$t('autorization-name')"
            >
              <input
                class="input-control"
                type="text"
                v-model="firstName"
                :placeholder="$t('autorization-name')"
                required
              />
            </form-field-wrapper>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="input-group m-0">
            <form-field-wrapper
              :v="$v.lastName"
              :validator="$v.lastName"
              :attribute="$t('autorization-surname')"
            >
              <input
                v-model="lastName"
                class="input-control"
                type="text"
                :placeholder="$t('autorization-surname')"
                required
              />
            </form-field-wrapper>
          </div>
        </div>
        <div class="col-12">
          <div class="input-group m-0">
            <button
              class="button w-sm-100"
              type="button"
              @click="twoFactorEmit"
            >
              <span>{{ $t('plans-text-21') }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <two-factor-modal
      v-if="twoFactorModal"
      :show="twoFactorModal"
      :validation="validation"
      @validation-complete="changeUserName"
      @close-modal="closeTwoFactorModal"
    />
  </div>
</template>
<script>
import FormFieldWrapper from '@/components/global/FormFieldWrapper.vue'
import TwoFactorModal from '@/components/global/TwoFactorModal.vue'
import { rules } from '@/libs/validator'
export default {
  name: 'PersonalDataTab',
  components: {
    TwoFactorModal,
    FormFieldWrapper
  },
  validations: {
    firstName: rules.firstNameRule,
    lastName: rules.lastNameRule
  },
  data() {
    return {
      twoFactorModal: false,
      validation: null,
      firstName: '',
      lastName: ''
    }
  },
  methods: {
    twoFactorEmit() {
      this.$v.$touch()
      if (this.$v.$invalid) return
      let data = { name: this.firstName, last_name: this.lastName }
      this.$store
        .dispatch('user/twoFactorEmit', data)
        .then((response) => {
          this.twoFactorModal = true
          this.validation = response.data.validation
        })
        .catch((error) => this.$showError(error.detail))
    },
    changeUserName() {
      let data = { name: this.firstName, last_name: this.lastName }
      this.$store.commit('user/SET_USERNAME', data)
      this.closeTwoFactorModal()
      this.firstName = ''
      this.lastName = ''
      this.$v.$reset()
    },
    closeTwoFactorModal() {
      this.twoFactorModal = false
      this.validation = null
    }
  }
}
</script>
