<template>
  <div>
    <div class="modal-backdrop fade show" v-if="show"></div>
    <div
      class="modal fade show"
      style="display: block"
      id="modal-deposit"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content card modal-card">
          <div class="modal-card-inner">
            <button
              class="btn-close modal-close"
              type="button"
              @click="closeModal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.863757 0.860853C1.19357 0.531039 1.7283 0.531039 2.05812 0.860853L13.4046 12.2073C13.7344 12.5371 13.7344 13.0718 13.4046 13.4016V13.4016C13.0747 13.7315 12.54 13.7315 12.2102 13.4016L0.863757 2.05521C0.533944 1.7254 0.533943 1.19067 0.863757 0.860853V0.860853Z"
                  fill="#625B7A"
                />
                <path
                  d="M0.862806 13.4019C0.532992 13.0721 0.532992 12.5373 0.862806 12.2075L12.2092 0.861073C12.5391 0.531259 13.0738 0.531259 13.4036 0.861073V0.861073C13.7334 1.19089 13.7334 1.72562 13.4036 2.05543L2.05717 13.4019C1.72735 13.7317 1.19262 13.7317 0.862806 13.4019V13.4019Z"
                  fill="#625B7A"
                />
              </svg>
            </button>

            <p v-if="loading">Loading...</p>

            <div v-else>
              <div class="modal-card-header" v-if="!wallet">
                {{ $t('email-verification-1') }}
              </div>
              <div class="modal-card-header" v-else>
                {{ $t('dashboard-text-7') }}
              </div>

              <email-verification-block
                v-if="!wallet"
                @close-modal="closeModal"
              />
              <wallet-block v-else />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EmailVerificationBlock from './EmailVerificationBlock.vue'
import WalletBlock from './WalletBlock.vue'
import { mapGetters } from 'vuex'
// import LoadingPage from '../global/preloaders/LoadingPage.vue'
export default {
  components: { WalletBlock, EmailVerificationBlock },
  name: 'ModalDeposit',
  // components: { LoadingPage },
  props: {
    show: {
      type: Boolean,
      require: true
    }
  },
  data() {
    return {
      loading: false
    }
  },
  created() {
    this.getWalletsData()
  },
  computed: {
    ...mapGetters({
      wallet: 'user/getUserWallet',
      emailVerified: 'user/getEmailVerify'
    })
  },

  methods: {
    getWalletsData() {
      this.loading = true
      this.$store
        .dispatch('user/getWallets')
        .catch((error) => this.$showError(error.detail))
        .finally(() => (this.loading = false))
    },
    closeModal() {
      this.$emit('close-modal')
    }
  }
}
</script>
