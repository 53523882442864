<template>
  <div class="col-md-3 app__content-col">
    <div class="card card_inside p-0 bg-transparent">
      <div class="card-inner">
        <div class="chart">
          <div class="chart_header">
            <h3 class="chart_header-title">
              {{ $t('dashboard-subtitle-7') }}:
            </h3>
            <div class="chart_header-period">
              {{ $t('dashboard-text-16') }}:
              <span class="chart_header-period-val"> {{ period }}</span>
            </div>
          </div>
          <div class="chart_inner flex-fill d-flex-center">
            <div class="w-100" id="chart-1">
              <apexchart :options="chartOptions" :series="series"></apexchart>
            </div>
          </div>
          <div class="chart_footer">
            <div class="chart_meta">
              <div
                class="chart_meta-dot"
                style="background-color: #51a6fe"
              ></div>
              <div class="chart_meta-title">{{ $t('done') }}</div>
              <div class="chart_meta-val">{{ done.toFixed(2) }} USDT</div>
            </div>
            <div class="chart_meta">
              <div
                class="chart_meta-dot"
                style="background-color: #141e2c"
              ></div>
              <div class="chart_meta-title">{{ $t('purpose') }}</div>
              <div class="chart_meta-val">{{ purpose.toFixed(2) }} USDT</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { mapGetters } from 'vuex'

export default {
  props: {
    done: {
      type: Number,
      default: 0,
      required: true
    },
    purpose: {
      type: Number,
      default: 0,
      required: true
    },
    period: {
      type: String,
      default: '-',
      required: true
    }
  },
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      isDarkTheme: 'app/getAppTheme'
    }),
    chartOptions() {
      return {
        chart: {
          type: 'radialBar',
          parentHeightOffset: 0,
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: '#141E2C'
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                offsetY: 0,
                fontSize: '18px',
                fontFamily: 'Onest, Arial, Helvetica, sans-serif',
                fontWeight: 600,
                color: this.isDarkTheme === 'dark' ? '#ffffff' : '#070707'
              }
            }
          }
        },
        fill: {
          colors: ['#51A6FE']
        }
      }
    },

    series() {
      let percent = this.purpose ? (this.done / this.purpose) * 100 : 0 //calculate percent
      return [percent.toFixed(0)]
    }
  }
}
</script>
