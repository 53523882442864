<template>
  <div class="app__content flex-fill">
    <page-title />
    <p v-if="loading">loading...</p>
    <div v-else>
      <div class="app__content-item mb-5">
        <div class="row gy-base">
          <affiliate-stats />
          <referral-block />
        </div>
      </div>
      <team-bonuses />
      <referrals-lvl-table />
      <referrals-search-table />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PageTitle from '../main/PageTitle.vue'
import AffiliateStats from '@/components/affiliateProgram/AffiliateStats.vue'
import ReferralBlock from '@/components/affiliateProgram/ReferralBlock.vue'
import TeamBonuses from './TeamBonuses.vue'
import ReferralsLvlTable from './ReferralsLvlTable.vue'
import ReferralsSearchTable from './ReferralsSearchTable.vue'
export default {
  name: 'AffiliateProgramPage',
  components: {
    PageTitle,
    AffiliateStats,
    ReferralBlock,
    TeamBonuses,
    ReferralsLvlTable,
    ReferralsSearchTable
  },
  data() {
    return {
      loading: false
    }
  },
  created() {
    this.loadData()
  },
  computed: {
    ...mapGetters({
      user: 'user/getUser',
      referals: 'user/getReferals'
    })
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    loadData() {
      this.loading = true
      Promise.all([this.getUserData(), this.getReferals(), this.getBalance()])
        .then(() => {
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    getBalance() {
      this.$store.dispatch('user/getBalance')
    },
    getUserData() {
      return this.$store.dispatch('user/getUserData')
    },
    getReferals() {
      return this.$store.dispatch('user/getReferals')
    }
  }
}
</script>
