<template>
  <div class="app__panel-nav-icon d-flex-center">
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.5 1.25C4.94365 1.25 1.25 4.94365 1.25 9.5C1.25 11.7385 2.14104 13.7685 3.58904 15.2553C4.68227 13.1017 6.91817 11.625 9.5 11.625C12.0818 11.625 14.3177 13.1017 15.411 15.2553C16.859 13.7685 17.75 11.7385 17.75 9.5C17.75 4.94365 14.0563 1.25 9.5 1.25ZM14.6375 15.9556C13.7592 13.9921 11.7887 12.625 9.5 12.625C7.21133 12.625 5.24083 13.9921 4.36254 15.9556C5.7722 17.079 7.5572 17.75 9.5 17.75C11.4428 17.75 13.2278 17.079 14.6375 15.9556ZM0.25 9.5C0.25 4.39137 4.39137 0.25 9.5 0.25C14.6086 0.25 18.75 4.39137 18.75 9.5C18.75 12.2875 17.5164 14.7874 15.5671 16.4825C13.9433 17.8946 11.8209 18.75 9.5 18.75C7.1791 18.75 5.05674 17.8946 3.43292 16.4825C1.48359 14.7874 0.25 12.2875 0.25 9.5Z"
        fill="#57435F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.5 9C8.32639 9 7.375 8.04861 7.375 6.875C7.375 5.70139 8.32639 4.75 9.5 4.75C10.6736 4.75 11.625 5.70139 11.625 6.875C11.625 8.04861 10.6736 9 9.5 9ZM6.375 6.875C6.375 8.60089 7.77411 10 9.5 10C11.2259 10 12.625 8.60089 12.625 6.875C12.625 5.14911 11.2259 3.75 9.5 3.75C7.77411 3.75 6.375 5.14911 6.375 6.875Z"
        fill="#57435F"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'PersonalInformationMenuIcon'
}
</script>
