<template>
  <div class="app__panel-nav-icon d-flex-center">
    <svg
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.125 2.5C0.125 1.25736 1.13236 0.25 2.375 0.25H14.625C15.8676 0.25 16.875 1.25736 16.875 2.5V15.7601C16.875 17.3184 15.3291 18.4049 13.8629 17.8771L12.4963 17.3851C12.1913 17.2753 11.8556 17.2883 11.56 17.4213L9.42332 18.3828C8.83613 18.647 8.16387 18.647 7.57668 18.3828L5.44002 17.4213C5.1444 17.2883 4.80867 17.2753 4.50366 17.3851L3.13712 17.8771C1.67088 18.4049 0.125 17.3184 0.125 15.7601V2.5ZM2.375 1.25C1.68464 1.25 1.125 1.80964 1.125 2.5V15.7601C1.125 16.6258 1.98382 17.2294 2.7984 16.9362L4.16495 16.4442C4.71395 16.2466 5.31828 16.2699 5.85038 16.5094L7.98704 17.4709C8.31326 17.6177 8.68674 17.6177 9.01296 17.4709L11.1496 16.5094C11.6817 16.2699 12.286 16.2466 12.8351 16.4442L14.2016 16.9362C15.0162 17.2294 15.875 16.6258 15.875 15.7601V2.5C15.875 1.80964 15.3154 1.25 14.625 1.25H2.375ZM4.5 5.125C4.5 4.84886 4.72386 4.625 5 4.625H8.5C8.77614 4.625 9 4.84886 9 5.125C9 5.40114 8.77614 5.625 8.5 5.625H5C4.72386 5.625 4.5 5.40114 4.5 5.125ZM4.5 8.625C4.5 8.34886 4.72386 8.125 5 8.125H12C12.2761 8.125 12.5 8.34886 12.5 8.625C12.5 8.90114 12.2761 9.125 12 9.125H5C4.72386 9.125 4.5 8.90114 4.5 8.625ZM4.5 12.125C4.5 11.8489 4.72386 11.625 5 11.625H12C12.2761 11.625 12.5 11.8489 12.5 12.125C12.5 12.4011 12.2761 12.625 12 12.625H5C4.72386 12.625 4.5 12.4011 4.5 12.125Z"
        fill="#57435F"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'NewsMenuIcon'
}
</script>
