<template>
  <div class="loading">
    <div class="loading__spinner"></div>
  </div>
</template>
<script>
export default {
  name: 'LoadingComponent'
}
</script>
<style lang="scss">
.loading {
  &__spinner {
    display: inline-block;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #3498db;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    margin: 9px 0;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
