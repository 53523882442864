<template>
  <div class="app__panel-nav-icon d-flex-center">
    <svg
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.17448 8.30669C1.95714 5.95872 4.15443 4.375 6.62941 4.375H7.81144C10.2864 4.375 12.4837 5.95872 13.2664 8.30669L13.9975 10.5H14.2204C15.4631 10.5 16.4704 11.5074 16.4704 12.75C16.4704 13.4568 16.1445 14.0875 15.6348 14.5C16.1445 14.9125 16.4704 15.5432 16.4704 16.25C16.4704 17.4926 15.4631 18.5 14.2204 18.5H8.97042C8.87423 18.5 8.77945 18.494 8.68644 18.4822V18.5H5.75441C1.8297 18.5 -0.941622 14.655 0.299479 10.9317L1.17448 8.30669ZM7.09931 17.5C6.85999 17.1425 6.72042 16.7125 6.72042 16.25C6.72042 15.5432 7.04634 14.9125 7.55609 14.5C7.04634 14.0875 6.72042 13.4568 6.72042 12.75C6.72042 11.5074 7.72778 10.5 8.97042 10.5H12.9434L12.3177 8.62292C11.6711 6.68329 9.85598 5.375 7.81144 5.375H6.62941C4.58486 5.375 2.7697 6.68329 2.12316 8.62292L1.24816 11.2479C0.222905 14.3237 2.51226 17.5 5.75441 17.5H7.09931ZM8.97042 14C8.28007 14 7.72042 13.4404 7.72042 12.75C7.72042 12.0596 8.28007 11.5 8.97042 11.5H14.2204C14.9108 11.5 15.4704 12.0596 15.4704 12.75C15.4704 13.4404 14.9108 14 14.2204 14H8.97042ZM14.2204 15H8.97042C8.28007 15 7.72042 15.5596 7.72042 16.25C7.72042 16.9404 8.28007 17.5 8.97042 17.5H14.2204C14.9108 17.5 15.4704 16.9404 15.4704 16.25C15.4704 15.5596 14.9108 15 14.2204 15Z"
        fill="#57435F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.97064 1.07174C4.66371 0.65013 3.70135 2.17094 4.54624 3.14003L5.62273 4.37477L8.81819 4.37477L9.89468 3.14003C10.7396 2.17094 9.77721 0.65013 8.47028 1.07174L7.65848 1.33362C7.37385 1.42544 7.06707 1.42544 6.78244 1.33362L5.97064 1.07174ZM3.79248 3.79718C2.23386 2.00944 4.07545 -0.590384 6.27765 0.120033L7.08945 0.381914C7.17448 0.409342 7.26645 0.409342 7.35147 0.381914L8.16327 0.120033C10.3655 -0.590384 12.2071 2.00944 10.6484 3.79718L9.42249 5.20335C9.32754 5.31226 9.19011 5.37477 9.04561 5.37477L5.39531 5.37477C5.25081 5.37477 5.11338 5.31226 5.01843 5.20334L3.79248 3.79718Z"
        fill="#57435F"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'BankingMenuIcon'
}
</script>
