<template>
  <div class="app__content flex-fill">
    <page-title />
    <div class="app__content-item">
      <div class="card">
        <div class="card-inner p-0 tabs">
          <user-widget />

          <div class="card-inner-item">
            <div class="tabs-buttons d-flex flex-wrap">
              <div
                v-for="(tab, idx) in tabList"
                :key="idx + tab.component"
                class="tabs-button"
                :class="{ active: selectedTab === tab.component }"
                @click="selectTab(tab.component)"
              >
                {{ $t(tab.label) }}
              </div>
            </div>
          </div>
          <div class="tabs-content">
            <component :is="selectedTab" />
          </div>
        </div>
      </div>
    </div>
    <session-component />
  </div>
</template>

<script>
import PasswordTab from './tabs/PasswordTab.vue'
import VerificationTab from './tabs/VerificationTab.vue'
import TwoFactorTab from './tabs/TwoFactorTab.vue'
import PersonalDataTab from './tabs/PersonalDataTab.vue'
import PageTitle from '../main/PageTitle.vue'
import SessionComponent from './SessionComponent.vue'
import UserWidget from './UserWidget.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'PersonalInfoPage',
  components: {
    PasswordTab,
    VerificationTab,
    TwoFactorTab,
    PersonalDataTab,
    PageTitle,
    UserWidget,
    SessionComponent
  },
  data() {
    return {
      // selectedTab: 'PersonalDataTab',
      tabRouts: {
        password: 'PasswordTab',
        twofactor: 'TwoFactorTab',
        personaldata: 'PersonalDataTab',
        verification: 'VerificationTab'
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/getUser',

      selectedTab: 'app/getSelectedTab'
    }),
    tabList() {
      return [
        { label: 'personal-text-1', component: 'PersonalDataTab' },
        { label: 'personal-text-2', component: 'TwoFactorTab' },
        { label: 'personal-text-3', component: 'PasswordTab' },
        { label: 'personal-text-4', component: 'VerificationTab' }
      ]
    }
  },
  created() {
    let tgId = this.$route.query.tg_id
    tgId ? this.enableTelegramTF(tgId) : this.getTwoFactorData()
  },
  mounted() {
    this.scrollToTop()
    if (this.$route.query.tab) {
      this.selectTab(this.tabRouts[this.$route.query.tab])
    }
  },
  methods: {
    selectTab(tab) {
      this.$store.dispatch('app/selectPersonalPageTab', tab)
      // this.selectedTab = tab
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    getTwoFactorData() {
      this.$store
        .dispatch('user/getTwoFactorData')
        .then((response) => {
          console.log(response)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    enableTelegramTF(tgId) {
      this.$store
        .dispatch('user/enableTwoFactor', { tg_id: tgId })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.$router.push({ name: 'Profile', params: { tab: 'TelegramTab' } })
        })
    }
  }
}
</script>
