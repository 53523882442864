<template>
  <div class="cookies card card_inside">
    <div class="card-inner d-flex cookies-inner">
      <div class="cookies-text">
        {{ $t('cookies-1') }}
        <a @click="openFile(file)">{{ $t('cookies-2') }}</a>
      </div>
      <button class="button cookies-confirm" type="button" @click="ok">
        <span>{{ $t('ok') }}</span>
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CookiesModal',
  data() {
    return {
      file: {
        fileType: 'Policy',
        ru: '/docs/Privacy_Policy_ru.pdf',
        default: '/docs/Privacy_Policy_en.pdf'
      }
    }
  },
  computed: {
    ...mapGetters({
      locale: 'app/getLocale'
    })
  },
  methods: {
    ok() {
      this.$store.commit('app/SET_AGREE_PRIVACY', true)
    },
    openFile(file) {
      const fileToOpen = this.locale === 'ru' ? file.ru : file.default
      const win = window.open(fileToOpen, '_blank')
      win.focus()
    }
  }
}
</script>
