<template>
  <header class="app__header d-flex align-items-center justify-content-between">
    <div class="d-xxl-none">
      <a class="logo" href="https://coinfuze.com/" target="_blank">
        <img
          class="dark-theme-only"
          src="@/assets/images/logo-white.svg"
          alt="logo"
        />
        <img
          class="light-theme-only"
          src="@/assets/images/logo-black.svg"
          alt="logo"
        />
      </a>
    </div>
    <h1
      class="m-0 flex-fill app__header-title"
      v-if="$route.meta.title !== 'backPlanManagement'"
    >
      {{ $t($route.meta.title) }}
    </h1>
    <router-link
      v-if="$route.meta.title === 'backPlanManagement'"
      class="button__link app__header-title"
      to="plans"
    >
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.16699 7L12.8337 7M1.16699 7L6.16699 12M1.16699 7L6.16699 2"
          stroke="#625B7A"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      {{ $t('back') }}
    </router-link>
    <div class="app__header-actions d-flex align-items-center">
      <div
        class="card app__header-action dropdown"
        :class="{ active: windowsOpened.balance }"
        @click="toggleWindow('balance')"
      >
        <div class="card-inner app__header-action-inner p-0 dropdown__toggle">
          <div class="app__header-action-icon d-flex-center">
            <svg
              class="fill-blue"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22 7C22 4.79086 20.2091 3 18 3H6C3.79086 3 2 4.79086 2 7V17C2 19.2091 3.79086 21 6 21H18C20.2091 21 22 19.2091 22 17V7Z"
                stroke="#37BBC6"
                stroke-linejoin="round"
              />
              <path
                d="M8 12C8 10.3431 6.65685 9 5 9H2V15H5C6.65685 15 8 13.6569 8 12V12Z"
                stroke="#37BBC6"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="app__header-action-content flex-fill">
            <div class="app__header-action-info">{{ $t('header-item-1') }}</div>
            <div class="app__header-action-val fw-600">
              {{ formatBalance(balance.total) }}
            </div>
          </div>
          <div class="dropdown__toggle-arrow d-flex-center">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 7.5L10 12.5L15 7.5"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <balance-window
          v-if="windowsOpened.balance"
          v-click-outside="() => closeWindow('balance')"
        />
      </div>

      <div
        class="card app__header-action dropdown"
        :class="{ active: windowsOpened.notification }"
      >
        <div
          class="card-inner app__header-action-inner p-0 dropdown__toggle no_arrow"
          @click="toggleWindow('notification')"
        >
          <div class="app__header-action-icon d-flex-center">
            <svg
              class="fill-blue"
              width="24"
              height="24"
              viewBox="0 0 18 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              v-if="!haveUnreadedNotification"
            >
              <path
                d="M15.6652 9.80283L16.1621 9.74676L15.6652 9.80283ZM15.3204 6.74652L14.8235 6.80258L15.3204 6.74652ZM16.5445 11.8523L16.1628 12.1753L16.5445 11.8523ZM1.82936 9.74676C1.86032 9.47236 2.10787 9.27502 2.38227 9.30598C2.65667 9.33694 2.85401 9.58449 2.82305 9.85889L1.82936 9.74676ZM1.45549 11.8523L1.83716 12.1753L1.45549 11.8523ZM9.92828 1V0.5C9.95336 0.5 9.97841 0.501887 10.0032 0.505646L9.92828 1ZM5.53158 17.1671C5.435 16.9084 5.56643 16.6204 5.82513 16.5238C6.08383 16.4272 6.37184 16.5586 6.46842 16.8173L5.53158 17.1671ZM11.5316 16.8173C11.6282 16.5586 11.9162 16.4272 12.1749 16.5238C12.4336 16.6204 12.565 16.9084 12.4684 17.1671L11.5316 16.8173ZM8 1.5C7.72386 1.5 7.5 1.27614 7.5 1C7.5 0.723858 7.72386 0.5 8 0.5V1.5ZM2.83013 14.4844H15.1699V15.4844H2.83013V14.4844ZM15.1684 9.85889L14.8235 6.80258L15.8172 6.69045L16.1621 9.74676L15.1684 9.85889ZM16.1628 12.1753C15.6109 11.5232 15.265 10.7153 15.1684 9.85889L16.1621 9.74676C16.2368 10.4092 16.5041 11.0306 16.9262 11.5293L16.1628 12.1753ZM2.82305 9.85889C2.72683 10.7116 2.39137 11.5205 1.83716 12.1753L1.07383 11.5293C1.49355 11.0334 1.75419 10.4129 1.82936 9.74676L2.82305 9.85889ZM15.1699 14.4844C16.2809 14.4844 16.9425 13.0965 16.1628 12.1753L16.9262 11.5293C18.2133 13.0502 17.1975 15.4844 15.1699 15.4844V14.4844ZM2.83013 15.4844C0.802546 15.4844 -0.213346 13.0502 1.07383 11.5293L1.83716 12.1753C1.05752 13.0965 1.71908 14.4844 2.83013 14.4844V15.4844ZM14.8235 6.80258C14.5103 4.02723 12.4309 1.88502 9.85336 1.49435L10.0032 0.505646C13.0424 0.966266 15.455 3.48041 15.8172 6.69045L14.8235 6.80258ZM6.46842 16.8173C6.82963 17.7849 7.81646 18.5 9 18.5V19.5C7.41348 19.5 6.04374 18.539 5.53158 17.1671L6.46842 16.8173ZM9 18.5C10.1835 18.5 11.1704 17.7849 11.5316 16.8173L12.4684 17.1671C11.9563 18.539 10.5865 19.5 9 19.5V18.5ZM8 0.5H9.92828V1.5H8V0.5Z"
                fill="#37BBC6"
              />
              <path
                d="M1.45544 11.8523C1.94242 11.2769 2.24902 10.5623 2.33471 9.80283L2.67959 6.74652C3.01729 3.75382 5.26329 1.42564 8.07167 1"
                stroke="#37BBC6"
                stroke-linecap="round"
              />
            </svg>

            <svg
              class="fill-blue"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              v-else
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.5663 3.99476C11.6075 4.26781 11.4196 4.52258 11.1465 4.5638C8.56782 4.95311 6.48955 7.08767 6.17661 9.85023L5.83173 12.8947C5.735 13.7486 5.38879 14.5539 4.83669 15.2037C4.06035 16.1175 4.71598 17.4999 5.83029 17.4999H18.17C19.2843 17.4999 19.9399 16.1175 19.1636 15.2037C18.6115 14.5539 18.2653 13.7486 18.1686 12.8947L17.9407 10.8829C17.9096 10.6085 18.1068 10.3609 18.3812 10.3298C18.6556 10.2987 18.9032 10.496 18.9343 10.7704L19.1622 12.7821C19.2369 13.4412 19.5039 14.0597 19.9257 14.5562C21.2162 16.0751 20.1944 18.4999 18.17 18.4999H5.83029C3.80594 18.4999 2.78412 16.0751 4.0746 14.5562C4.49644 14.0597 4.76342 13.4412 4.83809 12.7821L5.18296 9.73767C5.54542 6.53804 7.95915 4.03368 10.9972 3.57501C11.2703 3.53378 11.525 3.72172 11.5663 3.99476ZM8.82468 19.5317C9.08326 19.4348 9.37144 19.5659 9.46835 19.8244C9.82906 20.7869 10.8154 21.4999 12.0001 21.4999C13.1849 21.4999 14.1712 20.7869 14.5319 19.8244C14.6289 19.5659 14.917 19.4348 15.1756 19.5317C15.4342 19.6286 15.5653 19.9168 15.4683 20.1754C14.9557 21.5433 13.5855 22.4999 12.0001 22.4999C10.4148 22.4999 9.04461 21.5433 8.53195 20.1754C8.43504 19.9168 8.5661 19.6286 8.82468 19.5317Z"
                fill="#37BBC6"
              />
              <path
                d="M20 5C20 6.65685 18.6569 8 17 8C15.3431 8 14 6.65685 14 5C14 3.34315 15.3431 2 17 2C18.6569 2 20 3.34315 20 5Z"
                fill="#37BBC6"
              />
            </svg>
          </div>
        </div>

        <notification-window
          v-if="windowsOpened.notification"
          v-click-outside="() => closeWindow('notification')"
          @close-window="closeWindow('notification')"
          @show-notifications="showAllNotifications"
        ></notification-window>
      </div>
      <div
        class="card app__header-action dropdown"
        :class="{ active: windowsOpened.profile }"
      >
        <div
          class="card-inner app__header-action-inner p-0 dropdown__toggle"
          @click="toggleWindow('profile')"
        >
          <div class="app__header-action-icon d-flex-center">
            <user-avatar :key="avatarKey" />
          </div>
          <div class="app__header-action-content flex-fill">
            <div class="app__header-action-val">{{ formattedName }}</div>
          </div>
          <div class="dropdown__toggle-arrow d-flex-center">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 7.5L10 12.5L15 7.5"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <profile-window
          @close-window="closeWindow('profile')"
          v-if="windowsOpened.profile"
          v-click-outside="() => closeWindow('profile')"
          :initials="initials"
          :user="user"
          :freeBalance="formatBalance(balance.free)"
        />
      </div>

      <notification-modal
        :show="isAllNotificationOpened"
        @close-modal="closeNotificationModal"
      />
      <div class="app__header-action d-xxl-none">
        <button
          class="button__toggle"
          :class="{ active: isSidebarOpen }"
          @click.stop="toggleSidebar"
          type="button"
        >
          <span></span><span></span><span></span>
        </button>
      </div>
    </div>
  </header>
</template>

<script>
import BalanceWindow from '@/components/global/BalanceWindow.vue'
import NotificationWindow from '@/components/global/NotificationWindow.vue'
import ProfileWindow from '@/components/global/ProfileWindow.vue'
import NotificationModal from '../global/NotificationModal.vue'
import UserAvatar from '@/components/global/UserAvatar.vue'

import vClickOutside from 'v-click-outside'
import { mapGetters } from 'vuex'

export default {
  name: 'AppHeader',
  components: {
    BalanceWindow,
    NotificationWindow,
    ProfileWindow,
    UserAvatar,
    NotificationModal
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    isSidebarOpen: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data() {
    return {
      clickOutsideHandled: false,
      windowsOpened: {
        balance: false,
        notification: false,
        profile: false
      },
      isAllNotificationOpened: false
    }
  },
  computed: {
    ...mapGetters({
      locale: 'app/getLocale',
      avatarKey: 'user/getAvatarKey',
      balance: 'user/getBalance',
      notifications: 'notification/getLastNotification',
      haveUnreadedNotification: 'notification/getUnreadedCount',
      user: 'user/getUser'
    }),
    formattedName() {
      let last_name = ''
      if (this.user.last_name) {
        last_name = this.user.last_name
      }
      const fullName = this.user.name + ' ' + last_name
      if (fullName.length < 30) {
        return fullName
      } else {
        if (this.user.name.length < 10) {
          return this.user.name
        } else {
          return this.initials
        }
      }
    },
    initials() {
      let name = this.user.name.charAt(0).toUpperCase()
      let last_name = ''
      if (this.user.last_name) {
        last_name = this.user.last_name.charAt(0).toUpperCase()
      }
      return name + last_name
    }
  },
  methods: {
    closeSidebar() {
      this.$emit('close-sidebar')
    },
    toggleSidebar() {
      this.closeAllWindows()
      this.$emit('toggle-sidebar')
    },
    toggleWindow(windowName) {
      this.closeAllWindows()
      if (this.clickOutsideHandled) return
      this.closeSidebar()

      this.windowsOpened[windowName] = !this.windowsOpened[windowName]
    },
    closeWindow(windowName) {
      this.windowsOpened[windowName] = false
      this.clickOutsideHandled = true
      setTimeout(() => {
        this.clickOutsideHandled = false
      }, 500)
    },
    closeAllWindows() {
      Object.keys(this.windowsOpened).forEach((key) => {
        this.windowsOpened[key] = false
      })
    },

    showAllNotifications() {
      this.isAllNotificationOpened = true
      this.closeWindow('notification')
    },

    closeNotificationModal() {
      this.isAllNotificationOpened = false
    },

    formatBalance(balance) {
      return new Intl.NumberFormat(this.locale).format(balance) + ' USDT'
    }
  }
}
</script>
