<template>
  <div
    class="modal fade"
    :class="{ show: show }"
    :style="{ display: show ? 'block' : 'none' }"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content card modal-card">
        <div class="modal-card-inner">
          <button
            class="btn-close modal-close"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.863757 0.860853C1.19357 0.531039 1.7283 0.531039 2.05812 0.860853L13.4046 12.2073C13.7344 12.5371 13.7344 13.0718 13.4046 13.4016V13.4016C13.0747 13.7315 12.54 13.7315 12.2102 13.4016L0.863757 2.05521C0.533944 1.7254 0.533943 1.19067 0.863757 0.860853V0.860853Z"
                fill="#625B7A"
              />
              <path
                d="M0.862806 13.4019C0.532992 13.0721 0.532992 12.5373 0.862806 12.2075L12.2092 0.861073C12.5391 0.531259 13.0738 0.531259 13.4036 0.861073V0.861073C13.7334 1.19089 13.7334 1.72562 13.4036 2.05543L2.05717 13.4019C1.72735 13.7317 1.19262 13.7317 0.862806 13.4019V13.4019Z"
                fill="#625B7A"
              />
            </svg>
          </button>
          <div class="modal-card-header">{{ $t('two-factor-1') }}</div>
          <div class="modal-card-content">
            <div class="input-group mb-4">
              <label class="input-label">
                {{ $t('two-factor-2') }}
              </label>
              <div class="row mb-3">
                <div class="col-8">
                  <input
                    v-model="emailPass"
                    class="input-control"
                    type="text"
                    :placeholder="$t('two-factor-8')"
                    required
                  />
                </div>

                <div class="col-4">
                  <div v-if="!emailValidated">
                    <button
                      v-if="countdown === 0"
                      class="button w-sm-100"
                      type="button"
                      @click="resendEmailPass"
                    >
                      <span>{{ $t('two-factor-3') }}</span>
                    </button>
                    <button
                      v-else
                      class="button w-sm-100"
                      type="button"
                      @click="validate('email')"
                    >
                      <span>{{ $t('two-factor-4') }} </span>
                    </button>
                  </div>
                  <span v-else>{{ $t('two-factor-5') }}</span>
                </div>
              </div>

              <div class="input-meta" v-if="!emailValidated">
                {{ $t('two-factor-6') }}
                <span class="text-accent">{{ formattedTime }}</span>
              </div>
            </div>

            <div class="input-group m-0" v-if="twoFactorData.aditional_service">
              <label class="input-label">
                {{ $t('two-factor-7') }}
                {{ twoFactorData.aditional_service }}:
              </label>
              <div class="row mb-3">
                <div class="col-8">
                  <input
                    class="input-control"
                    type="text"
                    v-model="additionalPass"
                    :placeholder="$t('two-factor-8')"
                    required
                  />
                </div>
                <div class="col-4">
                  <button
                    v-if="!aditionalValidated"
                    class="button w-sm-100"
                    type="button"
                    @click="validate(twoFactorData.aditional_service)"
                  >
                    <span> {{ $t('two-factor-4') }} </span>
                  </button>
                  <span v-else> {{ $t('two-factor-5') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TwoFactorModal',

  props: {
    show: {
      type: Boolean,
      require: true
    },
    validation: {
      require: true,
      type: Object
    }
  },
  data() {
    return {
      loading: false,
      timer: null,
      emailPass: '',
      additionalPass: '',
      countdown: 180,
      emailValidated: false,
      aditionalValidated: false
    }
  },
  computed: {
    ...mapGetters({
      twoFactorData: 'user/getTwoFactorData'
    }),
    formattedTime() {
      const minutes = Math.floor(this.countdown / 60)
      const seconds = this.countdown % 60
      return `${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')}`
    }
  },
  mounted() {
    this.startTimer()
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  methods: {
    closeModal() {
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.$emit('close-modal')
    },
    validationComplite() {
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.$emit('validation-complete')
    },
    resendEmailPass() {
      let payload = {
        task_id: this.validation.task_id,
        resend_email: true
      }
      this.loading = true
      this.$store
        .dispatch('user/resendEmailPass', payload)
        .then(() => {
          this.startTimer()
        })
        .catch((error) => this.$showError(error.detail))
        .finally(() => (this.loading = false))
    },
    validate(type) {
      let payload = {
        task_id: this.validation.task_id,
        google: '',
        telegram: '',
        email: ''
      }
      switch (type) {
        case 'email':
          payload.email = this.emailPass
          break
        case 'Telegram Authenticator':
          payload.telegram = this.additionalPass
          break
        case 'Google Authenticator':
          payload.google = this.additionalPass
          break
      }

      this.loading = true
      this.$store
        .dispatch('user/validateTwoFactor', payload)
        .then((response) => {
          this.$showSuccess(response.response)
          type === 'email'
            ? (this.emailValidated = true)
            : (this.aditionalValidated = true)
        })
        .catch((error) => this.$showError(error.detail))
        .finally(() => {
          this.loading = false
          if (
            this.emailValidated &&
            (this.aditionalValidated || !this.validation.aditional_service)
          ) {
            this.validationComplite()
          }
        })
    },
    startTimer() {
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.countdown = 180
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--
        } else {
          clearInterval(this.timer)
        }
      }, 1000)
    }
  }
}
</script>
