<template>
  <div class="modal-card-content">
    <div class="user-links mb-4">
      <div class="user-links-item">
        <div class="card card_inside">
          <div class="card-inner d-flex alig-items-center">
            <div class="user-links-text flex-fill mw-100">
              {{ $t('deposit-popup-text-3') }}
              <span class="text-accent">{{ balance.free }} USDT</span
              ><img
                class="user-links-icon"
                src="@/assets/images/usdt.svg"
                alt="coinfuze"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="user-links-item">
        <div class="card card_inside">
          <div class="card-inner d-flex alig-items-center">
            <div class="user-links-text flex-fill">
              {{ $t('deposit-popup-text-4') }}
              <span class="text-accent">TRC20</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="message d-flex mb-4">
      <div class="message-icon">
        <img src="@/assets/images/warning-icon.svg" alt="coinfuze" />
      </div>
      <div class="message-text flex-fill">
        {{ $t('deposit-popup-text-6-1') }}
        <span class="text-blue">USDT</span>
        {{ $t('deposit-popup-text-6-2') }}
        <span class="text-blue">
          {{ $t('deposit-popup-text-6-3') }}
        </span>
        {{ $t('deposit-popup-text-6-4') }}
      </div>
    </div>
    <div class="modal-card-qr text-center mb-4">
      <img
        :src="wallet.qr"
        alt="coinfuze"
        :style="{ filter: theme === 'dark' ? 'invert(1)' : '' }"
      />
    </div>
    <div class="user-links">
      <div class="user-links-item">
        <div class="card card_inside">
          <div class="card-inner d-flex alig-items-center">
            <div class="user-links-text flex-fill">
              {{ $t('deposit-popup-text-7') }}
              <span class="text-accent">
                {{ formattedWallet }}
              </span>
            </div>
            <button
              class="button__clipboard"
              type="button"
              data-message="Copied!"
              data-value="TLqbsM1qW....UvTqHeVjA11gziy"
              @click="copyWallet"
            >
              <span class="tooltip__content" :class="{ show: flag }">{{
                $t('copied')
              }}</span>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.23223 1.23223C1.70107 0.763392 2.33696 0.5 3 0.5H11C11.663 0.5 12.2989 0.763392 12.7678 1.23223C13.2366 1.70107 13.5 2.33696 13.5 3V4.5H15C16.3807 4.5 17.5 5.61929 17.5 7V15C17.5 16.3807 16.3807 17.5 15 17.5H7C5.61929 17.5 4.5 16.3807 4.5 15V13.5H3C2.33696 13.5 1.70107 13.2366 1.23223 12.7678C0.763392 12.2989 0.5 11.663 0.5 11V3C0.5 2.33696 0.763392 1.70107 1.23223 1.23223ZM5.5 15C5.5 15.8284 6.17157 16.5 7 16.5H15C15.8284 16.5 16.5 15.8284 16.5 15V7C16.5 6.17157 15.8284 5.5 15 5.5H7C6.17157 5.5 5.5 6.17157 5.5 7V15ZM12.5 4.5H7C5.61929 4.5 4.5 5.61929 4.5 7V12.5H3C2.60218 12.5 2.22064 12.342 1.93934 12.0607C1.65804 11.7794 1.5 11.3978 1.5 11V3C1.5 2.60218 1.65804 2.22064 1.93934 1.93934C2.22064 1.65804 2.60218 1.5 3 1.5H11C11.3978 1.5 11.7794 1.65804 12.0607 1.93934C12.342 2.22064 12.5 2.60218 12.5 3V4.5Z"
                  fill="#625B7A"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'WalletBlock',
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      loading: false,
      flag: false
    }
  },
  computed: {
    ...mapGetters({
      wallet: 'user/getUserWallet',
      theme: 'app/getAppTheme',
      balance: 'user/getBalance'
    }),
    formattedWallet() {
      let wallet = this.wallet.wallet
      return (
        wallet.substring(0, 7) + '....' + wallet.substring(wallet.length - 7)
      )
    }
  },

  methods: {
    flagClick() {
      this.flag = true
      setTimeout(() => {
        this.flag = false
      }, 1000)
    },
    copyWallet() {
      const tempInput = document.createElement('input')
      tempInput.value = this.wallet.wallet
      document.body.appendChild(tempInput)
      tempInput.select()
      document.execCommand('copy')
      document.body.removeChild(tempInput)
      this.flagClick()
    }
  }
}
</script>
<style lang="css" scoped>
.tooltip__content.show {
  opacity: 1;
  transition: 1s;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.tooltip__content {
  padding-left: 50px;
  right: -20px;
  top: -30px;
  background: #1b2129;
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  color: #fff;
  display: inline-block;
  font-size: 12px;
  padding: 5px 8px;
  position: absolute;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  width: auto;
  opacity: 0;
}
</style>
