export default {
  'dashboard-text-5-1': 'سود از طرح‌های فعلی',
  'partnership-title': 'درخواست شراکت با کوین فیوز',
  'partnership-form-1': 'شناسه حساب شما',
  'partnership-form-2': 'شناسه سفیر',
  'partnership-form-3': 'پیوند به وبلاگ(ها):',
  'partnership-form-3-1': 'اینستاگرام، تلگرام، یوتیوب، توییتر و دیگران.',
  'partnership-form-4': 'تلگرام برای ارتباط',
  'partnership-form-5-1': 'من می‌پذیرم',
  'partnership-form-5-2': 'شرایط و ضوابط (همکاری)',
  'partnership-form-btn': 'ارسال',
  'partnership-form-footer': 'اطلاعات تماس:',
  'withdrawal-ph-1': 'شناسه کیف پول',
  'withdrawal-ph-2': 'مقدار برداشت',
  'confirm-modal-1': 'عمل را تایید کنید',
  'confirm-modal-2': 'تأیید',
  'confirm-modal-3': 'لغو',
  delete: 'حذف کردن',
  copy: 'کپی',
  download: 'دانلود',
  'modal-p-text-1': 'دستورالعمل‌های دقیق درباره کار با برنامه‌های سرمایه‌گذاری',
  'modal-p-text-2':
    'هنگامی که واریزی به حساب شما واریز شده است، می‌توانید مبالغ خود را در طرح انتخاب شده سرمایه‌گذاری کنید.',
  'modal-p-text-3':
    'برای فعال کردن طرح سرمایه‌گذاری خود، دستورالعمل‌های زیر را دنبال کنید.',
  'modal-p-text-4':
    "در حساب کاربری خود، به صفحه 'مدیریت طرح' بروید. اینجا اطلاعات دقیقی در مورد تمام محصولات سرمایه‌گذاری وجود دارد. این شامل دوره‌های سرمایه‌گذاری، هزینه‌های خدمات، و همچنین سودآوری طرح می‌شود.",
  'modal-p-text-5':
    "برای فعال کردن طرح موردنظر، روی دکمه 'انتخاب' کلیک کنید، مقدار سرمایه اولیه را که می‌خواهید با آن طرح راه‌اندازی کنید وارد کنید، و روی دکمه 'فعال‌سازی' کلیک کنید.",
  'modal-p-text-6':
    "با استفاده از ویژگی 'بازسازی خودکار'، می‌توانید خودکار مبالغ خود را به مدت طولانی سرمایه‌گذاری کنید و مزایای اضافی بدست آورید.",
  'modal-p-text-7':
    'فورا پس از شروع طرح سرمایه‌گذاری، می‌توانید گزینه را با یک کلیک فعال یا غیرفعال کنید.',
  'modal-p-text-8':
    'تبریک می‌گوییم، شما با موفقیت یک طرح سرمایه‌گذاری راه‌اندازی کرده‌اید!',
  'modal-p-text-9':
    'می‌توانید این تنظیمات را به دلخواه خود تغییر دهید، حتی پس از آغاز دوره اجرایی. اگر این گزینه را فعال کرده‌اید، پس از انقضای مدت زمان طرح سرمایه‌گذاری، تمام سرمایه، شامل تمام سود، به طور خودکار مجدداً سرمایه‌گذاری می‌شود. اگر این گزینه غیرفعال باشد، بازسازی خودکار انجام نمی‌شود، و تمام سرمایه با سود به موجودی آزاد اضافه می‌شود.',

  update: 'بارگذاری',
  'update-avatar': 'آواتار خود را بارگذاری کنید',
  save: 'ذخیره',
  'modal-d-text-1': 'دستورالعمل برای واریز وجه در CoinFuze',
  'modal-d-text-2': "به صفحه 'واریز' در حساب کاربری خود مراجعه کنید.",
  'modal-d-text-3':
    "برای شروع فرآیند واریز، بر روی دکمه 'افزودن موجودی' کلیک کنید.",
  'modal-d-text-4':
    'یک شناسه کیف پول منحصر به فرد برای ارسال وجه به شما ارائه می شود. لطفاً اطمینان حاصل کنید که این شناسه را هنگام انجام پرداخت استفاده می کنید.',
  'modal-d-text-5':
    'همچنین شما دارای گزینه استفاده از کد QR قرار داده شده در سمت چپ شناسه کیف پول هستید. می توانید آن را با تلفن همراه خود اسکن کنید تا اطلاعات را راحت‌تر وارد کنید.',
  'modal-d-text-6':
    'پس از ارسال وجه به کیف پول مشخص شده، وجوه به صورت خودکار به موجودی شما اضافه می شود. این معمولاً بین ۱ دقیقه و ۲ ساعت طول می کشد، اما ممکن است با توجه به بلاکچین و سایر عوامل زمان‌بندی متفاوت باشد.',
  'modal-d-text-7':
    "واریزهای تأیید شده در پایین صفحه در تب 'تاریخچه واریزها' نمایش داده می شوند. در اینجا می توانید تمام درخواست های واریز خود را پیگیری کنید.",
  'modal-d-text-8':
    'با دنبال کردن این راهنما، به راحتی و به صورت ایمن وجه خود را به حساب کاربری CoinFuze واریز کنید. اگر سوالی دارید یا نیاز به کمک بیشتری دارید، لطفاً با خدمات پشتیبانی ما تماس بگیرید.',

  'modal-w-text-1': 'برای شروع فرآیند برداشت، این دستورالعمل را دنبال کنید:',
  'modal-w-text-2': "در منوی سمت چپ حساب کاربری خود، به صفحه 'برداشت' بروید.",
  'modal-w-text-3': 'مقدار مورد نظر USDT که می‌خواهید بردارید را وارد کنید.',
  'modal-w-text-4':
    'کیف پول USDT TRC20 خود را مشخص کنید که می‌خواهید وجه را دریافت کنید.',
  'modal-w-text-5':
    'تراکنش را با وارد کردن کد تأیید که به ایمیل شما ارسال می شود و همچنین کد رمز (2FA) برای تأیید دو عاملی اگر آن را فعال کرده‌اید، تأیید کنید.',
  'modal-w-text-6':
    'پس از تأیید، درخواست برداشت شما پردازش می‌شود. این معمولاً از چند دقیقه تا ۲۴ ساعت طول می‌کشد، بسته به بارگیری سیستم.',
  'modal-w-text-7':
    'لطفاً توجه داشته باشید که حداقل مبلغ برداشت ۱۰ USDT است و یک کمیسیون شبکه کوچک وجود دارد. علاوه بر این، باید اطمینان حاصل کنید که مدت زمان برنامه سرمایه‌گذاری شما به پایان رسیده است و سود به موجودی آزاد شما اعتبار داده شده است تا بتوانید وجه را بردارید.',
  validation: {
    nameValidFormat: 'در {attribute} نباید اعداد یا نمادها وجود داشته باشد',
    twoCharactersInRow:
      '{attribute} نباید دارای بیش از دو کاراکتر مشابه پشت سر هم باشد',
    email: 'فرمت ایمیل نامعتبر است',
    required: '{attribute} الزامی است',
    minValue: 'حداقل مقدار {attribute} نمی تواند کمتر از {min} باشد',
    maxValue: 'حداکثر مقدار {attribute} نمی تواند بیشتر از {max} باشد',
    minLength: '{attribute} باید حداقل {min} کاراکتر داشته باشد',
    maxLength: '{attribute} نمی تواند بیشتر از {max} کاراکتر باشد',
    validFormat:
      '{attribute} باید شامل یک حرف بزرگ اولیه باشد و فقط شامل کاراکترها باشد',
    validPassword: '{attribute} باید حداقل شامل یک عدد و یک حرف بزرگ باشد',
    sameAsPassword: 'رمز عبور باید یکسان باشد',
    numeric: '{attribute} نامعتبر است. باید عدد باشد',
    minimumValue: 'مبلغ نباید کمتر از ۱۰۰ USDT باشد'
  },
  Country: 'کشور',
  'phone-input-placeholder': 'شماره تلفن خود را وارد کنید',

  'withdrawal-instruction': 'دستورات جزئی برداشت',
  'withdrawal-instruction-text-1':
    'برای شروع فرآیند برداشت از این دستورات پیروی کنید:',
  'withdrawal-instruction-text-2':
    "در کابینه شخصی خود در منوی چپ به صفحه 'برداشت از معاملات' بروید.",
  'withdrawal-instruction-text-3':
    'مقدار USDT مورد نظر برای برداشت را وارد کنید.',
  'withdrawal-instruction-text-4':
    'کیف پول USDT TRC20 خود را که می‌خواهید مبلغ را دریافت کنید مشخص کنید.',
  'withdrawal-instruction-text-5':
    'معامله را با وارد کردن کد تأییدیه‌ای که به ایمیل شما ارسال می‌شود و همچنین کد رمز عبور (2FA) احراز هویت دو عاملی اگر آن را متصل کرده‌اید، تأیید کنید.',
  'withdrawal-instruction-text-6':
    'پس از تأیید، درخواست برداشت شما پردازش می‌شود. این معمولاً از چند دقیقه تا 24 ساعت طول می‌کشد که به بار سیستم بستگی دارد.',
  'withdrawal-instruction-text-7':
    'لطفاً توجه داشته باشید که حداقل مقدار برای برداشت 10 USDT است و یک کارمزد شبکه کوچک وجود دارد. علاوه بر این، باید مطمئن شوید که طرح سرمایه‌گذاری شما منقضی شده و سودها به تراز موجودی آزاد منتقل شده باشد تا بتوانید برداشت کنید.',
  'plans-instruction-text-1':
    'با واریز سرمایه به حساب شما، می‌توانید سرمایه خود را در طرح انتخابی سرمایه‌گذاری کنید.',
  'plans-instruction-text-2':
    'برای فعال کردن طرح سرمایه‌گذاری خود، دستورات زیر را دنبال کنید.',
  'plans-instruction-text-3':
    "به صفحه 'طرح‌های سرمایه‌گذاری' در کابینه شخصی خود بروید. اینجا اطلاعات دقیقی در مورد هر طرح سرمایه‌گذاری را خواهید یافت. این موارد شامل شرایط سرمایه‌گذاری، کمیسیون‌ها و بازگشت طرح است.",
  'plans-instruction-text-4':
    "برای فعال کردن طرح مورد نظر، روی 'Plus' کلیک کرده و مقدار مورد نظر خود را برای شروع طرح در قسمت 'سرمایه اولیه' وارد کنید و روی دکمه 'فعال کردن' کلیک کنید.",
  'plans-instruction-text-5':
    "با ویژگی 'سرمایه‌گذاری خودکار'، می‌توانید به طور خودکار سرمایه خود را برای مدت طولانی سرمایه‌گذاری کنید و منافع اضافی بگیرید.",
  'plans-instruction-text-6':
    'فوراً پس از شروع یک طرح سرمایه‌گذاری، می‌توانید گزینه را با یک کلیک فعال یا غیرفعال کنید.',
  'plans-instruction-text-7':
    'تبریک می‌گوییم، شما با موفقیت یک طرح سرمایه‌گذاری راه‌اندازی کردید!',
  'plans-instruction-text-8':
    'شما می‌توانید این تنظیمات را به دلخواه تغییر دهید، حتی زمانی که مهلت مقرر قبل از شروع گذشته باشد. اگر این گزینه فعال شود، در پایان مدت زمان طرح سرمایه‌گذاری، کل سرمایه به همراه تمامی منافع به صورت خودکار سرمایه‌گذاری می‌شود. اگر این گزینه غیرفعال باشد، سرمایه به همراه منافع در معاملات آزاد منظور می‌شود.',
  'plans-instruction-text-9':
    'اگر "سرمایه‌گذاری خودکار" فعال باشد، تمام سرمایه و منافع طرح پس از انقضای آن به صورت دائمی سرمایه‌گذاری می‌شود تا زمانی که این گزینه غیرفعال شود.',
  'plans-instruction-text-10':
    'به این ترتیب، می‌توانید سرمایه خود را به صورت کاملاً خودکار برای مدت طولانی با کمترین هزینه سرمایه‌گذاری کنید. برای پرداخت کامل سرمایه، باید این ویژگی را غیرفعال کنید. پس از غیرفعال کردن این گزینه، کل سرمایه به همراه منافع در پایان مدت زمان فعلی طرح سرمایه‌گذاری در معاملات آزاد منظور می‌شود و هیچ هزینه اضافی‌ای در این مورد دریافت نخواهد شد.',
  'plans-instruction-text-11':
    "تمام اطلاعات مربوط به کار با طرح‌های سرمایه‌گذاری فعال و کامل در بخش 'تاریخچه طرح' در پایین صفحه قابل دسترسی است.",
  'popup-instruction-title-2': 'راهنمای گام به گام',
  'popup-instruction-text-1': 'دستورات واریز CoinFuze',

  'popup-instruction-text-2': "به صفحه 'واریز' در کابینه شخصی خود بروید.",
  'popup-instruction-text-3':
    "برای شروع فرآیند واریز، بر روی دکمه 'واریز' کلیک کنید.",
  'popup-instruction-text-4':
    'شما یک شناسه کیف پول منحصر به فرد برای ارسال وجه دریافت می‌کنید. لطفاً اطمینان حاصل کنید که این شناسه را هنگام پرداخت استفاده می‌کنید.',
  'popup-instruction-text-5':
    'همچنین امکان استفاده از کد QR که در سمت چپ شناسه کیف پول قرار دارد را دارید. می‌توانید آن را با تلفن همراه‌تان اسکن کنید تا ورود اطلاعات آسان‌تر شود.',
  'popup-instruction-text-6':
    'پس از ارسال وجه به کیف پول مشخص شده، وجه به صورت خودکار به موجودی شما اضافه می‌شود. این معمولاً بین 1 دقیقه و 2 ساعت طول می‌کشد، اما زمان ممکن است بستگی به بلاکچین و عوامل دیگر داشته باشد.',
  'popup-instruction-text-7':
    "واریزهای تایید شده در پایین صفحه زیر تب 'تاریخچه واریز' نمایش داده می‌شوند. در اینجا می‌توانید پیگیری درخواست‌های واریز خود را انجام دهید.",
  'popup-instruction-text-8':
    'برای انجام آسان و ایمن واریز خود در CoinFuze این دستورات را دنبال کنید. اگر سوالی دارید یا نیاز به کمک اضافی دارید، لطفاً با تیم پشتیبانی ما تماس بگیرید.',
  error: 'خطا',
  'partnership-text-31': 'تعداد ارجاع‌ها',
  'partnership-text-30': 'مقدار واریزها',
  'autorization-tab-1': 'ورود',
  'autorization-tab-2': 'ثبت نام',
  'autorization-login': 'نام کاربری',
  'autorization-username': 'نام کاربری/ایمیل/تلفن',
  'autorization-password': 'رمز عبور',
  'autorization-forgot': 'رمز عبور را فراموش کرده‌اید؟',
  'autorization-btn-login': 'ورود به حساب کاربری',
  'autorization-name': 'نام',
  'autorization-surname': 'نام خانوادگی',
  'autorization-repeat-password': 'تکرار رمز عبور',
  'autorization-ypass': 'رمز عبور شما',
  'autorization-newpass': 'رمز عبور شما',
  'autorization-email': 'ایمیل',
  'autorization-tel': 'تلفن',
  'autorization-promo': 'کد تبلیغاتی',
  'autorization-text-0': 'من تایید می‌کنم که',
  'autorization-text-1': 'شرایط و مقررات',
  'autorization-text-2': 'سیاست حریم خصوصی',
  'autorization-text-3': 'سیاست AML',
  'autorization-text-4': 'من 18 سال یا بیشتر دارم',
  'autorization-text-5': 'شرایط خدمات',
  'autorization-text-6': 'مرا به یاد داشته باش',

  'autorization-text-7': 'حساب کاربری ندارید؟',
  'autorization-text-8':
    'نام کاربری خود را وارد کنید و ما دستورالعمل را به ایمیل شما ارسال خواهیم کرد:',
  'autorization-text-10': 'آیا حساب کاربری دارید؟',
  'autorization-text-11': 'ورود',
  'autorization-btn-register': 'ثبت نام',
  'autorization-btn-cancel': 'بازنشانی رمز عبور',
  'autorization-status-2': 'ایمیل/تلفن مشخص شده یافت نشد.',
  'autorization-status-1': 'نام کاربری یا رمز عبور نادرست است',
  'autorization-status-name': 'نام نادرست است',
  'autorization-status-surname': 'نام خانوادگی نادرست است',
  'autorization-status-password':
    'رمز عبور باید حداقل 7 کاراکتر داشته باشد و شامل حداقل 1 حرف بزرگ و کوچک باشد',
  'autorization-status-repeat-password': 'رمز عبورها هماهنگ نیستند',
  'autorization-status-email': 'فرمت ایمیل نادرست است',
  'autorization-status-phone': 'شماره تلفن نادرست است',
  'autorization-status-agree-rules': 'شرایط را تأیید کنید',
  'autorization-status-agree-age': 'شرایط را تأیید کنید',
  'validation-1': 'برای ثبت نام باید بیش از 18 سال داشته باشید',
  'validation-2': 'برای ثبت نام باید شرایط و مقررات را پذیرفته باشید',
  'header-item-1': 'مجموع موجودی',
  'header-item-2': 'موجودی آزاد:',
  'header-item-3': 'موجودی فعال:',
  'non-message': 'هیچ اعلان جدیدی وجود ندارد',
  'message-btn': 'نمایش همه اعلان‌ها',
  'header-item-4': 'اطلاعات شخصی',
  'header-item-5': 'دعوت دوستان',
  'header-item-6': 'افزودن',
  'header-item-7': 'خروج',
  'footer-item-2': 'شرایط استفاده',
  'footer-item-3': 'سیاست حریم خصوصی',
  'footer-item-1': 'سیاست AML',
  'sidebar-item-1': 'داشبورد',
  'sidebar-item-2': 'بانکداری',
  'sidebar-item-3': 'مدیریت طرح',
  'sidebar-item-4': 'برنامه همکاری',
  'sidebar-item-5': 'برداشت',
  'sidebar-item-6': 'اطلاعات شخصی',
  'sidebar-item-7': 'اخبار',
  'sidebar-item-8': 'خروج از حساب کاربری',
  'dashboard-subtitle-1': 'حساب',
  'dashboard-subtitle-2': 'مالی',
  'dashboard-subtitle-3': 'سرمایه‌گذاری‌ها',
  'dashboard-subtitle-4': 'برنامه همکاری',
  'dashboard-subtitle-5': 'دوره',
  'dashboard-subtitle-6': 'نتیجه کنونی',
  'dashboard-subtitle-7': 'نتیجه مالی',
  'dashboard-text-1': 'موجودی آزاد',
  'dashboard-text-2': 'موجودی فعال',
  'dashboard-text-3': 'تایید شده',
  'dashboard-text-4': 'شرکت کننده از',
  'dashboard-text-5': 'کل سود طرح‌ها',
  'dashboard-text-6': 'درآمد از معرفی',
  'dashboard-text-7': 'واریز',
  'dashboard-text-8': 'برداشت‌ها',
  'dashboard-text-9': 'کل سود',
  'dashboard-text-10': 'طرح‌های فعال',
  'dashboard-text-10-1': 'طرح‌های بسته شده',
  'dashboard-text-11': 'طرح‌های بازسرمایه‌گذاری',
  'dashboard-text-12': 'تعداد شرکا',
  'dashboard-text-13': 'شرکای خط ۱',
  'dashboard-text-14': 'شرکای خط ۲',
  'dashboard-text-15': 'شرکای خط ۳',
  'dashboard-text-16': 'دوره',
  'dashboard-text-17': 'روز',
  'dashboard-text-18': 'هنوز خبری وجود ندارد',
  'dashboard-text-19': 'در حال حاضر',
  'dashboard-text-20': 'داده‌های کافی برای نمودار وجود ندارد',
  'dashboard-text-21': '- بدون سرمایه‌گذاری فعال',
  'dashboard-text-22': 'خواندن همه خبرها',
  'dashboard-text-22-1': 'همه را بخوان',
  'dashboard-text-23': 'طرح سرمایه‌گذاری',
  progressLabel: 'پیشرفت طرح سرمایه‌گذاری',
  daysLabel: 'روز تا اتمام',
  'deposit-subtitle-1-1': 'پیش از واریز وجه، مطمئن شوید که',

  'deposit-subtitle-1-2': 'ثبت حساب کاربری شما.',
  'deposit-subtitle-1-3': 'در غیر این صورت نمی‌توانید وجه واریز کنید.',
  'deposit-subtitle-2': 'پول در CoinFuze بسازید',
  'deposit-text-1':
    "برای واریز موجودی خود، روی دکمه 'واریز وجه به حساب خود' کلیک کنید.",
  'deposit-text-2':
    "تمام تراکنش‌های واریزی شما در بخش 'تاریخچه' زیر ذخیره می‌شود، جایی که می‌توانید وضعیت درخواست‌های خود را پیگیری کنید.",
  'deposit-text-3':
    "وقتی یک درخواست واریز وضعیت 'تکمیل شده' را دریافت کند، موجودی به طور خودکار به 'موجودی آزاد' شما اضافه می‌شود.",
  'deposit-text-4':
    'در حال حاضر ما از پایدارترین ارز رمزنگاری برای سیستم خود استفاده می‌کنیم - Tether USDT.',
  'deposit-text-5':
    'پس از انجام واریز، شما قادر به بهره‌مندی از تمام مزایای همکاری با CoinFuze خواهید بود.',
  'deposit-text-6': 'سود ماهیانه متوسط ​​- 6.8* درصد',
  'deposit-text-7': 'سود سالیانه - 98.7%',
  'deposit-text-8': 'سیستم معاملات کاملاً خودکار',
  'deposit-text-9':
    'امکان سرمایه‌گذاری مجدد و سیستم انتخاب انعطاف‌پذیر شرایط سرمایه‌گذاری',
  'deposit-text-10':
    '*داده‌های مربوط به سودآوری از آمار باز شده الگوریتم برای سال 2024 گرفته شده است.',
  'deposit-text-11': 'دستورالعمل دقیق در مورد چگونگی واریز',
  'deposit-text-12': 'آیا سوالی در مورد واریز دارید؟ در چت پشتیبانی بپرسید',

  'deposit-text-13':
    "برای واریز موجودی خود، روی دکمه 'واریز وجه به حساب خود' کلیک کنید.",
  'deposit-text-14':
    "تمام تراکنش‌های واریزی شما در بخش 'تاریخچه' زیر ذخیره می‌شود، جایی که می‌توانید وضعیت درخواست‌های خود را پیگیری کنید.",
  'deposit-text-15':
    "وقتی یک درخواست واریز وضعیت 'تکمیل شده' را دریافت کند، موجودی به طور خودکار به 'موجودی آزاد' شما اضافه می‌شود.",
  'deposit-text-16':
    'در حال حاضر ما از پایدارترین ارز رمزنگاری برای سیستم خود استفاده می‌کنیم - Tether USDT.',
  'deposit-text-17':
    'پس از انجام واریز، شما قادر به بهره‌مندی از تمام مزایای همکاری با CoinFuze خواهید بود.',
  'deposit-text-18':
    'آیا سوالی در مورد واریز دارید؟ آن را در چت پشتیبانی بپرسید.',
  'deposit-btn': 'واریز به حساب',
  notification: 'کپی شد',
  'deposit-popup-text-1': 'رمزارز',
  'deposit-popup-text-2': 'ارز واریزی',
  'deposit-popup-text-3': 'موجودی شما:',
  'deposit-popup-text-4': 'شبکه',
  'deposit-popup-text-5': 'توجه:',
  'deposit-popup-text-6-1': 'فقط',
  'deposit-popup-text-6-2': 'به این آدرس واریز کنید. سکه‌ها پس از',
  'deposit-popup-text-6-3': 'تأیید 6 شبکه به طور خودکار اعتبار داده می‌شوند.',
  'deposit-popup-text-6-4':
    'آدرس‌های قراردادهای هوش مصنوعی پشتیبانی نمی‌شوند (با ما تماس بگیرید).',
  'deposit-popup-text-7': 'آدرس واریز:',
  'deposit-popup-text-8': 'کپی کردن',
  history: 'تاریخچه',
  'deposit-history-text-1': 'تاریخ واریز',
  'deposit-history-text-2': 'روش پرداخت',
  'deposit-history-text-3': 'میزان واریز',
  'deposit-history-text-4': 'وضعیت تراکنش',
  'deposit-history-text-5': 'تراکنش',
  'deposit-history-text-6': 'تراکنشی وجود ندارد',
  'deposit-history-text-7': 'نمایش',
  'deposit-history-text-8': 'از',
  'deposit-history-text-9': 'از',
  'plans-subtitle-1': 'مدیریت سرمایه‌گذاری',
  'plans-subtitle-2': 'سرمایه‌گذاری‌های اولیه',

  'plans-text-1':
    'در این بخش، شما می‌توانید طرح‌های موجود را از بین بگیرید یا نتایج مالی برای تمامی طرح‌های فعال یا بسته شده خود را مشاهده کنید.',
  'plans-text-2':
    'شش طرح سرمایه‌گذاری برای شما قابل دسترسی است، هرکدام با شرایط و مقررات متفاوتی در مورد مدت زمان سرمایه‌گذاری، بازده مورد انتظار و هزینه‌های خدمات کوینفیوز می‌آیند.',
  'plans-text-3':
    "برای فعال‌سازی طرح انتخابی، کاربران باید حداقل مقدار سپرده را واریز کرده و روی دکمه 'فعال‌سازی' کلیک کنند.",
  'plans-text-4': 'دستورالعمل دقیق در مورد چگونگی کار با طرح‌های سرمایه‌گذاری.',
  'plans-text-5': 'سوالات متداول در بخش سرمایه‌گذاری.',
  'plans-text-6':
    "برای شروع سرمایه‌گذاری، باید یک واریز انجام دهید. شما می‌توانید این کار را در صفحه 'واریز وجه' در منوی چپ یا با استفاده از دکمه زیر انجام دهید.",
  'plans-text-7':
    'پس از پردازش واریز شما، طرح سرمایه‌گذاری انتخابی خود را فعال کنید.',
  'plans-text-8':
    'کوینفیوز امکان سرمایه‌گذاری خودکار را ارائه می‌دهد. این بدان معناست که در پایان مدت طرح، تمام سودهای دوره قبلی به طور خودکار به سرمایه اولیه طرح جدید منتقل می‌شوند.',
  'plans-text-9': 'برو به واریز',
  'plans-text-10': 'فعال‌سازی طرح سرمایه‌گذاری',
  'plans-text-11': 'بله',
  'plans-text-12': 'خیر',
  'plans-text-13': 'طرح‌های فعال',
  'plans-text-14': 'تاریخ شروع',
  'plans-text-15': 'تاریخ پایان',
  'plans-text-16': 'سرمایه اولیه',
  'plans-text-17': '% نتیجه مالی',
  'plans-text-18': 'سود در',
  'plans-text-19': 'وضعیت طرح',
  'plans-text-20': 'سرمایه‌گذاری مجدد',
  'plans-text-21': 'ذخیره',
  'plans-text-22': 'طرح‌های موجود',
  'plans-text-23': 'حداقل سرمایه‌گذاری',
  'plans-text-24': 'مدت زمان',
  'plans-text-25': 'بازده',
  'plans-text-26': 'تا',
  'plans-text-27': 'کمیسیون',
  'plans-text-28': 'مقدار سرمایه اولیه را وارد کنید:',
  'plans-text-29': 'فعال‌سازی',
  'plans-text-30': 'مقدار حداقل را وارد کنید',
  'plans-text-31': 'نام',
  'plans-text-32': 'سرمایه اولیه',
  'plans-text-33': 'نتیجه فعلی',
  'plans-text-34': 'سود',
  'plans-text-35': 'کمیسیون',
  'plans-text-36': 'فعال',
  'plans-text-37': 'بسته شده',
  'plans-text-38': 'طرح سرمایه‌گذاری',
  'plans-text-39': 'روزها',
  'plans-text-40': 'انتخاب',
  'plans-text-41': 'وضعیت:',

  'partnership-subtitle-1': 'برنامه معرفی دوستان',
  'partnership-subtitle-2': 'لینک معرفی شما',
  'partnership-subtitle-3': 'معرفی‌های دعوت شده',
  'partnership-subtitle-4': 'سطح 1 - کمیسیون بر روی واریز و سود',
  'partnership-subtitle-5': 'سطح 2 - کمیسیون بر روی واریز و سود',
  'partnership-subtitle-6': 'سطح 3 - کمیسیون بر روی واریز و سود',
  'partnership-text-1':
    'ما را به دوستان خود معرفی کنید و جوایزی را در حساب شخصی خود دریافت کنید.',
  'partnership-text-2': 'درآمد فرعی خود را از طریق برنامه معرفی کسب کنید.',
  'partnership-text-3': 'لینک معرفی خود را کپی کنید.',
  'partnership-text-4': 'به دوستان خود ارسال کنید.',
  'partnership-text-5': 'جوایز برای شارژ مجدد واریز آن‌ها دریافت کنید',
  'partnership-text-6': 'جوایز برای سود آن‌ها دریافت کنید',
  'partnership-text-7':
    'هر عضو کوینفیوز یک لینک معرفی منحصر به فرد و کد ترویجی برای به اشتراک گذاشتن با دوستان در سراسر جهان دریافت می‌کند. فقط این لینک را کپی کرده و به کسانی که می‌خواهید به کوینفیوز دعوت کنید ارسال کنید.',
  'partnership-text-8': 'کد ترویجی شما',
  'partnership-text-9': 'لینک معرفی یا کد ترویجی خود را به اشتراک بگذارید.',
  'partnership-text-10': 'آمار کلیک‌ها را بررسی کنید',
  'partnership-text-11':
    'تعداد افرادی که از طریق لینک شما ثبت نام کرده‌اند را پیگیری کنید',
  'partnership-text-12': 'مشاهده رشد سود شما',
  'partnership-text-13': 'مجموع دعوت شده‌ها',
  'partnership-text-14': 'دوستان سطح 1',
  'partnership-text-15': 'دوستان سطح 2',
  'partnership-text-16': 'دوستان سطح 3',
  'partnership-text-17': 'کل درآمد:',
  'partnership-text-18': 'جستجو',
  'partnership-text-19':
    'ببینید کدام افراد از طریق لینک ارجاعی شما ثبت نام کرده‌اند و چقدر جوایز دریافت کرده‌اید. فراموش نکنید که ما دارای برنامه معرفی با سه سطح هستیم. شما جوایز نه تنها برای کسانی که به کوینفیوز دعوت کرده‌اید، بلکه برای اعضایی که افراد دیگر را دعوت کرده‌اند نیز دریافت می‌کنید.',
  'partnership-text-20': 'نام معرف را وارد کنید',
  'partnership-text-21': 'جوایز واریز',
  'partnership-text-22': 'جوایز سود',
  'partnership-text-23': 'درآمد معرفی',
  'partnership-text-24': 'تاریخ ورود',
  'partnership-text-25': 'هیچ معرفی چنینی وجود ندارد',
  'partnership-text-26': 'جستجو...',
  'partnership-text-27': 'هیچ معرفی‌ای ندارد',
  'partnership-text-28': 'مرحله',
  'partnership-text-29': 'میزان تراکنش',
  'partnership-text-32': 'جوایز',
  'partnership-text-33': 'پیشرو',
  'partnership-text-34': 'سطح شما',
  'partnership-text-35': 'بسته',
  'partnership-text-36': 'لینک معرفی:',
  'partnership-text-37': 'کد ترویجی شما:',
  'partnership-text-38': 'سطح تیم فعال:',
  'partnership-text-39': 'کمیسیون بر روی واریز و سود:',
  'partnership-text-40': 'معرفی‌ها',
  'partnership-text-41': 'جوایز تیم',
  'news-text-1': 'تاکنون هیچ خبری موجود نیست.',
  'news-text-2': 'بیشتر بخوانید',
  'news-text-3': 'مواد تبلیغاتی',
  'news-text-4':
    'مواد تبلیغاتی ما را با دوستان خود به اشتراک بگذارید و درآمد کسب کنید!',
  'news-text-5':
    'ما یک انتخاب از مواد تبلیغاتی را برای شما تهیه کرده‌ایم تا بتوانید اعضای جدید کوینفیوز را جذب کنید.',
  'news-text-6': 'به اشتراک بگذارید و با دوستان خود درآمد کسب کنید!',
  'news-text-7':
    'هر عضو کوینفیوز یک لینک معرفی منحصر به فرد و کد ترویجی برای به اشتراک گذاشتن با دوستان در سراسر جهان دریافت می‌کند. فقط این لینک را کپی کرده و به کسانی که می‌خواهید به کوینفیوز دعوت کنید ارسال کنید.',
  'news-text-8': 'کد ترویجی شما',
  'news-text-9': 'لینک معرفی یا کد ترویجی خود را به اشتراک بگذارید.',
  'news-text-10': 'آمار کلیک‌ها را بررسی کنید',
  'news-text-11':
    'تعداد افرادی که از طریق لینک شما ثبت نام کرده‌اند را پیگیری کنید',
  'news-text-12': 'مشاهده رشد سود شما.',
  'news-text-13': 'تا 6% جوایز معرفی دریافت کنید',
  'withdrawal-text-1': 'تاریخ برداشت',
  'withdrawal-text-2': 'روش پرداخت',
  'withdrawal-text-3': 'مبلغ برداشت',
  'withdrawal-text-4': 'برای برداشت می‌بایستی هویت خود را تایید کنید',
  'withdrawal-text-5':
    'برای برداشت باید پروسه تایید هویت خود را با ارسال اسناد هویتی به پایان برسانید.',
  'withdrawal-text-6': 'تایید',
  'withdrawal-text-7': 'برداشت',
  'withdrawal-text-8': 'کد تایید ارسال شده به ایمیل خود را وارد کنید:',
  'withdrawal-text-9': 'کد را وارد کنید',
  'withdrawal-text-10': 'ارسال مجدد کد تایید از طریق:',
  'withdrawal-text-11': 'کد تایید را تایید کنید',
  'withdrawal-text-12': 'ارسال مجدد',
  'withdrawal-text-13': 'تایید شده',
  'withdrawal-text-14': 'ارسال مجدد کد تایید از طریق:',
  'withdrawal-text-15': 'کد از اپلیکیشن موبایل وارد کنید',
  'withdrawal-text-16': 'کد از',
  'withdrawal-text-17': 'اطلاعات:',
  'withdrawal-text-18':
    'موجودی آزاد موجودی قابل برداشت شماست. اگر سرمایه‌گذاری‌های فعالی دارید، آنها محاسبه نمی‌شوند چرا که تا پایان مدت سرمایه‌گذاری قفل شده‌اند.',
  'withdrawal-text-19': 'برداشت از موجودی',
  'withdrawal-text-19-1': 'قابل برداشت:',
  'withdrawal-text-19-2': 'شروع برداشت',
  'withdrawal-text-20':
    'در این صفحه، می‌توانید موجودی خود را به کیف پول رمزارزی خود برداشت کنید. دستورالعمل‌ها را دنبال کنید تا برداشت به سرعت و راحتی انجام شود.',
  'withdrawal-text-21': 'برداشت‌ها، مانند واریزها، در رمزارزها انجام می‌شوند.',
  'withdrawal-text-22':
    'برداشت ممکن است مدتی طول بکشد، زیرا تراکنش‌ها در شبکه رمزارز با سرعت‌های مختلف انجام می‌شوند.',
  'withdrawal-text-23':
    'درخواست‌های برداشت به صورت خودکار پذیرفته و در مدت زمان کوتاهی پردازش می‌شوند.',
  'withdrawal-text-24': 'شروع برداشت',
  'withdrawal-text-24-1': 'دستورالعمل دقیق برداشت',
  'withdrawal-text-25': 'سود سرمایه‌گذاری خود را افزایش دهید!',
  'withdrawal-text-26':
    'شما می‌توانید با بازسازی موجودی‌های خود حتی بیشتر از سود کسب کنید.',
  'withdrawal-text-27': 'سپرده خود و سود خود را مجدداً سرمایه‌گذاری کنید.',
  'withdrawal-text-28':
    'دوره سرمایه‌گذاری را با سرمایه اولیه بزرگتر شروع کنید.',
  'withdrawal-text-29': 'درآمد خود را روزانه افزایش دهید.',
  'withdrawal-text-30': 'اکنون مجدداً سرمایه‌گذاری کنید',
  'withdrawal-text-31': 'کیف پول مناسب برای برداشت را انتخاب کنید',
  'withdrawal-text-32':
    'برای برداشت موجودی به کیف پول خود اضافه کنید و درخواست برداشت بسازید.',
  'withdrawal-text-33': 'برداشت به این کیف پول',
  'withdrawal-text-34': 'ثبت درخواست',
  'withdrawal-text-35': 'اطلاعات:',
  'withdrawal-text-36':
    'موجودی نشان داده شده موجودی قابل برداشت شماست و مجموع موجودی‌های شما نیست. اگر سرمایه‌گذاری‌های فعال دارید، آنها محاسبه نمی‌شوند چرا که تا پایان دوره سرمایه‌گذاری قفل شده‌اند.',
  'withdrawal-text-37': 'مبلغ برداشت',
  'personal-text-1': 'اطلاعات شخصی',
  'personal-text-2': 'احراز هویت دو عاملی',
  'personal-text-3': 'رمز عبور',
  'personal-text-4': 'تایید هویت',
  'personal-text-5': 'نام شما',
  'personal-text-6': 'نام صحیح را وارد کنید',
  'personal-text-7': 'نام خانوادگی شما',
  'personal-text-8': 'نام خانوادگی صحیح را وارد کنید',
  'personal-text-9': 'لغو',
  'personal-text-10': 'ذخیره',
  'personal-text-11': 'فعال/غیرفعال',
  'personal-text-12':
    'احراز هویت دو عاملی (2FA) توسط ایمیل به صورت پیش‌فرض برای همه حساب‌ها فعال است و نمی‌توانید آن را غیرفعال کنید.',
  'personal-text-13':
    'برای تغییر آدرس ایمیل خود در CoinFuze، باید با پشتیبانی تماس بگیرید. پس از پردازش درخواست شما توسط پشتیبانی، شما اعلامیه دریافت خواهید کرد که آدرس شما با موفقیت به یک آدرس جدید تغییر کرده است.',
  'personal-text-14':
    'مهم است به یاد داشته باشید که به منظور جلوگیری از کلاهبرداری و حفاظت از داده‌های خود، پشتیبانی ممکن است اطلاعات یا اسناد اضافی را جهت احراز هویت شما درخواست کند.',
  'personal-text-15':
    '2FA غیرفعال است. ما به تمام مشتریان توصیه می‌کنیم که احراز هویت دو عاملی (2FA) را برای افزایش امنیت فعال کنند.',
  'personal-text-16': 'غیرفعال کردن',
  'personal-text-17': 'فعال کردن',
  'personal-text-18': 'حساب',
  'personal-text-19':
    'این ویژگی به شما اجازه می‌دهد تا از اپلیکیشن Google Authenticator یا حساب تلگرام خود برای احراز هویت دو عاملی استفاده کنید. علاوه بر تأیید از طریق ایمیل به ایمیل شما، شما نیز باید یک کد امنیتی از Google Authenticator یا تلگرام ارائه دهید تا بتوانید برخی از عملیات‌ها را در حساب کاربری شخصی خود انجام دهید. این عملیات‌ها شامل: برداشت موجودی، تغییر اطلاعات شخصی و همچنین رمز عبور حساب شما می‌شود. این اقدام از حملات هکرها به حساب کاربری شما جلوگیری می‌کند، حتی اگر توانسته باشند رمز عبور شما را به دست آورند.',
  'personal-text-20':
    'این ویژگی به شما اجازه می‌دهد تا از اپلیکیشن Google Authenticator یا یک اپلیکیشن سازگار مانند FreeOTP برای احراز هویت دو عاملی استفاده کنید. علاوه بر نام کاربری و رمز عبور، شما نیز باید یک کد امنیتی شش رقمی ارائه دهید تا بتوانید وارد این سایت شوید. کد امنیتی هر ۳۰ ثانیه تغییر می‌کند. این اقدام از حملات هکرها به حساب کاربری شما جلوگیری می‌کند، حتی اگر توانسته باشند رمز عبور شما را به دست آورند.',
  'personal-text-21': 'دریافت',
  'personal-text-22':
    'اپلیکیشن Google Authenticator را بر روی گوشی هوشمند یا کامپیوتر خود دانلود و نصب کنید.',
  'personal-text-23': 'از یکی از مراحل زیر استفاده کنید:',
  'personal-text-24':
    'اپلیکیشن Google Authenticator رسمی برای Android، iOS و BlackBerry. به یاد داشته باشید که زمان دستگاه خود را با سرور زمان همگام‌سازی کنید. تغییر زمان در دستگاه شما ممکن است منجر به عدم توانایی در ورود به حساب کاربری شما شود.',
  'personal-text-25': 'و',
  'personal-text-26':
    'به یاد داشته باشید که زمان دستگاه خود را با سرور زمان همگام‌سازی کنید. یک تغییر زمان در دستگاه شما ممکن است منجر به عدم توانایی ورود به حساب کاربری شما شود.',
  'personal-text-27': 'راه‌اندازی.',
  'personal-text-28': 'کد QR زیر را در',
  'personal-text-29': 'کلید سفارشی سازی',
  'personal-text-30':
    'اگر می‌خواهید کلید را تغییر دهید، ابتدا احراز هویت دو عاملی را غیرفعال کنید. هنگام تلاش برای فعال کردن آن مجدداً، یک کلید جدید ایجاد می‌شود.',
  'personal-text-31': 'فعال کردن احراز هویت دو عاملی.',
  'personal-text-33': 'کد امنیتی',
  'personal-text-34': 'رمز عبور شما',
  'personal-text-35': 'فعال کردن',
  'personal-text-37': 'رمز عبور فعلی',
  'personal-text-38': 'رمز عبور جدید',
  'personal-text-39': 'تأیید رمز عبور جدید',
  'personal-text-41': 'اسناد قابل دانلود',
  'personal-text-42': 'دانلود',
  'personal-text-43': 'فرمت قابل قبول',
  'personal-text-44': 'اسناد شما در حال احراز هویت هستند',
  'personal-text-45': 'اسناد شما تایید شده‌اند',
  'personal-text-46': 'اسناد شما',
  'personal-text-47': 'جلسات فعال',
  'personal-text-48': 'جلسات مرورگر',
  'personal-text-49':
    'مدیریت و خروج از جلسات فعال خود در مرورگرها و دستگاه‌های دیگر.',
  'personal-text-50':
    'در صورت لزوم، می‌توانید از تمامی جلسات مرورگر دیگر خود در تمامی دستگاه‌هایتان خارج شوید. برخی از جلسات اخیر شما در زیر لیست شده‌اند؛ با این حال این لیست ممکن است کامل نباشد. اگر معتقدید که حساب کاربری شما مورد اختراق قرار گرفته است، همچنین باید رمز عبور خود را به‌روز کنید.',
  'personal-text-51': 'این دستگاه',
  'personal-text-51-1': 'خروج',

  'personal-text-52': 'خروج از جلسات مرورگر دیگر',
  'personal-text-53': 'گواهینامه رانندگی',
  'personal-text-54': 'رسید بانکی',
  file: 'فایل انتخاب نشده است',
  document: 'پاسپورت',
  'personal-text-55':
    'برای تغییر رمز عبور، از احراز هویت دو عاملی (2FA) عبور کنید',
  'personal-text-56':
    'برای تغییر نام و نام خانوادگی، از احراز هویت دو عاملی (2FA) عبور کنید.',
  'personal-text-57':
    'در حال حاضر احراز هویت دو عاملی (2FA) بر روی این حساب فعال است.',
  'personal-text-58':
    'لطفاً این رمز عبور اضطراری یک بار مصرف را کپی کنید و در مکانی ایمن ذخیره کنید. اگر به دستگاه احراز هویت دو عاملی دسترسی ندارید، می‌توانید از این رمز عبور به جای کد امنیتی عادی خود استفاده کنید. این رمز عبور هنگام استفاده فوراً از بین می‌رود. ما توصیه می‌کنیم که این رمز عبور را چاپ کرده و در مکانی ایمن و دسترسی‌پذیر مانند کیف پول یا صندوق نگه‌دارید.',
  'personal-text-59': 'غیرفعال کردن احراز هویت دو عاملی (2FA).',
  'personal-text-60': 'لطفاً کد احراز هویت دو عاملی را وارد کنید.',
  'personal-text-61': 'ثبت',
  'personal-text-62': 'لغو',
  'text-63': 'به حساب کاربری شخصی بروید',
  'text-64': 'بازگشت',
  'text-65': 'اعلان‌ها',
  'text-66': 'علامت‌گذاری تمام اعلان‌ها به عنوان خوانده شده',
  'text-67': 'علامت‌گذاری',
  'text-68': 'ثبت‌نام شما تقریباً تکمیل شده است',
  'text-69':
    'شما باید قبل از سرمایه‌گذاری فرآیند ثبت‌نام را تکمیل کنید. برای انجام این کار، روی دکمه "تکمیل" کلیک کنید و ثبت‌نام خود را از ایمیل ارسال شده به ایمیل خود تأیید کنید.',
  'text-70': 'تکمیل ثبت‌نام',
  'text-71': 'زبان را انتخاب کنید',

  'cookies-1': 'با مرور در این سایت، شما به',
  'cookies-2': 'سیاست حفظ حریم خصوصی ما موافقت می‌کنید',
  ok: 'تایید',
  'table-1': 'ورود',
  'table-2': 'بونوس (واریز)',
  'table-3': 'بونوس (سود)',
  'table-4': 'تعداد معرفی‌ها',
  'table-5': 'تاریخ عضویت',
  'table-6': 'نوع تراکنش',
  'table-7': 'تاریخ',
  'table-8': 'مقدار واریز',
  'table-9': 'وضعیت تراکنش',
  'table-10': 'شناسه تراکنش',

  'profile-window-1': 'ورود:',
  'profile-window-2': 'تلفن:',
  'profile-window-3': 'زبان:',
  'profile-window-4': 'تم:',
  'profile-window-5': 'دعوت دوستان:',

  'two-factor-1': 'تأیید دو مرحله‌ای',
  'two-factor-2': 'کد تأیید ارسال شده به ایمیل خود را وارد کنید:',
  'two-factor-3': 'ارسال مجدد',
  'two-factor-4': 'تأیید',
  'two-factor-5': 'تأیید شده',
  'two-factor-6': 'ارسال مجدد کد جدید مجدداً از طریق:',
  'two-factor-7': 'کد تأیید ارسال شده به ایمیل شما را وارد کنید:',
  'two-factor-8': 'کد را وارد کنید',
  back: 'بازگشت به مدیریت برنامه',
  'back-to-site': 'بازگشت به سایت',
  'back-to-news': 'بازگشت به اخبار',
  support: 'پشتیبانی',
  title: 'عنوان',
  step: 'مرحله',
  'page-not-found': 'صفحه یافت نشد',
  home: 'خانه',

  'staking-1': 'بازگشت به مدیریت برنامه',
  'staking-2': 'استیکینگ ارز دیجیتال',
  'staking-3':
    'اگر لازم باشد، می‌توانید از تمام نشست‌های مرورگر دیگر خود روی تمام دستگاه‌های خود خارج شوید. برخی از نشست‌های اخیر شما در زیر فهرست شده‌اند؛ ولی این فهرست ممکن است کامل نباشد. اگر فکر می‌کنید حساب کاربری شما مورد دسترسی غیرمجاز قرار گرفته است، همچنین باید رمز عبور خود را به‌روز کنید.',
  'staking-4': 'فعال کردن',
  'staking-4-1': 'فعال کردن',
  'staking-5': 'رفتن به',
  'staking-6': 'نتیجه مالی:',
  'staking-7': 'تاریخ آخرین سود:',
  'staking-8': 'واریز به استیکینگ:',
  'staking-9': 'سطوح',
  'staking-10': 'آخرین سرمایه‌گذاری:',
  'staking-11': 'سطح',
  'staking-12': 'مقدار تجمعی',
  'staking-13': 'بونوس‌ها',
  'staking-14': 'گذشته',
  'staking-15': 'سطح شما',
  'staking-16': 'بسته',
  'staking-17': 'سطح استیکینگ:',
  'staking-18': 'فعال',
  'staking-19': 'غیرفعال',
  'staking-20': 'کامل شده',
  'staking-table-1': 'برنامه سرمایه‌گذاری',
  'staking-table-2': 'تاریخ شروع',
  'staking-table-3': 'تاریخ پایان',
  'staking-table-4': 'سرمایه اولیه',
  'staking-table-5': 'نتیجه کنونی، USDT',
  'staking-table-6': 'سود، USDT',
  'staking-table-7': 'کمیسیون، USDT',
  'staking-table-8': 'وضعیت برنامه',

  'plan-modal-1': 'برنامه سرمایه‌گذاری',
  'plan-modal-2': 'تاریخ شروع',
  'plan-modal-3': 'تاریخ پایان',
  'plan-modal-4': 'سرمایه اولیه',
  'plan-modal-5': 'نتیجه مالی',
  'plan-modal-6': 'سود در USDT',
  'plan-modal-7': 'مقدار سرمایه اولیه را وارد کنید:',
  'plan-modal-8': 'فعال سازی',

  'plan-page-1': 'برنامه‌های فعال',
  'plan-page-2': 'برنامه‌های موجود',

  done: 'انجام شد',
  purpose: 'هدف',
  'password-reset': 'رمز عبور به ایمیل شما ارسال شده است.',

  'table-next': 'بعدی',
  'table-prev': 'قبلی',

  'tr_status-1': 'در انتظار',
  'tr_status-2': 'دستی',
  'tr_status-3': 'رد شده',
  'tr_status-4': 'تایید شده',
  'tr-type-0': 'همه',
  'tr-type-1': 'واریز',
  'tr-type-2': 'برداشت',
  'tr-type-3': 'سود سهام',
  'tr-type-4': 'بونوس واریز',
  'tr-type-5': 'پرداخت معرفی',
  'tr-type-6': 'بونوس گردش تیم',
  'tr-type-7': 'فعال سازی طرح',
  'tr-type-8': 'بستن طرح',
  'tr-type-9': 'جریمه',
  'tr-type-10': 'کمیسیون',
  'document-1': 'کارت شناسایی',

  'deposit-benefits-1': 'درآمد همراه با',
  'deposit-benefits-2': 'سود ماهانه متوسط',
  'deposit-benefits-3': 'سود سالانه',
  'deposit-benefits-4': 'سیستم معاملاتی کاملاً خودکار.',
  'deposit-benefits-5':
    'فرصت سرمایه‌گذاری مجدد و سیستم انعطاف‌پذیر برای انتخاب شرایط سرمایه‌گذاری.',

  'staking-1-1': 'محصول سرمایه‌گذاری',
  'staking-1-2':
    'طراحی شده و متمرکز بر کاربرانی که می‌خواهند درآمد پسیو پایداری کسب کنند، ابزاری آسان برای مدیریت، به علاوه تجمع روزانه و برداشت سود.',
  'staking-1-3':
    'محصولی با پتانسیل برای توسعه و رشد در سودآوری، به لطف ۵ سطح از ابزار.',
  copied: 'کپی شده است',
  'email-verification-1': 'حساب کاربری خود را تایید کنید.',
  'email-verification-2':
    'پس از ثبت‌نام، یک ایمیل تاییدیه با دکمه‌ای برای تایید به ایمیل شما فرستاده شده است. اگر به هر دلیلی نمی‌توانید ایمیل را پیدا کنید، روی دکمه زیر کلیک کنید تا دوباره ایمیل ارسال شود.',
  'email-verification-3':
    'برای تایید حساب کاربری خود، روی دکمه موجود در ایمیل کلیک کنید.',
  'email-verification-4':
    'پس از تایید ایمیل، می‌توانید وارد حساب کاربری خود شوید و از تمام ویژگی‌های CoinFuze استفاده کنید.',
  'email-verification-5': 'ارسال',
  'email-verification-6': 'ارسال مجدد ایمیل تاییدیه',

  'def-file-input': 'پرونده انتخاب نشده است',
  'personal-text-16-1': 'تلگرام خود را متصل کنید',
  'new-notification': 'اعلان جدید',
  'personal-text-32':
    'برای اطمینان از اینکه همه چیز به درستی تنظیم شده است، کد امنیتی دریافت شده در گوگل اَتنتیکیتور و رمز عبور حساب کاربری خود را در فیلد زیر وارد کنید. اگر کد و رمز عبور صحیح باشند، بر روی دکمه "فعال کردن" کلیک کنید و ورود دو عاملی فعال می‌شود.',
  'personal-label-1': 'کد امنیتی',
  'personal-label-2': 'رمز عبور شما',
  'withdrawal-text-24-2': 'سوالات متداول در بخش برداشت وجه'
}
