<template>
  <div class="card-inner-item">
    <div class="user d-flex">
      <div class="user-avatar">
        <user-avatar :key="avatarKey" />
      </div>

      <div class="user-info d-flex flex-column flex-fill">
        <div class="user-info-header flex-fill">
          <div class="user-info-name fw-600 text-accent">
            {{ user.name }} {{ user.last_name }}
          </div>
          <div class="user-info-desc">{{ user.email }}</div>
        </div>
        <div class="user-info-footer">
          <div class="user-info-desc">
            {{ $t('dashboard-text-4') }}
            <span class="text-accent">{{ formatDate(user.registration) }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-wrapper">
      <button class="button button-small" @click="openCropModal">
        <span>{{ $t('update') }}</span>
      </button>
      <button
        class="button button_bordered button-small"
        :disabled="loading"
        @click="deleteAvatar"
      >
        <span>{{ $t('delete') }}</span>
      </button>
    </div>

    <crop-modal
      v-if="cropModal"
      :show="cropModal"
      @close-modal="closeCropModal"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import UserAvatar from '@/components/global/UserAvatar.vue'

import CropModal from './CropModal.vue'
export default {
  name: 'UserWidget.vue',
  components: {
    CropModal,
    UserAvatar
  },

  mixins: [],
  props: {},
  data() {
    return {
      loading: false,
      cropModal: false
    }
  },
  computed: {
    ...mapGetters({
      avatarKey: 'user/getAvatarKey',
      user: 'user/getUser'
    })
  },
  watch: {},
  mounted() {},
  methods: {
    deleteAvatar() {
      console.log('delete avatar')
      this.loading = true
      let payload = {
        avatar: 'default'
      }
      this.$store
        .dispatch('user/deleteAvatar', payload)
        .catch((error) => this.$showError(error.detail))
        .finally(() => (this.loading = false))
    },
    openCropModal() {
      this.cropModal = true
    },
    closeCropModal() {
      this.cropModal = false
    },

    formatDate(dateString) {
      const date = new Date(dateString)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()
      return `${day}.${month}.${year}`
    }
  }
}
</script>
<style lang="scss" scoped>
.btn-wrapper {
  display: flex;
  gap: 5px;
  flex-direction: column;
}
.button-small {
  padding: 0.4rem 2.3rem;
  font-size: 12px;
  margin-top: 5px;
  width: 8.857rem;
  @media (max-width: 576px) {
    width: 6.571rem;
  }
}
</style>
