export default {
  'dashboard-text-5-1': 'Profitto dai piani attuali',
  'partnership-title': 'Domanda di partnership con CoinFuze',
  'partnership-form-1': 'ID del tuo account',
  'partnership-form-2': "ID dell'ambasciatore",
  'partnership-form-3': 'Link al(i) blog:',
  'partnership-form-3-1': 'Instagram, Telegram, Youtube, Twitter, ecc.',
  'partnership-form-4': 'Telegram per la comunicazione',
  'partnership-form-5-1': 'Accetto',
  'partnership-form-5-2': 'Termini e condizioni (Affiliato)',
  'partnership-form-btn': 'Invia',
  'partnership-form-footer': 'Informazioni di contatto:',
  'withdrawal-ph-1': 'ID del portafoglio',
  'withdrawal-ph-2': 'Importo prelevato',
  'confirm-modal-1': "Сonferma l'azione",
  'confirm-modal-2': 'Conferma',
  'confirm-modal-3': 'Annulla',
  copy: 'Copiare',
  download: 'Scaricare',
  delete: 'Elimina',
  'modal-p-text-1':
    'Istruzioni dettagliate su come lavorare con i Piani di Investimento',
  'modal-p-text-2':
    'Una volta che il deposito è stato accreditato sul tuo account, puoi investire i tuoi fondi nel piano selezionato.',
  'modal-p-text-3':
    'Segui le istruzioni di seguito per attivare il tuo piano di investimento.',
  'modal-p-text-4':
    "Nel tuo account personale, vai alla pagina 'Gestione Piani'. Qui troverai informazioni dettagliate su tutti i prodotti di investimento. Questo include periodi di investimento, commissioni di servizio, nonché redditività del piano.",
  'modal-p-text-5':
    "Per attivare il piano desiderato, clicca sul pulsante 'Seleziona', inserisci l'importo di capitale iniziale con cui desideri avviare il piano e clicca sul pulsante 'Attiva'.",
  'modal-p-text-6':
    "Con la funzione 'Rinvestimento Automatico', puoi investire automaticamente i tuoi fondi per un lungo periodo e ottenere benefici aggiuntivi.",
  'modal-p-text-7':
    "Immediatamente dopo aver lanciato il piano di investimento, puoi attivare o disattivare l'opzione con un solo clic.",
  'modal-p-text-8':
    'Congratulazioni, hai lanciato con successo un piano di investimento!',
  'modal-p-text-9':
    'Puoi modificare questa impostazione a tua discrezione, anche dopo che il periodo di esecuzione è iniziato. Se hai attivato questa opzione, al termine del periodo del piano di investimento, tutto il capitale, compresi tutti i profitti, verrà reinvestito automaticamente. Se questa opzione è disattivata, il reinvestimento automatico non avviene e tutto il capitale con profitto verrà accreditato al Saldo Libero.',

  update: 'Carica',
  'update-avatar': 'Carica il tuo avatar',
  save: 'Salva',
  'modal-d-text-1': 'Istruzioni per Deposito di Fondi su CoinFuze',
  'modal-d-text-2': "Vai alla pagina 'Deposito' nel tuo account.",
  'modal-d-text-3':
    "Per avviare il processo di deposito, clicca sul pulsante 'Aggiungi Fondi'.",
  'modal-d-text-4':
    'Ti verrà fornito un identificativo unico del portafoglio per inviare fondi. Assicurati di utilizzare questo identificativo quando effettui il pagamento.',
  'modal-d-text-5':
    "Hai anche l'opzione di utilizzare il codice QR situato a sinistra dell'identificativo del portafoglio. Puoi scansionarlo con il tuo telefono cellulare per inserire i dati più facilmente.",
  'modal-d-text-6':
    'Dopo aver inviato i fondi al portafoglio specificato, i fondi verranno accreditati automaticamente sul tuo Saldo. Di solito ciò richiede tra 1 minuto e 2 ore, ma i tempi possono variare a seconda della blockchain e di altri fattori.',
  'modal-d-text-7':
    "I depositi confermati verranno visualizzati nella parte inferiore della pagina nella scheda 'Cronologia Depositi'. Qui puoi monitorare tutte le tue richieste di deposito.",
  'modal-d-text-8':
    'Segui questa guida per depositare fondi nel tuo account CoinFuze in modo semplice e sicuro. Se hai domande o hai bisogno di ulteriore assistenza, contatta il nostro servizio di supporto.',

  'modal-w-text-1':
    'Per avviare il processo di prelievo, segui queste istruzioni:',
  'modal-w-text-2':
    "Nel menu a sinistra del tuo account, vai alla pagina 'Prelievo'.",
  'modal-w-text-3': "Inserisci l'importo di USDT che desideri prelevare.",
  'modal-w-text-4':
    'Specifica il tuo portafoglio USDT TRC20 su cui desideri ricevere i fondi.',
  'modal-w-text-5':
    "Conferma la transazione inserendo il codice di conferma che verrà inviato alla tua email, oltre al codice di password (2FA) per l'autenticazione a due fattori se è abilitato.",
  'modal-w-text-6':
    'Dopo la conferma, la tua richiesta di prelievo verrà elaborata. Di solito ciò richiede da pochi minuti a 24 ore, a seconda del carico del sistema.',
  'modal-w-text-7':
    "Si noti che l'importo minimo di prelievo è di 10 USDT e vi è una piccola commissione di rete. Inoltre, è necessario assicurarsi che il termine del tuo piano di investimento sia scaduto e che il profitto sia stato accreditato sul tuo Saldo Libero per poter prelevare i fondi.",
  validation: {
    nameValidFormat: '{attribute} non deve contenere cifre o simboli',
    twoCharactersInRow:
      '{attribute} non deve avere più di due caratteri identici di fila',
    email: "Formato dell'email non valido",
    required: '{attribute} è obbligatorio',
    minValue:
      'Il valore minimo di {attribute} non può essere inferiore a {min}',
    maxValue:
      'Il valore massimo di {attribute} non può essere superiore a {max}',
    minLength: '{attribute} deve contenere almeno {min} caratteri',
    maxLength: '{attribute} deve contenere al massimo {max} caratteri',
    validFormat:
      '{attribute} deve contenere la prima lettera maiuscola e può contenere solo caratteri',
    validPassword:
      '{attribute} deve contenere almeno una cifra e un carattere maiuscolo',
    sameAsPassword: 'Le password devono essere identiche',
    numeric: '{attribute} non è valido. Deve essere un numero',
    minimumValue: "L'importo non deve essere inferiore a 100 USDT"
  },
  Country: 'Paese',
  'phone-input-placeholder': 'Inserisci il tuo numero di telefono',
  'withdrawal-instruction': 'Istruzioni dettagliate per il prelievo',
  'withdrawal-instruction-text-1':
    'Per avviare il processo di prelievo, segui queste istruzioni:',
  'withdrawal-instruction-text-2':
    "Nel tuo account personale, nel menu di sinistra, vai alla pagina 'Prelievo dei fondi'.",
  'withdrawal-instruction-text-3':
    "Inserisci l'importo di USDT che desideri prelevare.",
  'withdrawal-instruction-text-4':
    'Specifica il tuo portafoglio USDT TRC20 a cui desideri ricevere i fondi.',
  'withdrawal-instruction-text-5':
    "Conferma la transazione inserendo il codice di conferma che verrà inviato alla tua email, oltre al codice di password (2FA) dell'autenticazione a due fattori se lo hai collegato.",
  'withdrawal-instruction-text-6':
    'Dopo la conferma, la tua richiesta di prelievo verrà elaborata. Questo di solito richiede da pochi minuti a 24 ore, a seconda del carico di lavoro del sistema.',
  'withdrawal-instruction-text-7':
    "Tieni presente che l'importo minimo per il prelievo è di 10 USDT e vi è una piccola commissione di rete. Inoltre, dovrai assicurarti che il tuo piano di investimento sia scaduto e i profitti siano accreditati al saldo libero per poter prelevare.",
  'plans-instruction-text-1':
    'Una volta che il deposito è stato accreditato sul tuo account, puoi investire i tuoi fondi nel piano scelto.',
  'plans-instruction-text-2':
    'Segui le istruzioni di seguito per attivare il tuo piano di investimento.',
  'plans-instruction-text-3':
    "Vai alla pagina 'Piani di investimento' nel tuo account personale. Qui troverai informazioni dettagliate su ciascun piano di investimento, come i termini di investimento, le commissioni e il rendimento del piano.",
  'plans-instruction-text-4':
    "Per attivare il piano desiderato, fai clic su 'Aggiungi' e inserisci nell'campo 'Capitale iniziale' l'importo con cui desideri iniziare il piano, quindi fai clic sul pulsante 'Attiva'.",
  'plans-instruction-text-5':
    "Con la funzione 'Riinvestimento automatico', puoi investire automaticamente i tuoi fondi a lungo termine e ottenere benefici aggiuntivi.",
  'plans-instruction-text-6':
    "Immediatamente dopo aver avviato un piano di investimento, puoi attivare o disattivare l'opzione con un solo clic.",
  'plans-instruction-text-7':
    'Congratulazioni, hai avviato con successo un piano di investimento!',
  'plans-instruction-text-8':
    'Puoi modificare questa impostazione come desideri, anche quando la data di scadenza è già iniziata. Se hai attivato questa opzione, alla fine del termine del piano di investimento, tutto il capitale, compresi tutti i profitti, verrà reinvestito automaticamente. Se questa opzione è disattivata, non ci sarà alcun reinvestimento automatico e tutto il capitale con i profitti verrà accreditato al saldo gratuito.',
  'plans-instruction-text-9':
    'Se hai attivato il "Riinvestimento automatico", tutto il capitale e i profitti del piano verranno reinvestiti permanentemente dopo la scadenza, fino a quando non disabiliterai questa opzione.',
  'plans-instruction-text-10':
    "In questo modo, puoi investire completamente in modo automatico il tuo capitale a lungo termine con costi minimi. Per prelevare l'intero capitale, è necessario disattivare questa funzione. Una volta disattivata la funzione, l'intero capitale, compresi i rendimenti, verrà accreditato al saldo gratuito alla fine del termine del piano di investimento in corso. Non saranno addebitate commissioni aggiuntive quando si utilizza questa opzione.",
  'plans-instruction-text-11':
    "Tutte le informazioni su come lavorare con i Piani di investimento Attivi e Completati possono essere trovate nella sezione 'Cronologia del piano' nella parte inferiore della pagina.",
  'popup-instruction-title-2': 'Guida passo passo',
  'popup-instruction-text-1': 'Istruzioni per il deposito su CoinFuze',

  'popup-instruction-text-2':
    "Vai alla pagina 'Deposito' nel tuo account personale.",
  'popup-instruction-text-3':
    "Per avviare il processo di deposito, fai clic sul pulsante 'Deposito'.",
  'popup-instruction-text-4':
    'Ti verrà fornito un ID portafoglio unico per inviare fondi. Assicurati di utilizzare questo identificatore quando effettui un pagamento.',
  'popup-instruction-text-5':
    "Hai anche la possibilità di utilizzare il codice QR situato a sinistra dell'ID del portafoglio. Puoi scannerizzarlo con il tuo cellulare per semplificare l'inserimento dei dati.",
  'popup-instruction-text-6':
    'Dopo aver inviato fondi al portafoglio specificato, i fondi verranno automaticamente accreditati sul tuo saldo. Di solito ci vogliono da 1 minuto a 2 ore, ma i tempi possono variare a seconda della blockchain e di altri fattori.',
  'popup-instruction-text-7':
    "I depositi confermati verranno visualizzati nella parte inferiore della pagina nella scheda 'Cronologia depositi'. Qui potrai tenere traccia di tutte le tue richieste di deposito.",
  'popup-instruction-text-8':
    'Segui queste istruzioni per effettuare il tuo deposito su CoinFuze in modo semplice e sicuro. Se hai domande o hai bisogno di assistenza aggiuntiva, non esitare a contattare il nostro team di supporto.',
  error: 'Errore',
  'partnership-text-31': 'Numero di referenze',
  'partnership-text-30': 'Importo dei depositi',
  'autorization-tab-1': 'Autorizzazione',
  'autorization-tab-2': 'Registrazione',
  'autorization-login': 'Accesso',
  'autorization-username': 'Accesso/Email/Telefono',
  'autorization-password': 'Password',
  'autorization-forgot': 'Password dimenticata?',
  'autorization-btn-login': 'Accedi al tuo account',
  'autorization-name': 'Nome',
  'autorization-surname': 'Cognome',
  'autorization-repeat-password': 'Conferma password',
  'autorization-ypass': 'La tua password',
  'autorization-newpass': 'La tua password',
  'autorization-email': 'Email',
  'autorization-tel': 'Telefono',
  'autorization-promo': 'Codice promozionale',
  'autorization-text-0': 'Accetto i',
  'autorization-text-1': 'Termini e condizioni,',
  'autorization-text-2': 'Informativa sulla privacy',
  'autorization-text-3': 'Politica AML',
  'autorization-text-4': 'Ho 18 anni o più',
  'autorization-text-5': 'Termini di servizio',
  'autorization-text-6': 'Ricordami',
  'autorization-text-7': 'Non hai un account?',
  'autorization-text-8':
    'Inserisci il tuo accesso e ti invieremo istruzioni alla tua email:',
  'autorization-text-10': 'Hai già un account?',
  'autorization-text-11': 'Accedi',
  'autorization-btn-register': 'Registrazione',
  'autorization-btn-cancel': 'Reimposta password',
  'autorization-status-2': 'Email/telefono specificato non trovato.',
  'autorization-status-1': 'Accesso o password non validi',
  'autorization-status-name': 'Nome non corretto',
  'autorization-status-surname': 'Cognome non corretto',
  'autorization-status-password':
    'La password deve essere lunga almeno 7 caratteri e contenere almeno 1 lettera maiuscola e 1 lettera minuscola',
  'autorization-status-repeat-password': 'Le password non corrispondono',
  'autorization-status-email': 'Formato email non corretto',
  'autorization-status-phone': 'Numero di telefono non corretto',
  'autorization-status-agree-rules': 'Conferma le condizioni',
  'autorization-status-agree-age': 'Conferma le condizioni',
  'validation-1': 'Per registrarti devi essere maggiorenne',
  'validation-2': 'Per registrarti devi accettare i termini e le condizioni',
  'header-item-1': 'Saldo totale',
  'header-item-2': 'Saldo libero:',
  'header-item-3': 'Saldo attivo:',
  'non-message': 'Nessuna nuova notifica',
  'message-btn': 'Mostra tutte le notifiche',
  'header-item-4': 'Informazioni personali',
  'header-item-5': 'Invita amici',
  'header-item-6': 'Aggiungi',
  'header-item-7': 'Esci',
  'footer-item-2': "Condizioni d'uso",
  'footer-item-3': 'Informativa sulla privacy',
  'footer-item-1': 'Politica AML',
  'sidebar-item-1': 'Dashboard',
  'sidebar-item-2': 'Banking',
  'sidebar-item-3': 'Gestione piani',
  'sidebar-item-4': 'Programma affiliati',
  'sidebar-item-5': 'Prelievi',
  'sidebar-item-6': 'Informazioni personali',
  'sidebar-item-7': 'Notizie',
  'sidebar-item-8': "Esci dall'account",
  'dashboard-subtitle-1': 'Account',
  'dashboard-subtitle-2': 'Finanza',
  'dashboard-subtitle-3': 'Investimenti',
  'dashboard-subtitle-4': 'Programma Affiliati',
  'dashboard-subtitle-5': 'Termine',
  'dashboard-subtitle-6': 'Risultato Attuale',
  'dashboard-subtitle-7': 'Risultato Finanziario',
  'dashboard-text-1': 'Saldo Libero',
  'dashboard-text-2': 'Fondi Attivi',
  'dashboard-text-3': 'Verificato',
  'dashboard-text-4': 'Partecipante dal',
  'dashboard-text-5': 'Piani di Profitto Totali',
  'dashboard-text-6': 'Reddito da Referral',
  'dashboard-text-7': 'Deposito',
  'dashboard-text-8': 'Prelievi',
  'dashboard-text-9': 'Profitto Totale',
  'dashboard-text-10': 'Piani Attivi',
  'dashboard-text-10-1': 'Piani Chiusi',
  'dashboard-text-11': 'Piani su reinvestimento',
  'dashboard-text-12': 'Numero di Partner',
  'dashboard-text-13': 'Partner di 1a Linea',
  'dashboard-text-14': 'Partner di 2a Linea',
  'dashboard-text-15': 'Partner di 3a Linea',
  'dashboard-text-16': 'Periodo',
  'dashboard-text-17': 'giorni',
  'dashboard-text-18': 'Ancora nessuna notizia',
  'dashboard-text-19': 'Attualmente',
  'dashboard-text-20': 'Non abbastanza dati per il grafico',
  'dashboard-text-21': '- Nessun investimento attivo',
  'dashboard-text-22': 'Leggi tutte le notizie',
  'dashboard-text-22-1': 'Leggi tutto',
  'dashboard-text-23': 'Piano di Investimento',
  progressLabel: 'Progresso Piano di Investimento',
  daysLabel: 'Giorni al Completamento',
  'deposit-subtitle-1-1':
    'Prima di effettuare un deposito, assicurati di aver completato il',

  'deposit-subtitle-1-2': 'Registrazione del tuo account.',
  'deposit-subtitle-1-3':
    'Altrimenti non sarai in grado di effettuare un deposito.',
  'deposit-subtitle-2': 'Guadagna con CoinFuze',
  'deposit-text-1':
    "Per depositare il tuo saldo, clicca sul pulsante 'Ricarica il tuo Account'.",
  'deposit-text-2':
    "Tutte le tue transazioni di deposito verranno salvate nella sezione 'Cronologia' qui sotto, dove potrai monitorare lo stato delle tue richieste.",
  'deposit-text-3':
    "Quando una richiesta riceve lo stato 'Completato', i fondi saranno automaticamente aggiunti al tuo 'Saldo Libero'.",
  'deposit-text-4':
    'Al momento utilizziamo la criptovaluta più stabile per il nostro sistema: Tether USDT.',
  'deposit-text-5':
    'Dopo aver effettuato un deposito, potrai godere di tutti i vantaggi della collaborazione con CoinFuze.',
  'deposit-text-6': 'Profitto mensile medio - 6.8%*',
  'deposit-text-7': 'Profitto annuale - 98.7%',
  'deposit-text-8': 'Sistema di trading completamente automatizzato',
  'deposit-text-9':
    'Possibilità di reinvestimento e sistema flessibile per la scelta dei termini di investimento',
  'deposit-text-10':
    "*I dati sulla redditività sono tratti dalle statistiche aperte del lavoro dell'algoritmo per il 2024.",
  'deposit-text-11': 'Istruzioni dettagliate su come effettuare il deposito',
  'deposit-text-12':
    'Hai una domanda sul deposito? Chiedila nella chat di supporto',
  'deposit-text-13':
    "Per depositare il tuo saldo, clicca sul pulsante 'Deposita sul tuo Account'.",
  'deposit-text-14':
    "Tutte le tue transazioni di deposito saranno salvate nella sezione 'Cronologia' qui sotto, dove potrai monitorare lo stato delle tue richieste.",
  'deposit-text-15':
    "Quando una richiesta riceve lo stato 'Completato', i fondi saranno automaticamente aggiunti al tuo 'Saldo Libero'.",
  'deposit-text-16':
    'Al momento utilizziamo la criptovaluta più stabile per il nostro sistema: Tether USDT.',
  'deposit-text-17':
    'Dopo aver effettuato un deposito, potrai godere di tutti i vantaggi della collaborazione con CoinFuze.',
  'deposit-text-18':
    'Hai una domanda sul deposito? Chiedila nella chat di supporto.',
  'deposit-btn': 'Deposita sul tuo account',
  notification: 'Copiato',
  'deposit-popup-text-1': 'Criptovaluta',
  'deposit-popup-text-2': 'Valuta di deposito',
  'deposit-popup-text-3': 'Il tuo saldo:',
  'deposit-popup-text-4': 'Rete',
  'deposit-popup-text-5': 'Nota:',
  'deposit-popup-text-6-1': 'Invia solo',
  'deposit-popup-text-6-2':
    'a questo indirizzo di deposito. Le monete verranno accreditate automaticamente dopo',
  'deposit-popup-text-6-3': '6 conferme di rete.',
  'deposit-popup-text-6-4':
    'Gli indirizzi dei contratti intelligenti non sono supportati (contattaci).',
  'deposit-popup-text-7': 'Indirizzo di deposito:',
  'deposit-popup-text-8': 'Copia',
  history: 'Cronologia',
  'deposit-history-text-1': 'Data del Deposito',
  'deposit-history-text-2': 'Metodo di Pagamento',
  'deposit-history-text-3': 'Importo del Deposito',
  'deposit-history-text-4': 'Stato della Transazione',
  'deposit-history-text-5': 'Transazione',
  'deposit-history-text-6': 'Nessuna transazione',
  'deposit-history-text-7': 'Mostrato',
  'deposit-history-text-8': 'di',
  'plans-subtitle-1': 'Gestione degli Investimenti',
  'plans-subtitle-2': 'Primi Investimenti',
  'plans-text-1':
    'In questa sezione, puoi creare piani tra i piani disponibili o visualizzare i risultati finanziari di tutti i tuoi piani attivi o chiusi.',
  'plans-text-2':
    "Ci sono sei piani di investimento tra cui scegliere, ognuno con diverse condizioni per quanto riguarda il termine dell'investimento, i rendimenti attesi e le commissioni di servizio di Coinfuze.",
  'plans-text-3':
    "Per attivare il piano selezionato, gli utenti devono effettuare un deposito minimo e fare clic sul pulsante 'Attiva'.",
  'plans-text-4':
    'Istruzioni dettagliate su come lavorare con i Piani di Investimento.',
  'plans-text-5': 'Domande frequenti sulla sezione Investimenti.',
  'plans-text-6':
    "Per iniziare a investire, è necessario effettuare un deposito. Puoi farlo nella pagina 'Deposito' nel menu a sinistra o con il pulsante qui sotto.",
  'plans-text-7':
    'Dopo che il tuo deposito è stato elaborato, attiva il piano di investimento selezionato.',
  'plans-text-8':
    'CoinFuze offre la funzione di reinvestimento automatico. Ciò significa che alla fine del termine del piano, tutti i profitti del periodo precedente vengono trasferiti automaticamente al capitale iniziale del nuovo piano.',
  'plans-text-9': 'Vai al deposito',
  'plans-text-10': 'Attiva il piano di investimento',
  'plans-text-11': 'Sì',
  'plans-text-12': 'No',
  'plans-text-13': 'Piani attivi',
  'plans-text-14': 'Data di inizio',
  'plans-text-15': 'Data di fine',
  'plans-text-16': 'Capitale iniziale',
  'plans-text-17': '% risultato finanziario',
  'plans-text-18': 'Profitto in',
  'plans-text-19': 'Stato del piano',
  'plans-text-20': 'Reinvesti',
  'plans-text-21': 'Salva',
  'plans-text-22': 'Piani disponibili',
  'plans-text-23': 'Investimento minimo',
  'plans-text-24': 'Termine',
  'plans-text-25': 'Resa',
  'plans-text-26': 'fino a',
  'plans-text-27': 'Commissione',
  'plans-text-28': "Inserisci l'importo del capitale iniziale:",
  'plans-text-29': 'Attiva',
  'plans-text-30': "Inserisci l'importo minimo",
  'plans-text-31': 'Nome',
  'plans-text-32': 'Capitale iniziale',
  'plans-text-33': 'Risultato attuale',
  'plans-text-34': 'Profitto',
  'plans-text-35': 'Commissione',
  'plans-text-36': 'Attivo',
  'plans-text-37': 'Completato',
  'plans-text-38': 'Piano di investimento',
  'plans-text-39': 'giorni',
  'plans-text-40': 'Seleziona',
  'plans-text-41': 'Stato:',
  'partnership-subtitle-1': 'Programma di Referral',
  'partnership-subtitle-2': 'Il tuo link di referral',
  'partnership-subtitle-3': 'Referral invitati',
  'partnership-subtitle-4':
    'Livello 1 - Commissione sul deposito e sul profitto',
  'partnership-subtitle-5':
    'Livello 2 - Commissione sul deposito e sul profitto',
  'partnership-subtitle-6':
    'Livello 3 - Commissione sul deposito e sul profitto',
  'partnership-text-1': 'Raccomandaci ai tuoi amici.',
  'partnership-text-2':
    'Ottieni reddito passivo attraverso il programma di referral.',
  'partnership-text-3': 'Copia il tuo link di referral.',
  'partnership-text-4': 'Invialo ai tuoi amici.',
  'partnership-text-5': 'Ottieni bonus per i loro depositi.',
  'partnership-text-6': 'Ottieni bonus sui loro profitti.',
  'partnership-text-7':
    'Ogni membro di CoinFuze riceve un link di referral unico e un codice promozionale da condividere con gli amici provenienti da tutto il mondo. Copia semplicemente questo link e invialo a coloro che desideri invitare su CoinFuze.',
  'partnership-text-8': 'IL TUO PROMO',
  'partnership-text-9':
    'Condividi il tuo link di affiliazione o il codice promozionale.',
  'partnership-text-10': 'Studiare le statistiche dei click',
  'partnership-text-11':
    'Tieni traccia di quante persone si sono registrate attraverso il tuo link',
  'partnership-text-12': 'Guarda crescere i tuoi profitti.',
  'partnership-text-13': 'Totale invitati',
  'partnership-text-14': 'Partner di 1° Livello',
  'partnership-text-15': 'Partner di 2° Livello',
  'partnership-text-16': 'Partner di 3° Livello',
  'partnership-text-17': 'Guadagni totali:',
  'partnership-text-18': 'Ricerca',
  'partnership-text-19':
    'Guarda chi si è già registrato attraverso il tuo link di affiliazione e quanti bonus hai ricevuto. Non dimenticare che abbiamo un programma di referral a tre livelli. Ricevi bonus non solo per coloro che hai invitato su CoinFuze, ma anche per i membri che i tuoi referral hanno invitato.',
  'partnership-text-20': 'Inserisci il nome del tuo referral',
  'partnership-text-21': 'Bonus sui depositi',
  'partnership-text-22': 'Bonus sui profitti',
  'partnership-text-23': 'Reddito da partnership',
  'partnership-text-24': 'Data di accredito',
  'partnership-text-25': 'Nessun referral con questo nome',
  'partnership-text-26': 'Cerca...',
  'partnership-text-27': 'Nessun referral',
  'partnership-text-28': 'Livello',
  'partnership-text-29': 'Ammontare di fatturato',
  'partnership-text-32': 'Bonus',
  'partnership-text-33': 'Passato',
  'partnership-text-34': 'Il tuo livello',
  'partnership-text-35': 'Chiudi',
  'partnership-text-36': 'Link di referral:',
  'partnership-text-37': 'Il tuo promo:',
  'partnership-text-38': 'Fatturato del Team attivo:',
  'partnership-text-39': 'Commissione su deposito e su profitto:',
  'partnership-text-40': 'Referral',
  'partnership-text-41': 'Bonus del Team',
  'news-text-1': 'Ancora nessuna notizia.',
  'news-text-2': 'Leggi di più',
  'news-text-3': 'Materiali promozionali',
  'news-text-4':
    'Condividi i nostri materiali promozionali con i tuoi amici e guadagna!',
  'news-text-5':
    'Abbiamo selezionato dei materiali promozionali per te da utilizzare per attirare nuovi membri di CoinFuze.',
  'news-text-6': 'Condividi e guadagna con i tuoi amici!',
  'news-text-7':
    'Ogni membro di CoinFuze riceve un link di referral unico e un codice promozionale da condividere con amici provenienti da tutto il mondo. Basta copiare questo link e inviarlo a chi vuoi invitare su CoinFuze.',
  'news-text-8': 'IL TUO PROMO',
  'news-text-9':
    'Condividi il tuo link di affiliazione o il codice promozionale.',
  'news-text-10': 'Studia le statistiche dei click su di esso',
  'news-text-11':
    'Tieni traccia di quante persone si sono registrate attraverso il tuo link',
  'news-text-12': 'Guarda crescere i tuoi profitti.',
  'news-text-13': 'Guadagna fino al 6% di bonus di referral',
  'withdrawal-text-1': 'Data di Prelievo',
  'withdrawal-text-2': 'Metodo di Pagamento',
  'withdrawal-text-3': 'Importo di Prelievo',
  'withdrawal-text-4': 'Devi verificare la tua identità per prelevare i fondi',
  'withdrawal-text-5':
    "Devi completare il processo di verifica caricando i tuoi documenti d'identità prima del prelievo.",
  'withdrawal-text-6': 'Verifica',
  'withdrawal-text-7': 'Prelievo',
  'withdrawal-text-8':
    'Inserisci il codice di conferma inviato alla tua email:',
  'withdrawal-text-9': 'Inserisci il codice',
  'withdrawal-text-10': 'Rinvia nuovamente il codice tramite:',
  'withdrawal-text-11': 'Verifica il codice',
  'withdrawal-text-12': 'Rinvia',
  'withdrawal-text-13': 'Verificato',
  'withdrawal-text-14': 'Rinvia nuovamente il codice tramite:',
  'withdrawal-text-15': "Inserisci il codice dall'app mobile",
  'withdrawal-text-16': 'Inserisci il codice da',
  'withdrawal-text-17': 'Informazioni:',
  'withdrawal-text-18':
    "Il saldo libero è il saldo disponibile per il prelievo. Se hai investimenti attivi, essi non vengono conteggiati perché sono bloccati fino alla scadenza dell'investimento.",
  'withdrawal-text-19': 'Prelievo di Fondi',
  'withdrawal-text-19-1': 'Disponibile per il prelievo:',
  'withdrawal-text-19-2': 'Inizia Prelievi',
  'withdrawal-text-20':
    'In questa pagina puoi prelevare i fondi nel tuo wallet di criptovalute. Segui le istruzioni per effettuare il prelievo in modo rapido e conveniente.',
  'withdrawal-text-21':
    'I prelievi, come i depositi, sono disponibili in criptovaluta.',
  'withdrawal-text-22':
    'Il prelievo potrebbe richiedere del tempo, poiché le transazioni nella rete delle criptovalute avvengono a diverse velocità.',
  'withdrawal-text-23':
    'Le richieste di prelievo sono accettate e processate automaticamente in breve tempo.',
  'withdrawal-text-24': 'Inizia il prelievo',
  'withdrawal-text-24-1': 'Istruzioni dettagliate per il prelievo',
  'withdrawal-text-25': 'Aumenta la redditività dei tuoi investimenti!',
  'withdrawal-text-26':
    'Puoi ottenere ancora più profitti reinvestendo i tuoi fondi.',
  'withdrawal-text-27': 'Reinvesti il tuo deposito e i profitti che fai.',
  'withdrawal-text-28':
    'Inizia il periodo di investimento con un grande capitale iniziale.',
  'withdrawal-text-29': 'Guarda aumentare il tuo reddito ogni giorno.',
  'withdrawal-text-30': 'Reinvesti ora',
  'withdrawal-text-31': 'Scegli il portafoglio giusto per il prelievo',
  'withdrawal-text-32':
    'Per prelevare i fondi nel tuo portafoglio, aggiungilo al tuo account e crea una richiesta di prelievo.',

  'withdrawal-text-33': 'Prelievo su questo',
  'withdrawal-text-34': 'Invia richiesta',
  'withdrawal-text-35': 'Informazioni:',
  'withdrawal-text-36':
    'Il saldo mostrato è il tuo saldo disponibile, non il tuo totale dei fondi. Se hai investimenti attivi, non sono conteggiati perché sono bloccati fino alla fine del periodo di investimento.',
  'withdrawal-text-37': 'Importo Prelievo',
  'personal-text-1': 'Dati personali',
  'personal-text-2': 'Autenticazione a due fattori',
  'personal-text-3': 'Password',
  'personal-text-4': 'Verifica',
  'personal-text-5': 'Il tuo Nome',
  'personal-text-6': 'Inserisci un nome corretto',
  'personal-text-7': 'Il tuo cognome',
  'personal-text-8': 'Inserisci un cognome corretto',
  'personal-text-9': 'Annulla',
  'personal-text-10': 'Salva',
  'personal-text-11': 'Abilita/disabilita',
  'personal-text-12':
    "L'autenticazione a due fattori (2FA) tramite email è abilitata per impostazione predefinita per tutti gli account e non può essere disabilitata.",
  'personal-text-13':
    'Per cambiare il tuo indirizzo email in CoinFuze, dovresti contattare il supporto. Una volta che il supporto avrà elaborato la tua richiesta, riceverai una notifica che il tuo indirizzo è stato cambiato con successo con uno nuovo.',
  'personal-text-14':
    'È importante ricordare che, al fine di evitare frodi e proteggere i tuoi dati, il supporto potrebbe richiedere informazioni o documenti aggiuntivi per verificare la tua identità.',
  'personal-text-15':
    "2FA è disabilitato. Consigliamo a tutti i clienti di abilitare l'autenticazione a due fattori (2FA) per una maggiore sicurezza.",
  'personal-text-16': 'Disabilita',
  'personal-text-17': 'Abilita',
  'personal-text-18': 'Account',
  'personal-text-19':
    "Questa funzionalità ti consente di utilizzare Google Authenticator o il tuo account Telegram per l'autenticazione a due fattori. Oltre alla conferma dall'email al tuo indirizzo email, dovrai anche fornire un codice di sicurezza, da Google Authenticator o Telegram, per poter eseguire determinate azioni nel tuo account personale. Queste azioni includono: Prelievo di fondi, modifica delle informazioni personali e della password dell'account. Questo offre una protezione aggiuntiva contro i tentativi di accesso da parte di hacker, anche se sono riusciti a ottenere la tua password.",
  'personal-text-20':
    "Questa funzionalità ti consente di utilizzare Google Authenticator o un'applicazione compatibile come FreeOTP per l'autenticazione a due fattori. Oltre al tuo nome utente e alla tua password, dovrai anche fornire un codice di sicurezza composto da sei cifre per poter effettuare l'accesso a questo sito. Il codice di sicurezza cambia ogni 30 secondi. Questo offre una protezione aggiuntiva contro i tentativi di accesso da parte di hacker, anche se sono riusciti a ottenere la tua password.",
  'personal-text-21': 'Ottieni',
  'personal-text-22':
    'Scarica e installa Google Authenticator sul tuo smartphone o computer.',
  'personal-text-23': 'Utilizza uno dei seguenti metodi:',
  'personal-text-24':
    "L'app ufficiale di Google Authenticator per Android, iOS e BlackBerry. Ricorda di sincronizzare l'orologio del tuo dispositivo con il server di tempo. Uno spostamento di tempo sul tuo dispositivo potrebbe impedirti di accedere al tuo account personale.",
  'personal-text-25': 'e',
  'personal-text-26':
    "Ricorda di sincronizzare l'orologio del tuo dispositivo con il server di tempo. Uno spostamento di tempo sul tuo dispositivo potrebbe impedirti di accedere al tuo account personale.",
  'personal-text-27': 'Configurazione.',
  'personal-text-28': 'Scansiona il seguente codice QR a',
  'personal-text-29': 'Chiave Personalizzata',
  'personal-text-30':
    "Se desideri cambiare la chiave, disabilita l'autenticazione a due fattori. Quando cercherai di riattivarla, genererà una nuova chiave.",
  'personal-text-31': "Attiva l'autenticazione a due fattori.",
  'personal-text-33': 'Codice di Sicurezza',
  'personal-text-34': 'La Tua Password',
  'personal-text-35': 'Abilita',
  'personal-text-37': 'Password Attuale',
  'personal-text-38': 'Nuova Password',
  'personal-text-39': 'Conferma la nuova password',
  'personal-text-41': 'Documenti Scaricabili',
  'personal-text-42': 'Scarica',
  'personal-text-43': 'Formato accettabile',
  'personal-text-44': 'I tuoi documenti sono in fase di verifica',
  'personal-text-45': 'I tuoi documenti sono stati verificati',
  'personal-text-46': 'I tuoi documenti',
  'personal-text-47': 'Sessioni Attive',
  'personal-text-48': 'Sessioni del Browser',
  'personal-text-49':
    'Gestisci e esci dalle tue sessioni attive su altri browser e dispositivi.',
  'personal-text-50':
    'Puoi uscire da tutte le altre sessioni del browser su tutti i tuoi dispositivi se necessario. Di seguito sono elencate alcune delle tue sessioni recenti; tuttavia, questa lista potrebbe non essere esaustiva. Se ritieni che il tuo account sia stato compromesso, dovresti anche aggiornare la tua password.',
  'personal-text-51': 'Questo dispositivo',
  'personal-text-51-1': 'disconnettersi',

  'personal-text-52': 'Esci da altre sessioni del browser',
  'personal-text-53': 'Patente di Guida',
  'personal-text-54': 'Ricevuta Bancaria',
  file: 'File non selezionato',
  document: 'Passaporto',
  'personal-text-55':
    "Per cambiare la tua password, passa per l'autenticazione a due fattori (2FA)",
  'personal-text-56':
    "Per cambiare il tuo nome e cognome, passa per l'autenticazione a due fattori (2FA).",
  'personal-text-57':
    "L'autenticazione a due fattori (2FA) è attualmente abilitata su questo account.",
  'personal-text-58':
    'Copia questa password di emergenza monouso e conservala in un luogo sicuro. Se non hai accesso a un dispositivo di autenticazione a due fattori, puoi utilizzare questa password al posto del tuo codice di sicurezza regolare. Questa password viene immediatamente distrutta quando viene utilizzata. Ti consigliamo di stampare questa password e conservarne una copia in un luogo sicuro e accessibile, come il tuo portafoglio o una cassaforte.',
  'personal-text-59': "Disattivazione dell'autenticazione a due fattori.",
  'personal-text-60': 'Inserisci il codice di autenticazione a due fattori da.',
  'personal-text-61': 'Invia',
  'personal-text-62': 'Annulla',
  'text-63': 'Vai al tuo account personale',
  'text-64': 'Torna',
  'text-65': 'Notifiche',
  'text-66': 'Segna tutte le notifiche come lette',
  'text-67': 'Segna',
  'text-68': 'La tua registrazione è quasi completa',
  'text-69':
    "Devi completare il processo di registrazione prima di investire. Per farlo, clicca sul pulsante 'Completa' e conferma la tua registrazione dalla email inviata alla tua casella email.",
  'text-70': 'Completa la registrazione',
  'text-71': 'Seleziona la lingua',

  'cookies-1': 'Navigando su questo sito, accetti la',
  'cookies-2': 'nostra politica sulla privacy',
  ok: 'Ok',

  'table-1': 'Accesso',
  'table-2': 'Bonus (Deposito)',
  'table-3': 'Bonus (Profitti)',
  'table-4': 'Numero di referral',
  'table-5': 'Data di registrazione',
  'table-6': 'Tipo di transazione',
  'table-7': 'Data',
  'table-8': 'Importo',
  'table-9': 'Stato della transazione',
  'table-10': 'ID Transazione',

  'profile-window-1': 'Accesso:',
  'profile-window-2': 'Telefono:',
  'profile-window-3': 'Lingua:',
  'profile-window-4': 'Tema:',
  'profile-window-5': 'Invita amici:',

  'two-factor-1': 'Validazione a due fattori',
  'two-factor-2': 'Inserisci il codice di conferma inviato alla tua email:',
  'two-factor-3': 'Rispedisci',
  'two-factor-4': 'Convalida',
  'two-factor-5': 'Convalidato',
  'two-factor-6': 'Rispedisci nuovamente il codice tramite:',
  'two-factor-7': 'Inserisci il codice di conferma inviato al tuo',
  'two-factor-8': 'Inserisci il codice',

  back: 'Torna alla Gestione dei Piani',
  'back-to-site': 'Torna al Sito',
  'back-to-news': 'Torna alle Notizie',
  support: 'supporto',
  title: 'Titolo',
  step: 'Passo',
  'page-not-found': 'Pagina non trovata',
  home: 'Home',

  'staking-1': 'Torna alla Gestione dei Piani',
  'staking-2': 'stake di criptovalute',
  'staking-3':
    'Puoi disconnetterti da tutte le altre sessioni del browser su tutti i tuoi dispositivi se necessario. Alcune delle tue sessioni recenti sono elencate di seguito; tuttavia, questa lista potrebbe non essere esaustiva. Se ritieni che il tuo account sia stato compromesso, dovresti anche aggiornare la tua password.',
  'staking-4': 'Passare alla',
  'staking-4-1': 'Attivare',
  'staking-5': 'Staking',
  'staking-6': 'Risultato finanziario:',
  'staking-7': "Data dell'ultimo profitto:",
  'staking-8': 'Deposito nello staking:',
  'staking-9': 'Livelli',
  'staking-10': 'Ultimo investimento:',
  'staking-11': 'livello',
  'staking-12': 'Importo di turnover',
  'staking-13': 'Bonus',
  'staking-14': 'Passato',
  'staking-15': 'Il tuo livello',
  'staking-16': 'Chiudi',
  'staking-17': 'Livello di Staking:',
  'staking-18': 'Attivo',
  'staking-19': 'Inattivo',
  'staking-20': 'Completato',
  'staking-table-1': 'Piano di investimento',
  'staking-table-2': 'Data di inizio',
  'staking-table-3': 'Data di fine',
  'staking-table-4': 'Capitale iniziale',
  'staking-table-5': 'Risultato attuale, USDT',
  'staking-table-6': 'Profitto, USDT',
  'staking-table-7': 'Commissione, USDT',
  'staking-table-8': 'Stato del piano',

  'plan-modal-1': 'Piano di investimento',
  'plan-modal-2': 'Data di inizio',
  'plan-modal-3': 'Data di fine',
  'plan-modal-4': 'Capitale iniziale',
  'plan-modal-5': 'Risultato finanziario',
  'plan-modal-6': 'Profitto in USDT',
  'plan-modal-7': "Inserisci l'importo del capitale iniziale:",
  'plan-modal-8': 'Attiva',

  'plan-page-1': 'Piani attivi',
  'plan-page-2': 'Piani disponibili',
  done: 'Fatto',
  purpose: 'Scopo',
  'password-reset': 'La password è stata inviata alla tua email',
  'table-next': 'Avanti',
  'table-prev': 'Precedente',

  'tr_status-1': 'In attesa',
  'tr_status-2': 'Manuale',
  'tr_status-3': 'Rifiutato',
  'tr_status-4': 'Approvato',
  'tr-type-0': 'Tutto',
  'tr-type-1': 'Deposito',
  'tr-type-2': 'Prelievo',
  'tr-type-3': 'Dividendi',
  'tr-type-4': 'Bonus per deposito',
  'tr-type-5': 'Pagamento di riferimento',
  'tr-type-6': 'Bonus per il volume di squadra',
  'tr-type-7': 'Attivazione del piano',
  'tr-type-8': 'Chiusura del piano',
  'tr-type-9': 'Penale',
  'tr-type-10': 'Commissione',

  'document-1': 'Carta d’identità',

  'deposit-benefits-1': 'Guadagna con',
  'deposit-benefits-2': 'Profitto medio mensile',
  'deposit-benefits-3': 'Profitto annuale',
  'deposit-benefits-4': 'Sistema di trading completamente automatizzato.',
  'deposit-benefits-5':
    'Opportunità di reinvestimento e un sistema flessibile per scegliere i termini di investimento.',

  'staking-1-1': 'Prodotto di investimento',
  'staking-1-2':
    'progettato e focalizzato su utenti che desiderano ottenere un reddito passivo stabile, uno strumento facile da gestire, oltre a accrediti giornalieri e prelievo dei profitti.',
  'staking-1-3':
    'un prodotto con il potenziale per lo sviluppo e la crescita della redditività, grazie ai 5 livelli dello strumento.',
  copied: 'copiato',

  'email-verification-1': 'Verifica il tuo account.',
  'email-verification-2':
    "Una email di conferma è stata inviata al tuo indirizzo email al momento della registrazione con un pulsante di verifica. Se per qualche motivo non riesci a trovare l'email, clicca sul pulsante qui sotto e invieremo nuovamente l'email.",
  'email-verification-3':
    "Per confermare il tuo account, clicca sul pulsante nell'email.",
  'email-verification-4':
    'Dopo aver confermato la tua email, potrai accedere al tuo account e iniziare a utilizzare tutte le funzionalità di CoinFuze.',
  'email-verification-5': 'Invia',
  'email-verification-6': 'Reinvia email di conferma',

  'def-file-input': 'File non selezionato',
  'personal-text-16-1': 'Collega il tuo telegramma',
  'new-notification': 'Nuova notifica',
  'personal-text-32':
    "Per assicurarti che tutto sia configurato correttamente, inserisci il codice di sicurezza ricevuto in Google Authenticator e la password del tuo account nel campo sottostante. Se il codice e la password sono corretti, fai clic sul pulsante Abilita e l'autenticazione a due fattori verrà attivata.",
  'personal-label-1': 'Codice di sicurezza',
  'personal-label-2': 'La tua password',
  'withdrawal-text-24-2': 'Domande frequenti nella sezione Prelievi'
}
