import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './plugins/locales/index'

import './assets/styles/styles.scss'
import './assets/icomoon/style.css'

import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

import VueApexCharts from 'vue-apexcharts'
import vSelect from 'vue-select'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import '@sweetalert2/theme-dark/dark.scss'

import VueGoodTablePlugin from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import 'vue-select/dist/vue-select.css'
import Vuelidate from 'vuelidate'
import VuelidateErrorExtractor from 'vuelidate-error-extractor'
import { templates } from 'vuelidate-error-extractor'
import FormFieldWrapper from '@/components/global/FormFieldWrapper.vue'
// import { messages } from '@/libs/validator'

// window.$crisp = $crisp
import VueCroppie from 'vue-croppie'
import 'croppie/croppie.css' // import the croppie css manually

Vue.use(VueCroppie)
Vue.config.productionTip = false
Vue.use(VueTelInput)
Vue.use(VueApexCharts)
Vue.use(VueSweetalert2)
Vue.use(VueGoodTablePlugin)
Vue.component('apex-сhart', VueApexCharts)
Vue.component('v-select', vSelect)

Vue.use(Vuelidate)
Vue.use(VuelidateErrorExtractor, {
  i18n: 'validation',
  // messages: messages,
  template: FormFieldWrapper
})
Vue.component('form-group', templates.singleErrorExtractor.foundation6)

Vue.prototype.$showSuccess = function (msg) {
  Vue.swal({
    text: msg,
    icon: 'success',
    timer: 5000,
    toast: true,
    position: 'top-right',
    showConfirmButton: false
  })
}

Vue.prototype.$showError = function (msg) {
  if (msg === 'auth' || !msg) return
  Vue.swal({
    text: msg,
    icon: 'error',
    timer: 5000,
    toast: true,
    position: 'top-right',
    showConfirmButton: false
  })
}

new Vue({
  router,
  store,
  i18n,

  render: (h) => h(App)
}).$mount('#app')
