<template>
  <div class="card news">
    <div class="card-inner d-flex flex-column">
      <div class="news_image">
        <a class="d-block"
          ><img
            class="d-block w-100"
            :src="`https://ca.coinfuze.com${news.banner}`"
            alt="coinfuze"
        /></a>
      </div>
      <div class="news_content flex-fill">
        <div class="news_content-header">
          <h3 class="news_title h5" v-if="preview">
            <router-link
              :to="`/news/${news.slug}`"
              v-html="formatString(decodeHtmlEntities(news.title), 50)"
            />
          </h3>
          <h3
            class="news_title h5"
            v-else
            v-html="decodeHtmlEntities(news.title)"
          ></h3>
          <div class="news_date">{{ formatDate(news.created) }}</div>
        </div>
        <p
          class="news_desc"
          v-html="formatString(decodeHtmlEntities(news.text), 100)"
        ></p>
        <router-link
          v-if="preview"
          class="button__link news_more"
          :to="`/news/${news.slug}`"
          >{{ $t('dashboard-text-22-1') }}
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.833 7L1.16634 7M12.833 7L7.83301 12M12.833 7L7.83301 2"
              stroke="#37BBC6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NewsCard',
  props: {
    preview: {
      default: true,
      type: Boolean
    },
    news: {
      require: true,
      type: Object
    }
  },
  data() {
    return {}
  },
  methods: {
    openNews(slug) {
      this.$router.push(`news/${slug}`)
    },
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()
      return `${day}.${month}.${year}`
    },
    decodeHtmlEntities(text) {
      return new DOMParser().parseFromString(text, 'text/html').body.textContent
    },
    formatString(str, length) {
      return str.length > length && this.preview
        ? str.substring(0, length) + '...'
        : str
    }
  }
}
</script>
