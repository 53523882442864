<template>
  <div class="form-field">
    <p v-if="label" class="form-field-label">
      {{ label }}
    </p>

    <div class="form-field-item" v-if="!customInput">
      <slot />
    </div>

    <slot v-else />

    <label
      v-for="(error, idx) in activeErrorMessages"
      :key="`error-${idx}`"
      class="form-field-error-text"
    >
      {{ error }}
    </label>
  </div>
</template>

<script>
import { singleErrorExtractorMixin } from 'vuelidate-error-extractor'
export default {
  name: 'FormFieldWrapper',
  mixins: [singleErrorExtractorMixin],
  props: {
    label: {
      type: String
    },
    v: {
      type: Object,
      required: true
    },
    customInput: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.form-field {
  &-label {
    font-weight: 600;
    font-size: 18px;
    color: #696969;
    margin-bottom: 12px;
    padding-left: 2px;
  }

  &-item {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 4px;
    margin-bottom: 12px;

    i {
      position: absolute;
      right: 16px;
      font-size: 20px;
      color: grey;
      cursor: pointer;
    }
  }

  &-error-text {
    display: block;
    font-size: 12px;
    line-height: 16px;
    color: red;
    margin-left: 2px;
    margin-bottom: 16px;
  }
}
</style>
