<template>
  <img :src="userAvatar" alt="coinfuze" />
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UserAvatar',
  computed: {
    ...mapGetters({
      user: 'user/getUser'
    }),
    userAvatar() {
      let baseUrl = `https://ca.coinfuze.com/media/avatar/`
      return `${baseUrl}${this.user.avatar}?_=${Math.random()}`
    }
  }
}
</script>
