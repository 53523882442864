import ApiClient from '@/libs/http-client'

const UserServices = {
  // eslint-disable-next-line no-unused-vars
  endpoints(route, params) {
    const url = {
      getUserData: 'ca_clients/',
      registration: `ca_registration${params}`,
      resetPassword: 'reset_password/',
      getPlanList: 'all_invest_plans/',
      activatePlan: 'my_plans/',
      getPlansData: 'my_plans/',
      getReferals: `my_referals/`,
      changeUserData: 'ca_changeuserdata/',
      change_password: 'change_passwd/',
      validateTwoFactor: 'validate/',
      getBonusLevels: 'comand_bonus/',
      reinvestChange: 'reinvest_change/',
      updateBalance: 'balance/',
      wallets: 'work_wallets/',
      payments: 'my_payments/',
      docUpload: 'docupload/',
      stakingData: 'all_day_plans/',
      allStaking: 'my_days_plans/',
      avatarUpload: 'upload/',
      deleteAvatar: 'ca_changeuserdata/',
      affiliate: 'affiliate/',
      emailConfirmation: 'email_verify/'
    }

    return url[route]
  },

  get(url, data) {
    return ApiClient.get(this.endpoints(url, data))
  },
  post(url, data, params = {}, config = {}) {
    if (url === 'avatarUpload') {
      config.useFUrl = true
    }
    return ApiClient.post(this.endpoints(url, params), data, config)
  },
  patch(url, data, params = {}, config = {}) {
    return ApiClient.patch(this.endpoints(url, params), data, config)
  },
  delete(url, data, params = {}, config = {}) {
    return ApiClient.delete(this.endpoints(url, params), data, config)
  }
}

export default UserServices
