import ApiClient from '@/libs/http-client'

const NotificationServices = {
  // eslint-disable-next-line no-unused-vars
  endpoints(route, params) {
    const url = {
      notifications: `ca_notification/`,
      notificationsChunk: `ca_notification/?page=${params}`
    }

    return url[route]
  },

  get(url, data, params = {}) {
    return ApiClient.get(this.endpoints(url, params), data)
  },
  post(url, data, params = {}, config = {}) {
    return ApiClient.post(this.endpoints(url, params), data, config)
  },
  patch(url, data, params = {}, config = {}) {
    return ApiClient.patch(this.endpoints(url, params), data, config)
  },
  delete(url, data, params = {}, config = {}) {
    return ApiClient.delete(this.endpoints(url, params), data, config)
  }
}

export default NotificationServices
