<template>
  <main class="app app__start d-flex flex-column align-items-center">
    <div class="app__start-header">
      <div class="app__start-logo">
        <a href="https://coinfuze.com/">
          <img
            class="dark-theme-only"
            width="180"
            src="@/assets/images/coinfuzewh.png"
            alt="logo"
          />
          <img
            class="light-theme-only"
            width="180"
            src="@/assets/images/coinfuzebl.png"
            alt="logo"
          />
        </a>
      </div>
    </div>

    <div>
      <form
        class="app__start-content"
        @submit.prevent="sendForm"
        style="max-width: 29rem"
      >
        <div class="app__start-title text-center text-accent fw-600">
          {{ $t('partnership-title') }}
        </div>
        <div class="app__start-inner">
          <div class="input-group">
            <input
              class="input-control"
              type="text"
              disabled
              :placeholder="$t('partnership-form-1')"
              v-model="userID"
            />
          </div>
          <div class="input-group">
            <form-field-wrapper
              :v="$v.affiliateID"
              :validator="$v.affiliateID"
              :attribute="$t('autorization-login')"
            >
              <input
                class="input-control"
                type="text"
                :placeholder="$t('partnership-form-2')"
                v-model="affiliateID"
              />
            </form-field-wrapper>
          </div>

          <div class="input-group">
            <form-field-wrapper
              :v="$v.blogLinks"
              :validator="$v.blogLinks"
              :attribute="$t('autorization-login')"
            >
              <textarea
                class="input-control"
                style="padding-top: 13px; min-width: 100%"
                type="text"
                :placeholder="$t('partnership-form-3')"
                v-model="blogLinks"
              >
              </textarea>
            </form-field-wrapper>
            <p style="margin-bottom: -10px; padding-left: 10px">
              {{ $t('partnership-form-3-1') }}
            </p>
          </div>
          <div class="input-group">
            <form-field-wrapper
              :v="$v.tgAcc"
              :validator="$v.tgAcc"
              :attribute="$t('autorization-login')"
            >
              <input
                class="input-control"
                type="text"
                :placeholder="$t('partnership-form-4')"
                v-model="tgAcc"
              />
            </form-field-wrapper>
            <p style="margin-bottom: 0px; padding-left: 10px">
              (Telegram, Whatsapp, Skype)
            </p>
          </div>
          <div class="input-group pb-3">
            <div class="row">
              <div class="col-12">
                <label class="input-checkbox">
                  <input type="checkbox" v-model="accept" />
                  <span>
                    {{ $t('partnership-form-5-1') }}
                    <a
                      style="cursor: pointer"
                      target="_blank"
                      :href="
                        locale === 'ru'
                          ? `https://coinfuze.com/docs/policy_affiliate_ru.pdf`
                          : `https://coinfuze.com/docs/policy_affiliate_en.pdf`
                      "
                    >
                      {{ $t('partnership-form-5-2') }}
                    </a>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div class="input-group pb-2">
            <button type="submit" class="button w-100">
              <span> {{ $t('partnership-form-btn') }}</span>
            </button>
          </div>
        </div>
      </form>
    </div>

    <div class="app__start-footer fw-300 text-center">
      <p>
        {{ $t('partnership-form-footer') }}
        <a
          style="color: #37bbc6"
          target="_blank"
          href="mailto:marketing@coinfuze.com"
          >marketing@coinfuze.com</a
        >
      </p>
      <p>&copy; 2024 CoinFuze. All rights reserved.</p>
    </div>
  </main>
</template>
<script>
import FormFieldWrapper from '@/components/global/FormFieldWrapper.vue'
import { rules } from '@/libs/validator'
import { mapGetters } from 'vuex'
// import axios from 'axios'

export default {
  name: 'PartnershipForm',
  components: { FormFieldWrapper },
  validations: {
    userID: rules.userID,
    affiliateID: rules.affiliateID,
    tgAcc: rules.tgAcc,
    blogLinks: rules.blogLinks
  },
  data() {
    return {
      affiliateID: '',
      blogLinks: '',
      tgAcc: '',
      accept: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/getUser',
      locale: 'app/getLocale'
    }),
    userID() {
      return `${this.$t('partnership-form-1')}: ${this.user.id}`
    }
  },
  methods: {
    sendForm() {
      this.$v.$touch()
      if (this.$v.$invalid) return
      if (!this.accept) {
        return this.$showError(this.$t('validation-2'))
      }
      let payload = {
        // userID: this.userID,
        affiliate: this.affiliateID,
        blogs: this.blogLinks,
        contacts: this.tgAcc
      }

      this.$store
        .dispatch('user/affiliate', payload)
        .then((response) => {
          this.$showSuccess(response.data.detail)
          this.$router.push('/')
        })
        .catch((error) => this.$showError(error.detail))
        .finally(() => (this.loading = false))
    }
  }
}
</script>
