<template>
  <div class="tabs-content-item active" id="tab-sub-item-1">
    <div v-if="enable === 'Google Authenticator'">
      <div class="col-md-6">
        <div class="card-inner">
          <div class="input-group m-0" style="gap: 10px">
            <input
              class="input-control"
              type="text"
              placeholder="Your Password"
              required
              v-model="googleAuthCode"
            />
            <button
              class="button w-sm-100"
              style="margin-top: 10px"
              type="button"
              @click="disable"
            >
              <span>{{ $t('personal-text-16') }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="steps">
        <div class="card card_inside steps-item">
          <div class="card-inner">
            <div class="steps-item-header d-flex">
              <div class="steps-item-step fw-500">{{ $t('step') }} 1</div>
              <div class="steps-item-title fw-600 text-accent flex-fill">
                {{ $t('personal-text-21') }} Google Authenticator.
              </div>
            </div>
            <div class="steps-item-content">
              <div class="card-text">
                <p>
                  {{ $t('personal-text-22') }}
                </p>
                <p>{{ $t('personal-text-23') }}</p>
                <p>
                  {{ $t('personal-text-24') }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="card card_inside steps-item">
          <div class="card-inner">
            <div class="row">
              <div class="col-sm-6 d-flex flex-column">
                <div class="steps-item-header d-flex">
                  <div class="steps-item-step fw-500">{{ $t('step') }} 2</div>
                  <div class="steps-item-title fw-600 text-accent flex-fill">
                    {{ $t('personal-text-27') }}
                  </div>
                </div>
                <div class="steps-item-content flex-fill">
                  <div class="card-text">
                    <p class="d-none d-sm-block">
                      {{ $t('personal-text-28') }}
                    </p>
                  </div>
                </div>
                <div class="steps-item-qr d-sm-none">
                  <p>
                    {{ formatCode(twoFactorData.google_validation_key, 25) }}
                    <button
                      @click="copyCode"
                      style="cursor: pointer"
                      class="button__clipboard"
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M1.23223 1.23223C1.70107 0.763392 2.33696 0.5 3 0.5H11C11.663 0.5 12.2989 0.763392 12.7678 1.23223C13.2366 1.70107 13.5 2.33696 13.5 3V4.5H15C16.3807 4.5 17.5 5.61929 17.5 7V15C17.5 16.3807 16.3807 17.5 15 17.5H7C5.61929 17.5 4.5 16.3807 4.5 15V13.5H3C2.33696 13.5 1.70107 13.2366 1.23223 12.7678C0.763392 12.2989 0.5 11.663 0.5 11V3C0.5 2.33696 0.763392 1.70107 1.23223 1.23223ZM5.5 15C5.5 15.8284 6.17157 16.5 7 16.5H15C15.8284 16.5 16.5 15.8284 16.5 15V7C16.5 6.17157 15.8284 5.5 15 5.5H7C6.17157 5.5 5.5 6.17157 5.5 7V15ZM12.5 4.5H7C5.61929 4.5 4.5 5.61929 4.5 7V12.5H3C2.60218 12.5 2.22064 12.342 1.93934 12.0607C1.65804 11.7794 1.5 11.3978 1.5 11V3C1.5 2.60218 1.65804 2.22064 1.93934 1.93934C2.22064 1.65804 2.60218 1.5 3 1.5H11C11.3978 1.5 11.7794 1.65804 12.0607 1.93934C12.342 2.22064 12.5 2.60218 12.5 3V4.5Z"
                          fill="#625B7A"
                        />
                      </svg>
                    </button>
                  </p>
                </div>
                <div class="steps-item-footer">
                  <div class="message d-flex">
                    <div class="message-icon">
                      <img
                        src="@/assets/images/warning-icon.svg"
                        alt="coinfuze"
                      />
                    </div>
                    <div class="message-text text-accent flex-fill">
                      {{ $t('personal-text-30') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 d-none d-sm-block">
                <div class="steps-item-qr">
                  <img
                    :src="twoFactorData.qr"
                    alt="coinfuze"
                    width="150px"
                    height="150px"
                    :style="{ filter: theme === 'dark' ? 'invert(1)' : '' }"
                  />
                  <p>
                    {{ formatCode(twoFactorData.google_validation_key) }}
                    <button
                      @click="copyCode"
                      style="cursor: pointer"
                      class="button__clipboard"
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M1.23223 1.23223C1.70107 0.763392 2.33696 0.5 3 0.5H11C11.663 0.5 12.2989 0.763392 12.7678 1.23223C13.2366 1.70107 13.5 2.33696 13.5 3V4.5H15C16.3807 4.5 17.5 5.61929 17.5 7V15C17.5 16.3807 16.3807 17.5 15 17.5H7C5.61929 17.5 4.5 16.3807 4.5 15V13.5H3C2.33696 13.5 1.70107 13.2366 1.23223 12.7678C0.763392 12.2989 0.5 11.663 0.5 11V3C0.5 2.33696 0.763392 1.70107 1.23223 1.23223ZM5.5 15C5.5 15.8284 6.17157 16.5 7 16.5H15C15.8284 16.5 16.5 15.8284 16.5 15V7C16.5 6.17157 15.8284 5.5 15 5.5H7C6.17157 5.5 5.5 6.17157 5.5 7V15ZM12.5 4.5H7C5.61929 4.5 4.5 5.61929 4.5 7V12.5H3C2.60218 12.5 2.22064 12.342 1.93934 12.0607C1.65804 11.7794 1.5 11.3978 1.5 11V3C1.5 2.60218 1.65804 2.22064 1.93934 1.93934C2.22064 1.65804 2.60218 1.5 3 1.5H11C11.3978 1.5 11.7794 1.65804 12.0607 1.93934C12.342 2.22064 12.5 2.60218 12.5 3V4.5Z"
                          fill="#625B7A"
                        />
                      </svg>
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card card_inside steps-item">
          <div class="card-inner">
            <div class="steps-item-header d-flex">
              <div class="steps-item-step fw-500">{{ $t('step') }} 3</div>
              <div class="steps-item-title fw-600 text-accent flex-fill">
                {{ $t('personal-text-31') }}
              </div>
            </div>
            <div class="steps-item-content">
              <div class="card-text">
                <p class="mb-4">
                  {{ $t('personal-text-32') }}
                </p>
              </div>
              <div class="row gy-base">
                <div class="col-md-6">
                  <div class="input-group m-0">
                    <input
                      class="input-control"
                      type="text"
                      :placeholder="$t('personal-label-1')"
                      required
                      v-model="googleAuthCode"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-group m-0">
                    <input
                      class="input-control"
                      type="text"
                      :placeholder="$t('personal-label-2')"
                      required
                      v-model="userPass"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button class="button w-sm-100" type="button" @click="enableGoogleAuth">
        <span>{{ $t('personal-text-17') }}</span>
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AuthenticatorTab',
  props: {
    enable: {}
  },
  data() {
    return {
      loading: false,
      userPass: '',
      googleAuthCode: ''
    }
  },
  computed: {
    ...mapGetters({
      twoFactorData: 'user/getTwoFactorData',
      theme: 'app/getAppTheme'
    })
  },
  watch: {},

  methods: {
    copyCode() {
      const tempInput = document.createElement('input')
      tempInput.value = this.twoFactorData.google_validation_key
      document.body.appendChild(tempInput)
      tempInput.select()
      document.execCommand('copy')
      document.body.removeChild(tempInput)
    },
    formatCode(code, length = 12) {
      return code ? code.slice(0, length) + '...' : ''
    },
    enableGoogleAuth() {
      let payload = {
        otp_key: this.googleAuthCode,
        google_validation_key: this.twoFactorData.google_validation_key,
        user_password: this.userPass
      }
      this.loading = true
      this.$store
        .dispatch('user/enableTwoFactor', payload)
        .catch((error) => this.$showError(error.detail))
        .then(() => (this.googleAuthCode = ''))
        .finally(() => {
          this.$emit('select-tab', 'AuthenticatorTab')
          this.loading = false
        })
    },
    disable() {
      let payload = {
        otp_key: this.googleAuthCode
      }
      this.loading = true
      this.$store
        .dispatch('user/disableTwoFactor', payload)
        .catch((error) => this.$showError(error.detail))
        .then(() => (this.googleAuthCode = ''))
        .finally(() => {
          this.$emit('select-default-tab')
          this.loading = false
        })
    }
  }
}
</script>
