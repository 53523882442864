<template>
  <div class="tabs-content-item active" id="tab-item-3">
    <div class="card-inner-item">
      <div class="row gy-base">
        <div class="col-lg-4">
          <div class="input-group m-0">
            <input
              class="input-control"
              type="password"
              :placeholder="$t('autorization-ypass')"
              required
              v-model="oldPass"
            />
          </div>
        </div>
        <div class="col-lg-4">
          <div class="input-group m-0">
            <form-field-wrapper
              :v="$v.password"
              :validator="$v.password"
              :attribute="$t('autorization-newpass')"
            >
              <input
                class="input-control"
                type="password"
                :placeholder="$t('autorization-newpass')"
                v-model.trim="password"
                required
              />
            </form-field-wrapper>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="input-group m-0">
            <form-field-wrapper
              :v="$v.confirmPassword"
              :validator="$v.confirmPassword"
              :attribute="$t('autorization-repeat-password')"
            >
              <input
                class="input-control"
                type="password"
                :placeholder="$t('autorization-repeat-password')"
                v-model.trim="confirmPassword"
                required
              />
            </form-field-wrapper>
          </div>
        </div>
        <div class="col-12">
          <div class="input-group m-0">
            <button
              class="button w-sm-100"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#modal-successfull"
              @click="changePassword"
            >
              <span>{{ $t('plans-text-21') }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <two-factor-modal
      v-if="twoFactorModal"
      :show="twoFactorModal"
      :validation="validation"
      @validation-complete="validationComplete"
      @close-modal="closeTwoFactorModal"
    />
  </div>
</template>
<script>
import TwoFactorModal from '@/components/global/TwoFactorModal.vue'
import FormFieldWrapper from '@/components/global/FormFieldWrapper.vue'
import { rules } from '@/libs/validator'
export default {
  name: 'PasswordTab',
  components: { TwoFactorModal, FormFieldWrapper },
  validations: {
    password: rules.passwordRule,
    confirmPassword: rules.confirmPasswordRule
  },
  data() {
    return {
      loading: false,
      twoFactorModal: false,
      oldPass: '',
      password: '',
      confirmPassword: '',
      validation: null
    }
  },
  methods: {
    validationComplete() {
      this.closeTwoFactorModal()
      this.oldPass = ''
      this.password = ''
      this.confirmPassword = ''
      this.$v.$reset()
    },
    closeTwoFactorModal() {
      this.twoFactorModal = false
      this.validation = null
    },

    changePassword() {
      this.$v.$touch()
      if (this.$v.$invalid) return
      let data = {
        old_password: this.oldPass,
        new_password: this.password
      }
      this.loading = true
      this.$store
        .dispatch('user/changePassword', data)
        .then((response) => {
          this.validation = response.data.validation
          this.twoFactorModal = true
        })
        .catch((error) => this.$showError(error.detail))
        .finally(() => (this.loading = false))
    }
  }
}
</script>
