<template>
  <div class="col-md-6 app__content-col">
    <div class="card card_inside p-0 bg-transparent">
      <div class="card-inner">
        <div class="chart">
          <div class="chart_header">
            <h3
              class="chart_header-title d-flex justify-content-between align-items-center"
            >
              {{ $t('plans-text-33') }}
              <div class="stat_item-val">
                {{ finResult }} USDT
                <div class="stat_item-icon">
                  <img src="@/assets/images/usdt.svg" alt="coinfuze" />
                </div>
              </div>
            </h3>
            <div class="chart_header-period">
              {{ $t('dashboard-text-16') }}:
              <span class="chart_header-period-val">{{ period }}</span>
            </div>
          </div>
          <div class="chart_inner flex-fill" id="chart-3">
            <apex-сhart
              type="area"
              :options="chartOptions"
              :series="series"
            ></apex-сhart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LineChart',
  props: {
    chartData: {
      type: Object,
      require: true
    },
    period: {
      type: String,
      default: '-',
      required: true
    },
    finResult: {
      type: Number,
      default: 0,
      required: true
    }
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          height: '100%',
          width: '100%',
          type: 'area',
          zoom: { enabled: false },
          toolbar: {
            show: false
          },
          fontFamily: 'Onest, Arial, Helvetica, sans-serif'
        },
        colors: ['#51A6FE'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 3,
          curve: 'smooth'
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            shadeIntensity: 0.8,
            opacityFrom: 0.5,
            opacityTo: 0
            // stops: [0, 80, 100]
          }
        },
        xaxis: {
          type: 'datetime',
          labels: {
            style: {
              colors: '#625B7A',
              fontSize: '12px'
            }
          },
          tooltip: {
            enabled: false
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          tickAmount: 4,
          floating: false,

          labels: {
            style: {
              colors: '#625B7A',
              fontSize: '12px'
            },
            formatter: function (value) {
              return value.toFixed(2)
            },
            offsetX: -15
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        tooltip: {
          enabled: false
        },
        grid: {
          show: true,
          borderColor: '#3A4E65',
          strokeDashArray: 7
        }
      }
    },
    series() {
      let seriesOpt = { name: 'Revenue', data: [] }
      if (this.period === '-') return [seriesOpt]
      this.chartData.profit_board.forEach((elem) => {
        seriesOpt.data.push({ x: elem[0], y: +elem[1] })
      })
      return [seriesOpt]
    }
  }
}
</script>
