<template>
  <div class="col-lg-9 app__content-col">
    <div class="app__content-header align-items-center">
      <h2 class="app__content-title h3 m-0">{{ $t('dashboard-text-23') }}</h2>
      <div class="input-group m-0">
        <!-- <select
          v-if="plans.length"
          style="padding-right: 50px"
          class="input-control input-control-select small-select"
          v-model="selectedPlanId"
        >
          <option
            v-for="(plan, idx) in activePlans"
            :key="plan.id + 'plan' + idx"
            :value="plan.id"
          >
            {{ plan.plan.name }}
          </option>
        </select> -->
        <div
          v-if="activePlans.length"
          class="cselect-wrapper"
          :class="{ active: planMenu }"
          @click="togglePlanMenu"
          v-click-outside="() => (this.planMenu = false)"
        >
          <span class="cselect-label">
            {{ $t('plans-text-38') }} {{ planNumber(selectedPlan.plan) }}
          </span>
          <div class="cselect-arrow" v-if="activePlans.length > 1">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 7.5L10 12.5L15 7.5"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="cselect-list" v-if="activePlans.length > 1">
            <span
              class="cselect-item"
              v-for="(plan, idx) in plansList"
              :key="plan.id + 'plan' + idx"
              @click="selectPlan(plan)"
            >
              {{ $t('plans-text-38') }} {{ planNumber(plan.plan) }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-inner">
        <div class="row gy-base">
          <doughnut-chart
            :totalDays="plans.length ? selectedPlan.days_in_palan : 0"
            :daysPassed="
              plans.length
                ? selectedPlan.days_in_palan - selectedPlan.days_to_end
                : 0
            "
            :period="period"
          />
          <progress-chart
            :done="financeResult.done"
            :purpose="financeResult.purpose"
            :period="period"
          />
          <line-chart
            :period="period"
            :chartData="plans.length ? selectedPlan : {}"
            :finResult="plans.length ? selectedPlan.fin_resalt_usdt : 0"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DoughnutChart from '../charts/DoughnutChart.vue'
import ProgressChart from '../charts/ProgressChart.vue'
import LineChart from '../charts/LineChart.vue'
import vClickOutside from 'v-click-outside'

export default {
  name: 'ChartsCard',
  components: {
    DoughnutChart,
    LineChart,
    ProgressChart
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      loading: true,
      planMenu: false,
      selectedPlanId: ''
    }
  },
  mounted() {
    this.selectedPlanId = this.plans[0]?.id
  },
  computed: {
    ...mapGetters({
      plans: 'user/getInvestHystory'
    }),
    plansList() {
      return this.activePlans.filter((plan) => plan.id !== this.selectedPlanId)
    },
    activePlans() {
      return this.plans.filter((plan) => plan.active)
      // return this.plans
    },
    selectedPlan() {
      if (this.selectedPlanId) {
        return this.plans.find((plan) => plan.id === this.selectedPlanId)
      } else return this.plans[0]
    },
    period() {
      if (!this.plans.length) return '-'
      let startPeriod = this.formatDate(this.selectedPlan.start_time)
      let endPeriod = this.formatDate(this.selectedPlan.end_time)
      return `${startPeriod} - ${endPeriod}`
    },
    financeResult() {
      if (!this.plans.length) return { done: 0, purpose: 0 }
      let purpose =
        this.selectedPlan.pay_summ *
        this.selectedPlan.max_percent_graphic *
        0.01 *
        (100 - this.selectedPlan.plan.comission) *
        0.01
      let done = this.selectedPlan.day_profit
      return { done, purpose }
    }
  },
  methods: {
    planNumber(plan) {
      let val = plan.name.slice(-1)
      return `No. ${val}`
    },
    togglePlanMenu() {
      this.planMenu = !this.planMenu
    },
    selectPlan(plan) {
      this.selectedPlanId = plan.id
    },
    getPlanList() {
      this.loading = true
      this.$store
        .dispatch('user/getPlanList')
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false
        })
    },
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()
      return `${day}.${month}.${year}`
    },

    scrollLeft() {
      if (this.selectedPlan) this.selectedPlan--
    },
    scrollRight() {
      if (this.selectedPlan <= this.plans.length - 1) this.selectedPlan++
    }
  }
}
</script>

<style lang="scss" scoped>
.cselect-wrapper {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 1.214rem;
  border: 1px solid var((--s-card-border-bg-color));
  background-color: var(--s-card-bg-color);
  color: var(--s-app-accent-color);
  padding: 1px 10px;
  &.active {
    .cselect-arrow {
      transform: rotate(180deg);
    }
    .cselect-list {
      overflow-y: auto;
      max-height: 210px;
      z-index: 9;
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }
}
.cselect-label {
  padding: 0.371rem;
}
.cselect-arrow {
  position: relative;
  display: flex;
  padding: 0.371rem;
  transition: transform ease-in-out 0.2s;
  -webkit-transition: transform ease-in-out 0.2s;
  path {
    stroke: var(--s-app-accent-color);
  }
}
.cselect-list {
  position: absolute;
  top: 40px;
  display: none;
  border: 1px solid var((--s-card-border-bg-color));
  background-color: var(--s-card-bg-color);
  color: var(--s-app-accent-color);
  border-radius: 1.214rem;
  padding: 10px 0px;
  width: auto;
}
.cselect-item {
  padding: 0px 20px;
}
.cselect-item:hover {
  background-color: var(--s-input-hover-bg-color);
}
</style>
