<template>
  <div>
    <div class="modal-backdrop fade show" v-if="show" @click="closeModal"></div>

    <div
      class="modal fade"
      :class="{ show: show }"
      :style="{ display: show ? 'block' : 'none' }"
      id="modal-product"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="--bs-modal-width: 27.643rem"
      >
        <div class="modal-content card modal-card">
          <div
            class="modal-card-inner plan"
            :class="`plan-${planNumber.value}`"
          >
            <button
              class="btn-close modal-close"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="closeModal"
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.863757 0.860853C1.19357 0.531039 1.7283 0.531039 2.05812 0.860853L13.4046 12.2073C13.7344 12.5371 13.7344 13.0718 13.4046 13.4016V13.4016C13.0747 13.7315 12.54 13.7315 12.2102 13.4016L0.863757 2.05521C0.533944 1.7254 0.533943 1.19067 0.863757 0.860853V0.860853Z"
                  fill="#625B7A"
                />
                <path
                  d="M0.862806 13.4019C0.532992 13.0721 0.532992 12.5373 0.862806 12.2075L12.2092 0.861073C12.5391 0.531259 13.0738 0.531259 13.4036 0.861073V0.861073C13.7334 1.19089 13.7334 1.72562 13.4036 2.05543L2.05717 13.4019C1.72735 13.7317 1.19262 13.7317 0.862806 13.4019V13.4019Z"
                  fill="#625B7A"
                />
              </svg>
            </button>
            <div class="modal-card-header border-0">
              <div class="plan-header d-flex align-items-center p-0 m-0">
                <div class="plan-header-icon d-flex-center">
                  <img
                    :src="
                      require(`@/assets/images/plan-icon-${planNumber.value}.png`)
                    "
                    alt="coinfuze"
                  />
                </div>
                <div class="plan-header-info flex-fill">
                  <div class="plan-header-name">
                    {{ $t('plan-modal-1') }}
                    <br />
                    <span>{{ planNumber.label }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-card-content p-0">
              <div class="plan-content">
                <div class="plan-content-line">
                  <div class="plan-content-name">{{ $t('plan-modal-2') }}</div>
                  <div class="plan-content-val">{{ planParam.startDate }}</div>
                </div>
                <div class="plan-content-line">
                  <div class="plan-content-name">{{ $t('plan-modal-3') }}</div>
                  <div class="plan-content-val">{{ planParam.endDate }}</div>
                </div>
                <div class="plan-content-line">
                  <div class="plan-content-name">{{ $t('plan-modal-4') }}</div>
                  <div class="plan-content-val">
                    {{ planParam.startupCapital }}
                  </div>
                </div>
                <div class="plan-content-line">
                  <div class="plan-content-name">{{ $t('plans-text-27') }}</div>
                  <div class="plan-content-val">{{ plan.comission }}%</div>
                </div>
                <!-- <div class="plan-content-line">
                <div class="plan-content-name">{{ $t('plan-modal-6') }}</div>
                <div class="plan-content-val">
                  {{ planParam.profitUSDT }}
                </div>
              </div> -->
              </div>
            </div>
            <div class="modal-card-footer">
              <div class="input-group mb-4">
                <label class="input-label">{{ $t('plan-modal-7') }}</label>
                <form-field-wrapper :v="$v.paySumm" :validator="$v.paySumm">
                  <input
                    v-model="paySumm"
                    class="input-control"
                    type="number"
                    placeholder="0.0 USDT"
                    required
                  />
                  <span
                    class="input-max"
                    style="cursor: pointer"
                    @click="setMax"
                    >MAX</span
                  >
                </form-field-wrapper>
              </div>
              <button
                class="button w-sm-100"
                type="button"
                :disabled="loading"
                @click="initActivation"
              >
                <span>{{ $t('plan-modal-8') }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <confirmation-modal
      :show="confirmationModal"
      @close="closeConfirmation"
      @confirm="confirmActivation"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import FormFieldWrapper from '@/components/global/FormFieldWrapper.vue'
import ConfirmationModal from '@/components/global/ConfirmationModal.vue'

import { rules } from '@/libs/validator'
export default {
  name: 'PlanModal',
  components: {
    FormFieldWrapper,
    ConfirmationModal
  },
  props: {
    show: {
      type: Boolean,
      require: true
    },
    plan: {
      require: true,
      type: Object
    }
  },
  validations: {
    paySumm: rules.paysumRule
  },
  data() {
    return {
      paySumm: '',
      confirmationModal: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      balance: 'user/getBalance'
    }),

    planNumber() {
      let val = this.plan.name.slice(-1)
      return { label: `No. ${val}`, value: parseInt(val) }
    },

    planParam() {
      const startDate = new Date()
      const endDate = new Date()
      endDate.setDate(startDate.getDate() + this.plan.plan_duration)

      const startDateFormatted = startDate.toLocaleDateString(this.$i18n.locale)
      const endDateFormatted = endDate.toLocaleDateString(this.$i18n.locale)

      const startupCapital = this.paySumm >= 100 ? this.paySumm : '-'
      let profitUSDT = '-'

      if (this.paySumm >= 100) {
        const annualProfit = this.paySumm * (this.plan.profit / 100)
        const dailyProfit = annualProfit / 365
        profitUSDT = (dailyProfit * this.plan.plan_duration).toFixed(2)
      }

      return {
        startDate: startDateFormatted,
        endDate: endDateFormatted,
        startupCapital,
        profitUSDT
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit('close-modal')
    },
    closeConfirmation() {
      this.confirmationModal = false
    },
    setMax() {
      this.paySumm = this.balance.free
    },
    initActivation() {
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.confirmationModal = true
    },
    confirmActivation() {
      this.closeConfirmation()
      this.activatePlan()
    },
    activatePlan() {
      this.loading = true

      let payload = {
        plan: this.plan.id,
        pay_summ: this.paySumm
      }
      this.$store
        .dispatch('user/activatePlan', payload)
        .then((response) => {
          this.paySumm = ''
          this.$v.$reset()
          this.$showSuccess(response.data.response)
          this.closeModal()
        })

        .catch((error) => this.$showError(error.detail))
        .finally(() => (this.loading = false))
    }
  }
}
</script>
