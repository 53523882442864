import io from 'socket.io-client'

let socket

const SocketClient = {
  connect(socketOpt) {
    const defaultOptions = {
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 500
    }
    const options = { ...defaultOptions, ...socketOpt }

    if (!socket) {
      socket = io(process.env.VUE_APP_SOCKET_IO_URL, options)
    }

    return new Promise((resolve, reject) => {
      socket.on('connect', () => {
        resolve()
      })

      socket.on('connect_error', (error) => {
        reject(error)
      })
      socket.on('reconnect_attempt', () => {
        console.log('reconnecting ...')
      })
    })
  },

  disconnect() {
    if (socket) {
      socket.disconnect()
      socket = null
    }
  },

  on(event, callback) {
    socket.on(event, callback)
  },

  emit(event, data) {
    socket.emit(event, data)
  },

  off(event, callback) {
    socket.off(event, callback)
  }
}

export default SocketClient
