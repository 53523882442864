<template>
  <div class="col-lg-3 col-md-6 app__content-col">
    <div class="card card_gradient">
      <div class="card-inner p-0">
        <div class="card-inner-item p-base">
          <h3 class="card-title title">{{ cardTitle }}</h3>
          <div class="stat_item">
            <div class="stat_item-title">{{ subtitle }}</div>
            <div class="stat_item-val stat_item-val-large">
              {{ subTitleVal }}
              <div class="stat_item-icon">
                <img src="@/assets/images/usdt.svg" alt="coinfuze" />
              </div>
            </div>
          </div>
        </div>
        <div class="card-inner-item p-base">
          <div class="stat">
            <div class="row stat_row">
              <div
                class="col-6"
                v-for="(stat, idx) in statsItems"
                :key="stat.title + stat.val + idx"
              >
                <div class="stat_item stats">
                  <div class="stat_item-title">{{ stat.title }}</div>
                  <div class="stat_item-val" v-if="stat.type === 'Verified'">
                    <span v-if="stat.val" class="text-green">
                      {{ $t('plans-text-11') }}
                    </span>
                    <span
                      v-else
                      class="text-red"
                      style="cursor: pointer"
                      @click="verifyTab"
                    >
                      {{ $t('plans-text-12') }}
                    </span>
                  </div>
                  <div class="stat_item-val" v-else>
                    {{ stat.val }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DashboardCard',
  props: {
    cardTitle: {
      require: true,
      type: String
    },
    subtitle: {
      require: true,
      type: String
    },
    subTitleVal: {
      require: true,
      type: String
    },
    statsItems: {
      require: true,
      type: Array
    }
  },
  methods: {
    verifyTab() {
      this.$router.push({ path: '/profile', query: { tab: 'verification' } })
    }
  }
}
</script>
<style lang="scss" scoped>
.stats {
  min-height: 63px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 992px) and (max-width: 1110px) {
    min-height: 83px;
  }
}
</style>
