/* eslint-disable no-empty-pattern */
import NewsServices from '@/services/api/news.services'

let state = {
  news: [],
  lastNews: [],
  promoMaterials: []
}

const mutations = {
  SET_LOGOUT(state) {
    state.news = []
    state.lastNews = []
    state.promoMaterials = []
  },
  SET_NEWS(state, news) {
    state.news = news
  },
  SET_LAST_NEWS(state, lastNews) {
    state.lastNews = lastNews
  },
  SET_PROMO_MATERIALS(state, data) {
    state.promoMaterials = data
  }
}

const actions = {
  logout({ commit }) {
    commit('SET_LOGOUT')
  },
  getLastNews({ commit }, data) {
    return new Promise((resolve, reject) => {
      NewsServices.get('getLastNews', data)
        .then((response) => {
          commit('SET_LAST_NEWS', response.data.results)
          return resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAllNews({ commit }, data) {
    return new Promise((resolve, reject) => {
      NewsServices.get('getAllNews', data)
        .then((response) => {
          commit('SET_NEWS', response.data.results)
          return resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getPromoMaterials({ commit }, data) {
    return new Promise((resolve, reject) => {
      NewsServices.get('getPromoMaterials', data)
        .then((response) => {
          commit('SET_PROMO_MATERIALS', response.data.results)
          return resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

const getters = {
  getLatestNews: (state) => state.lastNews[0] ?? {},
  getAllNews: (state) => state.news,
  getNewsBySlug: (state) => (slug) => {
    const findNews = (newsArray) => newsArray.find((news) => slug === news.slug)
    return findNews(state.news) || findNews(state.lastNews) || {}
  },
  getNewsShortList: (state) => (slug) => {
    const findNews = state.lastNews
      .filter((news) => slug !== news.slug)
      .slice(0, 3)
    return findNews
  },
  getPromoMaterials: (state) => state.promoMaterials
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
