<template>
  <div class="app__content-item">
    <div class="app__content-header">
      <div class="row gy-base w-100">
        <div class="col-lg-3">
          <h3 class="app__content-title h3">{{ $t('personal-text-47') }}</h3>
        </div>
        <div class="col-lg-9">
          <p class="app__content-desc">
            {{ $t('personal-text-50') }}
          </p>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-inner p-0">
        <div class="card-inner-item">
          <div class="sessions">
            <div
              class="sessions-item card card_inside"
              v-for="(session, idx) in sessions"
              :key="`${idx}${session.ip_adres}`"
            >
              <div
                class="card-inner sessions-item-inner d-flex align-items-start"
                style="flex-wrap: wrap"
              >
                <div class="sessions-item-icon d-flex-center">
                  <svg
                    v-if="session.device.device.type === 'mobile'"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7 2.5C6.17157 2.5 5.5 3.17157 5.5 4V20C5.5 20.8284 6.17157 21.5 7 21.5H17C17.8284 21.5 18.5 20.8284 18.5 20V4C18.5 3.17157 17.8284 2.5 17 2.5H7ZM4.5 4C4.5 2.61929 5.61929 1.5 7 1.5H17C18.3807 1.5 19.5 2.61929 19.5 4V20C19.5 21.3807 18.3807 22.5 17 22.5H7C5.61929 22.5 4.5 21.3807 4.5 20V4Z"
                      fill="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.5 18C11.5 17.7239 11.7239 17.5 12 17.5H12.01C12.2861 17.5 12.51 17.7239 12.51 18C12.51 18.2761 12.2861 18.5 12.01 18.5H12C11.7239 18.5 11.5 18.2761 11.5 18Z"
                      fill="white"
                    />
                  </svg>
                  <svg
                    v-else
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5 4.5C4.17157 4.5 3.5 5.17157 3.5 6V14C3.5 14.8284 4.17157 15.5 5 15.5H19C19.8284 15.5 20.5 14.8284 20.5 14V6C20.5 5.17157 19.8284 4.5 19 4.5H5ZM2.5 6C2.5 4.61929 3.61929 3.5 5 3.5H19C20.3807 3.5 21.5 4.61929 21.5 6V14C21.5 15.3807 20.3807 16.5 19 16.5H5C3.61929 16.5 2.5 15.3807 2.5 14V6Z"
                      fill="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M1.5 20C1.5 19.7239 1.72386 19.5 2 19.5H22C22.2761 19.5 22.5 19.7239 22.5 20C22.5 20.2761 22.2761 20.5 22 20.5H2C1.72386 20.5 1.5 20.2761 1.5 20Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div class="sessions-item-content flex-fill">
                  <div class="sessions-item-title fw-500 text-accent">
                    {{ session.device.os.name }}
                  </div>
                  <div class="sessions-item-desc">
                    <p>
                      {{ session.device.browser.name }},{{
                        session.device.browser.version
                      }}
                    </p>
                    <p>{{ session.ip_adres }}</p>
                  </div>
                </div>
                <button
                  @click="logoutSession(session.id)"
                  class="button button_bordered"
                  type="button"
                >
                  {{ $t('personal-text-51-1') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card-inner-item">
          <button
            class="button button_bordered"
            type="button"
            @click="logoutSessions"
          >
            <span>{{ $t('personal-text-52') }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'SessionComponent',

  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      sessions: 'app/getAllSessions'
    })
  },
  created() {
    this.getAllSessions()
  },
  methods: {
    getAllSessions() {
      this.loading = true
      this.$store
        .dispatch('app/getAllSessions')
        .catch((error) => this.$showError(error.detail))
        .finally(() => (this.loading = false))
    },
    logoutSession(id) {
      this.loading = true
      this.$store
        .dispatch('app/logoutSession', { session_id: id })
        .catch((error) => this.$showError(error.detail))
        .finally(() => (this.loading = false))
    },
    logoutSessions() {
      this.loading = true
      this.$store
        .dispatch('app/logoutSessions')
        .catch((error) => this.$showError(error.detail))
        .finally(() => (this.loading = false))
    }
  }
}
</script>
