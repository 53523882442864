<template>
  <div class="app__content flex-fill">
    <page-title />

    <div class="app__content-item mb-5">
      <div class="tabs" v-if="contentItem === 'Banking'">
        <div class="tabs-buttons d-flex">
          <div
            class="tabs-button"
            :class="{ active: selectedTab === 'Deposit' }"
            @click="selectTab('Deposit')"
          >
            {{ $t('dashboard-text-7') }}
          </div>
          <div
            class="tabs-button"
            :class="{ active: selectedTab === 'Withdrawals' }"
            @click="selectTab('Withdrawals')"
          >
            {{ $t('dashboard-text-8') }}
          </div>
        </div>
        <div class="tabs-content">
          <deposite-block v-if="selectedTab === 'Deposit'" />
          <withdrawals-block
            v-if="selectedTab === 'Withdrawals'"
            @start-withdrawals="selectContentItem('Withdrawals')"
          />
        </div>
      </div>
      <div v-if="contentItem === 'Withdrawals'" class="card">
        <withdrawals-content @cancel="selectContentItem('Banking')" />
      </div>
    </div>

    <hystory-table />
  </div>
</template>

<script>
import HystoryTable from '../global/HystoryTable.vue'
// import LoadingPage from '../global/preloaders/LoadingPage.vue'
import DepositeBlock from './DepositeBlock.vue'
import WithdrawalsBlock from './WithdrawalsBlock.vue'
import PageTitle from '../main/PageTitle.vue'
import WithdrawalsContent from './WithdrawalsContent.vue'

export default {
  name: 'BankingPage',
  components: {
    DepositeBlock,
    WithdrawalsBlock,
    PageTitle,
    WithdrawalsContent,
    // LoadingPage,
    HystoryTable
  },
  data() {
    return {
      loading: false,
      selectedTab: 'Deposit',
      contentItem: 'Banking'
    }
  },
  created() {
    this.getBalance()
  },
  mounted() {
    this.scrollToTop()
  },
  methods: {
    selectTab(tab) {
      this.selectedTab = tab
    },
    getBalance() {
      this.loading = true
      this.$store
        .dispatch('user/getBalance')
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false
        })
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    selectContentItem(item) {
      this.contentItem = item
    }
  }
}
</script>
