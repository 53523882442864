<template>
  <div class="modal-card-content">
    <p>
      {{ $t('email-verification-2') }}
    </p>
    <p>{{ $t('email-verification-3') }}</p>
    <p>
      {{ $t('email-verification-4') }}
    </p>
    <button
      class="button w-sm-100"
      style="margin-top: 15px"
      @click="requestNewLetter"
      :disabled="loading"
    >
      <span> {{ $t('email-verification-5') }} </span>
    </button>
    <p>{{ $t('email-verification-6') }}</p>
  </div>
</template>
<script>
export default {
  name: 'EmailVerificationBlock',

  data() {
    return {
      loading: false
    }
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    closeModal() {
      this.$emit('close-modal')
    },
    requestNewLetter() {
      this.loading = true
      this.$store
        .dispatch('user/requestNewLetter')
        .then((response) => {
          this.$showSuccess(response.data.response)
        })
        .catch((error) => this.$showError(error.detail))
        .finally(() => {
          this.loading = false
          this.closeModal()
        })
    }
  }
}
</script>
<style lang="" scoped></style>
