<template>
  <div class="dropdown__content card dropdown__content_size_huge">
    <div class="card-inner p-0">
      <div class="notiofications">
        <div class="notiofications-items">
          <div v-if="notifications.length">
            <notification-item
              v-for="(item, idx) in notifications"
              :key="`${idx}${item.id}`"
              :notification="item"
            />
          </div>
          <div class="notiofications-empty" v-else>
            {{ $t('non-message') }}
          </div>
        </div>
        <div class="notiofications-footer">
          <a
            class="button__link notiofications-more"
            @click="showAllNotifications"
            style="cursor: pointer"
            data-bs-toggle="modal"
            data-bs-target="#modal-notiofications"
            >{{ $t('message-btn') }}
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.833 7L1.16634 7M12.833 7L7.83301 12M12.833 7L7.83301 2"
                stroke="#37BBC6"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NotificationItem from './NotificationItem.vue'
export default {
  name: 'NotificationWindow',
  components: { NotificationItem },
  computed: {
    ...mapGetters({
      notifications: 'notification/getLastNotification'
    })
  },
  mounted() {
    // this.readNotifications()
  },
  methods: {
    closeWindow() {
      this.$emit('close-window')
    },
    showAllNotifications() {
      this.$emit('show-notifications')
      this.closeWindow()
    },
    readNotifications() {
      let arr = []
      this.notifications.forEach((n) => {
        if (!n.readed) arr.push(n.id)
      })
      if (arr.length)
        this.$store.dispatch('notification/readNotifications', { msg: arr })
    }
  }
}
</script>
