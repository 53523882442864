<template>
  <div class="app__panel" :class="{ active: isSidebarOpen }">
    <div class="app__panel-inner card h-100">
      <div class="card-inner d-flex flex-column h-100 p-0">
        <a
          class="app__panel-logo logo d-none d-md-block"
          href="https://coinfuze.com/"
          target="_blank"
          ><img
            class="dark-theme-only"
            src="@/assets/images/logo-white.svg"
            alt="logo" /><img
            class="light-theme-only"
            src="@/assets/images/logo-black.svg"
            alt="logo"
        /></a>

        <nav class="app__panel-nav">
          <ul class="reset-list app__panel-nav-list">
            <li
              v-for="(item, idx) in menuItems"
              :key="`${item.label}+${idx}`"
              class="app__panel-nav-item"
              @click="selectMenuItem(item.routerName)"
            >
              <a
                class="app__panel-nav-link"
                :class="{ current: isCurrentRoute(item.routerName) }"
              >
                <div class="app__panel-nav-link-inner">
                  <component :is="item.icon" />
                  <div class="app__panel-nav-text flex-fill">
                    {{ item.label }}
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </nav>
        <div
          class="app__panel-decor flex-fill d-flex align-items-center justify-content-center"
        >
          <img src="@/assets/images/panel-decor-logo.svg" alt="coinfuze" />
        </div>
        <div class="app__panel-footer d-flex flex-column">
          <div class="app__panel-footer-item">
            <a
              class="button button_bordered pl-0 pr-0 w-100"
              @click="loadCrisp"
            >
              <span> Coinfuze {{ $t('support') }} </span>
            </a>
          </div>
          <div class="app__panel-footer-item">
            <ul
              class="reset-list socials d-flex align-items-center justify-content-center"
            >
              <li
                class="socials-li"
                v-for="(contact, idx) in contactItems"
                :key="idx + contact.link"
              >
                <a
                  class="socials-link d-flex-center"
                  :href="contact.link"
                  target="_blank"
                >
                  <component :is="contact.icon" />
                </a>
              </li>
            </ul>
          </div>
          <div class="app__panel-footer-item p-0">
            <a
              class="button__link app__panel-return d-flex"
              href="https://coinfuze.com/"
              target="_blank"
            >
              <BackToSiteMenuIcon />
              {{ $t('back-to-site') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardMenuIcon from '@/components/icons/DashboardMenuIcon.vue'
import AffiliateProgramMenuIconVue from '@/components/icons/AffiliateProgramMenuIcon.vue'
import BankingMenuIcon from '@/components/icons/BankingMenuIcon.vue'
import FAQMenuIcon from '@/components/icons/FAQMenuIcon.vue'
import NewsMenuIcon from '@/components/icons/NewsMenuIcon.vue'
import PersonalInformationMenuIcon from '@/components/icons/PersonalInformationMenuIcon.vue'
import PlanManagementMenuIcon from '@/components/icons/PlanManagementMenuIcon.vue'
import BackToSiteMenuIcon from '@/components/icons/BackToSiteMenuIcon.vue'
import TwitterIcon from '@/components/icons/TwitterIcon.vue'
import TelegramIcon from '@/components/icons/TelegramIcon.vue'
import DiscordIcon from '@/components/icons/DiscordIcon.vue'
import { Crisp } from 'crisp-sdk-web'
import { mapGetters } from 'vuex'
export default {
  name: 'SideBarMenu',
  components: {
    DashboardMenuIcon,
    AffiliateProgramMenuIconVue,
    BankingMenuIcon,
    FAQMenuIcon,
    NewsMenuIcon,
    PersonalInformationMenuIcon,
    PlanManagementMenuIcon,
    BackToSiteMenuIcon,
    TwitterIcon,
    TelegramIcon,
    DiscordIcon
  },
  props: {
    isSidebarOpen: {
      type: Boolean,
      default: true,
      required: true
    }
  },

  created() {
    this.configureCrispChat()
  },
  computed: {
    ...mapGetters({
      user: 'user/getUser'
    }),
    menuItems() {
      return [
        {
          label: this.$t('sidebar-item-1'),
          routerName: 'Dashboard',
          icon: DashboardMenuIcon
        },
        {
          label: this.$t('sidebar-item-2'),
          routerName: 'Banking',
          icon: BankingMenuIcon
        },
        {
          label: this.$t('sidebar-item-3'),
          routerName: 'Plans',
          icon: PlanManagementMenuIcon
        },
        {
          label: this.$t('sidebar-item-4'),
          routerName: 'Partnership',
          icon: AffiliateProgramMenuIconVue
        },
        {
          label: this.$t('sidebar-item-6'),
          routerName: 'Profile',
          icon: PersonalInformationMenuIcon
        },
        {
          label: 'FAQ',
          routerName: 'FAQ',
          icon: FAQMenuIcon
        },
        {
          label: this.$t('sidebar-item-7'),
          routerName: 'News',
          icon: NewsMenuIcon
        }
      ]
    },
    contactItems() {
      return [
        { icon: TelegramIcon, link: 'https://t.me/coinfuze' },
        { icon: DiscordIcon, link: 'https://discord.gg/B6MCMDR9qP' },
        { icon: TwitterIcon, link: 'https://twitter.com/Coin_fuze' }
      ]
    }
  },
  methods: {
    configureCrispChat() {
      Crisp.configure(process.env.VUE_APP_CRISP, {
        autoload: false,
        locale: this.$i18n.locale
      })
      Crisp.user.setEmail(this.user.email)
      Crisp.user.setNickname(this.user.name)
    },
    loadCrisp() {
      Crisp.chat.open()
    },

    selectMenuItem(routerName) {
      if (routerName === 'FAQ')
        return window.open('https://faq.coinfuze.com/', '_blank')
      if (this.$route.name === routerName) return
      this.$router.push({ name: routerName })
      this.$emit('close-sidebar')
    },

    isCurrentRoute(routerName) {
      return this.$route.name === routerName
    },
    logoutUser() {
      this.$store.dispatch('app/logout')
      this.$router.push('/auth')
    },

    closeSidebar() {
      this.$refs.menuSidebar.classList.remove('sidebar--open')
      //this.isSidebarOpen = false;
      this.closeBurger()
      this.applyOverflowHidden()
    },
    closeBurger() {
      const headerBurgerElement = document.querySelector('.header__burger')
      if (headerBurgerElement) {
        headerBurgerElement.classList.remove('open')
      }
    },
    applyOverflowHidden() {
      if (this.isSidebarOpen) {
        document.body.classList.add('hidden')
      } else {
        document.body.classList.remove('hidden')
      }
    }
  }
}
</script>
