<template>
  <div class="col plan-col">
    <div class="card card_inside plan" :class="`plan-${planNumber.value}`">
      <div class="card-inner p-0">
        <div class="plan-img pb-0">
          <img
            :src="require(`@/assets/images/plan-img-${planNumber.value}.png`)"
            alt="coinfuze"
          />
        </div>
        <div class="plan-header d-flex align-items-center">
          <div class="plan-header-info flex-fill">
            <div class="plan-header-name">
              {{ $t('plans-text-38') }} <span>{{ planNumber.label }}</span>
            </div>
          </div>
        </div>
        <div class="plan-content">
          <div class="plan-content-line">
            <div class="plan-content-name">{{ $t('plans-text-23') }}</div>
            <div class="plan-content-val">
              100 USDT
              <img
                class="plan-content-icon"
                src="@/assets/images/usdt.svg"
                alt="coinfuze"
              />
            </div>
          </div>
          <div class="plan-content-line">
            <div class="plan-content-name">{{ $t('plans-text-24') }}</div>
            <div class="plan-content-val">
              {{ plan.plan_duration }} {{ $t('plans-text-39') }}
            </div>
          </div>
          <div class="plan-content-line">
            <div class="plan-content-name">{{ $t('plans-text-25') }}</div>
            <div class="plan-content-val">
              {{ $t('plans-text-26') }} {{ plan.profit }}%
            </div>
          </div>
          <div class="plan-content-line">
            <div class="plan-content-name">{{ $t('plans-text-27') }}</div>
            <div class="plan-content-val">{{ plan.comission }}%</div>
          </div>
        </div>
        <div class="plan-footer">
          <a
            style="cursor: pointer"
            class="plan-button"
            data-bs-toggle="modal"
            data-bs-target="#modal-product"
            @click="opePlanModal"
            >{{ $t('plans-text-40') }}</a
          >
        </div>
      </div>
    </div>
    <plan-modal :show="planModal" :plan="plan" @close-modal="closePlanModal" />
  </div>
</template>
<script>
import PlanModal from './PlanModal.vue'
export default {
  name: 'AvailablePlanComponent',
  components: { PlanModal },
  props: {
    plan: {
      require: true,
      type: Object
    }
  },
  data() {
    return {
      planModal: false
    }
  },
  computed: {
    planNumber() {
      let val = this.plan.name.slice(-1)
      return { label: `No. ${val}`, value: parseInt(val) }
    }
  },
  methods: {
    opePlanModal() {
      this.planModal = true
    },
    closePlanModal() {
      this.planModal = false
    }
  }
}
</script>
