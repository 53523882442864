<script>
import SocketClient from '@/libs/socket-client'
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      token: 'app/getAuthToken',
      user_id: 'user/getUserId',
      channel: 'user/getSocketCh'
    }),
    socketOptions() {
      let options = {
        auth: {
          CHANNEL: this.channel,
          USER_ID: this.user_id
        }
      }
      return options
    }
  },
  beforeMount() {
    this.connectSocket(this.socketOptions)
  },
  beforeDestroy() {
    this.disconnectSocket()
  },
  methods: {
    connectSocket(options) {
      SocketClient.connect(options)
        .then(() => {
          SocketClient.on('message', (response) =>
            this.responseHandler(response)
          )
        })
        .catch((error) => {
          console.error('Socket connection error:', error)
        })
        .finally(() => {
          // this.sendMessage()
        })
    },
    disconnectSocket() {
      SocketClient.off('message', this.responseHandler)
      SocketClient.disconnect()
    },
    sendMessage() {
      SocketClient.emit('message', { message: 'hello it`s me :)' })
    },
    responseHandler(response) {
      const keysToMutations = {
        messages: 'notification/ADD_NEW_NOTIFICATIONS',
        client_payment_history: 'user/ADD_PAYMENT_HISTORY',
        client_invest_history: 'user/ACTIVATE_PLAN',
        balance: 'user/UPDATE_BALANCE',
        client_user: 'user/UPDATE_USER',
        client_stacking: 'user/SET_USER_STAKING_DATA',
        two_factor_data: 'user/SET_TWO_FACTOR_DATA',
        anotherKey: 'commitForAnotherKey' //example
      }

      Object.entries(keysToMutations).forEach(([key, mutation]) => {
        if (response[key] !== null && response[key] !== undefined) {
          this.$swal({
            target: document.getElementById('main'),
            html: this.$t('new-notification'),
            icon: 'info',
            timer: 3000,
            toast: true,
            modal: false,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', this.$swal.stopTimer)
              toast.addEventListener('mouseleave', this.$swal.resumeTimer)
            },
            position: 'top-right',
            showConfirmButton: false
          })

          this.$store.commit(mutation, response[key])
        }
      })
    }
  }
}
</script>
