<template>
  <div class="app__content flex-fill">
    <div class="app__content-item d-xxl-none">
      <router-link class="button__link" to="plans"
        ><svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.16699 7L12.8337 7M1.16699 7L6.16699 12M1.16699 7L6.16699 2"
            stroke="#625B7A"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        {{ $t('staking-1') }}
      </router-link>
    </div>
    <div class="app__content-item mb-5">
      <div class="card">
        <div class="card-inner">
          <div class="row gy-base mb-5">
            <div class="col-lg-6">
              <h3 class="card-title">DailyFuze {{ $t('staking-2') }}</h3>
              <p>
                {{ $t('staking-1-1') }}
                <span class="text-blue">DailyFuze</span>
                {{ $t('staking-1-2') }}
                <span class="text-blue">DailyFuze</span>
                {{ $t('staking-1-3') }}
              </p>
            </div>
            <div class="col-lg-6">
              <form action="/" method="POST">
                <div class="row gy-base">
                  <div class="col-12">
                    <div class="input-group m-0">
                      <form-field-wrapper
                        :v="$v.paySumm"
                        :validator="$v.paySumm"
                      >
                        <input
                          v-model="paySumm"
                          class="input-control"
                          type="number"
                          placeholder="0.0 USDT"
                          required
                        />
                        <span
                          class="input-max"
                          style="cursor: pointer"
                          @click="setMax"
                          >MAX</span
                        >
                      </form-field-wrapper>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <button
                      class="button w-100"
                      type="button"
                      @click="stackInit"
                    >
                      <span> {{ $t('staking-4-1') }} DailyFuze </span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="staking flex-fill w-100">
            <div class="staking-progress">
              <div class="staking-progress-inner text-center">
                <div class="staking-progress-lvl">
                  <div class="staking-progress-lvl-info">
                    {{ $t('staking-5') }} lvl:
                  </div>
                  <div class="staking-progress-lvl-val text-blue fw-600">
                    {{
                      Object.keys(this.userStakingData).length
                        ? userStakingData.level
                        : 1
                    }}
                  </div>
                </div>
                <div
                  class="staking-progress-fill"
                  :style="{ width: `${stakingStats.percent}%` }"
                ></div>
              </div>
              <div
                class="staking-progress-footer d-flex justify-content-between align-items-center text-accent"
              >
                <span>{{ stakingStats.from }} USDT</span>
                <span>{{ stakingStats.to }} USDT</span>
              </div>
            </div>
            <div class="row gy-base">
              <div class="col-md-6">
                <div class="card card_inside staking-item p-0 border-0">
                  <div class="card-inner p-16">
                    <div class="stat_item-title">{{ $t('staking-6') }}</div>
                    <div class="stat_item-val stat_item-val-lvl">
                      {{ devident_summ }} USDT
                      <div class="stat_item-icon">
                        <img src="@/assets/images/usdt.svg" alt="coinfuze" />
                      </div>
                      <span
                        class="staking-status status-label approved"
                        v-if="lastTransaction.tr_amount"
                      >
                        +{{ lastTransaction.tr_amount.toFixed(2) }}
                      </span>
                    </div>
                    <div class="stat_item-footer d-flex">
                      <div class="stat_item-footer-item">
                        {{ $t('staking-7') }}
                        <span class="text-accent">
                          {{ lastTr }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card card_inside staking-item p-0 border-0">
                  <div class="card-inner p-16">
                    <div class="stat_item-title">{{ $t('staking-8') }}</div>
                    <div class="stat_item-val stat_item-val-lvl">
                      {{
                        Object.keys(userStakingData).length
                          ? userStakingData.turnover.toFixed(2)
                          : 0
                      }}
                      USDT
                      <div class="stat_item-icon">
                        <img src="@/assets/images/usdt.svg" alt="coinfuze" />
                      </div>
                    </div>
                    <div class="stat_item-footer d-flex">
                      <div class="stat_item-footer-item">
                        {{ $t('staking-10') }}
                        <span class="text-accent">
                          {{ latestStartTime }}
                        </span>
                      </div>
                      <!-- <div class="stat_item-footer-item">
                        Steaking duration:
                        <span class="text-accent">13 days</span>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="app__content-item mb-5">
      <div class="app__content-header">
        <h3 class="app__content-title">{{ $t('staking-9') }}</h3>
      </div>
      <div class="card">
        <div class="card-inner">
          <div class="levels">
            <div class="row levels-row flex-wrap">
              <staking-lvl-card
                v-for="(lvl, idx) in enrichedLevels"
                :key="`level-${idx}${lvl.name}`"
                :level="lvl"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <staking-table
      :rows="
        userStakingData.clientdayplans_set
          ? userStakingData.clientdayplans_set
          : []
      "
    />
    <confirmation-modal
      :show="confirmationModal"
      @close="closeConfirmation"
      @confirm="confirmStack"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import StakingLvlCard from './StakingLvlCard.vue'
import StakingTable from './StakingTable.vue'
import FormFieldWrapper from '@/components/global/FormFieldWrapper.vue'
import ConfirmationModal from '@/components/global/ConfirmationModal.vue'

import { rules } from '@/libs/validator'
export default {
  name: 'StakingPage',
  components: {
    StakingLvlCard,
    StakingTable,
    FormFieldWrapper,
    ConfirmationModal
  },
  validations: {
    paySumm: rules.paysumRule
  },
  data() {
    return {
      confirmationModal: false,
      loading: false,
      paySumm: ''
    }
  },
  created() {
    this.getAllStaking()
  },
  mounted() {
    this.scrollToTop()
  },
  computed: {
    ...mapGetters({
      balance: 'user/getBalance',
      stakingData: 'user/getStakingData',
      userStakingData: 'user/getUserStakingData',
      lastTransaction: 'user/getLastStakingTR',
      devident_summ: 'user/getDevidentSumm'
    }),
    stakingStats() {
      let defaultObj = {
        from: 0,
        to: 1000,
        percent: 0
      }
      if (!Object.keys(this.userStakingData).length) return defaultObj
      let targetLvl = this.stakingData[0].dayplanslevels_set.find(
        (item) => item.sort_id === this.userStakingData.level
      )
      let percentage =
        (this.userStakingData.turnover / targetLvl.money_turnover_to) * 100
      return {
        from: this.formatNum(targetLvl.money_turnover),
        to: this.formatNum(targetLvl.money_turnover_to),
        percent: Math.round(percentage)
      }
    },
    latestStartTime() {
      if (!Object.keys(this.userStakingData).length) return '-'
      const latest = this.userStakingData.clientdayplans_set.reduce(
        (latestDate, currentPlan) => {
          const currentDate = new Date(currentPlan.start_time)
          return latestDate > currentDate ? latestDate : currentDate
        },
        new Date(0)
      )
      return this.formatDate(latest.toISOString())
    },
    lastTr() {
      return this.lastTransaction.date_create === '-'
        ? '-'
        : this.formatDate(this.lastTransaction.date_create)
    },
    enrichedLevels() {
      const lvl = this.userStakingData.level
      return this.stakingData[0].dayplanslevels_set
        .slice()
        .map((level) => {
          let crown = [5].includes(level.sort_id)
          let status

          if (lvl > level.sort_id) {
            status = 1
          } else if (lvl === level.sort_id) {
            status = 2
          } else if (lvl < level.sort_id) {
            if (level.sort_id === 5 && lvl !== level.sort_id) {
              status = 4
            } else {
              status = 3
            }
          }

          return { ...level, crown, status }
        })
        .reverse()
    }
  },
  methods: {
    getAllStaking() {
      this.loading = true
      return this.$store
        .dispatch('user/getAllStaking')
        .catch((error) => this.$showError(error.detail))
        .finally(() => {
          this.loading = false
          this.getBalance()
        })
    },
    getBalance() {
      this.loading = true
      this.$store
        .dispatch('user/getBalance')
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false
        })
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    setMax() {
      this.paySumm = this.balance.free
    },
    stackInit() {
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.confirmationModal = true
    },
    closeConfirmation() {
      this.confirmationModal = false
    },
    confirmStack() {
      this.closeConfirmation()
      this.stack()
    },
    stack() {
      let payload = {
        pay_summ: this.paySumm,
        plan: this.stakingData[0].id
      }
      this.loading = true
      this.$store
        .dispatch('user/stack', payload)
        .then((response) => {
          this.paySumm = ''
          this.$v.$reset()
          this.$showSuccess(response.data.response)
        })
        .catch((error) => this.$showError(error.detail))
        .finally(() => (this.loading = false))
    },
    formatNum(num) {
      return new Intl.NumberFormat(this.$i18n.locale).format(num)
    },
    formatDate(dateString) {
      if (!dateString) return '-'
      const date = new Date(dateString)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()
      return `${day}.${month}.${year}`
    }
  }
}
</script>
