<template>
  <div class="col">
    <div
      class="card card_inside levels-box p-0 bg-transparent"
      :class="`status-${level.status}`"
    >
      <div class="card-inner p-0">
        <span class="levels-box-tag">
          {{ $t('partnership-text-28') }} {{ level.sort_id }}
          <svg
            v-if="level.crown"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.24123 9.1774L0.442796 3.98777C0.383733 3.60452 0.820358 3.3429 1.13055 3.57565L3.61817 5.44115C3.68283 5.48959 3.75669 5.52434 3.83523 5.54328C3.91377 5.56223 3.99534 5.56497 4.07498 5.55133C4.15461 5.5377 4.23063 5.50798 4.29839 5.46398C4.36616 5.41999 4.42424 5.36265 4.46911 5.29546L6.54023 2.18921C6.75898 1.86108 7.24111 1.86108 7.45986 2.18921L9.53098 5.29546C9.57585 5.36265 9.63393 5.41999 9.7017 5.46398C9.76946 5.50798 9.84548 5.5377 9.92512 5.55133C10.0048 5.56497 10.0863 5.56223 10.1649 5.54328C10.2434 5.52434 10.3173 5.48959 10.3819 5.44115L12.8695 3.57565C13.1802 3.3429 13.6164 3.60452 13.5573 3.98777L12.7589 9.1774H1.24123ZM12.1582 12.057H1.84192C1.76304 12.057 1.68493 12.0415 1.61205 12.0113C1.53917 11.9811 1.47295 11.9369 1.41717 11.8811C1.30452 11.7684 1.24123 11.6156 1.24123 11.4563V10.1373H12.7589V11.4563C12.7589 11.788 12.4898 12.057 12.1582 12.057Z"
              fill="#FF2DD1"
            />
          </svg>
        </span>
        <div class="card-inner-item">
          <div class="stat_item">
            <div class="stat_item-title">{{ $t('partnership-text-29') }}</div>
            <div class="stat_item-val stat_item-val-medium">
              {{ formatBalance(level.comand_turnover) }}
              <div class="stat_item-icon">
                <img src="@/assets/images/usdt.svg" alt="coinfuze" />
              </div>
            </div>
          </div>
        </div>
        <div class="card-inner-item">
          <div class="stat_item">
            <div class="stat_item-title">{{ $t('partnership-text-32') }}</div>
            <div class="stat_item-val stat_item-val-medium">
              {{ formatBalance(level.bonus) }}
            </div>
          </div>
        </div>
        <div class="levels-box-footer">
          <div class="levels-box-footer-text" v-if="level.status == 1">
            <svg
              width="16"
              height="12"
              viewBox="0 0 16 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.4571 0.292893C15.8476 0.683418 15.8476 1.31658 15.4571 1.70711L6.29044 10.8738C5.89992 11.2643 5.26675 11.2643 4.87623 10.8738L0.292893 6.29044C-0.0976311 5.89992 -0.0976311 5.26675 0.292893 4.87623C0.683417 4.4857 1.31658 4.4857 1.70711 4.87623L5.58333 8.75245L14.0429 0.292893C14.4334 -0.0976311 15.0666 -0.0976311 15.4571 0.292893Z"
                fill="#37BBC6"
              />
            </svg>
            {{ $t('partnership-text-33') }}
          </div>
          <div class="levels-box-footer-text" v-if="level.status == 2">
            {{ $t('partnership-text-34') }}
          </div>
          <div
            class="levels-box-footer-text"
            v-if="closeStatuses.includes(level.status)"
          >
            {{ $t('partnership-text-35') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LevelCard',
  props: {
    level: {
      require: true,
      type: Object
    }
  },
  data() {
    return {
      closeStatuses: [3, 4, 5, 6]
    }
  },
  methods: {
    formatBalance(balance) {
      return new Intl.NumberFormat(this.$i18n.locale).format(balance)
    }
  }
}
</script>
