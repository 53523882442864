<template>
  <div class="app__panel-nav-icon d-flex-center">
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.69701 1.81543C2.21013 1.81543 1.81543 2.21013 1.81543 2.69701V5.46017C1.81543 5.94705 2.21013 6.34175 2.69701 6.34175H5.46017C5.94705 6.34175 6.34175 5.94705 6.34175 5.46017V2.69701C6.34175 2.21013 5.94705 1.81543 5.46017 1.81543H2.69701ZM0.81543 2.69701C0.81543 1.65784 1.65784 0.81543 2.69701 0.81543H5.46017C6.49933 0.81543 7.34175 1.65784 7.34175 2.69701V5.46017C7.34175 6.49933 6.49933 7.34175 5.46017 7.34175H2.69701C1.65784 7.34175 0.81543 6.49933 0.81543 5.46017V2.69701ZM9.65753 2.69701C9.65753 1.65784 10.4999 0.81543 11.5391 0.81543H14.3023C15.3414 0.81543 16.1839 1.65784 16.1839 2.69701V5.46017C16.1839 6.49933 15.3414 7.34175 14.3023 7.34175H11.5391C10.4999 7.34175 9.65753 6.49933 9.65753 5.46017V2.69701ZM11.5391 1.81543C11.0522 1.81543 10.6575 2.21013 10.6575 2.69701V5.46017C10.6575 5.94705 11.0522 6.34175 11.5391 6.34175H14.3023C14.7892 6.34175 15.1839 5.94705 15.1839 5.46017V2.69701C15.1839 2.21013 14.7892 1.81543 14.3023 1.81543H11.5391ZM2.69701 10.6575C2.21013 10.6575 1.81543 11.0522 1.81543 11.5391V14.3023C1.81543 14.7892 2.21013 15.1839 2.69701 15.1839H5.46017C5.94705 15.1839 6.34175 14.7892 6.34175 14.3023V11.5391C6.34175 11.0522 5.94705 10.6575 5.46017 10.6575H2.69701ZM0.81543 11.5391C0.81543 10.4999 1.65784 9.65754 2.69701 9.65754H5.46017C6.49933 9.65754 7.34175 10.4999 7.34175 11.5391V14.3023C7.34175 15.3414 6.49933 16.1839 5.46017 16.1839H2.69701C1.65784 16.1839 0.81543 15.3414 0.81543 14.3023V11.5391ZM11.5391 10.6575C11.0522 10.6575 10.6575 11.0522 10.6575 11.5391V14.3023C10.6575 14.7892 11.0522 15.1839 11.5391 15.1839H14.3023C14.7892 15.1839 15.1839 14.7892 15.1839 14.3023V11.5391C15.1839 11.0522 14.7892 10.6575 14.3023 10.6575H11.5391ZM9.65753 11.5391C9.65753 10.4999 10.4999 9.65754 11.5391 9.65754H14.3023C15.3414 9.65754 16.1839 10.4999 16.1839 11.5391V14.3023C16.1839 15.3414 15.3414 16.1839 14.3023 16.1839H11.5391C10.4999 16.1839 9.65753 15.3414 9.65753 14.3023V11.5391Z"
        fill="#57435F"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'DashboardMenuIcon'
}
</script>
