<template>
  <div class="col-lg-6">
    <div class="card h-100">
      <div class="card-inner">
        <div class="card-header">
          <h3 class="card-title">{{ $t('news-text-3') }}</h3>
          <p>
            {{ $t('news-text-4') }}
          </p>
          <p>
            {{ $t('news-text-5') }}
          </p>
        </div>
        <div class="materials">
          <div
            class="materials-inner customscroll"
            style="padding-bottom: 30px"
          >
            <material-card
              v-for="(material, idx) in promoMaterials"
              :key="`${idx}_${material.id}`"
              :material="material"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import MaterialCard from './MaterialCard.vue'

export default {
  name: 'PromoMaterials',
  components: { MaterialCard },
  mixins: [],
  props: {},
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      promoMaterials: 'news/getPromoMaterials'
    })
  },
  created() {
    this.getPromoData()
  },
  methods: {
    getPromoData() {
      this.loading = true
      this.$store
        .dispatch('news/getPromoMaterials')
        .catch((error) => this.$showError(error.detail))
        .finally(() => (this.loading = false))
    }
  }
}
</script>
<style lang="scss" scoped></style>
