<template>
  <div class="card-inner">
    <div class="row gy-base">
      <div class="col-lg-6 d-flex flex-column align-items-start">
        <h3 class="card-title flex-fill">
          {{ $t('withdrawal-text-31') }}
        </h3>
        <div class="row">
          <div class="col-xl-10">
            <div class="message d-flex">
              <div class="message-icon">
                <img src="@/assets/images/warning-icon.svg" alt="coinfuze" />
              </div>
              <div class="message-text flex-fill">
                {{ $t('withdrawal-text-36') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <form action="/" method="POST">
          <div class="input-group">
            <input
              class="input-control"
              type="text"
              v-model="tr_wallet"
              :placeholder="$t('withdrawal-ph-1')"
              required
            />
          </div>
          <div class="input-group">
            <input
              class="input-control"
              type="number"
              :placeholder="$t('withdrawal-ph-2')"
              required
              v-model="withdrawalAmount"
            />
            <span class="input-max" style="cursor: pointer" @click="max">
              MAX
            </span>
          </div>
          <div class="row justify-content-between gy-base">
            <div class="col-xl-6">
              <button
                class="button w-100"
                type="button"
                :disabled="!withdrawalAmount"
                @click="createWithdrawalTask"
              >
                <span>{{ $t('withdrawal-text-34') }}</span>
              </button>
            </div>
            <div class="col-xl-3">
              <a class="button button_bordered w-100" @click="cancel">
                <span> {{ $t('personal-text-9') }} </span>
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>

    <two-factor-modal
      v-if="confirmModal"
      @close-modal="closeConfirmModal"
      @validation-complete="withdrawalComplete"
      :show="confirmModal"
      :validation="validation"
    />

    <div class="modal-backdrop fade show" v-if="confirmModal"></div>
  </div>
</template>

<script>
import TwoFactorModal from '../global/TwoFactorModal.vue'

import { mapGetters } from 'vuex'
export default {
  name: 'WithdrawalsContent',
  components: { TwoFactorModal },
  data() {
    return {
      confirmModal: false,
      loading: false,
      tr_wallet: '',
      validation: null,
      withdrawalAmount: null
    }
  },
  computed: {
    ...mapGetters({
      balance: 'user/getBalance'
    })
  },
  methods: {
    max() {
      this.withdrawalAmount = this.balance.free
    },
    getHistroryData() {
      this.loading = true
      this.$store
        .dispatch('user/getHistoryData')
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.newsLoading = false
        })
    },
    cancel() {
      this.$emit('cancel')
    },
    closeConfirmModal() {
      this.confirmModal = false
    },
    withdrawalComplete() {
      this.closeConfirmModal()
      this.withdrawalAmount = null
      this.tr_wallet = ''
      this.getHistroryData()
    },
    createWithdrawalTask() {
      this.loading = true
      let payload = {
        tr_amount: this.withdrawalAmount,
        tr_wallet: this.tr_wallet
        // tr_wallet: 'TN5MAUKH1h18MmKP8JPjfuHTpXKpQsP4oP'
      }
      this.$store
        .dispatch('user/createWithdrawalTask', payload)
        .then((response) => {
          this.validation = response.data.validation
          this.confirmModal = true
        })
        .catch((error) => this.$showError(error.detail))
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
