<template>
  <div class="tabs-content-item" id="tab-sub-item-2">
    <div v-if="enable === 'Telegram Authenticator'">
      <!-- <input
        class="input-control"
        type="text"
        placeholder="Your Password"
        required
        v-model="TGCode"
      /> -->
      <button class="button w-sm-100" type="button" @click="disable">
        <span>{{ $t('personal-text-16') }}</span>
      </button>
    </div>
    <div v-else>
      <div class="card card_inside steps-item">
        <div class="card-inner">
          <div class="steps-item-header">
            <div class="steps-item-title fw-600 text-accent">
              {{ $t('personal-text-16-1') }}
            </div>
          </div>
          <div class="steps-item-content">
            <div class="card-text">
              <p>
                {{ $t('personal-text-14') }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <button class="button w-sm-100" type="button" @click="enableTG">
        <span>{{ $t('personal-text-17') }}</span>
      </button>
    </div>
    <two-factor-modal
      v-if="twoFactorModal"
      :show="twoFactorModal"
      :validation="validation"
      @validation-complete="validationComplete"
      @close-modal="closeTwoFactorModal"
    />
  </div>
</template>
<script>
import TwoFactorModal from '@/components/global/TwoFactorModal.vue'

export default {
  name: 'TelegramTab',
  components: {
    TwoFactorModal
  },
  props: {
    enable: {}
  },
  data() {
    return {
      twoFactorModal: false,
      validation: null,
      TGCode: ''
    }
  },

  methods: {
    enableTG() {
      const telegramLink = 'https://t.me/sequrity2faCF_bot'
      window.open(telegramLink, '_blank')
      this.$emit('select-tab', 'TelegramTab')
    },
    closeTwoFactorModal() {
      this.twoFactorModal = false
      this.validation = null
    },
    disable() {
      this.$store
        .dispatch('user/disableTwoFactor', { tg_id: 'DISABLE' })
        .then((response) => {
          this.validation = response.validation
          this.twoFactorModal = true
        })
        .finally(() => {
          this.$emit('select-default-tab')
        })
    },
    validationComplete() {
      this.twoFactorModal = false
    }
  }
}
</script>
