/* eslint-disable no-empty-pattern */
import UserServices from '@/services/api/user.services'
import Vue from 'vue'

let state = {
  user: null,
  userName: null,
  avatarKey: 1,
  plansList: [],
  referals: {},
  bonusLevels: [],
  twoFactorData: {
    google_validation_key: false,
    qr: false,
    aditional_service: false
  },
  wallets: [],
  stakingData: [],
  lastStakingTr: { date_create: '-', tr_amount: 0 },
  devidentSumm: 0,
  userStakingData: {}
}

const mutations = {
  SET_USER(state, user) {
    state.user = user
  },
  SET_AVATAR_KEY(state) {
    state.avatarKey = Math.random()
  },
  UPDATE_USER(state, payload) {
    Object.keys(payload).forEach((key) => {
      Vue.set(state.user, key, payload[key])
    })
  },
  SET_LOGOUT(state) {
    state.devidentSumm = 0
    state.avatarKey = 1
    state.user = null
    state.userName = null
    state.plansList = []
    state.bonusLevels = []
    state.referals = {}
    state.wallets = []
    state.stakingData = []
    state.userStakingData = []
    state.lastStakingTr = { date_create: '-', tr_amount: 0 }
    state.twoFactorData = {
      google_validation_key: false,
      qr: false,
      aditional_service: false
    }
  },
  SET_USER_NAME(state, userName) {
    state.userName = userName
  },
  SET_PLAN_LIST(state, plansList) {
    state.plansList = plansList
  },
  SET_REFERALS(state, referals) {
    state.referals = referals
  },
  SET_USERNAME(state, data) {
    state.user.name = data.name
    state.user.last_name = data.last_name
  },
  SET_BONUS_LEVELS(state, levels) {
    state.bonusLevels = levels
  },
  SET_TWO_FACTOR_DATA(state, data) {
    const { aditional_service, qr, google_validation_key } = data
    state.twoFactorData.aditional_service = aditional_service || false
    state.twoFactorData.qr = qr || false
    state.twoFactorData.google_validation_key = google_validation_key || false
  },
  SET_PLAN_DATA(state, data) {
    state.user.investhystory = data
  },
  ACTIVATE_PLAN(state, data) {
    // Проверяем, существует ли уже элемент с таким же id
    const index = state.user.investhystory.findIndex(
      (item) => item.id === data.id
    )
    // Если элемент не найден, index будет -1 и мы можем добавить новый элемент
    if (index === -1) {
      state.user.investhystory.push(data)
    }
  },

  REINVEST_PLAN(state, data) {
    state.user.investhystory.find((plan) => plan.id === data.id).reinvest =
      data.reinvest
  },
  SET_WALLETS(state, data) {
    state.wallets = data
  },
  SET_STAKING_DATA(state, data) {
    state.stakingData = data
  },
  SET_USER_STAKING_DATA(state, data) {
    state.userStakingData = data
  },
  SET_LAST_STAKING_TR(state, data) {
    Object.assign(state.lastStakingTr, data)
  },
  UPDATE_BALANCE(state, data) {
    state.user.money_active = data.money_active
    state.user.money_free = data.money_free
  },
  SET_DEVIDENT_SUMM(state, data) {
    state.devidentSumm = data
  },
  EMAIL_VERIFY(state, data) {
    state.user.email_verify = data
  },
  SET_INVEST_HISTORY(state, data) {
    state.user.investhystory = data
  },
  SET_PAYMENT_HISTORY(state, data) {
    state.user.paymenthystory = data
  },
  SET_AVATAR(state, id) {
    state.user.avatar = id + '.png'
  },
  DELETE_AVATAR(state) {
    state.user.avatar = 'ava.png'
  },
  ADD_PAYMENT_HISTORY(state, payload) {
    const index = state.user.paymenthystory.findIndex(
      (item) => item.id === payload.id
    )
    if (index === -1) {
      state.user.paymenthystory.unshift(payload)
    } else {
      state.user.paymenthystory.splice(index, 1, payload)
    }
  }
}

const actions = {
  //Auth
  logout({ commit }) {
    commit('SET_LOGOUT')
  },
  getUserData({ commit }) {
    return new Promise((resolve, reject) => {
      UserServices.get('getUserData')
        .then((response) => {
          commit('UPDATE_USER', response.data.results[0])
          return resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getBalance({ commit }) {
    return new Promise((resolve, reject) => {
      UserServices.get('updateBalance')
        .then((response) => {
          commit('UPDATE_BALANCE', response.data)
          return resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  registration({ commit, dispatch }, { data, param }) {
    return new Promise((resolve, reject) => {
      UserServices.post('registration', data, param)
        .then((response) => {
          dispatch('app/setAuthToken', response.data.rsp, { root: true })
          commit('SET_USER', response.data.user)
          commit('SET_USER_NAME', response.data.caarea)
          return resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  resetPassword({}, data) {
    return new Promise((resolve, reject) => {
      UserServices.post('resetPassword', data)
        .then((response) => {
          return resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  changePassword({}, data) {
    return new Promise((resolve, reject) => {
      UserServices.post('change_password', data)
        .then((response) => {
          return resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  resetPassConfirm({}, data) {
    return new Promise((resolve, reject) => {
      UserServices.post('resetPassword', data)
        .then((response) => {
          return resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  //User Data
  setUserName({ commit }, data) {
    commit('SET_USER_NAME', data)
  },
  setUser({ commit }, data) {
    commit('SET_USER', data)
  },
  getWallets({ commit }) {
    return new Promise((resolve, reject) => {
      UserServices.get('wallets')
        .then((response) => {
          commit('SET_WALLETS', response.data.results)
          return resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getHistoryData({ commit }) {
    return new Promise((resolve, reject) => {
      UserServices.get('payments')
        .then((response) => {
          commit('SET_PAYMENT_HISTORY', response.data)
          return resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getPlansData({ commit }) {
    return new Promise((resolve, reject) => {
      UserServices.get('getPlansData')
        .then((response) => {
          commit('SET_INVEST_HISTORY', response.data)
          return resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createWithdrawalTask({}, payload) {
    return new Promise((resolve, reject) => {
      UserServices.post('payments', payload)
        .then((response) => {
          return resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  documentUpload({}, data) {
    return new Promise((resolve, reject) => {
      UserServices.post('docUpload', data)
        .then((response) => {
          return resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  avatarUpload({ commit }, data) {
    return new Promise((resolve, reject) => {
      UserServices.post('avatarUpload', data)
        .then((response) => {
          commit('SET_AVATAR', data.ID)
          commit('SET_AVATAR_KEY')
          return resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteAvatar({ commit }, data) {
    return new Promise((resolve, reject) => {
      UserServices.post('deleteAvatar', data)
        .then((response) => {
          commit('SET_AVATAR', 'ava')
          commit('SET_AVATAR_KEY')
          return resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  //Plan Managment
  getPlanList({ commit }, data) {
    return new Promise((resolve, reject) => {
      UserServices.get('getPlanList', data)
        .then((response) => {
          commit('SET_PLAN_LIST', response.data.results)
          return resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  activatePlan({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UserServices.post('activatePlan', payload)
        .then((response) => {
          commit('ACTIVATE_PLAN', response.data.data)
          commit('UPDATE_USER', response.data.user)
          return resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  reinvestPlan({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UserServices.post('reinvestChange', payload)
        .then((response) => {
          commit('REINVEST_PLAN', payload)
          return resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 2 Factor
  twoFactorEmit({}, data) {
    return new Promise((resolve, reject) => {
      UserServices.post('changeUserData', data)
        .then((response) => {
          return resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getTwoFactorData({ commit }) {
    return new Promise((resolve, reject) => {
      UserServices.get('changeUserData')
        .then((response) => {
          commit('SET_TWO_FACTOR_DATA', response.data)
          return resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  enableTwoFactor({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      UserServices.post('changeUserData', payload)
        .then((response) => {
          dispatch('getTwoFactorData')
          return resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  disableTwoFactor({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      UserServices.post('changeUserData', payload)
        .then((response) => {
          resolve(response.data.response)
          return dispatch('getTwoFactorData')
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  validateTwoFactor({}, payload) {
    return new Promise((resolve, reject) => {
      UserServices.post('validateTwoFactor', payload)
        .then((response) => {
          return resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  resendEmailPass({}, payload) {
    return new Promise((resolve, reject) => {
      UserServices.post('validateTwoFactor', payload)
        .then((response) => {
          return resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  //Referrals
  getBonusLevels({ commit }) {
    return new Promise((resolve, reject) => {
      UserServices.get('getBonusLevels')
        .then((response) => {
          commit('SET_BONUS_LEVELS', response.data.results)
          return resolve(response.data.results)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getReferals({ commit }) {
    return new Promise((resolve, reject) => {
      UserServices.get('getReferals')
        .then((response) => {
          commit('SET_REFERALS', response.data.response)
          return resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  //Staking data
  getStakingData({ commit }) {
    return new Promise((resolve, reject) => {
      UserServices.get('stakingData')
        .then((response) => {
          commit('SET_STAKING_DATA', response.data.results)
          return resolve(response.data.results)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAllStaking({ commit }) {
    return new Promise((resolve, reject) => {
      UserServices.get('allStaking')
        .then((response) => {
          if (response.data.results.length) {
            commit('SET_USER_STAKING_DATA', response.data.results[0])
            commit('SET_LAST_STAKING_TR', response.data.last_transaction)
            commit('SET_DEVIDENT_SUMM', response.data.devident_summ)
          }

          return resolve(response.data.results)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  stack({ commit }, payload) {
    return new Promise((resolve, reject) => {
      UserServices.post('allStaking', payload)
        .then((response) => {
          commit('SET_USER_STAKING_DATA', response.data.results[0])
          commit('SET_LAST_STAKING_TR', response.data.last_transaction)
          commit('UPDATE_BALANCE', response.data.user)
          commit('SET_DEVIDENT_SUMM', response.data.devident_summ)
          return resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  requestNewLetter({}) {
    return new Promise((resolve, reject) => {
      UserServices.get('emailConfirmation')
        .then((response) => {
          return resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  verifyEmail({}, payload) {
    return new Promise((resolve, reject) => {
      UserServices.post('emailConfirmation', payload)
        .then((response) => {
          return resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  affiliate({}, payload) {
    return new Promise((resolve, reject) => {
      UserServices.post('affiliate', payload)
        .then((response) => {
          return resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

const getters = {
  getUser: (state) => state.user,
  getUserId: (state) => state.user.id,
  getSocketCh: (state) => state.user.CHANNEL,
  getBalance: (state) => {
    return {
      free: state.user.money_free,
      active: state.user.money_active,
      total: state.user.money_free + state.user.money_active
    }
  },
  getAvatarKey: (state) => state.avatarKey,
  getPaymentHystory: (state) => state.user.paymenthystory,
  getInvestHystory: (state) => state.user.investhystory,
  getTotalsStats: (state) => state.user.totals_new,
  getBonusLevels: (state) => state.bonusLevels,
  getPlanList: (state) => state.plansList,
  getReferals: (state) => state.referals,
  getTwoFactorData: (state) => state.twoFactorData,
  getUserWallet: (state) => (state.wallets[0] ? state.wallets[0] : false),
  getStakingData: (state) => state.stakingData,
  getUserStakingData: (state) => state.userStakingData,
  getLastStakingTR: (state) => state.lastStakingTr,
  getDevidentSumm: (state) => (state.devidentSumm ? state.devidentSumm : 0),
  getEmailVerify: (state) => state.user.email_verify
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
