<template>
  <div class="tabs-content-item active" id="tab-item-1">
    <div class="card">
      <div class="card-inner">
        <div class="row gy-base">
          <div class="col-lg-6 d-flex flex-column align-items-start">
            <p>
              {{ $t('deposit-text-13') }}
            </p>
            <p>
              {{ $t('deposit-text-14') }}
            </p>
            <p>
              {{ $t('deposit-text-15') }}
            </p>
            <p>{{ $t('deposit-text-16') }} - TRC 20</p>
            <p>
              {{ $t('deposit-text-17') }}
            </p>

            <button
              style="margin-top: 15px"
              class="button w-sm-100"
              @click="openDepositModal"
            >
              <span>{{ $t('deposit-btn') }}</span>
            </button>
          </div>
          <div class="col-lg-6">
            <div class="card card_gradient d-none d-md-block">
              <div class="card-inner">
                <h3 class="card-title flex-fill">
                  {{ $t('deposit-benefits-1') }}
                  <span class="text-blue">CoinFuze!</span>
                </h3>
                <ul class="reset-list dot-list">
                  <li>
                    {{ $t('deposit-benefits-2') }}
                    <span class="text-blue">~ 9%*</span>
                  </li>
                  <li>
                    {{ $t('deposit-benefits-3') }}
                    <span class="text-blue"> ~ 120%</span>
                  </li>
                  <li>{{ $t('deposit-benefits-4') }}</li>
                  <li>
                    {{ $t('deposit-benefits-5') }}
                  </li>
                </ul>
              </div>
            </div>
            <ul class="reset-list links-list mt-4">
              <li>
                <span class="btn-link" @click="openDepositTutorialModal">
                  {{ $t('deposit-text-11') }}
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.833 7L1.16634 7M12.833 7L7.83301 12M12.833 7L7.83301 2"
                      stroke="#37BBC6"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </li>
              <li>
                <span class="btn-link" @click="loadCrisp"
                  >{{ $t('deposit-text-18') }}
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.833 7L1.16634 7M12.833 7L7.83301 12M12.833 7L7.83301 2"
                      stroke="#37BBC6"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <deposit-tutorial-modal
      v-if="showTutorialModal"
      @close-modal="closeDepositTutorialModal"
      :show="showTutorialModal"
    />
    <modal-deposit
      v-if="showDepositModal"
      @close-modal="closeDepositModal"
      :show="showDepositModal"
    />
  </div>
</template>
<script>
import { Crisp } from 'crisp-sdk-web'

import { mapGetters } from 'vuex'
import DepositTutorialModal from './DepositTutorialModal.vue'
import ModalDeposit from './ModalDeposit.vue'
export default {
  name: 'DepositeBlock',
  components: { ModalDeposit, DepositTutorialModal },

  data() {
    return {
      showDepositModal: false,
      showTutorialModal: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/getUser'
    })
  },
  methods: {
    loadCrisp() {
      Crisp.chat.open()
    },
    closeDepositModal() {
      this.showDepositModal = false
    },
    openDepositModal() {
      this.showDepositModal = true
    },
    openDepositTutorialModal() {
      this.showTutorialModal = true
    },
    closeDepositTutorialModal() {
      this.showTutorialModal = false
    }
  }
}
</script>
<style lang="scss" scoped>
.btn-link {
  color: var(--s-blue-color);
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}
</style>
