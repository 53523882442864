<template>
  <div class="app app__start d-flex flex-column align-items-center">
    <div class="app__start-header">
      <div class="app__start-logo">
        <a href="https://coinfuze.com/">
          <img
            class="dark-theme-only"
            src="@/assets/images/logo-white.svg"
            alt="logo"
          />
          <img
            class="light-theme-only"
            src="@/assets/images/logo-black.svg"
            alt="logo"
          />
        </a>
      </div>
    </div>
    <div class="app__start-content">
      <div class="app__start-title text-center text-accent fw-600">
        {{ $t('page-not-found') }}
        <router-link to="/">{{ $t('home') }}</router-link>
      </div>
    </div>
    <div class="app__start-footer fw-300 text-center">
      &copy; 2024 CoinFuze. All rights reserved.
    </div>
  </div>
</template>
<script>
export default {
  name: 'NotFound'
}
</script>
<style lang="scss">
// @import '../assets/styles/variables.scss';
// h1 {
//   color: $main-text;
// }
</style>
