<template>
  <footer class="app__footer">
    <div class="card">
      <div
        class="app__footer-inner card-inner d-flex flex-wrap align-items-center fw-300"
      >
        <div class="app__footer-copyright flex-fill">
          &copy; 2024 CoinFuze. All rights reserved.
        </div>
        <ul class="app__footer-list reset-list d-flex flex-wrap">
          <li
            class="app__footer-li"
            v-for="(file, idx) in files"
            :key="`${idx}+${file.fileType}`"
          >
            <a
              :href="
                localeList.includes(locale)
                  ? `https://coinfuze.com${files[idx].link}_${locale}.pdf`
                  : `https://coinfuze.com${files[idx].default}`
              "
              target="_blank"
            >
              {{ $t(`footer-item-${idx + 1}`) }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FooterItem',
  data() {
    return {
      files: [
        {
          fileType: 'AML',
          link: '/docs/AML',
          default: '/docs/AML_en.pdf'
        },
        {
          fileType: 'Terms',
          link: '/docs/General_Terms',
          default: '/docs/General_Terms_en.pdf'
        },
        {
          fileType: 'Policy',
          link: '/docs/Privacy_Policy',
          default: '/docs/Privacy_Policy_en.pdf'
        }
        // {
        //   fileType: 'Terms',
        //   ru: '/docs/General_Terms_ru.pdf',
        //   default: '/docs/General_Terms_en.pdf'
        // },
        // {
        //   fileType: 'Policy',
        //   ru: '/docs/Privacy_Policy_ru.pdf',
        //   default: '/docs/Privacy_Policy_en.pdf'
        // },
        // {
        //   fileType: 'AML',
        //   ru: '/docs/AML_ru.pdf',
        //   default: '/docs/AML_en.pdf'
        // }
      ]
    }
  },
  computed: {
    ...mapGetters({
      locale: 'app/getLocale',
      localeList: 'app/getLocalesList'
    })
  },
  methods: {
    openFile(file) {
      const fileToOpen = this.locale === 'ru' ? file.ru : file.default
      const win = window.open(fileToOpen, '_blank')
      win.focus()
    }
  }
}
</script>
