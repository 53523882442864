import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

/**
 * Vuex modules
 */
import AppModule from './modules/app.module'
import UserModule from './modules/user.module'
import newsModule from './modules/news.module'
import notificationsModule from './modules/notifications.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app: AppModule,
    user: UserModule,
    news: newsModule,
    notification: notificationsModule
  },
  plugins: [
    createPersistedState({
      key: 'coinfuze',
      paths: ['app', 'user', 'news', 'notification'],
      storage: {
        getItem: (key) => localStorage.getItem(key),
        setItem: (key, data) => localStorage.setItem(key, data),
        removeItem: (key) => localStorage.removeItem(key)
      }
    })
  ]
})
