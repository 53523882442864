<template>
  <div class="app__panel-nav-icon d-flex-center">
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.77238 1.9C4.77238 0.823348 5.68916 0 6.75724 0H8.24209C9.31017 0 10.2269 0.823347 10.2269 1.9C10.2269 2.40547 10.7678 2.72684 11.2117 2.48516L11.2907 2.4422C12.2233 1.93449 13.4287 2.22514 13.9845 3.13268L14.7269 4.34511C15.2941 5.27142 14.9443 6.44161 13.996 6.95781C13.5665 7.19164 13.5665 7.80835 13.996 8.04219C14.9443 8.55839 15.2941 9.72858 14.7269 10.6549L13.9845 11.8673C13.4287 12.7749 12.2233 13.0655 11.2907 12.5578L11.2117 12.5148C10.7678 12.2732 10.2269 12.5945 10.2269 13.1C10.2269 14.1767 9.31017 15 8.24209 15H6.75724C5.68916 15 4.77238 14.1767 4.77238 13.1C4.77238 12.5945 4.23156 12.2732 3.78761 12.5148L3.70868 12.5578C2.776 13.0655 1.57059 12.7749 1.01487 11.8673L0.272439 10.6549C-0.294778 9.72859 0.0550122 8.5584 1.00329 8.0422C1.43286 7.80836 1.43286 7.19164 1.00329 6.9578C0.0550134 6.4416 -0.294777 5.27141 0.27244 4.3451L1.01487 3.13267C1.57059 2.22513 2.776 1.93448 3.70868 2.44219L3.78761 2.48516C4.23156 2.72683 4.77238 2.40547 4.77238 1.9ZM6.75724 1C6.18519 1 5.77238 1.43026 5.77238 1.9C5.77238 3.16415 4.41981 3.96786 3.3095 3.36346L3.23057 3.32049C2.74285 3.055 2.13202 3.22321 1.86768 3.65488L1.12525 4.86732C0.872409 5.28023 1.00928 5.8225 1.4814 6.0795C2.60682 6.69213 2.60682 8.30787 1.48139 8.9205C1.00928 9.1775 0.872408 9.71977 1.12525 10.1327L1.86768 11.3451C2.13202 11.7768 2.74285 11.945 3.23057 11.6795L3.3095 11.6365C4.41981 11.0321 5.77238 11.8358 5.77238 13.1C5.77238 13.5697 6.18519 14 6.75724 14H8.24209C8.81414 14 9.22694 13.5697 9.22694 13.1C9.22694 11.8358 10.5795 11.0321 11.6898 11.6365L11.7688 11.6795C12.2565 11.945 12.8673 11.7768 13.1316 11.3451L13.8741 10.1327C14.1269 9.71976 13.9901 9.17749 13.5179 8.92049C12.3925 8.30786 12.3925 6.69214 13.5179 6.07951C13.9901 5.82251 14.1269 5.28024 13.8741 4.86733L13.1316 3.65489C12.8673 3.22322 12.2565 3.05501 11.7688 3.3205L11.6898 3.36346C10.5795 3.96787 9.22694 3.16416 9.22694 1.9C9.22694 1.43026 8.81414 1 8.24209 1H6.75724Z"
        fill="#57435F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.5 5.375C6.32639 5.375 5.375 6.32639 5.375 7.5C5.375 8.67361 6.32639 9.625 7.5 9.625C8.67361 9.625 9.625 8.67361 9.625 7.5C9.625 6.32639 8.67361 5.375 7.5 5.375ZM4.375 7.5C4.375 5.77411 5.77411 4.375 7.5 4.375C9.22589 4.375 10.625 5.77411 10.625 7.5C10.625 9.22589 9.22589 10.625 7.5 10.625C5.77411 10.625 4.375 9.22589 4.375 7.5Z"
        fill="#57435F"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'PlanManagementMenuIcon'
}
</script>
