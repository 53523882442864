<template>
  <div class="app__content-item mb-5">
    <div class="app__content-header">
      <h3 class="app__content-title">{{ $t('history') }}</h3>
    </div>
    <div class="card overflow-hidden">
      <div class="card-inner">
        <div class="plans">
          <div class="customscroll plan-row">
            <vue-good-table
              :columns="columns"
              :rows="formattedRows"
              :pagination-options="paginationOptions"
              styleClass="vgt-table condensed "
              style="min-width: 1120px"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'plan_name'">
                  {{ props.row.plan_name }}
                </span>
                <span v-if="props.column.field == 'id'">
                  {{ props.row.id }}
                </span>

                <span v-if="props.column.field == 'date_create'">
                  {{ props.row.date_create }}
                </span>

                <span v-if="props.column.field == 'date_end'">
                  {{ props.row.date_end }}
                </span>

                <span v-if="props.column.field == 'start_capital'">
                  {{ props.row.start_capital }}
                </span>

                <span v-if="props.column.field == 'result'">
                  {{ props.row.result }}
                </span>
                <span v-if="props.column.field == 'tr_commission'">
                  {{ props.row.tr_commission }}
                </span>
                <span v-if="props.column.field == 'tr_profit'">
                  {{ props.row.tr_profit }}
                </span>
                <span
                  v-if="props.column.field == 'tr_status'"
                  class="status-label"
                  :class="props.row.tr_status"
                >
                  {{ props.row.tr_status }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PlanTable',
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      investhystory: 'user/getInvestHystory'
    }),
    columns() {
      return [
        {
          label: this.$t('staking-table-1'),
          field: 'plan_name'
        },
        {
          label: 'ID',
          field: 'id'
        },
        {
          label: this.$t('staking-table-2'),
          field: 'date_create'
        },
        {
          label: this.$t('staking-table-3'),
          field: 'date_end'
        },
        {
          label: this.$t('staking-table-4'),
          field: 'start_capital'
        },
        {
          label: this.$t('staking-table-5'),
          field: 'result'
        },
        {
          label: this.$t('staking-table-7'),
          field: 'tr_commission'
        },
        {
          label: this.$t('staking-table-6'),
          field: 'tr_profit'
        },
        {
          label: this.$t('staking-table-8'),
          field: 'tr_status'
        }
      ]
    },
    formattedRows() {
      return this.investhystory.map((row) => ({
        ...row,
        plan_name: `No. ${row.plan.name.slice(-1)}`,
        date_create: this.formatDate(row.start_time),
        date_end: this.formatDate(row.end_time),
        start_capital: row.pay_summ.toFixed(2),

        result: `${row.final_profit.toFixed(2)}
         (${row.final_percent.toFixed(0)}%)`,

        tr_commission: row.comission_usdt.toFixed(2),
        tr_profit: row.day_profit.toFixed(2),

        tr_status: row.active ? this.$t('staking-18') : this.$t('staking-20')
      }))
    },
    paginationOptions() {
      return {
        enabled: true,
        perPageDropdownEnabled: false,
        mode: 'page',
        nextLabel: this.$t('table-next'),
        prevLabel: this.$t('table-prev')
      }
    }
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()
      return `${day}.${month}.${year}`
    }
  }
}
</script>
