/* eslint-disable no-empty-pattern */
import NotificationsServices from '@/services/api/notification.services'
import Vue from 'vue'
let state = {
  notifications: [],
  chunkLength: 5,
  unreadedCount: 0,
  count: 0
}

const mutations = {
  SET_LOGOUT(state) {
    state.notifications = []
    state.count = 0
  },
  SET_NOTIFICATIONS(state, payload) {
    state.notifications = payload
  },
  SET_NOTIFICATIONS_COUNT(state, count) {
    state.count = count
  },
  SET_UNREADED_COUNT(state, count) {
    state.unreadedCount = count
  },
  ADD_NEW_NOTIFICATIONS(state, payload) {
    state.notifications.unshift(payload.results[0])
    state.count = payload.count
    state.unreadedCount += 1
  },
  READ_NOTIFICATIONS(state, data) {
    state.notifications.forEach((notification) => {
      if (data.includes(notification.id)) {
        Vue.set(notification, 'readed', true)
      }
    })
  },
  ADD_NEW_CHUNK(state, payload) {
    const uniqueNotifications = payload.filter(
      (newNotification) =>
        !state.notifications.some(
          (existingNotification) =>
            existingNotification.id === newNotification.id
        )
    )

    state.notifications = [...state.notifications, ...uniqueNotifications]
  }
}

const actions = {
  logout({ commit }) {
    commit('SET_LOGOUT')
  },
  getAllNotifications({ commit }, data) {
    return new Promise((resolve, reject) => {
      NotificationsServices.get('notifications', data)
        .then((response) => {
          commit('SET_NOTIFICATIONS', response.data.results)
          commit('SET_NOTIFICATIONS_COUNT', response.data.count)
          commit('SET_UNREADED_COUNT', response.data.unread)

          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchNotificationChunk({ commit }, page) {
    return new Promise((resolve, reject) => {
      NotificationsServices.get('notificationsChunk', {}, page)
        .then((response) => {
          commit('ADD_NEW_CHUNK', response.data.results)
          commit('SET_NOTIFICATIONS_COUNT', response.data.count)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addNewNotification({ commit }, data) {
    commit('SET_NOTIFICATIONS', data)
  },

  readNotifications({ commit }, data) {
    return new Promise((resolve, reject) => {
      NotificationsServices.post(
        'notifications',
        data,
        {},
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
        .then((response) => {
          commit('READ_NOTIFICATIONS', data.msg)
          commit('SET_UNREADED_COUNT', response.data.unread)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

const getters = {
  getNotifications: (state) => state.notifications,
  getLastNotification: (state) => state.notifications.slice(0, 3),
  getNotificationCount: (state) => state.count,
  getUnreadedCount: (state) => state.unreadedCount,
  getChunkLength: (state) => state.chunkLength
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
