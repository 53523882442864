<template>
  <main class="app app__start d-flex flex-column align-items-center">
    <div class="app__start-header">
      <div class="app__start-logo">
        <a href="https://coinfuze.com/">
          <img
            class="dark-theme-only"
            src="@/assets/images/logo-white.svg"
            alt="logo"
          />
          <img
            class="light-theme-only"
            src="@/assets/images/logo-black.svg"
            alt="logo"
          />
        </a>
      </div>
    </div>

    <router-view></router-view>

    <div class="app__start-footer fw-300 text-center">
      &copy; 2024 CoinFuze. All rights reserved.
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AuthPage',
  data() {
    return {
      isLangOpened: false
    }
  },
  beforeMount() {
    this.setDefaultLocale()
  },
  computed: {
    ...mapGetters({
      locales: 'app/getLocalesList'
    })
  },
  methods: {
    setDefaultLocale() {
      const locale = this.$route.query.locale
      if (locale && this.locales.includes(locale)) {
        this.$store.dispatch('app/setDefaultLocale', locale)
      }
    },
    toggleMenu() {
      this.isLangOpened = !this.isLangOpened
    },
    closeLangMenu() {
      this.isLangOpened = false
    }
  }
}
</script>
<style lang="scss">
.app__start {
  min-height: 100vh;
  justify-content: space-between;
  gap: var(--s-base-val);
  padding-left: var(--s-base-val);
  padding-right: var(--s-base-val);
  box-sizing: border-box;

  @media screen and (min-width: 768px) {
    background-image: url('../assets/images/start-bg.png');
    background-position: center;
    background-size: cover;
  }
  &-header,
  &-footer {
    padding: calc(var(--s-base-val) * 2);
    color: var(--s-input-ph-color);
  }
  &-logo {
    @media screen and (min-width: 1200px) {
      img {
        max-width: 11.714rem;
        min-width: 11.714rem;
        width: 100%;
      }
    }
  }
  &-content {
    max-width: 25.714rem;
    width: 100%;
  }

  &-title {
    font-size: var(--s-base-val-2);
    margin-bottom: var(--s-base-val-3);
  }
}
</style>
