<template>
  <div class="app__content flex-fill">
    <div class="app__content-header d-xxl-none">
      <div class="app__content-title h1 text-accent fw-600">
        {{ $t($route.meta.title) }}
      </div>
    </div>
    <div class="app__content-item mb-5">
      <div class="news-line customscroll">
        <div class="row gy-base flex-nowrap">
          <div
            class="col-lg-4 col-md-6 col-sm-6 col-12"
            v-for="(news, idx) in newsList"
            :key="news.id + idx + news.slug"
          >
            <news-card :news="news" class="h-100" />
          </div>
        </div>
      </div>
    </div>
    <div class="app__content-item">
      <div class="row gy-base">
        <promo-materials />
        <referral-block />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ReferralBlock from '@/components/affiliateProgram/ReferralBlock.vue'
import NewsCard from './NewsCard.vue'
import PromoMaterials from './PromoMaterials.vue'

export default {
  name: 'NewsPage',
  components: { NewsCard, ReferralBlock, PromoMaterials },
  data() {
    return {
      loading: false,
      randomTime: 0
    }
  },
  created() {
    this.loading = true
    this.$store
      .dispatch('news/getAllNews')
      .catch((error) => this.$showError(error.detail))
      .finally(() => (this.loading = false))
  },
  mounted() {
    this.scrollToTop()
  },
  computed: {
    ...mapGetters({
      newsList: 'news/getAllNews'
    })
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0)
    }
  }
}
</script>
<style lang="scss" scoped>
.news-line {
  @media (min-width: 1200px) {
    max-width: calc(100vw - 320px);
  }
}
</style>
