<template>
  <div class="app__content flex-fill">
    <page-title />
    <div class="app__content-item mb-5">
      <div class="row gy-base">
        <investment-management-block />
        <staking-block />
      </div>
    </div>
    <div class="app__content-item mb-5" v-if="activePlans.length">
      <div class="app__content-header">
        <h3 class="app__content-title">{{ $t('plan-page-1') }}</h3>
      </div>
      <div class="card">
        <div class="card-inner">
          <div class="plans">
            <div class="plan-row customscroll">
              <div class="row gy-base">
                <active-plan-component
                  v-for="(plan, idx) in activePlans"
                  :key="`${plan.id}'active plan'${idx} `"
                  :plan="plan"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="app__content-item mb-5">
      <div class="app__content-header">
        <h3 class="app__content-title">{{ $t('plan-page-2') }}</h3>
      </div>
      <div class="card">
        <div class="card-inner">
          <div class="plans">
            <div class="plan-row customscroll">
              <div class="row gy-base">
                <available-plan-component
                  v-for="(avPlan, idx) in sortedPlanList"
                  :key="idx + avPlan.name"
                  :plan="avPlan"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <plan-table />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import PageTitle from '../main/PageTitle.vue'
import PlanTable from './PlanTable.vue'
import ActivePlanComponent from './ActivePlanComponent.vue'
import AvailablePlanComponent from './AvailablePlanComponent.vue'
import StakingBlock from './StakingBlock.vue'
import InvestmentManagementBlock from './InvestmentManagementBlock.vue'

export default {
  name: 'PlanManagementPage',
  components: {
    PageTitle,
    PlanTable,
    ActivePlanComponent,
    AvailablePlanComponent,
    StakingBlock,
    InvestmentManagementBlock
  },
  data() {
    return {
      loading: true
    }
  },
  computed: {
    ...mapGetters({
      investhystory: 'user/getInvestHystory',
      planList: 'user/getPlanList'
    }),
    sortedPlanList() {
      const sortedList = [...this.planList]
      return sortedList.sort((a, b) => a.plan_duration - b.plan_duration)
    },
    activePlans() {
      return this.investhystory.filter((plan) => plan.active)
    }
  },
  created() {
    this.getPlanList()
      .then(this.getAllStaking)
      .then(this.getStakingData)
      .then(this.getPlansData)
      .then(this.getBalance)
      .finally(() => {
        this.loading = false
      })
  },
  mounted() {
    this.scrollToTop()
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    getPlansData() {
      return this.$store
        .dispatch('user/getPlansData')
        .catch((error) => this.$showError(error.detail))
    },
    getAllStaking() {
      return this.$store
        .dispatch('user/getAllStaking')
        .catch((error) => this.$showError(error.detail))
    },
    getStakingData() {
      return this.$store
        .dispatch('user/getStakingData')
        .catch((error) => this.$showError(error.detail))
    },
    getPlanList() {
      return this.$store
        .dispatch('user/getPlanList')
        .catch((error) => this.$showError(error.detail))
    },
    getBalance() {
      this.$store
        .dispatch('user/getBalance')
        .catch((error) => console.log(error))
    }
  }
}
</script>
