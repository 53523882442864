<template>
  <div class="tabs-content-item active" id="tab-item-4">
    <div class="card-inner-item">
      <div class="row gy-base">
        <div class="col-lg-3">
          <div class="input-group m-0">
            <div
              class="cselect-wrapper"
              :class="{ active: docTypeMenu }"
              @click="toggleDocTypeMenu"
            >
              <span class="cselect-label">
                {{ localizedDocType }}
              </span>
              <div class="cselect-arrow">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 7.5L10 12.5L15 7.5"
                    stroke="white"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="cselect-list">
                <span
                  class="cselect-item"
                  v-for="(docType, idx) in docTypeList"
                  :key="'docType' + docType.type + idx"
                  @click="selectDocType(docType)"
                >
                  {{ docType.label }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-7">
          <file-input @file-selected="handleFileUpload" ref="fileInput" />
        </div>
        <div class="col-lg-2">
          <button class="button" @click="uploadDocument" :disabled="!file">
            <span>{{ $t('personal-text-42') }}</span>
          </button>
        </div>
        <div class="col-12">
          <div class="input-group m-0">
            <div class="message d-flex" v-if="user.kyc_ok">
              <div class="message-icon">
                <img src="@/assets/images/warning-icon.svg" alt="coinfuze" />
              </div>
              <div class="message-text text-accent flex-fill">
                {{ $t('personal-text-45') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import FileInput from '@/components/global/FileInput.vue'
export default {
  components: { FileInput },
  name: 'VerificationTab',
  data() {
    return {
      loading: false,
      docType: { type: 'Passport', label: this.$t('document') },
      docTypeMenu: false,
      file: null
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/getUser'
    }),
    docTypeList() {
      return [
        { type: 'Passport', label: this.$t('document') },
        { type: 'ID card', label: this.$t('document-1') }
      ]
    },
    localizedDocType() {
      return this.docType.type === 'Passport'
        ? this.$t('document')
        : this.$t('document-1')
    }
  },

  methods: {
    toggleDocTypeMenu() {
      this.docTypeMenu = !this.docTypeMenu
    },
    selectDocType(docType) {
      this.docType = docType
    },
    handleFileUpload(file) {
      this.file = file
    },
    uploadDocument() {
      let payload = {
        [this.docType.type]: this.file
      }
      if (!this.file) return
      this.loading = true
      this.$store
        .dispatch('user/documentUpload', payload)
        .then((response) => {
          this.$showSuccess(response.data.response)
          this.$refs.fileInput.clearFileHandler()
        })
        .catch((error) => this.$showError(error.detail))
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.cselect-wrapper {
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 0.714rem;
  color: var(--s-input-ph-color);
  background-color: var(--s-input-bg-color);
  border: 1px solid var(--s-card-border-color);
  color: var(--s-app-accent-color);
  padding: 1px 10px;
  min-height: 46px;
  &.active {
    .cselect-arrow {
      transform: rotate(180deg);
    }
    .cselect-list {
      overflow-y: auto;
      max-height: 210px;
      z-index: 9;
      display: flex;
      flex-direction: column;
      // gap: 5px;
    }
  }
}
.cselect-label {
  padding: 0.371rem;
}
.cselect-arrow {
  position: relative;
  display: flex;
  padding: 0.371rem;
  transition: transform ease-in-out 0.2s;
  -webkit-transition: transform ease-in-out 0.2s;
  path {
    stroke: var(--s-app-accent-color);
  }
}
.cselect-list {
  position: absolute;
  top: 50px;
  left: -10px;
  display: none;
  border: 1px solid var((--s-card-border-bg-color));
  background-color: var(--s-card-bg-color);
  color: var(--s-app-accent-color);
  border-radius: 1.214rem;
  padding: 10px 0px;
  width: auto;
}
.cselect-item {
  padding: 10px 25px;
}
.cselect-item:hover {
  background-color: var(--s-input-hover-bg-color);
}
</style>
