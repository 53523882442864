<template>
  <div class="app__panel-nav-icon d-flex-center">
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.64258 11.5942C1.58732 12.1218 1.125 12.7582 1.125 13.3125C1.125 13.8668 1.58732 14.5032 2.64258 15.0308C3.66793 15.5435 5.11942 15.875 6.75 15.875C8.38058 15.875 9.83207 15.5435 10.8574 15.0308C11.9127 14.5032 12.375 13.8668 12.375 13.3125C12.375 12.7582 11.9127 12.1218 10.8574 11.5942C9.83207 11.0815 8.38058 10.75 6.75 10.75C5.11942 10.75 3.66793 11.0815 2.64258 11.5942ZM2.19536 10.6998C3.38683 10.104 4.99784 9.75 6.75 9.75C8.50216 9.75 10.1132 10.104 11.3046 10.6998C12.4662 11.2806 13.375 12.1754 13.375 13.3125C13.375 14.4496 12.4662 15.3444 11.3046 15.9252C10.1132 16.521 8.50216 16.875 6.75 16.875C4.99784 16.875 3.38683 16.521 2.19536 15.9252C1.0338 15.3444 0.125 14.4496 0.125 13.3125C0.125 12.1754 1.0338 11.2806 2.19536 10.6998Z"
        fill="#57435F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.75 1.125C5.09315 1.125 3.75 2.46815 3.75 4.125C3.75 5.78185 5.09315 7.125 6.75 7.125C8.40685 7.125 9.75 5.78185 9.75 4.125C9.75 2.46815 8.40685 1.125 6.75 1.125ZM2.75 4.125C2.75 1.91586 4.54086 0.125 6.75 0.125C8.95914 0.125 10.75 1.91586 10.75 4.125C10.75 6.33414 8.95914 8.125 6.75 8.125C4.54086 8.125 2.75 6.33414 2.75 4.125Z"
        fill="#57435F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.0595 7.12353C10.8337 7.44732 10.5724 7.74447 10.2812 8.00934C10.5496 8.08439 10.8325 8.12452 11.1248 8.12452C12.8507 8.12452 14.2498 6.72541 14.2498 4.99952C14.2498 3.40247 13.0518 2.08523 11.5054 1.89746C11.6707 2.24982 11.798 2.62355 11.882 3.01338C12.6817 3.31845 13.2498 4.09264 13.2498 4.99952C13.2498 6.17312 12.2984 7.12452 11.1248 7.12452C11.103 7.12452 11.0812 7.12419 11.0595 7.12353Z"
        fill="#57435F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.6167 13.5345C14.5866 13.94 14.4743 14.3325 14.2899 14.7057C14.5525 14.6285 14.8 14.5416 15.0295 14.446C15.5407 14.233 15.987 13.9666 16.3132 13.6453C16.6412 13.3222 16.8749 12.9138 16.8749 12.4376C16.8749 11.9614 16.6412 11.553 16.3132 11.23C15.987 10.9086 15.5407 10.6423 15.0295 10.4293C14.2341 10.0979 13.2239 9.87169 12.1104 9.78711C12.7462 10.1483 13.2842 10.573 13.6974 11.0447C14.0464 11.1318 14.3645 11.2356 14.6449 11.3524C15.0838 11.5352 15.4063 11.7402 15.6114 11.9423C15.8148 12.1427 15.8749 12.3098 15.8749 12.4376C15.8749 12.5655 15.8148 12.7325 15.6114 12.9329C15.4063 13.135 15.0838 13.34 14.6449 13.5229C14.6355 13.5268 14.6261 13.5307 14.6167 13.5345Z"
        fill="#57435F"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'AffiliateProgramMenuIcon'
}
</script>
