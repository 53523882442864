<template>
  <div>
    <div class="modal-backdrop fade show" v-if="show" @click="closeModal"></div>
    <div
      class="modal fade"
      :class="{ show: show }"
      :style="{ display: show ? 'block' : 'none' }"
      id="modal-notiofications"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content card modal-card">
          <div class="modal-card-inner">
            <button
              class="btn-close modal-close"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="closeModal"
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.863757 0.860853C1.19357 0.531039 1.7283 0.531039 2.05812 0.860853L13.4046 12.2073C13.7344 12.5371 13.7344 13.0718 13.4046 13.4016V13.4016C13.0747 13.7315 12.54 13.7315 12.2102 13.4016L0.863757 2.05521C0.533944 1.7254 0.533943 1.19067 0.863757 0.860853V0.860853Z"
                  fill="#625B7A"
                />
                <path
                  d="M0.862806 13.4019C0.532992 13.0721 0.532992 12.5373 0.862806 12.2075L12.2092 0.861073C12.5391 0.531259 13.0738 0.531259 13.4036 0.861073V0.861073C13.7334 1.19089 13.7334 1.72562 13.4036 2.05543L2.05717 13.4019C1.72735 13.7317 1.19262 13.7317 0.862806 13.4019V13.4019Z"
                  fill="#625B7A"
                />
              </svg>
            </button>
            <div class="modal-card-header">{{ $t('text-65') }}</div>
            <div class="modal-card-content p-0">
              <div class="notiofications">
                <div class="notiofications-items customscroll">
                  <div v-show="notifications.length">
                    <notification-item
                      v-for="(item, idx) in notifications"
                      :key="`${idx}${item.id}all`"
                      :notification="item"
                    />
                    <p v-if="loading" class="loading">Loading...</p>
                    <div
                      ref="observerElement"
                      class="observer"
                      style="margin-bottom: 20px"
                      v-show="this.notifications.length !== this.count"
                    ></div>
                  </div>
                  <div
                    class="notiofications-empty"
                    v-if="!notifications.length"
                  >
                    {{ $t('non-message') }}
                  </div>
                </div>
                <div class="notiofications-footer">
                  <a
                    class="button__link notiofications-more"
                    style="cursor: pointer"
                    @click="readAllNotifications"
                  >
                    {{ $t('text-66') }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import NotificationItem from './NotificationItem.vue'
export default {
  name: 'NotificationModal',
  components: { NotificationItem },
  mixins: [],
  props: {
    show: {
      type: Boolean,
      require: true
    }
  },
  data() {
    return {
      loading: false,
      observer: null
    }
  },
  computed: {
    ...mapGetters({
      notifications: 'notification/getNotifications',
      count: 'notification/getNotificationCount',
      chunkLength: 'notification/getChunkLength'
    }),
    nextPage() {
      const fullPagesLoaded = Math.floor(
        this.notifications.length / this.chunkLength
      )
      const isLastPageFull = this.notifications.length % this.chunkLength === 0

      return !isLastPageFull || this.notifications.length < this.count
        ? fullPagesLoaded + 1
        : null
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && !this.loading) {
              this.fetchNotificationsChunk()
            }
          })
        },
        { threshold: 1.0 }
      )

      this.observeLastNotificationsItem()
    })
  },

  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect()
    }
  },
  methods: {
    observeLastNotificationsItem() {
      if (this.observer && this.$refs.observerElement) {
        this.observer.observe(this.$refs.observerElement)
      }
    },
    closeModal() {
      this.$emit('close-modal')
    },
    fetchNotificationsChunk() {
      this.loading = true
      if (this.nextPage) {
        this.$store
          .dispatch('notification/fetchNotificationChunk', this.nextPage)
          .catch((error) => this.$showError(error.detail))
          .finally(() => (this.loading = false))
      }
    },
    readAllNotifications() {
      let notioficationsIds = []
      this.notifications.map((n) => notioficationsIds.push(n.id))
      this.$store.dispatch('notification/readNotifications', {
        msg: notioficationsIds
      })
    }
    // readAllNotifications() {
    //   this.$store.dispatch('notification/readNotifications', {
    //     msg: 'all'
    //   })
    // }
  }
}
</script>
<style lang="scss" scoped>
.loading {
  text-align: center;
}
.observer {
  margin-top: 10px;
  padding: 10px;
}
</style>
