import Axios from 'axios'
import store from '@/store'
import router from '@/router'
import Vue from 'vue'

const options = {
  // getLocale: (state) => state.locale,
  baseURL: `${process.env.VUE_APP_API}api/`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data'
  }
}
const httpClient = Axios.create(options)
//interceptors.request
httpClient.interceptors.request.use((config) => {
  if (config.useFUrl) {
    config.baseURL = 'https://ca.coinfuze.com/'

    delete config.useFUrl
  }
  if (store.getters['app/getLocale']) {
    config.headers['Accept-Language'] = store.getters['app/getLocale']
  }
  if (store.getters['app/getAuthToken']) {
    config.headers['Token'] = store.getters['app/getAuthToken']
  }
  return config
})

//interceptors.response
httpClient.interceptors.response.use(
  (response) => {
    if (response.config.headers['authorization']) {
      store.commit(
        'app/SET_AUTH_TOKEN',
        response.config.headers['authorization']
      )
      return response
    } else return response
  },
  (error) => {
    if (error.code === 'ERR_NETWORK') {
      return Vue.swal({
        target: document.getElementById('main'),
        html: error.message,
        icon: 'error',
        timer: 7000,
        toast: true,
        modal: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Vue.swal.stopTimer)
          toast.addEventListener('mouseleave', Vue.swal.resumeTimer)
        },
        position: 'top-right',
        showConfirmButton: false
      })
    }
    console.log('error', error)
    const { data, status } = error.response
    if (status === 403) {
      let locales = store.getters['app/getLocalesList']
      const locale = router.currentRoute.query.locale
      let redirectPath = '/auth/login'
      if (locale && locales.includes(locale)) {
        redirectPath += `?locale=${locale}`
      }
      //session expiered
      store.commit('app/SET_LOGOUT')
      if (router.currentRoute.path !== '/auth/login') {
        localStorage.setItem('loginRedirect', router.currentRoute.fullPath)
        router.push(redirectPath)
      }
      return Promise.reject({ detail: 'auth' })
    }
    if (!data.detail) {
      return Vue.swal({
        target: document.getElementById('main'),
        html: 'Something Went Wrong',
        icon: 'error',
        timer: 7000,
        toast: true,
        modal: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Vue.swal.stopTimer)
          toast.addEventListener('mouseleave', Vue.swal.resumeTimer)
        },
        position: 'top-right',
        showConfirmButton: false
      })
    }

    Vue.swal({
      target: document.getElementById('main'),
      html: data.detail,
      icon: 'error',
      timer: 7000,
      toast: true,
      modal: false,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Vue.swal.stopTimer)
        toast.addEventListener('mouseleave', Vue.swal.resumeTimer)
      },
      position: 'top-right',
      showConfirmButton: false
    })
    return Promise.reject(data)
  }
)

const ApiClient = {
  get(url, conf = {}) {
    return httpClient.get(url, conf)
  },

  put(url, data = {}, conf = {}) {
    return httpClient.put(url, data, conf)
  },

  delete(url, conf = {}) {
    return httpClient.delete(url, conf)
  },

  post(url, data = {}, conf = {}) {
    return httpClient.post(url, data, conf)
  },

  patch(url, data = {}, conf = {}) {
    return httpClient.patch(url, data, conf)
  }
}

export default ApiClient
