<template>
  <div class="app__content-item mb-5">
    <div class="app__content-header">
      <h3 class="app__content-title">{{ $t('partnership-text-40') }}</h3>
    </div>
    <div class="app__content-filter d-flex flex-wrap">
      <div class="tabs flex-fill w-sm-100">
        <div class="tabs-buttons d-flex flex-wrap">
          <div
            v-for="(lvl, idx) in levels"
            :key="idx + lvl.label"
            class="tabs-button"
            :class="{ active: selectedLvl === lvl.value }"
            @click="selectActiveLvl(lvl.value)"
          >
            {{ lvl.label }}
          </div>
        </div>
      </div>
      <div class="tags d-flex flex-wrap w-sm-100">
        <div class="tags-item">
          {{ $t('partnership-text-39') }}
          <span class="text-blue fw-500"> {{ percent }} % </span>
        </div>
        <div class="tags-item">
          {{ $t('partnership-text-38') }}
          <span class="text-blue fw-500">
            {{ formatBalance(user.referal_comand_turnover) }} USDT
          </span>
        </div>
      </div>
    </div>
    <div class="card overflow-hidden">
      <div class="card-inner">
        <div class="plans">
          <div class="customscroll plan-row">
            <vue-good-table
              :columns="columns"
              :rows="formattedRows"
              :pagination-options="paginationOptions"
              styleClass="vgt-table condensed"
              style="min-width: 1120px"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'login'" class="wrapper">
                  <img
                    class="avatar"
                    :src="`https://ca.coinfuze.com/media/avatar/${props.row.avatar}`"
                    alt="ava"
                  />
                  {{ props.row.login }}
                </span>
                <span v-if="props.column.field == 'id'" class="wrapper">
                  {{ props.row.id }}
                </span>
                <span
                  v-if="props.column.field == 'deposit_bonus'"
                  class="wrapper"
                >
                  {{ props.row.deposit_bonus }}
                </span>
                <span
                  v-if="props.column.field == 'profit_bonus'"
                  class="wrapper"
                >
                  {{ props.row.profit_bonus }}
                </span>
                <span
                  v-if="props.column.field == 'referal_count'"
                  class="wrapper"
                >
                  {{ props.row.referal_count }}
                </span>
                <span
                  v-if="props.column.field == 'registration'"
                  class="wrapper"
                >
                  {{ props.row.registration }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ReferralsLvlTable',
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      selectedLvl: 'first_line'
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/getUser',
      referals: 'user/getReferals'
    }),
    columns() {
      return [
        {
          label: this.$t('table-1'),
          field: 'login'
        },
        {
          label: 'ID',
          field: 'id'
        },
        {
          label: this.$t('table-2'),
          field: 'deposit_bonus'
        },
        {
          label: this.$t('table-3'),
          field: 'profit_bonus'
        },
        {
          label: this.$t('table-4'),
          field: 'referal_count'
        },
        {
          label: this.$t('table-5'),
          field: 'registration'
        }
      ]
    },
    rows() {
      return this.referals[this.selectedLvl]
    },
    formattedRows() {
      return this.rows.map((row) => ({
        ...row,
        registration: this.formatDate(row.registration)
      }))
    },
    levels() {
      let lvls = [
        {
          label: `${this.$t('partnership-text-28')} 1`,
          value: 'first_line'
        },
        {
          label: `${this.$t('partnership-text-28')} 2`,
          value: 'second_line'
        },
        {
          label: `${this.$t('partnership-text-28')} 3`,
          value: 'third_line'
        }
      ]
      return lvls
    },
    paginationOptions() {
      return {
        enabled: true,
        perPageDropdownEnabled: false,
        mode: 'page',
        nextLabel: this.$t('table-next'),
        prevLabel: this.$t('table-prev')
      }
    },
    percent() {
      return this.referals[`${this.selectedLvl}_percent`][0].percent
    }
  },
  methods: {
    selectActiveLvl(lvl) {
      this.selectedLvl = lvl
    },
    formatBalance(balance) {
      return new Intl.NumberFormat(this.$i18n.locale).format(balance)
    },
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()
      return `${day}.${month}.${year}`
    }
  }
}
</script>
<style scoped lang="scss">
.wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  min-height: 35px;
}
.avatar {
  max-width: 35px;
  border-radius: 0.571rem;
}
</style>
