<template>
  <ul class="lang-submenu" v-show="isWindowOpen">
    <span
      v-for="(locale, idx) in locales"
      :key="`${idx}+${locale}-locale-window`"
      class="lang-submenu__link"
      :class="{ active: locale === currentLocale }"
      @click="setLocale(locale)"
      >{{ locale.toUpperCase() }}</span
    >
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'LangSelectWindow',
  props: {
    isWindowOpen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      currentLocale: 'app/getLocale',
      locales: 'app/getLocalesList'
    })
  },
  methods: {
    closeWindow() {
      this.$emit('close-window')
    },
    setLocale(locale) {
      this.$store
        .dispatch('app/setLocale', locale)
        .then(() => {
          this.$i18n.locale = locale
          document.documentElement.setAttribute('lang', locale)
          this.$store
            .dispatch('news/getLastNews')
            .catch((error) => this.$showError(error.detail))
          this.$store
            .dispatch('news/getAllNews')
            .catch((error) => this.$showError(error.detail))
          this.closeWindow()
        })
        .catch((error) => this.$showError(error.detail))
    }
  }
}
</script>
