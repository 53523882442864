<template>
  <div class="app-wrapper">
    <side-bar-menu
      :isSidebarOpen="isSidebarOpen"
      @close-sidebar="closeSidebar"
    ></side-bar-menu>

    <div class="app__inner flex-fill d-flex flex-column">
      <app-header
        :isSidebarOpen="isSidebarOpen"
        @toggle-sidebar="toggleSideBar"
        @close-sidebar="closeSidebar"
      ></app-header>
      <router-view v-slot="{ Component }">
        <component :is="Component"></component>
      </router-view>
      <app-footer />
    </div>

    <div
      class="app__overlay"
      :class="{ active: isSidebarOpen }"
      @click="closeSidebar"
    ></div>
    <cookies-modal v-if="!agreePolicy" />
  </div>
</template>

<script>
import AppHeader from '@/components/main/AppHeader.vue'
import SideBarMenu from '@/components/main/SideBarMenu.vue'
import AppFooter from '@/components/main/AppFooter.vue'
import CookiesModal from '@/components/global/CookiesModal.vue'
import vClickOutside from 'v-click-outside'
import SocketMixin from '@/mixins/SocketMixin.vue'

export default {
  components: { AppHeader, SideBarMenu, AppFooter, CookiesModal },
  name: 'DashboardView',
  mixins: [SocketMixin],
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      isSidebarOpen: false
    }
  },

  created() {
    this.getAllNotification()
  },
  computed: {
    agreePolicy() {
      return this.$store.getters['app/getAgreePrivacy']
    }
  },
  methods: {
    toggleSideBar() {
      this.isSidebarOpen = !this.isSidebarOpen
    },
    closeSidebar() {
      this.isSidebarOpen = false
    },
    handleAfterLeave(transitionName) {
      if (transitionName === 'fade') return
      this.transitionName =
        transitionName === 'slide-left' ? 'slide-right' : 'slide-left'
    },
    getAllStaking() {
      return this.$store
        .dispatch('user/getAllStaking')
        .catch((error) => this.$showError(error.detail))
    },
    getStakingData() {
      return this.$store
        .dispatch('user/getStakingData')
        .catch((error) => this.$showError(error.detail))
    },
    getAllNotification() {
      return this.$store
        .dispatch('notification/getAllNotifications')
        .catch((error) => this.$showError(error.detail))
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
