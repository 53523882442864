<template>
  <div
    class="notiofications-item"
    style="cursor: pointer"
    @click="readNotification"
  >
    <div class="notiofications-header">
      <div
        class="notiofications-title"
        :class="{ active: !notification.readed }"
        v-html="notification[`tittle_${this.$i18n.locale}`]"
      ></div>
      <div class="notiofications-date d-none d-sm-block">
        {{ formatDate(notification.create) }}
      </div>
    </div>
    <div
      class="notiofications-content"
      v-html="notification[`message_${this.$i18n.locale}`]"
    ></div>
    <div class="notiofications-date d-sm-none">
      {{ formatDate(notification.create) }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'NotificationItem',
  props: {
    notification: {
      require: true,
      type: Object
    }
  },
  methods: {
    readNotification() {
      this.$store.dispatch('notification/readNotifications', {
        msg: [this.notification.id]
      })
    },
    formatDate(dateStr) {
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }
      const date = new Date(dateStr)
      return date.toLocaleDateString(this.$i18n.locale, options)
    }
  }
}
</script>
