<template>
  <div class="card card_inside materials-item">
    <div class="card-inner p-16 d-flex materials-item-inner">
      <div class="materials-item-img">
        <!-- <img
          v-if="material.media_type === 'doc'"
          src="@/assets/images/materials-img-1.jpg"
          alt="coinfuze"
        />  -->
        <!-- <img
          v-if="material.media_type === 'doc'"
          src="@/assets/images/hero-img-m.png"
          alt="coinfuze"
          style="background: #0d0f13"
        /> -->
        <div
          v-if="material.media_type === 'doc'"
          class="doc-image-wrapper image-wrapper"
        ></div>
        <div
          v-else
          class="image-wrapper"
          :style="{
            background:
              'url(https://ca.coinfuze.com' +
              material.site_path +
              ') 0 0 / cover no-repeat'
          }"
        >
          <!-- <img
            class="img-p"
            :src="`https://ca.coinfuze.com${material.site_path}`"
            alt="coinfuze"
          /> -->
        </div>
      </div>
      <div class="materials-item-content d-flex flex-column flex-fill">
        <h3 class="h5 materials-item-title flex-fill">
          {{ material[`title_${locale}`] }}
        </h3>
        <!-- <div v-if="material.media_type === 'img'" class="code-wrapper">
          {{ htmlCode }}
        </div> -->
        <a
          v-if="material.media_type === 'doc'"
          class="button button_bordered materials-item-more"
          download="document.pdf"
          target="_blank"
          :href="`https://ca.coinfuze.com${material.site_path}`"
        >
          <span>{{ $t('download') }} </span>
        </a>
        <button
          v-else
          class="button button_bordered materials-item-more"
          @click="copy"
          style="padding: 0.714rem 0.87rem"
        >
          <span class="tooltip__content" :class="{ show: copyFlag }">
            {{ $t('copied') }}
          </span>
          <span>{{ $t('copy') }} &lt;HTML&gt;</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MaterialCard',
  components: {},
  mixins: [],
  props: {
    material: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      copyFlag: false
    }
  },
  computed: {
    ...mapGetters({
      locale: 'app/getLocale',
      user: 'user/getUser'
    }),
    htmlCode() {
      const promoUrl = `http://ca.coinfuze.com/auth/register?promo=${this.user.referal_link}`
      const imgUrl = `https://ca.coinfuze.com${this.material.site_path}`
      return `<a href="${promoUrl}" target="_blank"><img src="${imgUrl}" alt="promo"></a>`
    }
  },
  watch: {},
  mounted() {},
  methods: {
    flagClick() {
      this.copyFlag = true
      setTimeout(() => {
        this.copyFlag = false
      }, 1000)
    },
    copy() {
      const tempInput = document.createElement('input')
      tempInput.value = this.htmlCode
      document.body.appendChild(tempInput)
      tempInput.select()
      document.execCommand('copy')
      document.body.removeChild(tempInput)
      this.flagClick()
    }
  }
}
</script>
<style lang="css" scoped>
.tooltip__content.show {
  opacity: 1;
  transition: 1s;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.doc-image-wrapper {
  background-image: url(../../assets/images/hero-img-m.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  background-color: #1b2129;
}

.image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 90.75px;
  border-radius: calc(var(--s-base-val) / 2);
  overflow: hidden;
}
.img-p {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
.tooltip__content {
  padding-left: 50px;
  right: -20px;
  top: -30px;
  background: #1b2129;
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  color: #fff;
  display: inline-block;
  font-size: 12px;
  padding: 5px 8px;
  position: absolute;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  width: auto;
  opacity: 0;
}
</style>
