<template>
  <div class="tabs-content-item active" id="tab-item-2">
    <div class="card">
      <div class="card-inner">
        <div class="row gy-base">
          <div class="col-lg-6 d-flex flex-column align-items-start">
            <div class="flex-fill mb-3">
              <h3 class="card-title">{{ $t('withdrawal-text-19') }}</h3>
              <div class="stat_item">
                <div class="stat_item-title">
                  {{ $t('withdrawal-text-19-1') }}
                </div>
                <div
                  class="stat_item-val stat_item-val-balance text-blue fw-600"
                >
                  {{ balance.free }} USDT
                </div>
              </div>
            </div>
            <button class="button w-sm-100" @click="startWithdrawals">
              <span> {{ $t('withdrawal-text-19-2') }}</span>
            </button>
          </div>
          <div class="col-lg-6">
            <p>
              {{ $t('withdrawal-text-20') }}
            </p>
            <ul class="reset-list dot-list mt-4">
              <li>
                {{ $t('withdrawal-text-21') }}
                Tether USDT.
              </li>
              <li>
                {{ $t('withdrawal-text-22') }}
              </li>
              <li>
                {{ $t('withdrawal-text-23') }}
              </li>
            </ul>
            <ul class="reset-list links-list mt-4">
              <li>
                <span class="btn-link" @click="openWithdrawalsTutorialModal">
                  {{ $t('withdrawal-text-24-1') }}
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.833 7L1.16634 7M12.833 7L7.83301 12M12.833 7L7.83301 2"
                      stroke="#37BBC6"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </li>
              <li>
                <a
                  target="_blank"
                  :href="`https://faq.coinfuze.com/${locale}/withdrawal-questions/how-to-make-a-withdrawal`"
                >
                  {{ $t('withdrawal-text-24-2') }}
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.833 7L1.16634 7M12.833 7L7.83301 12M12.833 7L7.83301 2"
                      stroke="#37BBC6"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <withdrawals-tutorial-modal
      v-if="showTutorialModal"
      @close-modal="closeWithdrawalsTutorialModal"
      :show="showTutorialModal"
    />
    <email-verification-modal
      v-if="showEmailModal"
      @close-modal="closeEmailModal"
      :show="showEmailModal"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import EmailVerificationModal from './EmailVerificationModal.vue'
import WithdrawalsTutorialModal from './WithdrawalsTutorialModal.vue'
export default {
  components: { WithdrawalsTutorialModal, EmailVerificationModal },
  name: 'WithdrawalsBlock',
  data() {
    return {
      showTutorialModal: false,
      showEmailModal: false
    }
  },
  computed: {
    ...mapGetters({
      balance: 'user/getBalance',
      emailVerified: 'user/getEmailVerify',
      user: 'user/getUser',
      locale: 'app/getLocale'
    })
  },
  methods: {
    openWithdrawalsTutorialModal() {
      this.showTutorialModal = true
    },
    closeWithdrawalsTutorialModal() {
      this.showTutorialModal = false
    },
    openEmailModal() {
      this.showEmailModal = true
    },
    closeEmailModal() {
      this.showEmailModal = false
    },
    startWithdrawals() {
      this.emailVerified
        ? this.$emit('start-withdrawals')
        : this.openEmailModal()
    }
  }
}
</script>
<style lang="scss" scoped>
.btn-link {
  color: var(--s-blue-color);
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}
</style>
