import { render, staticRenderFns } from "./MaterialCard.vue?vue&type=template&id=4ea58560&scoped=true"
import script from "./MaterialCard.vue?vue&type=script&lang=js"
export * from "./MaterialCard.vue?vue&type=script&lang=js"
import style0 from "./MaterialCard.vue?vue&type=style&index=0&id=4ea58560&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ea58560",
  null
  
)

export default component.exports