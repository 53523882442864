<template>
  <div>
    <div class="app__start-title text-center text-accent fw-600">
      {{ $t('password-reset') }}
    </div>
    <!-- <p>{{ $t('password-reset-2') }}</p> -->
    <div class="input-group pb-2">
      <button class="button w-100" @click="ok">
        <span> {{ $t('ok') }}</span>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PasswordResetConfirm',
  data() {
    return {
      loading: false
    }
  },

  created() {
    this.confirm()
  },
  methods: {
    ok() {
      this.$router.push('/auth')
    },
    confirm() {
      this.loading = true

      this.$store
        .dispatch('user/resetPassConfirm', this.$route.query)
        .then((response) => this.$showSuccess(response.data.response))
        .catch((error) => this.$showError(error.detail))
        .finally(() => (this.loading = false))
    }
  }
}
</script>
