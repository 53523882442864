<template>
  <div class="app__content-item mb-5">
    <div class="app__content-header">
      <h3 class="app__content-title">{{ $t('partnership-text-41') }}</h3>
    </div>
    <!-- <loading-page v-if="loading" /> -->
    <p v-if="loading">loading...</p>
    <div class="card" v-else>
      <div class="card-inner">
        <div class="staking flex-fill w-100 mb-5">
          <div class="staking-progress">
            <div class="staking-progress-inner text-center">
              <div class="staking-progress-lvl">
                <div class="staking-progress-lvl-info">
                  {{ $t('partnership-text-38') }}
                </div>
                <div
                  class="staking-progress-lvl-val text-blue fw-600 d-flex align-items-center justify-content-center"
                >
                  {{ formatBalance(user.referal_comand_turnover) }} USDT
                  <div class="stat_item-icon">
                    <img src="@/assets/images/usdt.svg" alt="coinfuze" />
                  </div>
                </div>
              </div>
              <div
                class="staking-progress-fill"
                :style="{ width: `${turnoverProgress.percent}%` }"
              ></div>
            </div>
            <div
              class="staking-progress-footer d-flex justify-content-between align-items-center text-accent"
            >
              <span>{{ formatBalance(turnoverProgress.from) }} USDT</span>
              <span>{{ formatBalance(turnoverProgress.to) }} USDT</span>
            </div>
          </div>
        </div>
        <div class="levels">
          <div class="row levels-row flex-wrap">
            <level-card
              v-for="(level, idx) in enrichedBonusLevels"
              :key="idx + level.name"
              :level="level"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import LevelCard from './LevelCard.vue'
// import LoadingPage from '../global/preloaders/LoadingPage.vue'
export default {
  name: 'TeamBonuses',
  components: {
    LevelCard
    // LoadingPage
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/getUser',
      bonusLevels: 'user/getBonusLevels'
    }),
    turnoverProgress() {
      const { referal_bonus_level, referal_comand_turnover } = this.user

      // let referal_bonus_level = 10
      let from = 0
      let to = 0

      let maxCT = this.bonusLevels.reduce((max, lvl) =>
        lvl.comand_turnover > max ? lvl.comand_turnover : max
      )

      this.bonusLevels.forEach((level) => {
        if (level.sort_id === referal_bonus_level + 1) {
          to = level.comand_turnover
        } else if (level.sort_id === referal_bonus_level) {
          from = level.comand_turnover
        } else if (referal_bonus_level === this.bonusLevels.length)
          to = maxCT.comand_turnover
      })

      if (referal_bonus_level === 0) {
        const firstLevel = this.bonusLevels.find((level) => level.sort_id === 1)
        to = firstLevel ? firstLevel.comand_turnover : 0
      }

      let percent = 0
      if (to !== 0) {
        percent = Math.min(
          100,
          Math.max(0, ((referal_comand_turnover - from) / (to - from)) * 100)
        )
      }

      return { from, to, percent }
    },
    enrichedBonusLevels() {
      const { referal_bonus_level } = this.user

      // Определяем sort_id для последних трех элементов
      const maxSortId = this.bonusLevels.length // Максимальный sort_id, предполагая, что они начинаются с 1 и идут последовательно
      const lastThreeSortIds = [maxSortId - 2, maxSortId - 1, maxSortId] // Последние три sort_id

      return this.bonusLevels
        .map((level) => {
          let crown = lastThreeSortIds.includes(level.sort_id) // crown true для последних трех элементов
          let status

          // Определение статуса
          if (referal_bonus_level > level.sort_id) {
            status = 1
          } else if (referal_bonus_level === level.sort_id) {
            status = 2
          } else {
            status = 3

            if (level.sort_id === lastThreeSortIds[2]) {
              status = 6
            } else if (level.sort_id === lastThreeSortIds[1]) {
              status = 5
            } else if (level.sort_id === lastThreeSortIds[0]) {
              status = 4
            }
          }

          return { ...level, crown, status }
        })
        .reverse()
    }
  },
  created() {
    this.getBonusLevels()
  },
  methods: {
    getBonusLevels() {
      this.loading = true
      this.$store
        .dispatch('user/getBonusLevels')
        .catch((error) => this.$showError(error.detail))
        .finally(() => (this.loading = false))
    },
    formatBalance(balance) {
      return new Intl.NumberFormat(this.$i18n.locale).format(balance)
    }
  }
}
</script>
