<template>
  <div class="dropdown__content dropdown__content_size_large card">
    <div class="card-inner p-0">
      <div class="dropdown__content-item custom">
        <div class="dropdown__content-line d-flex">
          <div class="dropdown__content-info">{{ $t('profile-window-1') }}</div>
          <div class="dropdown__content-val">{{ user.login }}</div>
        </div>
        <div class="dropdown__content-line d-flex">
          <div class="dropdown__content-info">ID:</div>
          <div class="dropdown__content-val">{{ user.id }}</div>
        </div>
        <div class="dropdown__content-line d-flex">
          <div class="dropdown__content-info">{{ $t('profile-window-2') }}</div>
          <div class="dropdown__content-val">{{ user.phone }}</div>
        </div>
        <div class="dropdown__content-line d-flex">
          <div class="dropdown__content-info">Email:</div>
          <div class="dropdown__content-val">{{ user.email }}</div>
        </div>
      </div>

      <div
        class="dropdown__content-item custom"
        v-click-outside="() => (this.isSelectLangWindowOpened = false)"
      >
        <div class="dropdown__content-line d-flex" style="position: relative">
          <div class="dropdown__content-info">{{ $t('profile-window-3') }}</div>
          <div class="dropdown__content-val">
            <span style="cursor: pointer" @click="toggleLangWindow">{{
              $i18n.locale.toUpperCase()
            }}</span>
          </div>
          <lang-select-window
            v-if="isSelectLangWindowOpened"
            v-click-outside="closeLangWindow"
            :isWindowOpen="isSelectLangWindowOpened"
            @close-window="closeLangWindow"
          />
        </div>
      </div>
      <div class="dropdown__content-item custom">
        <div class="dropdown__content-line d-flex">
          <div class="dropdown__content-info">{{ $t('profile-window-4') }}</div>
          <div class="dropdown__content-val">
            <label class="input-switcher input-switcher-theme">
              <input
                class="theme-switcher"
                type="checkbox"
                v-model="isDarkTheme"
              /><span></span>
            </label>
          </div>
        </div>
      </div>
      <div class="dropdown__content-item custom">
        <div class="dropdown__content-line d-flex">
          <div class="dropdown__content-info">{{ $t('profile-window-5') }}</div>
          <div class="dropdown__content-val">
            <a style="cursor: pointer" @click="toPartnership">
              {{ $t('header-item-6') }}</a
            >
          </div>
        </div>
      </div>
      <div class="dropdown__content-item">
        <a class="button__link" style="cursor: pointer" @click="logoutUser"
          ><svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.16699 7L12.8337 7M1.16699 7L6.16699 12M1.16699 7L6.16699 2"
              stroke="#625B7A"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          {{ $t('header-item-7') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import LangSelectWindow from './LangSelectWindow.vue'
import vClickOutside from 'v-click-outside'
export default {
  components: { LangSelectWindow },
  name: 'ProfileWindow',
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    initials: {
      type: String,
      default: ' '
    },
    user: {
      type: Object,
      default: () => {
        return { id: null, login: null, phone: null, email: null }
      }
    },
    freeBalance: {
      type: String,
      default: '0.00 USDT'
    }
  },
  data() {
    return {
      isSelectLangWindowOpened: false
    }
  },
  computed: {
    isDarkTheme: {
      get() {
        return this.$store.getters['app/getAppTheme'] === 'dark'
      },
      set(value) {
        this.$store.commit('app/SET_THEME', value ? 'dark' : 'light')
        this.setTheme(value ? 'dark' : 'light')
      }
    }
  },
  watch: {
    isDarkTheme(newValue) {
      this.setTheme(newValue ? 'dark' : 'light')
    }
  },
  methods: {
    setTheme(theme) {
      const body = document.querySelector('body')
      body.setAttribute('data-theme', theme)
    },
    closeWindow() {
      this.$emit('close-window')
    },
    logoutUser() {
      this.$store.dispatch('app/logout').then(() => this.$router.push('/auth'))
    },
    toggleLangWindow() {
      this.isSelectLangWindowOpened = !this.isSelectLangWindowOpened
    },
    toPartnership() {
      this.$router.push({ name: 'Partnership' })
      this.closeWindow()
    },
    closeLangWindow() {
      this.isSelectLangWindowOpened = false
    }
  }
}
</script>
