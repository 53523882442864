<template>
  <div
    class="modal fade"
    :class="{ show: show }"
    :style="{ display: show ? 'block' : 'none' }"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content card modal-card" v-click-outside="closeModal">
        <div class="modal-card-inner">
          <button
            class="btn-close modal-close"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.863757 0.860853C1.19357 0.531039 1.7283 0.531039 2.05812 0.860853L13.4046 12.2073C13.7344 12.5371 13.7344 13.0718 13.4046 13.4016V13.4016C13.0747 13.7315 12.54 13.7315 12.2102 13.4016L0.863757 2.05521C0.533944 1.7254 0.533943 1.19067 0.863757 0.860853V0.860853Z"
                fill="#625B7A"
              />
              <path
                d="M0.862806 13.4019C0.532992 13.0721 0.532992 12.5373 0.862806 12.2075L12.2092 0.861073C12.5391 0.531259 13.0738 0.531259 13.4036 0.861073V0.861073C13.7334 1.19089 13.7334 1.72562 13.4036 2.05543L2.05717 13.4019C1.72735 13.7317 1.19262 13.7317 0.862806 13.4019V13.4019Z"
                fill="#625B7A"
              />
            </svg>
          </button>
          <div class="modal-card-header">{{ $t('update-avatar') }}</div>
          <div class="modal-card-content">
            <p v-if="loading">Loading ...</p>
            <div v-else>
              <file-input
                @file-change="croppie"
                @file-clear="clear"
                ref="fileInput"
                :type="'img'"
              />
              <vue-croppie
                :enableResize="false"
                style="margin-top: 10px"
                ref="croppieRef"
                :enableOrientation="true"
                :boundary="{ width: 290, height: 290 }"
                :viewport="{ width: 285, height: 285, type: 'square' }"
              >
              </vue-croppie>
              <!-- the result -->
              <!-- <img :src="cropped" /> -->
              <button class="button" @click="crop">
                <span>{{ $t('save') }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vClickOutside from 'v-click-outside'
import { mapGetters } from 'vuex'

import FileInput from '@/components/global/FileInput.vue'
export default {
  name: 'CropModal',
  components: { FileInput },
  mixins: [],
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    show: {
      type: Boolean,
      require: true
    }
  },
  data() {
    return {
      loading: false,
      croppieImage: '',
      cropped: null
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/getUser'
    })
  },
  watch: {},
  mounted() {},
  methods: {
    closeModal() {
      this.$emit('close-modal')
    },
    clear() {
      this.croppieImage = ''
      this.cropped = null
      this.$refs.croppieRef.refresh()
    },
    croppie(e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) return

      var reader = new FileReader()
      reader.onload = (e) => {
        this.$refs.croppieRef.bind({
          url: e.target.result
        })
      }

      reader.readAsDataURL(files[0])
    },
    crop() {
      let options = {
        // type: 'blob',
        type: 'base64',
        size: { width: 285, height: 285 },
        format: 'jpeg'
      }
      this.$refs.croppieRef.result(options, (output) => {
        this.cropped = this.croppieImage = output
        this.uploadAvatar()
      })
    },
    uploadAvatar() {
      this.loading = true
      let payload = {
        ID: this.user.id,
        file: this.cropped
      }
      this.$store
        .dispatch('user/avatarUpload', payload)
        .then(() => {
          // console.log(response)
          this.closeModal()
        })
        .catch((error) => this.$showError(error.detail))
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style lang="" scoped></style>
