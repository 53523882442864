<template>
  <div class="col-lg-6">
    <div class="card h-100">
      <p v-if="loading">Loading ...</p>
      <div v-else class="card-inner d-flex flex-column align-items-start">
        <div class="staking flex-fill w-100 mb-4">
          <div class="staking-header d-flex flex-wrap">
            <h3 class="staking-title flex-fill m-0">
              <span class="text-blue">DailyFuze</span> {{ $t('staking-2') }}
            </h3>
            <div
              class="staking-status status-label rejected"
              v-if="!Object.keys(userStakingData).length"
            >
              {{ $t('staking-19') }}
            </div>
            <div v-else class="staking-status status-label approved">
              {{ $t('staking-18') }}
            </div>
          </div>
          <div class="staking-progress">
            <div class="staking-progress-inner text-center">
              <div class="staking-progress-lvl">
                <div class="staking-progress-lvl-info">
                  {{ $t('staking-17') }}
                </div>
                <div class="staking-progress-lvl-val text-blue fw-600">
                  {{
                    Object.keys(this.userStakingData).length
                      ? userStakingData.level
                      : 1
                  }}
                </div>
              </div>
              <div
                class="staking-progress-fill"
                :style="{ width: `${stakingStats.percent}%` }"
              ></div>
            </div>
            <div
              class="staking-progress-footer d-flex justify-content-between align-items-center text-accent"
            >
              <span>{{ stakingStats.from }} USDT</span>
              <span>{{ stakingStats.to }} USDT</span>
            </div>
          </div>
          <div class="row gy-base">
            <div class="col-md-6">
              <div class="card card_inside staking-item p-0 border-0">
                <div class="card-inner p-16">
                  <div class="stat_item-title">{{ $t('staking-6') }}</div>
                  <div class="stat_item-val stat_item-val-large">
                    {{ devident_summ }} USDT
                    <div class="stat_item-icon">
                      <img src="@/assets/images/usdt.svg" alt="coinfuze" />
                    </div>
                  </div>
                  <div class="stat_item-footer d-flex">
                    <div class="stat_item-footer-item">
                      <p>{{ $t('staking-7') }}</p>

                      <p class="text-accent">{{ lastTr }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card card_inside staking-item p-0 border-0">
                <div class="card-inner p-16">
                  <div class="stat_item-title">{{ $t('staking-8') }}</div>
                  <div class="stat_item-val stat_item-val-large">
                    {{
                      Object.keys(userStakingData).length
                        ? userStakingData.turnover.toFixed(2)
                        : 0
                    }}
                    USDT
                    <div class="stat_item-icon">
                      <img src="@/assets/images/usdt.svg" alt="coinfuze" />
                    </div>
                  </div>
                  <div class="stat_item-footer d-flex">
                    <div class="stat_item-footer-item">
                      <p>{{ $t('staking-10') }}</p>
                      <p class="text-accent">
                        {{ latestStartTime }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <router-link class="button button_bordered w-sm-100" to="staking">
          <span v-if="!Object.keys(userStakingData).length">
            {{ $t('staking-4-1') }}</span
          >
          <span v-else> {{ $t('staking-4') }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'StakingBlock',
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      loading: false
    }
  },
  mounted() {
    console.log(this.stakingData)
  },
  computed: {
    ...mapGetters({
      stakingData: 'user/getStakingData',
      userStakingData: 'user/getUserStakingData',
      lastTransaction: 'user/getLastStakingTR',
      devident_summ: 'user/getDevidentSumm'
    }),
    stakingStats() {
      let defaultObj = {
        from: 0,
        to: 1000,
        percent: 0
      }
      if (!Object.keys(this.userStakingData).length) return defaultObj
      if (!this.stakingData.length) return defaultObj
      let targetLvl = this.stakingData[0].dayplanslevels_set.find(
        (item) => item.sort_id === this.userStakingData.level
      )
      let percentage =
        (this.userStakingData.turnover / targetLvl.money_turnover_to) * 100
      return {
        from: this.formatNum(targetLvl.money_turnover),
        to: this.formatNum(targetLvl.money_turnover_to),
        percent: Math.round(percentage)
      }
    },
    latestStartTime() {
      if (!Object.keys(this.userStakingData).length) return '-'
      const latest = this.userStakingData.clientdayplans_set.reduce(
        (latestDate, currentPlan) => {
          const currentDate = new Date(currentPlan.start_time)
          return latestDate > currentDate ? latestDate : currentDate
        },
        new Date(0)
      )
      return this.formatDate(latest.toISOString())
    },
    lastTr() {
      return this.lastTransaction.date_create === '-'
        ? '-'
        : this.formatDate(this.lastTransaction.date_create)
    }
  },
  methods: {
    formatNum(num) {
      return new Intl.NumberFormat(this.$i18n.locale).format(num)
    },
    formatDate(dateString) {
      if (!dateString) return '-'
      const date = new Date(dateString)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()
      return `${day}.${month}.${year}`
    }
  }
}
</script>
