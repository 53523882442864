<template>
  <div class="app__content-item mb-5">
    <div class="app__content-header">
      <h3 class="app__content-title">Staking</h3>
    </div>
    <div class="card overflow-hidden">
      <div class="card-inner">
        <div class="plans">
          <div class="customscroll plan-row">
            <vue-good-table
              :columns="columns"
              :rows="formattedRows"
              :pagination-options="paginationOptions"
              :sort-options="{
                enabled: true,
                initialSortBy: { field: 'start_time', type: 'desc' }
              }"
              styleClass="vgt-table condensed "
              style="min-width: 1120px"
            >
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StakingTable',
  props: {
    rows: {
      type: Array,
      require: true
    }
  },

  computed: {
    columns() {
      return [
        {
          label: 'ID',
          field: 'id'
        },
        {
          label: this.$t('staking-table-2'),
          field: 'start_time'
        },
        {
          label: this.$t('staking-table-3'),
          field: 'end_time'
        },
        {
          label: this.$t('staking-table-4'),
          field: 'pay_summ'
        },
        {
          label: this.$t('staking-table-6'),
          field: 'profit'
        },
        {
          label: this.$t('staking-table-9'),
          field: 'status'
        }
      ]
    },
    formattedRows() {
      return this.rows?.map((row) => ({
        ...row,
        start_time: this.formatDate(row.start_time),
        end_time: this.formatDate(row.end_time),
        pay_summ: row.pay_summ.toFixed(2) + ' USDT',
        status: row.status ? this.$t('staking-18') : this.$t('staking-20')
      }))
    },
    paginationOptions() {
      return {
        enabled: true,
        perPageDropdownEnabled: false,
        mode: 'page',
        nextLabel: this.$t('table-next'),
        prevLabel: this.$t('table-prev')
      }
    }
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()
      return `${day}.${month}.${year}`
    }
  }
}
</script>
