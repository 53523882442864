<template>
  <div class="app app__start d-flex flex-column align-items-center">
    <div class="app__start-header">
      <div class="app__start-logo">
        <a href="https://coinfuze.com/">
          <img
            class="dark-theme-only"
            src="@/assets/images/logo-white.svg"
            alt="logo"
          />
          <img
            class="light-theme-only"
            src="@/assets/images/logo-black.svg"
            alt="logo"
          />
        </a>
      </div>
    </div>
    <div class="app__start-content">
      <div class="app__start-title text-center text-accent fw-600">
        Loading....
        <!-- <button @click="verifyEmail">verify</button> -->
      </div>
    </div>
    <div class="app__start-footer fw-300 text-center">
      &copy; 2024 CoinFuze. All rights reserved.
    </div>
  </div>
</template>
<script>
export default {
  name: 'EmailActivationPage',
  data() {
    return {
      loading: false
    }
  },
  created() {
    this.verifyEmail()
  },
  methods: {
    verifyEmail() {
      this.loading = true
      let payload = {
        verify: this.$route.query.verify,
        clid: this.$route.query.clid
      }
      this.$store
        .dispatch('user/verifyEmail', payload)
        .then((response) => {
          this.$showSuccess(response.data.response)
          this.$store.commit('user/EMAIL_VERIFY', true)
          this.$router.push({ name: 'Banking' })
        })
        .catch((error) => this.$showError(error.detail))
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style lang="" scoped></style>
