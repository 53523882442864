<template>
  <div class="app__content flex-fill">
    <div class="app__content-item d-xxl-none">
      <router-link class="button__link" :to="{ name: 'News' }"
        ><svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.16699 7L12.8337 7M1.16699 7L6.16699 12M1.16699 7L6.16699 2"
            stroke="#625B7A"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        {{ $t('back-to-news') }}
      </router-link>
    </div>
    <div class="app__content-item">
      <div class="row gy-base">
        <div class="col-lg-8">
          <news-card :news="newsContent" :preview="false" />
        </div>
        <div class="col-lg-4">
          <div class="row gy-base">
            <div
              class="col-12"
              v-for="(news, idx) in newsList"
              :key="idx + news.slug"
            >
              <news-card :news="news" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NewsCard from './NewsCard.vue'

export default {
  name: 'NewsComponent',
  components: { NewsCard },
  data() {
    return {}
  },
  computed: {
    newsList() {
      return this.$store.getters['news/getNewsShortList'](
        this.$route.params.slug
      )
    },
    newsContent() {
      return this.$store.getters['news/getNewsBySlug'](this.$route.params.slug)
    }
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()
      return `${day}.${month}.${year}`
    }
  }
}
</script>
