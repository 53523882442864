<template>
  <div class="app__content-item mb-5">
    <div class="app__content-header">
      <div class="row gy-base w-100">
        <div class="col-lg-3">
          <h3 class="app__content-title h3">{{ $t('partnership-text-18') }}</h3>
        </div>
        <div class="col-lg-9">
          <p class="app__content-desc w-100 mw-100">
            {{ $t('partnership-text-19') }}
          </p>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-inner">
        <div class="plans">
          <input
            type="text"
            class="input-control"
            :placeholder="$t('partnership-text-26')"
            v-model="searchTerm"
          />

          <div class="customscroll plan-row">
            <vue-good-table
              v-if="searchTerm"
              :columns="columns"
              :rows="formattedRows"
              :pagination-options="paginationOptions"
              :search-options="searchOptions"
              styleClass="vgt-table condensed"
              style="min-width: 1120px"
            >
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ReferralsSearchTable',
  data() {
    return {
      searchTerm: ''
    }
  },
  computed: {
    ...mapGetters({
      referals: 'user/getReferals'
    }),
    columns() {
      return [
        {
          label: 'ID',
          field: 'id'
        },
        {
          label: this.$t('table-1'),
          field: 'login'
        },
        {
          label: this.$t('table-2'),
          field: 'deposit_bonus'
        },
        {
          label: this.$t('table-3'),
          field: 'profit_bonus'
        },
        {
          label: this.$t('table-4'),
          field: 'referal_count'
        },
        {
          label: this.$t('table-5'),
          field: 'registration'
        }
      ]
    },
    paginationOptions() {
      return {
        enabled: true,
        perPageDropdownEnabled: false,
        mode: 'page',
        nextLabel: this.$t('table-next'),
        prevLabel: this.$t('table-prev')
      }
    },
    searchOptions() {
      return {
        enabled: true,
        externalQuery: this.searchTerm
      }
    },
    rows() {
      let allReferrals = [
        ...this.referals.first_line,
        ...this.referals.second_line,
        ...this.referals.third_line
      ]
      return allReferrals
    },
    formattedRows() {
      return this.rows.map((row) => ({
        ...row,
        registration: this.formatDate(row.registration)
      }))
    }
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()
      return `${day}.${month}.${year}`
    }
  }
}
</script>
