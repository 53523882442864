<template>
  <div class="col-md-3 app__content-col">
    <div class="card card_inside p-0 bg-transparent">
      <div class="card-inner">
        <div class="chart">
          <div class="chart_header">
            <h3 class="chart_header-title">{{ $t('plans-text-24') }}</h3>
            <div class="chart_header-period">
              {{ $t('dashboard-text-16') }}:
              <span class="chart_header-period-val">{{ period }}</span>
            </div>
          </div>

          <div class="chart_inner flex-fill d-flex-center">
            <apexchart
              :options="chartOptions"
              :series="[daysPassed, totalDays - daysPassed]"
            ></apexchart>
          </div>
          <div class="chart_footer">
            <div class="chart_meta">
              <div
                class="chart_meta-dot"
                style="background-color: #51a6fe"
              ></div>
              <div class="chart_meta-title">{{ $t('progressLabel') }}</div>
              <div class="chart_meta-val">
                {{ daysPassed }} {{ $t('dashboard-text-17') }}
              </div>
            </div>
            <div class="chart_meta">
              <div
                class="chart_meta-dot"
                style="background-color: #f86525"
              ></div>
              <div class="chart_meta-title">{{ $t('daysLabel') }}</div>
              <div class="chart_meta-val">
                {{ totalDays - daysPassed }} {{ $t('dashboard-text-17') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { mapGetters } from 'vuex'
export default {
  components: {
    apexchart: VueApexCharts
  },
  props: {
    totalDays: {
      type: Number,
      default: 0,
      required: true
    },
    daysPassed: {
      type: Number,
      default: 0,
      required: true
    },
    period: {
      type: String,
      default: '-',
      required: true
    }
  },

  computed: {
    ...mapGetters({
      isDarkTheme: 'app/getAppTheme'
    }),
    chartOptions() {
      return {
        chart: {
          width: '100%',
          type: 'donut',
          // toolbar: false,
          fontFamily: 'Onest, Arial, Helvetica, sans-serif',
          parentHeightOffset: 0,
          sparkline: {
            enabled: true
          }
        },

        dataLabels: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        legend: {
          show: false
        },
        stroke: {
          width: 5,
          colors: this.isDarkTheme === 'dark' ? ['#12171D'] : ['#F4F5F9'],
          lineCap: 'round'
        },
        colors: ['#51A6FE', '#F86525'],
        plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              labels: {
                show: true,
                name: {
                  // offsetY: 5
                },
                value: {
                  offsetY: 0,
                  color: this.isDarkTheme === 'dark' ? '#ffffff' : '#070707',
                  show: true,
                  label: '10'
                },
                total: {
                  show: true,
                  showAlways: true,
                  // fontSize: '18px',
                  // fontFamily: 'Onest, Arial, Helvetica, sans-serif',
                  // fontWeight: 600,
                  label: ' '
                }
              }
            }
          }
        }
      }
    }
  }
}
</script>
