<template>
  <div class="app__content-header d-xxl-none">
    <div class="app__content-title h1 text-accent fw-600">
      {{ $t($route.meta.title) }}
    </div>
  </div>
</template>
<script>
export default { name: 'PageTitle' }
</script>
